import Vue from "vue";

export default {
    getClients: async () => {
        const response = await Vue.axios.get(`vacationDebts/clients`);
        return response.data;
    },
    getEmployees: async clientId => {
        const response = await Vue.axios.get(`vacationDebts/clients/${clientId}/employees`);
        return response.data;
    },
    getEmployeeDebts: async (clientId, individualClientId) => {
        const response = await Vue.axios.get(`vacationDebts/clients/${clientId}/employees/${individualClientId}`);
        return response.data;
    },
};
