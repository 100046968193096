<template>
    <div class="page" :style="getStyle()">
        <slot />
    </div>
</template>
<script>
import MixinLayout from "../../mixin-folder/layout.js";
export default {
    mixins: [MixinLayout],
    props: {
        maxWidth: {
            type: Number,
            default: 750,
        },
        paddingX: {
            type: [Array, String, Number],
            default() {
                return [0, 0];
            },
        },
        paddingY: {
            type: [Array, String, Number],
            default() {
                return ["large", "xlarge"];
            },
        },
    },
    methods: {
        getStyle() {
            const paddingX = this.getValues(this.paddingX);
            const paddingY = this.getValues(this.paddingY);
            // console.log("bp", paddingY);
            return {
                "--mobile-padding-x": paddingX.mobile + "px",
                "--mobile-padding-y": paddingY.mobile + "px",
                "--desktop-padding-x": paddingX.desktop + "px",
                "--desktop-padding-y": paddingY.desktop + "px",
                "--max-width": this.maxWidth + "px",
            };
        },
    },
};
</script>
<style lang="postcss" scoped>
.page {
    max-width: var(--max-width);
}
.desktop,
.laptop {
    .page {
        padding: var(--desktop-padding-y) var(--desktop-padding-x);
    }
}
.mobile,
.tablet {
    .page {
        padding: var(--mobile-padding-y) var(--mobile-padding-x);
    }
}
</style>
