<template>
    <div class="card-tail flex space-x-4" v-loading="$waiting.is('loading')" v-if="formData">
        <el-form ref="ruleForm" class="w-1/2" :model="formData" label-position="left" label-width="230px" :rules="rules">
            <el-form-item label="Klient">
                <el-input disabled v-model="formData.clientName" />
            </el-form-item>
            <el-form-item label="Leverantör">
                <el-input disabled v-model="formData.creditorName" />
            </el-form-item>
            <el-form-item label="BG/PG/Kontonummer/IBAN">
                <el-input disabled v-model="formData.accountNumber" />
            </el-form-item>
            <el-form-item label="Betalnings typ">
                <el-input disabled v-model="formData.paymentType" />
            </el-form-item>
            <el-form-item label="BIC" v-if="!isSekInvoice">
                <el-input disabled v-model="formData.bic" />
            </el-form-item>
            <el-form-item label="Skatterättslig hemvist" v-if="!isSekInvoice">
                <el-input disabled v-model="formData.residenceForTax" />
            </el-form-item>
            <el-form-item label="Address" v-if="!isSekInvoice">
                <el-input disabled v-model="formData.supplierAddress" />
            </el-form-item>
            <el-form-item label="Faturadatum">
                <el-input disabled v-model="formData.invoiceDate" />
            </el-form-item>
            <el-form-item label="Förfallodatum">
                <el-input disabled v-model="formData.dueDate" />
            </el-form-item>
            <el-form-item label="Ocr/Meddelande">
                <el-input disabled v-model="formData.ocr" />
            </el-form-item>
            <el-form-item label="Faturanummer">
                <el-input disabled v-model="formData.invoiceNumber" />
            </el-form-item>
            <el-form-item label="Belopp">
                <el-input disabled v-model="formData.belopp"><template slot="suffix">SEK</template></el-input>
            </el-form-item>
            <el-form-item label="Ex Moms">
                <el-input disabled v-model="formData.totalNetAmount"><template slot="suffix">SEK</template></el-input>
            </el-form-item>
            <el-form-item label="Moms" v-if="!formData.isReported">
                <el-input disabled v-model="formData.totalVat"><template v-if="displaySuffix(formData.totalVat)" slot="suffix">SEK</template></el-input>
            </el-form-item>
            <el-form-item label="Avrundning">
                <el-input disabled v-model="formData.rounding"><template v-if="displaySuffix(formData.rounding)" slot="suffix">SEK</template></el-input>
            </el-form-item>
            <el-form-item label="Påminnelseavgift">
                <el-input disabled v-model="formData.reminderFee"><template v-if="displaySuffix(formData.reminderFee)" slot="suffix">SEK</template></el-input>
            </el-form-item>
            <el-form-item label="Omvänd moms" v-if="formData.isReported">
                <el-input disabled v-model="formData.reverseVat"><template slot="suffix">SEK</template></el-input>
            </el-form-item>
            <el-form-item label="Valuta" v-if="!isSekInvoice">
                <el-input disabled v-model="formData.currency" />
            </el-form-item>
            <el-form-item label="Utländskt Belopp" v-if="!isSekInvoice">
                <el-input disabled v-model="formData.internationalGrossAmount" />
            </el-form-item>
            <el-form-item label="Inköpsland">
                <el-input disabled v-model="formData.creditorCountry" />
            </el-form-item>
            <el-form-item label="Omvänd Byggmoms">
                <el-input disabled v-model="formData.creditorReversedConstructionVat" />
            </el-form-item>
            <el-form-item label="Momsnummer" v-if="formData.creditorReportedVat != ''">
                <el-input disabled v-model="formData.creditorReportedVat" />
            </el-form-item>
            <el-form-item label="Moms" v-if="formData.creditorVat != ''">
                <el-input disabled v-model="formData.creditorVat" />
            </el-form-item>
            <el-form-item label="Periodisera">
                <el-checkbox v-model="formData.isAccrual" disabled />
            </el-form-item>
            <el-form-item label="Startdatum" v-if="formData.isAccrual">
                <el-input disabled v-model="formData.startDate" />
            </el-form-item>
            <el-form-item label="Slutdatum" v-if="formData.isAccrual">
                <el-input disabled v-model="formData.endDate" />
            </el-form-item>
            <el-form-item label="Credit note">
                <el-checkbox v-model="formData.isCreditNote" disabled />
            </el-form-item>
            <el-form-item label="Kommentar">
                <el-input type="textarea" disabled v-model="formData.comment" />
            </el-form-item>
            <el-form-item label="Godkännarens kommentar" prop="amlApproverComment">
                <el-input type="textarea" :disabled="formData.status != 12" v-model="formData.amlApproverComment" />
            </el-form-item>
            <el-form-item label="Godkännare" v-if="formData.status != 12 && formData.amlApprover != null">
                <el-input disabled v-model="formData.amlApprover" />
            </el-form-item>
            <el-form-item label="Godkänt datum" v-if="formData.status != 12 && formData.amlApprover != null">
                <el-input disabled v-model="formData.amlApprovedDate" />
            </el-form-item>
            <VouchersTable :voucher-rows="formData.voucherRows" :account-plan-items="accountPlanItems" :write-comments="false" :periodize="formData.isAccrual" @showAccruals="showAccruals" />
            <div class="pt-5">
                <el-button @click="$router.back()">Tillbaka</el-button>
                <el-button type="primary" v-if="formData.status == 12" :disabled="disableApproveBtn" :loading="$waiting.is('updating')" @click="approve('ruleForm')">Godkänna</el-button>
            </div>
        </el-form>
        <div class="w-1/2">
            <div v-if="contentUrl && imgType == 1">
                <Pdf :file="contentUrl" height="900px" width="100%" />
            </div>
            <div v-else>
                <zoom-img :size="2" :imageurl="contentUrl" />
            </div>
        </div>
        <AccrualModal :visible="accrualModal" :data="accrualModalData" @close="accrualModal = false" :accruals-for-data="selectAccruals" />
    </div>
</template>
<script>
import Api from "../supplierInvoice.api";
import Enum from "../supplierInvoice.enum.js";

import { mapState } from "vuex";

export default {
    data: function() {
        return {
            formData: {},
            rules: {
                amlApproverComment: [{ required: true, message: "vänligen ange godkännarens kommentar", trigger: "blur" }],
            },
            disableApproveBtn: false,
            contentUrl: undefined,
            imgType: 0,
            accrualModal: false,
            accrualModalData: [],
            selectAccruals: {},
        };
    },

    components: {
        VouchersTable: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/vouchersTable/v1/VouchersTable.vue"),
        Pdf: () => import(/* webpackChunkName: "Pdf" */ "@/components/pdf"),
        "zoom-img": () => import(/* webpackChunkName: "ZoomImg" */ "@/components/zoomImg"),
        AccrualModal: () => import(/* webpackChunkName: "AccrualModal" */ "../ui/accrualModal.vue"),
    },

    async created() {
        let docId = this.$route.query.docId;
        this.$waiting.start("loading");
        this.formData = await Api.getSupplierInvoice(docId);
        var image = await Api.getInvoiceImage(docId);
        this.$waiting.end("loading");

        if (image) {
            this.contentUrl = image.image;
            this.imgType = image.imageType;
        }
    },

    methods: {
        async approve(ruleForm) {
            await new Promise(resolve => this.$refs[ruleForm].validate(valid => valid && resolve()));

            this.$waiting.start("updating");
            var response = await Api.approveSupplierInvoice(this.formData.creditorInvoiceId, this.formData.amlApproverComment);
            if (response == "error") {
                this.$notify.error({ title: "Error" });
                this.disableApproveBtn = false;
            } else {
                this.disableApproveBtn = true;
                this.$notify.success({ title: "Utfört" });
            }
            this.$waiting.end("updating");
            this.$router.back();
        },

        async showAccruals({ account, debit, credit, startDate, endDate }) {
            this.accrualModal = true;
            this.selectAccruals = { costAccount: account, amount: debit != 0 ? debit : credit, invoiceDate: this.formData.invoiceDate, startDate: startDate, stopDate: endDate };
            this.accrualModalData = await Api.getAccruals({
                costAccount: account,
                amount: debit != 0 ? debit : credit,
                invoiceDate: this.formData.invoiceDate,
                startDate: startDate,
                stopDate: endDate,
            });
        },

        displaySuffix(value) {
            return value ? true : false;
        },
    },

    computed: {
        ...mapState({
            accountsInfo: state => state.supplierInvoice.accountsInfo,
        }),

        accountPlanItems() {
            if (this.selectedCompanyType == Enum.companyType.Aktiebolag) return this.stockCompanyAccountPlanItems;
            else return this.privateFirmAccountPlanItems;
        },

        stockCompanyAccountPlanItems() {
            var stockAccountPlans = this.accountsInfo.filter(a => a.companyType == 1)[0].accounts;
            return stockAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                };
            });
        },

        privateFirmAccountPlanItems() {
            var privateFirmAccountPlans = this.accountsInfo.filter(a => a.companyType == 2)[0].accounts;
            return privateFirmAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                };
            });
        },

        isSekInvoice() {
            if (this.formData.currency && this.formData.currency.trim().toUpperCase() == "SEK") {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>
