import SupplierInvoiceUpload from "./ui/supplierInvoiceList/supplierInvoiceUpload.vue";
import SupplierInvoiceList from "./ui/supplierInvoiceList/supplierInvoiceList.vue";
import SupplierInvoiceList2 from "./list/supplierInvoiceList2.vue";
import SupplierInvoiceUploadedList from "./ui/supplierInvoiceList/supplierInvoiceUploadedList.vue";
import SupplierInvoiceForm from "./ui/supplierInvoiceForm/supplierInvoiceForm.vue";
import HighValueSupplierInvoice from "./highValueSupplierInvoice/highValueSupplierInvoiceList.vue";
import SupplierInvoice from "./highValueSupplierInvoice/supplierInvoiceReadOnlyForm.vue";
import SupplierInvoiceR from "./supplierInvoice.vue";

const routes = [
    {
        path: "/supplierInvoiceUpload",
        name: "supplierInvoiceUpload",
        component: SupplierInvoiceUpload,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/supplierInvoiceListOld",
        name: "supplierInvoiceListOld",
        component: SupplierInvoiceList,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura gamla",
        },
    },
    {
        path: "/supplierInvoiceList",
        name: "supplierInvoiceList",
        component: SupplierInvoiceList2,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/supplierInvoiceUploadedList",
        name: "supplierInvoiceUploadedList",
        component: SupplierInvoiceUploadedList,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/supplierInvoiceForm",
        name: "supplierInvoiceForm",
        component: SupplierInvoiceForm,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/highValueSupplierInvoice",
        name: "highValueSupplierInvoice",
        component: HighValueSupplierInvoice,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/supplierInvoice",
        name: "supplierInvoice",
        component: SupplierInvoice,
        meta: {
            forAuth: true,
            title: "Leverantörsfaktura",
        },
    },
    {
        path: "/supplierInvoice/:supplierInvoiceId",
        name: "SupplierInvoice",
        component: SupplierInvoiceR,
        meta: {
            forAuth: true,
            title: "SupplierInvoice",
        },
    },

];
export default routes;
