<template>
    <div style="display: inline-block;">
        <label tabindex="0" @click="clicked" @keyup.space.prevent="keyup" @keydown.space.prevent="keydown" class="bt-check-box" :class="{ disabled: disabled }">
            <span style="display: flex;">
                <div>
                    <div :class="{ 'cb-border': true, 'cb-checked': selected }" />
                </div>
                <div class="label label-text has-text-bolageriet">{{ label }}</div>
            </span>
        </label>
        <span v-show="error" class="error-text">{{ error }}</span>
    </div>
</template>

<script>
export default {
    name: "CheckBox",
    props: {
        disabled: { type: Boolean, default: false },
        label: { type: String, default: "" },
        value: { type: Boolean },
        error: { type: String, default: "" },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.selected = this.value;
            },
        },
    },
    data() {
        return {
            selected: true,
        };
    },
    model: {
        event: "change",
    },
    created: function() {
        // this.selected = this.value;
    },

    methods: {
        clicked: function() {
            if (!this.disabled) {
                this.selected = !this.selected;
                this.$emit("change", this.selected);
            }
        },
        keyup() {
            this.selected = !this.selected;
            this.$emit("change", this.selected);
        },
    },
};
</script>

<style lang="postcss" scoped>
.error-text {
    font-size: 14px;

    color: #ff7878;
    font-weight: 500;
    margin-top: 0px;
}
.disabled {
    .bt-check-box {
        pointer-events: none;
    }
    .cb-checked::after {
        background-color: #bababa !important;
    }
    .cb-border {
        border: 2px solid #edf0f2;
    }
}
.bt-check-box {
    cursor: pointer;
}
.cb-border {
    margin-right: 10px;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid #cadeea;
    border-radius: 3px;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cb-checked::after {
    stroke: #fff;
    content: url(../../../src/assets/svg/check.svg);
    position: absolute;
    width: 18px;
    height: 18px;
    left: -1px;
    top: -1px;
    background-color: #14abf4;
    padding: 4px 4px 4px 3px;
    border-radius: 3px;
}
.bt-check-box:focus {
    outline: none;
}
label:focus div.cb-checked::after {
    background-color: #1296d8;
}
label:focus div.cb-border {
    border: 2px solid #a9b9c2;
}
.label-text {
    display: inline-block;
    font-size: 16px;
}
.mobile {
    .label-text {
        font-size: 15px;
    }
}
</style>
