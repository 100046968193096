<template>
    <div style="dispaly: inline-block;">
        <label tabindex="0" @click="clicked" @keyup.space.prevent="keyup" @keydown.space.prevent="keydown" class="bt-check-box" :class="{ disabled: disabled }">
            <div v-bind:class="{ 'cb-border': true, 'cb-checked': selected }" />
            <div class="label label-text has-text-bolageriet">{{ label }}</div>
        </label>
    </div>
</template>

<script>
export default {
    name: "check-box",
    props: ["disabled", "label", "value"],
    data() {
        return {
            selected: true,
        };
    },
    model: {
        event: "change",
    },
    created: function() {
        this.selected = this.value;
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.selected = this.value;
            },
        },
    },

    methods: {
        clicked: function() {
            if (!this.disabled) {
                this.selected = !this.selected;
                this.$emit("change", this.selected);
            }
        },
        keyup() {
            this.selected = !this.selected;
            this.$emit("change", this.selected);
        },
        keydown() {},
    },
};
</script>

<style scoped>
/* .disabled {
  .bt-check-box {
    pointer-events: none;
  }
  .cb-checked::after {
    background-color: #bababa;
  }
  .cb-border {
    border: 2px solid #edf0f2;
  }
} */
.bt-check-box {
    cursor: pointer;
}
.cb-border {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid #cadeea;
    border-radius: 3px;
    user-select: none;
}
.cb-checked::after {
    stroke: #fff;
    content: url(../../src/assets/svg/check.svg);
    position: absolute;
    width: 20px;
    height: 20px;
    left: -2px;
    top: -2px;
    background-color: #48c774;
    padding: 5px;
    border-radius: 3px;
}
.bt-check-box:focus {
    outline: none;
}
label:focus div.cb-checked::after {
    background-color: #48c774;
}
label:focus div.cb-border {
    border: 2px solid #a9b9c2;
}
.label-text {
    display: inline-block;
    position: relative;
    top: -4px;
    left: 8px;
    font-size: 15;
}
/* .mobile {
  .label-text {
    font-size: var(--formInputLabelSizeMobile);
  } 
}*/
</style>
