const routes = [
    {
        path: "/k10_declaration",
        name: "k10_declaration",
        component: () => import(/* webpackChunkName: "K10DeclarationView" */ "./K10Declaration.vue"),
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/k10_declaration/:id",
        name: "k10_declaration_details",
        component: () => import(/* webpackChunkName: "K10DeclarationDetailsView" */ "./K10DeclarationDetails.vue"),
        meta: {
            forAuth: true,
        },
    },
];

export default routes;
