import moment from "moment";

export default {
    getAccruals(voucherRows, rowCount, accountPlanItems, sourceDate) {
        let result = [];
        let accrualGroup = 1;
        voucherRows.forEach(item => {
            if (item.startDate && item.endDate) {
                result.push(item);
                let accountInfo = accountPlanItems.find(i => i.value === item.account);
                if (!accountInfo.accruable) return [];
                item.accrualGroup = accrualGroup;
                item.accrualType = 0;
                const startDate = moment(item.startDate);
                const endDate = moment(item.endDate);
                const invoiceDate = moment(sourceDate);
                if (item.debit > 0 && startDate._isValid) {
                    result.push({
                        id: ++rowCount,
                        account: item.account,
                        debit: 0,
                        credit: item.debit,
                        text: "",
                        readOnly: true,
                        autoGenerated: false,
                        startDate: "",
                        endDate: "",
                        accrualGroup: 999999,
                    });
                    const { asset, debt } = this.calculateAccrual(startDate, endDate, invoiceDate, item.debit);
                    if (debt > 0) {
                        result.push({
                            id: ++rowCount,
                            account: accountInfo.debtAccount,
                            debit: debt,
                            credit: 0,
                            text: "",
                            readOnly: true,
                            autoGenerated: false,
                            startDate: "",
                            endDate: "",
                            accrualGroup: accrualGroup,
                            accrualType: 2,
                        });
                    }
                    if (asset > 0) {
                        result.push({
                            id: ++rowCount,
                            account: accountInfo.assetAccount,
                            debit: asset,
                            credit: 0,
                            text: "",
                            readOnly: true,
                            autoGenerated: false,
                            startDate: "",
                            endDate: "",
                            accrualGroup: accrualGroup,
                            accrualType: 1,
                        });
                    }
                    accrualGroup++;
                }
            } else {
                result.push(item);
            }
        });
        return result;
    },
    bothStartAndEndDateIsInvalid(startDate, endDate) {
        return !startDate._isValid && !endDate._isValid;
    },
    calculateAccrual(startDate, endDate, bookingDate, amount) {
        let debt = 0;
        let asset = 0;
        let restAmount = amount;
        for (let date = startDate.clone(); date <= endDate; date = date.add(1, "month").startOf("month")) {
            const interimType = (date.month() < bookingDate.month() && date.year() == bookingDate.year()) || date.year() < bookingDate.year() ? "Debt" : "Asset";
            if (date.month() === bookingDate.month() && date.year() === bookingDate.year() && bookingDate.date() !== 1) {
                const paymentDebt = this.calculatePayment(startDate, endDate, date, bookingDate, amount, restAmount, "Debt");
                restAmount -= paymentDebt;
                const paymentAsset = this.calculatePayment(startDate, endDate, date, bookingDate, amount, restAmount, "Asset");
                restAmount -= paymentAsset;
                asset += paymentAsset;
                debt += paymentDebt;
            } else if (interimType === "Debt") {
                const payment = this.calculatePayment(startDate, endDate, date, bookingDate, amount, restAmount, interimType);
                debt += payment;
                restAmount -= payment;
            } else {
                const payment = this.calculatePayment(startDate, endDate, date, bookingDate, amount, restAmount, interimType);
                asset += payment;
                restAmount -= payment;
            }
        }
        return { asset, debt };
    },
    calculatePayment(startDate, endDate, currentDate, bookingDate, amount, restAmount, accrualType) {
        if (startDate.month() === currentDate.month() && startDate.year() === currentDate.year() && startDate.date() !== 1) {
            const totalDays = endDate.diff(startDate, "days");
            const dayAveragePayment = amount / totalDays;
            const lastDay = startDate.clone().endOf("month");
            const firstMonthPayment = dayAveragePayment * lastDay.diff(startDate, "days");
            return Math.floor(firstMonthPayment);
        } else if (bookingDate.month() === currentDate.month() && bookingDate.year() === currentDate.year()) {
            const totalDays = endDate.diff(startDate, "days");
            const dayAveragePayment = amount / totalDays;
            if (accrualType === "Debt") {
                return Math.floor(dayAveragePayment * bookingDate.date());
            } else if (currentDate.month() === endDate.month() && currentDate.year() == endDate.year()) {
                return restAmount;
            } else {
                const lastDay = bookingDate.clone().endOf("month");
                console.log(lastDay);
                return Math.floor(dayAveragePayment * lastDay.diff(bookingDate, "days"));
            }
        } else if (endDate.month() === currentDate.month() && endDate.year() === currentDate.year()) {
            return restAmount;
        } else {
            const monthsBetween = endDate.diff(startDate, "months", true) + 1;
            console.log(monthsBetween);
            const monthAveragePayment = amount / Math.floor(monthsBetween);
            return Math.floor(monthAveragePayment);
        }
    },
};
