import Enum from "./receipt.enums";

export function calculateRepresentation(representationType, övrigtInkMoms, matInkMoms, strakölInkMoms, vinSpritInkMoms, dricks, amountOfParticipants) {
    if (amountOfParticipants < 2) {
        return {
            deductable: 0,
            nonDeductable: 0,
            vat: 0,
        };
    }

    let threshold = 0;
    let threshholdOthers = 180;
    let rule = "";
    if (representationType === Enum.typesOfRepresentation.LunchDinner) {
        threshold = 90;
        rule = "Lunch, Middag";
    } else if (representationType === Enum.typesOfRepresentation.BreakfastSnack) {
        threshold = 60;
        rule = "Frukost, fika";
    } else if (representationType === Enum.typesOfRepresentation.DemonstrationViewing) {
        threshold = 60;
        rule = "Demostration, visning";
    } else if (representationType === Enum.typesOfRepresentation.NoFood) {
        rule = "Ingen mat";
    }
    let total = övrigtInkMoms + matInkMoms + strakölInkMoms + vinSpritInkMoms + dricks;
    let matExMoms = matInkMoms * (100 / 112);
    let matExMomsPerson = matExMoms / amountOfParticipants;
    let övrigtExMoms = övrigtInkMoms * 0.8;
    let övrigtExMomsPerson = övrigtExMoms / amountOfParticipants;
    let matInklSpritExMoms = (strakölInkMoms + vinSpritInkMoms) * 0.8 + (matInkMoms * 100) / 112;
    let matInklSpritExMomsPerson = matInklSpritExMoms / amountOfParticipants;
    let totalExMoms = övrigtExMoms + matInklSpritExMoms;
    let totalMoms = total - totalExMoms;
    let totalMomsPerson = totalMoms / amountOfParticipants;
    let delSprit = 0;
    if (matExMoms > 0) {
        delSprit = 1 - matExMoms / matInklSpritExMoms;
    } else {
        if (matInklSpritExMoms > 0) delSprit = 1;
    }

    if (
        (representationType === Enum.typesOfRepresentation.LunchDinner ||
            representationType === Enum.typesOfRepresentation.BreakfastSnack ||
            representationType === Enum.typesOfRepresentation.DemonstrationViewing) &&
        matInkMoms === 0 &&
        övrigtInkMoms === 0
    ) {
        return {
            deductable: 0,
            nonDeductable: totalExMoms,
            vat: totalMoms,
        };
    }

    const momsregler = [];

    if (representationType == Enum.typesOfRepresentation.BreakfastSnack) {
        momsregler.push({
            Benämning: "Förfriskning och enklare förtäring",
            Avdragsgill: matExMoms > amountOfParticipants * threshold ? 0 : matExMoms,
            Moms: matExMomsPerson > 300 ? 36 * amountOfParticipants : matExMomsPerson * 0.12 * amountOfParticipants,
            Extern: true,
            Intern: true,
        });
    } else if (representationType == Enum.typesOfRepresentation.DemonstrationViewing) {
        momsregler.push({
            Benämning: "Demostration eller visning",
            Avdragsgill: matExMoms > amountOfParticipants * threshold ? amountOfParticipants * threshold : matExMoms,
            Moms: matExMoms > amountOfParticipants * threshold ? threshold * 0.12 * amountOfParticipants : matExMomsPerson * 0.12 * amountOfParticipants,
            Extern: true,
            Intern: true,
        });
    } else {
        momsregler.push({
            Benämning: "Verklig 2017",
            Avdragsgill: 0,
            Moms: matInklSpritExMomsPerson > 300 ? ((1 - delSprit) * 300 * 0.12 + delSprit * 300 * 0.25) * amountOfParticipants : matInkMoms - matExMoms + (strakölInkMoms + vinSpritInkMoms) * 0.2,
            Extern: true,
            Intern: true,
        });
        momsregler.push({
            Benämning: "Schablon 2017",
            Avdragsgill: 0,
            Moms: matInklSpritExMomsPerson > 300 && strakölInkMoms + vinSpritInkMoms > 0 ? (totalMomsPerson >= 46 ? 46 * amountOfParticipants : totalMoms) : 0,
            Extern: true,
            Intern: true,
        });
    }

    let deductable = 0;
    let nonDeductable = 0;
    let vat = 0;

    if (övrigtExMoms > 0) {
        deductable += övrigtExMoms > amountOfParticipants * threshholdOthers ? amountOfParticipants * threshholdOthers : övrigtExMoms;
        vat = övrigtExMomsPerson > threshholdOthers ? threshholdOthers * 0.25 * amountOfParticipants : övrigtExMomsPerson * 0.25 * amountOfParticipants;
    }

    const maxs = momsregler.sort((a, b) => b.Moms - a.Moms);
    const max = maxs[0];

    if (max) {
        vat += max.Moms;
        rule += ", \n" + max.Benämning;
        deductable += max.Avdragsgill;
    }

    if (övrigtExMoms > 0) {
        rule += ", \nÖvrigt/golf/teater/musik/hyra av lokal";
    }

    nonDeductable = total - deductable - vat;

    return {
        deductable,
        nonDeductable,
        vat,
        rule,
    };
}
