<template>
    <div :style="getStyle()">
        <h1 v-if="level === '1'" class="heading-title1 text-base">
            <slot />
        </h1>
        <h2 v-if="level === '2'" class="heading-title2 text-base">
            <slot />
        </h2>
        <h3 v-if="level === '3'" class="heading-title3 text-base">
            <slot />
        </h3>
    </div>
</template>
<script>
export default {
    props: {
        level: { type: String, default: "2" },
        color: { type: String, default: "" },
    },
    methods: {
        getStyle() {
            let color = "#415770";
            if (this.color === "blue") {
                color = "#14abf4";
            } else if (this.color === "comp") {
                color = "#ff7878";
            } else if (this.color === "base") {
                color = "#415770";
            } else {
                return "";
            }
            return `color: ${color};`;
        },
    },
};
</script>
<style lang="postcss" scoped>
.heading-title1 {
    font-size: 35px;
    font-weight: 600;
    margin: 0;
}
.heading-title2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.heading-title3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}
.text-base {
    /* font-family: "futura-pt"; */
    margin-left: -0.07em;
    transform: translateY(0.41em);
}
.text-base:before {
    content: "";
    display: block;
    height: 0;
    margin-top: -0.84em;
}
/* .mobile {
  .heading-title1 {
    font-size: 25px;
    font-weight: 600;
    margin: 0;
  }
  .heading-title2 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  .heading-title3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
} */
</style>
