<template>
    <el-dialog :fullscreen="true" style="padding: 3em 2em; margin-left: 5rem; height: 100%;" :visible="dialogVisible" :before-close="handleClose" v-on:update:visibleSync="visibleSync = $event">
        <span class="kyc-dialog-title" slot="title">Känn din kund - </span>
        <span class="kyc-dialog-title" slot="title">{{ clientName }}</span>
        <div v-loading="loading">
            <div v-if="clientId && kycClientData">
                <kyc-company-info-header :status="status" :client-id="clientId" :company-info="kycClientData" />
                <kyc-company-info-comparison-main v-if="!loading && comparisonDataReady" :answers="comparisonData" />
                <kyc-company-info-sni-codes :sni-codes="sniCodesData" />
                <kyc-company-info-owners :client-id="clientId" />
                <kyc-handle-company-info-footer
                    :client-name="clientName"
                    :risk-info="riskData"
                    @cancelKyc="handleClose"
                    :client-id="clientId"
                    @kycEventExecute="onKycEventExecute"
                    :risk-class="kycClientData.riskGradeManual"
                />
            </div>
        </div>
    </el-dialog>
</template>

<script>
import Api from "../kycByClient.api.js";
import kycByClientNewHeaderInfo from "./kycByClientNewHeaderInfo.vue";
import kycByClientNewMainContentComparison from "./kycByClientNewMainContentComparison.vue";
import kycByClientNewSniCodes from "./kycByClientNewSniCodes.vue";
import kycByClientNewOwners from "./kycByClientNewOwners.vue";
import kycByClientNewFooter from "./kycByClientNewFooter.vue";
export default {
    components: {
        "kyc-company-info-header": kycByClientNewHeaderInfo,
        "kyc-company-info-comparison-main": kycByClientNewMainContentComparison,
        "kyc-company-info-sni-codes": kycByClientNewSniCodes,
        "kyc-company-info-owners": kycByClientNewOwners,
        "kyc-handle-company-info-footer": kycByClientNewFooter,
    },
    data() {
        return {
            visibleSync: this.dialogVisible,
            kycClientData: null,
            loading: false,
            clientName: "",
        };
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
            default: undefined,
        },
        status: {
            type: String,
            default: undefined,
        },
    },
    watch: {
        visibleSync(val) {
            this.$emit("update:dialogVisible", val);
        },
        clientId: {
            immediate: true,
            async handler(newClientId) {
                if (newClientId) {
                    try {
                        this.loading = true;
                        this.kycClientData = await Api.getClientKycData(newClientId);
                        this.clientName = `${this.kycClientData.clientNumber}, ${this.kycClientData.clientName}`;
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.loading = false;
                    }
                }
            },
        },
    },
    computed: {
        sniCodesData() {
            if (!this.kycClientData) return {};
            else {
                return {
                    current: this.kycClientData.currentSniCodes || [],
                    syna: this.kycClientData.synaSniCodes || [],
                };
            }
        },
        riskData() {
            if (!this.kycClientData) return {};
            else {
                return this.kycClientData.riskClass;
            }
        },
        comparisonDataReady() {
            return !!(this.kycClientData?.oldCompanyInfo && this.kycClientData?.newCompanyInfo && this.kycClientData?.actualCompanyInfo);
        },
        comparisonData() {
            if (!this.comparisonDataReady) return {};
            const { oldCompanyInfo, newCompanyInfo, actualCompanyInfo } = this.kycClientData;
            return { oldCompanyInfo, newCompanyInfo, actualCompanyInfo };
        },
    },
    methods: {
        onKycEventExecute() {
            this.$emit("eventExecuted");
        },
        resetData() {
            this.kycClientData = null;
            this.clientId = undefined;
            this.status = undefined;
        },
        handleClose(done) {
            this.resetData();
            this.$emit("close");
            done();
        },
    },
};
</script>

<style scoped>
div >>> .el-dialog {
    background-color: #fafafa;
}

.kyc-dialog-title {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #1d3f67 !important;
}
</style>
