import AccountFiles from "./AccountFiles.vue";

const routes = [
    {
        path: "/account_files",
        name: "account_files",
        component: AccountFiles,
        meta: {
            title: "Kontoutdrag",
            forAuth: true,
        },
    },
];
export default routes;
