<template>
    <el-card class="sni-card">
        <div slot="header" class="clearfix sni-card-header">
            <span>{{ title }}</span>
        </div>
        <table>
            <thead>
                <tr>
                    <th>SNI-Kod</th>
                    <th>Beskrivning</th>
                    <th>Kontantintensiv</th>
                    <th>Riskbranch</th>
                    <th>Risknivå</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(sni, index) in sniCodes" :key="index">
                    <td>{{ sni.sniCode }}</td>
                    <td>{{ sni.description }}</td>
                    <td>{{ sni.cashIntensive ? "Ja" : "Nej" }}</td>
                    <td>{{ sni.riskIndustry ? "Ja" : "Nej" }}</td>
                    <td>{{ sni.risk }}</td>
                </tr>
            </tbody>
        </table>
    </el-card>
</template>

<script>
export default {
    name: "SniCard",
    props: {
        title: {
            type: String,
            required: true,
        },
        sniCodes: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped>
.sni-card {
    width: 100%;
    border-radius: 18px;
}
.sni-card-header {
    font-weight: bold;
    color: #1d3f67;
    font-size: 18px;
}
div >>> .el-card__body {
    padding: 0 20px 3em 20px !important;
}

div >>> .el-card__header {
    padding-bottom: 2em !important;
    border-bottom: none !important;
}

thead {
    border-bottom: 1px solid #b4c8e0;
}
table {
    width: 100%;
    overflow: hidden;
}

tbody tr {
    border-bottom: 1px solid #b4c8e0;
    transition: background-color 0.2s ease;
}

td,
th {
    font-size: 12px;
    white-space: pre-line;
    text-wrap: nowrap;
    padding-bottom: 1em;
    padding-right: 1em;
}

td {
    padding-top: 1em;
    font-size: 12px;
    color: #1d3f67;
    white-space: pre-line;
}

tr > th {
    font-weight: bold;
    color: #1d3f67;
    font-size: 14px;
}
</style>
