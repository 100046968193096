<template>
    <section>
        <!-- <img-view v-if="size === 0" class="lg-view-create" :imgUrl="imageurl" background="rgb(242 242 242)"></img-view>
        <img-view v-if="size === 1" class="lg-v-view" :imgUrl="imageurl" background="rgb(242 242 242)"></img-view> -->
        <img-view v-if="size === 2" class="lg-pi-view" :img-url="imageurl" background="rgb(242 242 242)" />
    </section>
</template>

<script>
import Vue from "vue";
import imgView from "vue-img-view";
Vue.use(imgView);

export default {
    props: ["imageurl", "size"],
    data() {
        return {
            lgsize: undefined,
            smsize: undefined,
        };
    },
    created() {},
};
</script>
<style lang="postcss" scoped>
.lg-view-create {
    height: 900px;
    width: 500px;
    position: relative;
    margin-left: 0 auto;
    margin-right: 0 auto;
    @media (max-width: 1500px) {
        height: 435px;
        width: 435px;
        position: relative;
        margin-left: -18px;
        margin-right: 0 auto;
    }
    @media (min-width: 900px) {
        height: 450px;
        width: 450px;
        position: relative;
        margin-left: auto;
        margin-right: 0 auto;
    }
    @media (min-width: 1700px) {
        height: 500px;
        width: 500px;
        position: relative;
        margin-left: auto;
        margin-right: 0 auto;
    }
    @media (min-width: 1200px and (max-width: 1480px)) {
        height: 450px;
        width: 450px;
        position: relative;
        margin-left: auto;
        margin-right: 0 auto;
    }
    @media (width: 1024px) {
        height: 400px;
        width: 400px;
        position: relative;
        margin-left: -18px;
        margin-right: 0 auto;
    }
    @media screen and (max-width: 1280px) and (min-width: 1025px) {
        height: 400px;
        width: 400px;
        position: relative;
        margin-left: -18px;
        margin-right: 0 auto;
    }
}

.mobile {
    .lg-view-create .sm-view-create {
        height: 900px;
        width: 300px;
        position: relative;
        margin: auto;
    }
}

.lg-pi-view {
    height: 650px;
    width: 100%;
    /* position: relative; */
    /* margin: auto; */
}

.lg-v-view {
    height: 900px;
    width: 400px;
    position: relative;
}
</style>
