<template>
    <div class="control">
        <input
            aria-label="Ange lösenord"
            class="input"
            type="password"
            v-model="text"
            ref="input"
            @input="onInput"
            autocomplete="off"
            autocapitalize="off"
            autocorrect="off"
            spellcheck="false"
            @keydown.enter.prevent="onEnter"
            @keydown.esc.prevent="onEsc"
        />
    </div>
</template>

<script>
export default {
    props: ["value", "focus"],
    watch: {
        value: {
            immediate: true,
            handler() {
                this.result = this.value;
                this.text = "*".repeat(this.value.length);
            },
        },
    },
    data: function() {
        return {
            text: "",
            result: "",
        };
    },
    mounted: function() {
        if (this.focus != undefined) this.$refs.input.focus();
    },
    methods: {
        onInput(key) {
            const inputLength = this.text.length;
            const resultLength = this.result.length;
            if (key.inputType == "insertText" || key.inputType == "insertFromPaste") {
                const newString = this.text.substr(resultLength);
                this.text = "*".repeat(inputLength);
                this.result = this.result.substr(0, inputLength - 1) + newString;
            } else {
                this.result = this.result.substr(0, inputLength);
            }
            this.$emit("input", this.result);
        },
        onEnter() {
            this.$emit("on-enter");
        },
        onEsc() {
            this.$emit("on-esc");
        },
    },
};
</script>
