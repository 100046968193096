<template>
    <div class="card-tail card-f-h">
        <Stack>
            <Columns align-y="center">
                <Column :width="15" v-if="client">
                    <Heading level="2" color="blue">{{ client.clientName }}</Heading>
                </Column>
            </Columns>
            <div class="tabs is-boxed" v-bind:class="{ disabledbutton: isLoading }">
                <li v-bind:key="key" v-for="(tab, key) in tabs" :class="{ 'is-active': tab.isActive }">
                    <div style="display: flex; flex-direction: row">
                        <span>
                            <a @click="selectTab(tab)">
                                <TextBase size="medium" weight="bold" color="black">{{ tab.name }}</TextBase>
                                <span v-if="isLoading && tab.isActive">
                                    <button class="button is-loading is-white is-small" />
                                </span>
                            </a>
                        </span>
                    </div>
                </li>
            </div>
            <Stack style="margin-top: 35px">
                <component v-bind:is="currentTabComponent" />
            </Stack>
        </Stack>
    </div>
</template>
<script>
import TextBase from "../../components/content/Text.vue";
import Stack from "../../components/layout/Stack.vue";
import Columns from "../../components/layout/Columns.vue";
import Heading from "../../components/content/Heading.vue";
import Column from "../../components/layout/Column.vue";
import OwnerInformation from "./ownerInformation.vue";
import Operations from "./operations.vue";
import Api from "../kycByClient/kycByClient.api";
export default {
    components: {
        TextBase,
        Stack,
        Columns,
        Column,
        Heading,
        Operations,
        OwnerInformation,
    },
    data: function() {
        return {
            //   companyInfo: {},
            isLoading: false,
            client: undefined,
            //   informationProp: undefined,
            //   clientDetails: undefined,
            tabs: [
                { name: "Verksamhet", isActive: false, value: "Operation" },
                { name: "Verklighuvudman", isActive: false, value: "OwnerInformation" },
            ],
            selectedTab: {},
        };
    },
    async created() {
        this.clientId = this.$route.query.Id;
        if (this.clientId) {
            this.client = await Api.clientDetails(this.clientId);
            //   console.log(this.client.clientName);
        }
    },
    methods: {
        selectTab(selectedTab) {
            if (selectedTab != undefined) {
                this.tabs.forEach(tab => {
                    tab.isActive = tab.value == selectedTab.value;
                });
                this.selectedTab = selectedTab;
            }
        },
    },
    computed: {
        currentTabComponent() {
            switch (this.selectedTab.value) {
                case "OwnerInformation":
                    return "OwnerInformation";
                case "Operation":
                    return "Operations";
                default:
                    return "Operations";
            }
        },
    },
};
</script>
