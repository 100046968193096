const routes = [
    {
        path: "/bank_files",
        name: "bank_files",
        component: () => import(/* webpackChunkName: "BankFilesView" */ "./BankFiles.vue"),
        meta: {
            title: "Bank filer",
            forAuth: true,
        },
    },
    {
        path: "/bank_files/:fileId",
        name: "bank_file_details",
        component: () => import(/* webpackChunkName: "BankFileDetailsView" */ "./BankFileDetails.vue"),
        meta: {
            forAuth: true,
        },
    },
];

export default routes;
