<template>
    <div class="card-tail card-f-h">
        <el-tabs v-model="activeTab">
            <el-tab-pane label="Lista" name="lista">
                <ConciliationList v-if="activeTab == 'lista'" />
            </el-tab-pane>
            <el-tab-pane label="Avstämning" name="editor">
                <Conciliation v-if="activeTab == 'editor'" />
            </el-tab-pane>
            <el-tab-pane label="Status" name="status">
                <ConciliationStatus v-if="activeTab == 'status'" />
            </el-tab-pane>
            <el-tab-pane label="Bankkonton" name="accounts">
                <ConciliationAccounts v-if="activeTab == 'accounts'" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    components: {
        Conciliation: () => import("./conciliationEditor/conciliation"),
        ConciliationList: () => import("./conciliationList/conciliationList"),
        ConciliationStatus: () => import("./conciliationStatus/conciliationStatus"),
        ConciliationAccounts: () => import("./conciliationAccounts/conciliationAccounts"),
    },

    data() {
        return {
            activeTab: "editor",
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Avstämning");
    },
};
</script>
