const routes = [
    {
        path: "/account_categories",
        name: "categories",
        component: () => import(/* webpackChunkName: "AccountCategories" */ "./AccountCategories.vue"),
        meta: {
            title: "Kategories",
            forAuth: true,
        },
    },
];

export default routes;
