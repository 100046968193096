import Vue from "vue";
import langSe from "element-ui/lib/locale/lang/sv-SE";
import locale from "element-ui/lib/locale";
import "./element-variables.scss";

import {
    Table,
    TableColumn,
    Pagination,
    Button,
    ButtonGroup,
    Tooltip,
    Loading,
    Input,
    Notification,
    Select,
    Option,
    OptionGroup,
    Dialog,
    Form,
    FormItem,
    // Switch,
    Radio,
    RadioButton,
    RadioGroup,
    InputNumber,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Timeline,
    TimelineItem,
    Upload,
    MessageBox,
    Popover,
    DatePicker,
    // Step,
    // Steps,
    // Rate,
    Tag,
    // Transfer,
    Checkbox,
    Tabs,
    TabPane,
    Descriptions,
    DescriptionsItem,
    Alert,
    Card,
    Collapse,
    CollapseItem,
    Row,
    Col,
    Scrollbar,
    Autocomplete,
} from "element-ui";

locale.use(langSe);
Vue.use(Autocomplete);
Vue.use(Row);
Vue.use(Col);
Vue.use(Scrollbar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Card);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Tooltip);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
// Vue.use(Switch);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(InputNumber);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
// Vue.use(Pagination);
// Vue.use(Timeline);
// Vue.use(TimelineItem);
Vue.use(Upload);
Vue.use(Popover);
Vue.use(DatePicker);
// Vue.use(Slider);
// Vue.use(Step);
// Vue.use(Steps);
// Vue.use(Rate);
Vue.use(Tag);
// Vue.use(Transfer);
Vue.use(Checkbox);
// Vue.use(Rate);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Alert);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$notify = Notification;
Vue.prototype.$ELEMENT = { size: "small" };
// Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
