<template>
    <div class="ml-2">
        <div class="columns" v-loading="$waiting.is('loading')">
            <div class="column is-desktop card-f-h mt-4" v-if="exportData && duplicateRecord == undefined">
                <form-fields
                    ref="formFields"
                    :supplier-invoice="exportData"
                    @createReceipt="createReceipt(...arguments)"
                    @saveSupplierInvoice="saveSupplierInvoice(...arguments)"
                    @denySupplierInvoice="denySupplierInvoice(...arguments)"
                    @deleteInvoice="showDeleteModal()"
                    @bookInvoice="bookInvoice"
                />
            </div>
            <div class="column is-desktop mt-48">
                <div v-if="contentUrl && imgType == 1">
                    <Pdf :file="contentUrl" height="900px" width="100%" />
                </div>
                <div v-else>
                    <zoom-img :size="2" :imageurl="contentUrl" />
                </div>
            </div>
        </div>
        <div v-show="showDuplicateModal">
            <duplicate-modal
                :is-duplicate.sync="isDuplicate"
                :duplicate-check="duplicateCheck"
                :status="duplicateStatus"
                @viewDuplicateDocument="viewDuplicateDocument"
                @saveDuplicateInvoice="saveDuplicateInvoice"
                @bookDuplicateInvoice="bookDuplicateInvoice"
            />
        </div>
        <delete-modal :is-modal-visible.sync="isModalVisible" @deleteInvoice="deleteInvoice" />
        <error-modal :is-modal-visible.sync="errorMessageShow" :error-message.sync="errorMessage" />
    </div>
</template>
<script>
import Api from "../../supplierInvoice.api";
import Enum from "../../supplierInvoice.enum";
export default {
    data: function() {
        return {
            exportData: undefined,
            tempInvoiceNo: 0,
            contentUrl: undefined,
            imgType: 0,
            docId: undefined,
            status: undefined,
            duplicateRecord: false,
            isDuplicate: false,
            duplicateId: undefined,
            duplicateCheck: false,
            isNextQueueLocked: false,
            isModalVisible: false,
            duplicateStatus: Enum.CreditorInvoiceStatus.Utkast,
            errorMessageShow: false,
            errorMessage: "",
        };
    },

    components: {
        Pdf: () => import(/* webpackChunkName: "Pdf" */ "../../../../components/pdf"),
        "form-fields": () => import(/* webpackChunkName: "ZoomImg" */ "./supplierInvoiceFormFields"),
        "zoom-img": () => import(/* webpackChunkName: "ZoomImg" */ "../../../../components/zoomImg"),
        "delete-modal": () => import(/* webpackChunkName: "DeleteModal" */ "../../ui/deleteInvoiceModal"),
        "duplicate-modal": () => import(/* webpackChunkName: "DuplicateModal" */ "../../ui/duplicateInvoiceModal"),
        "error-modal": () => import(/* webpackChunkName: "ErrorModal" */ "../../ui/errorMessageModal"),
    },

    beforeCreate() {
        this.$store.commit("supplierInvoice/setSaveBtnLoading", false);
        this.$store.commit("supplierInvoice/setSendBtnLoading", false);
        this.$store.commit("supplierInvoice/setReceiptBtnLoading", false);
        this.$store.commit("supplierInvoice/setDeleteBtnLoading", false);
    },

    async created() {
        this.$waiting.start("loading");
        this.docId = this.$route.query.docId;
        this.status = this.$route.query.status;
        this.duplicateRecord = this.$route.query.duplicateRecord;
        if (this.duplicateRecord != undefined && this.duplicateRecord) {
            this.exportData = await Api.getInvoiceImage(this.docId);
            if (this.exportData) {
                this.contentUrl = this.exportData.image;
                this.imgType = this.exportData.imageType;
            }
        }
        if (!this.status && this.duplicateRecord == undefined) {
            this.status = Enum.supplierInvoiceStatusList.NotSet;
        }
        if (this.docId != null && this.docId != "" && this.duplicateRecord == undefined) {
            this.exportData = await Api.handleConfirmInvoice(this.docId, this.status);

            if (!this.exportData.isLocked) {
                this.$router.push(`/supplierInvoiceList`);
            } else if (this.exportData) {
                if (this.exportData && this.exportData.isDuplicate) {
                    this.duplicateCheck = false;
                    this.isDuplicate = true;
                    this.duplicateId = this.exportData.duplicateRecordId;
                }
                this.contentUrl = this.exportData.image;
                this.imgType = this.exportData.imageType;
                this.tempInvoiceNo = this.exportData.tempSupplierInvoice.invoiceNumber;
            }
        }
        this.$waiting.end("loading");
    },

    methods: {
        async bookInvoice(value) {
            this.duplicateStatus = Enum.CreditorInvoiceStatus.Attesteras;
            this.saveInvoiceId(value);
            var tempInvoice = this.assignTempSupplierObj(value);
            var isSaved = await Api.bookInvoice(tempInvoice);
            this.handleApiResponse(isSaved);
        },

        showDeleteModal() {
            this.isModalVisible = true;
        },

        async deleteInvoice() {
            if (this.exportData.status == Enum.CreditorInvoiceStatus.Utkast) {
                var deleteId = this.exportData.tempSupplierInvoice.tempSupplierInvoiceId;
            } else {
                deleteId = this.exportData.tempSupplierInvoice.supplierInvoiceCaptureId;
            }
            var isdeleted = await Api.deleteInvoice(deleteId, this.exportData.status);
            this.$notify.success({ title: "Utfört" });
            if (isdeleted) {
                this.isModalVisible = false;
            } else {
                this.isModalVisible = false;
            }
            this.$router.push(`/supplierInvoiceList`);
        },

        async viewDuplicateDocument() {
            window.open(`/supplierInvoiceForm/?docId=` + this.duplicateId + "&duplicateRecord=true");
        },

        closeLockedModal() {
            this.isNextQueueLocked = false;
        },

        closeModal() {
            this.isDuplicate = false;
        },

        bookDuplicateInvoice() {
            this.duplicateCheck = true;
            var child = this.$refs.formFields;
            child.saveObjectChanges(Enum.CreditorInvoiceStatus.Attesteras);
        },

        saveDuplicateInvoice() {
            this.duplicateCheck = true;
            var child = this.$refs.formFields;
            child.saveObjectChanges(Enum.CreditorInvoiceStatus.Utkast);
        },

        async denySupplierInvoice(value, status) {
            var tempInvoice = this.assignTempSupplierObj(value);
            var isDenied = await Api.denySupplierInvoice(tempInvoice, status);
            this.$notify.success({ title: "Utfört" });
            if (isDenied && isDenied.isSuccess) {
                this.$router.push(`/supplierInvoiceList`);
            }
        },

        saveInvoiceId(value) {
            value.tempSupplierInvoice.tempSupplierInvoiceId = this.docId;
            value.status = this.status;
            value.duplicateCheck = this.duplicateCheck;
        },

        processSave(saveReturn) {
            if (saveReturn && saveReturn.isSuccess) {
                this.duplicateCheck = false;
                this.isDuplicate = false;
                this.ShowNextInvoice();
            } else if (saveReturn && saveReturn.isDuplicate) {
                this.duplicateCheck = false;
                this.isDuplicate = true;
                this.duplicateId = saveReturn.duplicateRecordId;
            } else if (saveReturn && saveReturn.errorMessage != null) {
                this.$store.commit("supplierInvoice/clearSendBtnLoading");
                this.$store.commit("supplierInvoice/clearReceiptBtnLoading");

                this.duplicateCheck = false;
                this.errorMessageShow = true;
                this.errorMessage = saveReturn.errorMessage;
            }
        },
        handleApiResponse(saveReturn) {
            if (saveReturn && saveReturn.isSuccess) {
                console.log("🚀 - file: supplierInvoiceForm.vue - line 203 - handleApiResponse - saveReturn.isSuccess", saveReturn.isSuccess);
                this.$notify.success({ title: "Utfört" });
                this.duplicateCheck = false;
                this.isDuplicate = false;
                this.ShowNextInvoice();
            } else if (saveReturn && saveReturn.isDuplicate) {
                this.duplicateCheck = false;
                this.isDuplicate = true;
                this.duplicateId = saveReturn.duplicateRecordId;
            } else if (saveReturn && saveReturn.errorMessage != null) {
                this.$store.commit("supplierInvoice/clearSendBtnLoading");
                this.$store.commit("supplierInvoice/clearReceiptBtnLoading");

                this.duplicateCheck = false;
                this.errorMessageShow = true;
                this.errorMessage = saveReturn.errorMessage;
            }
        },

        assignTempSupplierObj(tempSupplierInvoiceDto) {
            tempSupplierInvoiceDto.voucherTable.forEach(object => {
                delete object["id"];
            });

            let tempInvoice = {
                tempSupplierInvoice: tempSupplierInvoiceDto.tempSupplierInvoice,
                clientInfo: tempSupplierInvoiceDto.clientInfo,
                creditorInfo: tempSupplierInvoiceDto.creditorInfo,
                supplierRadioValues: tempSupplierInvoiceDto.supplierRadioValues,
                supplierAccountValues: tempSupplierInvoiceDto.supplierAccountValues,
                duplicateCheck: tempSupplierInvoiceDto.duplicateCheck,
                isCreditNote: tempSupplierInvoiceDto.isCreditNote,
                voucherTable: tempSupplierInvoiceDto.voucherTable,
                uploadedByClient: tempSupplierInvoiceDto.tempSupplierInvoiceDto,
                isVatNumInEurope: tempSupplierInvoiceDto.isVatNumInEurope,
                originalNetAmount: tempSupplierInvoiceDto.originalNetAmount,
                originalVatAmount: tempSupplierInvoiceDto.originalVatAmount,
                status: tempSupplierInvoiceDto.status,
                comments: tempSupplierInvoiceDto.comments,
                isAccrual: tempSupplierInvoiceDto.isAccrual,
            };
            return tempInvoice;
        },

        async saveSupplierInvoice(value, status) {
            this.$store.commit("supplierInvoice/setSaveBtnLoading", true);
            this.saveInvoiceId(value);
            var tempInvoice = this.assignTempSupplierObj(value);
            var isSaved = await Api.saveSupplierInvoice(tempInvoice, status);
            this.$notify.success({ title: "Utfört" });
            if (isSaved) this.processSave(isSaved);
        },

        async createReceipt(value) {
            this.$store.commit("supplierInvoice/setReceiptBtnLoading", true);
            this.saveInvoiceId(value);
            var tempInvoice = this.assignTempSupplierObj(value);
            var isSaved = await Api.createReceipt(tempInvoice);
            this.$notify.success({ title: "Utfört" });
            if (isSaved) this.processSave(isSaved);
        },

        async ShowNextInvoice() {
            var nextQueue = await Api.getNextQueue();
            if (nextQueue && nextQueue != "") {
                window.location.href = nextQueue;
            } else {
                this.isNextQueueLocked = true;
                this.$router.push(`/supplierInvoiceList`);
            }
        },
    },

    computed: {
        showDuplicateModal() {
            if (this.exportData) {
                if (
                    this.exportData.status == Enum.CreditorInvoiceStatus.Utkast ||
                    this.exportData.status == Enum.supplierInvoiceStatusList.Exported ||
                    this.exportData.status == Enum.supplierInvoiceStatusList.NotSet
                ) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
