const Enum = {
    kycByClientStatus: {
        0: "Utkast",
        1: "Inskickad",
        2: "Godkänd",
        3: "Ingen åtgärd krävs",
        4: "Ej påbörjad",
    },
    PageStatus: {
        Utkast: 0,
        Inskickad: 1,
        Godkänd: 2,
        IngenAtgärdKrävs: 3,
        EjPåbörjad: 4,
    },
    kycByClientStatusNew: {
        sentToClient: 0,
        inProgress: 1,
        submitted: 2,
        noActionRequired: 3,
        notSent: 4,
        toSupplement: 5,
        supplemented: 6,
        needExtraCheck: 7,
        denied: 8,
        blocked: 9,
    },
    kycByClientNewCorporateTax: {
        0: "Saknas F-Skatt information",
        1: "F-Skatt Registerad",
        2: "F-Skatt Ej registerad",
    },
    kycByClientNewVatStatus: {
        0: "Saknas Moms information",
        1: "Moms Registerad",
        2: "Moms Ej registerad",
    },
    kycByClientNewEmployerStatus: {
        0: "Saknas arbetsgivarinformation",
        1: "Registerad arbetsgivare",
        2: "Ej registerad arbetsgivare",
    },
    kycByClientNewTurnOver: {
        1: "0 - 500 000",
        2: "500 000 - 1 000 000",
        3: "1 000 000 - 3 000 000",
        4: "3 000 000 - 5 000 000",
        5: "5 000 000 - 20 000 000",
        6: "Över 20 000 000",
    },
};
export default Enum;
