<template>
    <div class="card-tail card-f-h">
        <Page :max-width="1500">
            <Stack>
                <!-- <SearchClient v-if="pageState == 0" class="is-half" placeholder="Sök klient..." :options="clients" @on-selected="onClientChange" style="width: 300px" /> -->
                <ClientsSearch v-if="pageState == 0" @on-selected="onClientChange" />
                <List v-if="pageState == 0" :items="employees" :scroll="true" :search="false" :headers="headers" style="font-size: 13px;height: 500px;width: 70%;" @click="clickRow" />
                <EmployeePage v-if="pageState == 1" :client-id="clientId" :individual-client-id="individualClientId" @back="goBackToList" />
            </Stack>
        </Page>
    </div>
</template>
<script>
import Page from "@/components/layout/Page.vue";
import Stack from "@/components/layout/Stack.vue";
// import SearchClient from "../../components/searchClient.vue";
import List from "./vacationDebtList.vue";
import EmployeePage from "./vacationDebtEmployeePage.vue";

import Api from "./vacationDebt.api.js";

export default {
    components: {
        Page,
        Stack,
        // SearchClient,
        List,
        EmployeePage,
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
    },

    data: function() {
        return {
            clients: [],
            employees: [],
            pageState: 0,
            clientId: "",
            individualClientId: "",
            headers: [
                { name: "Namn", prop: "name" },
                { name: "Personnummer", prop: "civicNumber" },
                { name: "Dagar", prop: "days", align: "right" },
                { name: "Semesterdagslön", prop: "dayVacationSalary + tillägg", align: "right" },
                { name: "Semesterlöneskuld", prop: "vacationSalaryDebt", align: "right" },
                { name: "Arbetsgivaravgiftsskuld", prop: "taxDebt", align: "right" },
                { name: "Total skuld", prop: "summaryDebt", align: "right" },
            ],
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Semesterskuld");
        document.title = "Semesterskuld";
    },

    // async created() {
    // this.clients = await Api.getClients();
    // },

    methods: {
        async onClientChange(client) {
            if (!client) {
                this.employees = [];
                return;
            }
            this.clientId = client.value;
            let result = await Api.getEmployees(client.value);
            this.employees = result.employees;
            this.employees.push({
                name: "Total",
                vacationSalaryDebt: result.vacationSalaryDebt,
                taxDebt: result.taxDebt,
                summaryDebt: result.summaryDebt,
            });
            document.title = "Semesterskuld - " + client.name;
        },

        async clickRow(item) {
            this.pageState = 1;
            this.individualClientId = item.individualClientId;
        },

        goBackToList() {
            this.pageState = 0;
            this.individualClientId = 0;
        },
    },
};
</script>
