<template>
    <div class="card-tail card-f-h">
        <Page :max-width="1500">
            <Stack>
                <List v-if="pageState == 0" :items="filteredpayroll" :scroll="true" :search="false" :headers="headers" style="font-size: 13px;height: 500px;width: 100%;" @click="clickRow" />
                <ClientPage v-if="pageState == 1" :client-id="clientId" />
            </Stack>
        </Page>
    </div>
</template>
<script>
import Page from "@/components/layout/Page.vue";
import Stack from "@/components/layout/Stack.vue";
import List from "./salaryVacationList.vue";
import ClientPage from "./salaryVacationClientPage.vue";
import Api from "./salaryVacation.api.js";

export default {
    components: {
        Page,
        Stack,
        List,
        ClientPage,
    },

    data: function() {
        return {
            payrolls: [],
            filteredpayroll: [],
            earners: [],
            pageState: 0,
            clientId: "",
            headers: [{ name: "Klient", prop: "client" }],
            headers2: [{ name: "Namn", prop: "name" }],
        };
    },

    watch: {
        isGlobalClientSelected: function() {
            this.getPayroll();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Semesterberedning");
        document.title = "Semesterberedning";
    },

    async created() {
        this.payrolls = await Api.getPayrolls();
        this.getPayroll();
    },

    methods: {
        getPayroll() {
            if (this.isGlobalClientSelected) {
                this.filteredpayroll = this.payrolls.filter(payroll => payroll.clientId == this.$store.state.topBar.selectedClient.value);
            } else {
                this.filteredpayroll = this.payrolls;
            }
        },

        async clickRow(item) {
            this.pageState = 1;
            this.clientId = item.clientId;
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
