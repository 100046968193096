<template>
    <div>
        <div class="card-tail card-f-h">
            <el-tabs v-model="activeTab">
                <el-tab-pane label="Att hantera" name="toHandle" lazy>
                    <div class="card-tail" v-loading="$waiting.is('loading')">
                        <el-table :data="awaitingApprovalInvoices" size="small" @current-change="handleDraftInvoiceClick">
                            <el-table-column prop="factoringPaymentStatus" label="Status" sortable="custom" width="150" />
                            <el-table-column prop="clientName" label="Klient" />
                            <el-table-column prop="customerName" label="Kund" />
                            <el-table-column prop="grossAmount" label="Bruttobelopp" align="right" width="150">
                                <template slot-scope="scope">{{ scope.row.grossAmount | swedishNumberFormat }} SEK</template>
                            </el-table-column>
                            <el-table-column prop="invoiceDate" label="Fakturadatum" sortable="custom" align="right" width="150" />
                            <el-table-column prop="dueDate" label="Förfallodatum" sortable="custom" align="right" width="150" />
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Hanterade" name="handled">
                    <TopBarInvoices @searchInputChange="searchInputChange" @searchParametersChanged="syncDataWithQueryParameters" />
                    <el-table :default-sort="preSelectedSort" :data="otherStatusInvoices" size="small" @current-change="handleInvoiceClick" :row-class-name="getRowColor">
                        <el-table-column prop="factoringPaymentStatus" label="Status" sortable="custom" width="90" />
                        <el-table-column prop="invoiceNumber" label="F#" width="100" />
                        <el-table-column prop="clientName" label="Klient" />
                        <el-table-column prop="customerName" label="Kund" />
                        <el-table-column prop="remainingAmount" label="Kvar att betala" align="right" width="130">
                            <template slot-scope="scope">{{ scope.row.remainingAmount | swedishNumberFormat }} SEK</template>
                        </el-table-column>
                        <el-table-column prop="grossAmount" label="Bruttobelopp" sortable="custom" align="right" width="140">
                            <template slot-scope="scope">{{ scope.row.grossAmount | swedishNumberFormat }} SEK</template>
                        </el-table-column>
                        <el-table-column prop="invoiceDate" label="Fakturadatum" sortable="custom" align="right" width="150" />
                        <el-table-column prop="dueDate" label="Förfallodatum" sortable="custom" align="right" width="150" />
                        <el-table-column prop="timeWhenPaid" label="Utbetald" sortable="custom" align="right" width="130" />
                        <el-table-column prop="commission" label="Provision" sortable="custom" align="right" width="110" />
                    </el-table>
                    <!-- <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalInvoiceNumber" /> -->
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Api from "./factoring.api.js";
import queryString from "query-string";

export default {
    data() {
        return {
            activeTab: "toHandle",
            awaitingApprovalInvoices: [],
            otherStatusInvoices: [],
            factoringInvoiceId: null,
            clientId: "",
            totalInvoiceNumber: 0,

            pageNumber: 1,
            pageSize: 15,
            sortBy: "",
            sortByType: "",
            query: "",
            clientStatus: "active",
            searchQuery: "",
            status: "",
            clientName: "",
            clientNr: null,
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Faktura");
        document.title = "Fakturor";
    },

    async created() {
        this.getClientHeader();
        this.activeComponent = this.$route.meta.activeTab;
        this.$waiting.start("loading");
        try {
            const response = await Api.getAwaitingApprovalInvoicesForAllClients(this.$route.params.clientId);
            this.awaitingApprovalInvoices = response.awaitingApprovalInvoices;
        } catch (error) {
            console.error("Error fetching awaitingApprovalInvoices:", error);
        } finally {
            this.$waiting.end("loading");
        }

        this.updateUrlAndGetAll();
    },

    components: {
        TopBarInvoices: () => import(/* webpackChunkName: "FactoringTopBar" */ "./TopBarInvoices.vue"),
        // Pagination: () => import(/* webpackChunkName: "PaginationEl" */ "../../components/paginationEl.vue"),
    },

    methods: {
        async getClientHeader() {
            this.header = await Api.getClientHeader(this.clientId);
        },

        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            query.clientStatus = this.clientStatus;
            query.isTest = this.isTest;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },

        searchInputChange() {
            this.$refs.paginationComponent.updateUrl(1);
            this.updateUrlAndGetAll();
        },

        syncDataWithQueryParameters() {
            setTimeout(() => {
                this.updateUrlAndGetAll();
            }, 200);
        },
        updateUrlAndGetAll() {
            const { pageNumber, pageSize, sortBy, sortByType, search, status } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.sortBy = sortBy || "";
            this.sortByType = sortByType || "";
            this.status = status && status !== "paidCount" ? status : "paidCount";
            this.getFactoringInvoiceList();
        },
        async getFactoringInvoiceList() {
            try {
                var response = await Api.getOtherStatusInvoicesForAllClients(
                    this.$route.params.clientId,
                    this.pageNumber,
                    this.pageSize,
                    this.query,
                    this.sortBy,
                    this.sortByType,
                    this.clientName,
                    this.clientNr,
                    this.status,
                );

                this.otherStatusInvoices = response.data;
                this.totalInvoiceNumber = parseInt(response.headers["x-pagination-totalcount"]);
            } catch (error) {
                console.error("Error loading data:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },

        getRowColor(invoice) {
            switch (invoice.row.factoringPaymentStatus) {
                case "Betald":
                    return "row-green";
                case "Förfallen" && "Nekad":
                    return "row-red";
                case "Överbetald":
                    return "row-yellow";
                default:
                    return {};
            }
        },

        handleInvoiceClick(invoice) {
            console.log("🚀 ~ handleInvoiceClick ~ invoice:", invoice);
            console.log("route: ", this.$route);
            this.$waiting.start("loading");
            this.isRenderedData = true;
            this.selectedInvoiceId = invoice.factoringInvoiceId;
            this.clientId = invoice.clientId;

            try {
                const routePath = `/clientApproved/${this.clientId}/getInvoiceInfo/${this.selectedInvoiceId}`;

                this.$router.push({
                    path: routePath,
                    query: {
                        selectedInvoiceId: this.selectedInvoiceId,
                    },
                });
            } catch (error) {
                console.error("Error handling invoice click:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },

        handleDraftInvoiceClick(invoice) {
            this.$waiting.start("loading");
            this.isRenderedData = true;
            this.selectedInvoiceId = invoice.factoringInvoiceId;
            this.clientId = invoice.clientId;

            try {
                const routePath = `/clientApproved/${this.clientId}/getDraftInvoiceInfo/${this.selectedInvoiceId}`;

                this.$router.push({
                    path: routePath,
                    query: {
                        selectedInvoiceId: this.selectedInvoiceId,
                    },
                });
            } catch (error) {
                console.error("Error handling invoice click:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },
};
</script>
<style>
.row-green {
    background-color: #c8e6c9 !important;
}
.row-red {
    background-color: #ffcdd2 !important;
}
.row-yellow {
    background-color: #fff9c4 !important;
}
</style>
