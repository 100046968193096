<template>
    <div v-loading="$waiting.is('loading')">
        <div>
            <button class="button is-info" style="margin-bottom: 25px;" @click="pollRossum" v-if="content.name == 'Validera'">Uppdatera</button>
            <my-list :items="filteredList" @click="processSIClick" :scroll="true" :search="false" :headers="headers" style="font-size: 13px">
                <template slot-scope="doc">
                    <td>
                        {{ doc.supplierInvoiceCaptureId }}
                        <span v-if="doc.version == 0" style="color:#B8B8B8;"> <i class="fas fa-asterisk"/></span>
                    </td>
                    <td>{{ doc.clientName }}</td>
                    <td>{{ doc.supplierName }}</td>
                    <td>{{ doc.invoiceNumber }}</td>
                    <td style="text-align: end">
                        <p v-if="doc.amount != null">
                            {{ doc.amount | swedishNumberFormat }}
                        </p>
                    </td>
                    <td>{{ doc.currency }}</td>
                    <td>{{ doc.dueDate }}</td>
                    <td>
                        {{ getStatus(doc.supplierInvoiceCaptureStatus) }}
                    </td>
                    <td>{{ doc.filename }}</td>
                    <td>
                        {{ doc.uploadedByClient }}
                    </td>
                    <td v-if="canDelete && !isWaitingToPay" style="color: #eb9494" v-on:click.stop="" @click="showModal(doc)">
                        <i class="fas fa-times" title="Ta bort" />
                    </td>
                    <td v-if="doc.isDuplicate && !isWaitingToPay" style="color:#79a6d2;" v-on:click.stop="" @click="openDuplicateWindow(doc)">
                        <i class="fas fa-exclamation-triangle" title="Visa dubblett" />
                    </td>
                    <td v-if="doc.isLocked && !isWaitingToPay" style="color:#79a6d2;" v-on:click.stop="">
                        <i class="fas fa-lock" :title="doc.lockedBy" />
                    </td>
                    <td v-if="isWaitingToPay">
                        {{ doc.autoGiroStatus }}
                    </td>
                    <td v-if="isWaitingToPay">
                        {{ doc.painStatus }}
                    </td>
                    <td v-if="showCopyButton(doc)" style="color:#79a6d2;" v-on:click.stop="" @click="triggerDialogue(doc)">
                        <i class="far fa-copy" title="Kopiera faktura" />
                    </td>
                    <td v-on:click.stop="" v-if="isCreditNote">
                        <check-box @change="updateCreditNoteStatus(doc)" v-model="doc.creditNoteStatus" />
                    </td>
                </template>
            </my-list>
        </div>
        <div v-if="showPagination">
            <pagination :total-pages="totalPages" :current-page="currentPage" :total="1" @pagechanged="pageChanged" />
        </div>
        <delete-modal :is-modal-visible.sync="isModalVisible" :delete-failure.sync="deleteFailure" @deleteInvoice="removeInvoice" :loading="deleteLoading" />
    </div>
</template>
<script>
import moment from "moment";
import "moment/locale/se";
import Api from "../../supplierInvoice.api";
import Enum from "../../supplierInvoice.enum";
export default {
    props: ["content", "newVersion", "selectedClient"],

    data: function() {
        return {
            pageSize: 1250,
            currentPage: 1,
            totalCount: 0,
            deleteLoading: false,
            canDelete: true,
            selectedDoc: undefined,
            isModalVisible: false,
            deleteFailure: false,
            documentsList: undefined,
            selectedStatus: undefined,
            showPagination: undefined,
            headers: [
                { name: "Id", prop: "supplierInvoiceCaptureId", width: "90px" },
                { name: "Klient", prop: "clientName", width: "300px" },
                { name: "Leverantör", prop: "supplierName", width: "300px" },
                { name: "Fakturanummer", prop: "invoiceNumber", width: "110px" },
                { name: "Belopp", prop: "amount", width: "110px", align: "right" },
                { name: "Valuta", prop: "amount", width: "50px" },
                { name: "Förfallodatum", prop: "dueDate", width: "110px" },
                { name: "Status", prop: "supplierInvoiceCaptureStatus", width: "110px" },
                { name: "Filenamn", prop: "filename", width: "200px" },
                { name: "Uppladdad av", prop: "uploadedByClient", width: "200px" },
            ],
        };
    },

    watch: {
        content: {
            immediate: true,
            handler() {
                this.init();
            },
        },
        selectedClient: {
            handler() {
                this.init();
            },
        },
    },

    components: {
        "my-list": () => import("../../../../components/list"),
        "delete-modal": () => import("../../ui/deleteInvoiceModal"),
        "check-box": () => import("../../../../components/checkbox.vue"),
        pagination: () => import("../../../../components/pagination"),
    },

    created() {
        this.$waiting.end("loading");
    },

    methods: {
        async pollRossum() {
            this.$waiting.start("loading");
            await Api.rossumPollAction();
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("loading");
            this.documentsList = undefined;
            switch (this.content.name) {
                case Enum.supplierInvoiceTabList.Validate:
                    this.documentsList = await Api.getDocumentsToReview();
                    break;
                case Enum.supplierInvoiceTabList.Register:
                    this.documentsList = await Api.getDocumentsExported();
                    break;
                default:
                    break;
            }
        },

        triggerDialogue(doc) {
            const self = this;
            this.$dialog
                .confirm("Är du säker på att du vill skapa en kopia av denna faktura?")
                .then(function(isSuccess) {
                    if (isSuccess == true) {
                        self.copyCreditorInvoice(doc);
                    }
                })
                .catch(function() {});
        },

        async copyCreditorInvoice(doc) {
            var result = await Api.copySupplierInvoice(doc.supplierInvoiceCaptureId);
            if (result) {
                const type = result == true ? "success" : "error";
                var messsage = result == true ? "Kopia av leverantörsfakturan har skapats." : "Det blev ett fel när en kopia skulle skapas.";

                this.$toasted.show(messsage, {
                    theme: "bubble",
                    duration: null,
                    position: "bottom-center",
                    type: type,
                    keepOnHover: true,
                    action: {
                        text: "Cancel",
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
        },

        async pageChanged(value) {
            this.currentPage = Math.round(value);
            this.documentsList = await Api.getCreditInvoicePageList(this.selectedStatus, this.selectedClientId, this.currentPage, this.pageSize);
        },

        async callDocumentList(value) {
            switch (value) {
                case Enum.supplierInvoiceTabList.Deleted:
                case Enum.supplierInvoiceTabList.Denied:
                case Enum.supplierInvoiceTabList.Paid:
                case Enum.supplierInvoiceTabList.AdminDenied:
                    this.showPagination = true;
                    this.documentsList = await Api.getCreditInvoicePageList(this.selectedStatus, this.selectedClientId, this.currentPage, this.pageSize);
                    break;
                case Enum.supplierInvoiceTabList.WaitingOnClient:
                case Enum.supplierInvoiceTabList.WaitingToPay:
                    this.showPagination = false;
                    this.documentsList = await Api.getCreditInvoiceList(this.selectedStatus, this.selectedClientId);
                    break;
                case Enum.supplierInvoiceTabList.Creditnote:
                    this.showPagination = false;
                    this.documentsList = await Api.getCreditNote(this.selectedClientId);
                    break;
                case Enum.supplierInvoiceTabList.ReceiptCreated:
                    this.showPagination = false;
                    this.documentsList = await Api.getReceiptInvoices(this.selectedClientId);
                    break;
                default:
                    this.showPagination = false;
                    this.documentsList = await Api.getCreditInvoiceList(this.selectedStatus, this.selectedClientId);
                    break;
            }
        },

        async init() {
            try {
                this.currentPage = 1;
                if (this.content.name != Enum.supplierInvoiceTabList.NotSet) {
                    if (
                        this.content.name != Enum.supplierInvoiceTabList.Validate &&
                        this.content.name != Enum.supplierInvoiceTabList.Register &&
                        this.content.name != Enum.supplierInvoiceTabList.NotSet
                    ) {
                        this.documentsList = undefined;

                        switch (this.content.name) {
                            case Enum.supplierInvoiceTabList.Deleted:
                                this.selectedStatus = Enum.CreditorInvoiceStatus.Deleted;
                                break;
                            case Enum.supplierInvoiceTabList.Denied:
                                this.selectedStatus = Enum.CreditorInvoiceStatus.Nekad;
                                break;
                            case Enum.supplierInvoiceTabList.WaitingOnClient:
                                this.selectedStatus = Enum.CreditorInvoiceStatus.Attesteras;
                                break;
                            case Enum.supplierInvoiceTabList.WaitingToPay:
                                this.selectedStatus = Enum.CreditorInvoiceStatus.VäntarBetalning;
                                break;
                            case Enum.supplierInvoiceTabList.Paid:
                                this.selectedStatus = Enum.CreditorInvoiceStatus.Betald;
                                break;
                            case Enum.supplierInvoiceTabList.AdminDenied:
                                this.selectedStatus = Enum.CreditorInvoiceStatus.AdminNekad;
                                break;
                            default:
                                this.selectedStatus = Enum.CreditorInvoiceStatus.Deleted;
                                break;
                        }
                        await this.callDocumentList(this.content.name);
                        if (this.documentsList && this.documentsList[0]) this.totalCount = this.documentsList[0].totalCount;
                    } else {
                        this.showPagination = false;
                        this.documentsList = undefined;
                        switch (this.content.name) {
                            case Enum.supplierInvoiceTabList.Validate:
                                this.documentsList = await Api.getDocumentsToReview();
                                break;
                            case Enum.supplierInvoiceTabList.Register:
                                this.documentsList = await Api.getDocumentsExported();
                                break;
                            default:
                                break;
                        }
                    }
                }
                this.headers = this.headers.filter(function(item) {
                    return item.name !== "Autogiro" && item.name !== "Pain Status" && item.name !== "";
                });
                if (this.content.name == Enum.supplierInvoiceTabList.WaitingToPay) {
                    this.headers.push({ name: "Autogiro", prop: "autoGiroStatus", width: "100px" });
                    this.headers.push({ name: "Pain Status", prop: "painStatus", width: "150px" });
                } else {
                    this.headers.push({ name: "", prop: "number", width: "30px" });
                    this.headers.push({ name: "", prop: "duplicateRecordId", width: "20px" });
                    this.headers.push({ name: "", prop: "isLocked", width: "20px" });
                }
                this.$emit("loading", this.documentsList);
            } catch {
                console.log("error");
                this.$waiting.end("loading");
                this.$emit("errorLoading");
            }
        },

        showModal(doc) {
            this.isModalVisible = true;
            this.selectedDoc = doc;
        },

        closeModal() {
            this.isModalVisible = false;
        },

        updateCreditNoteStatus(doc) {
            Api.updateCreditNoteStatus(doc.supplierInvoiceCaptureId, doc.creditNoteStatus);
        },

        async removeInvoice() {
            this.deleteLoading = true;
            var isdeleted = await Api.deleteInvoice(this.selectedDoc.supplierInvoiceCaptureId, this.selectedDoc.supplierInvoiceCaptureStatus);
            if (isdeleted) {
                this.deleteFailure = false;
                this.isModalVisible = false;
                this.$notify.success({ title: "Utfört" });
            } else {
                this.isModalVisible = false;
                this.deleteFailure = true;
            }
            this.documentsList = await Api.getUploadedDocuments();
            this.deleteLoading = false;
        },

        async openDuplicateWindow(doc) {
            this.openImage(doc.duplicateRecordId);
        },

        getStatus(val) {
            switch (this.content.name) {
                case Enum.supplierInvoiceTabList.Validate:
                case Enum.supplierInvoiceTabList.Register:
                    this.canDelete = true;
                    return Enum.supplierInvoiceStatus[val];
                default:
                    this.canDelete = false;
                    return Enum.CreditorInvoiceStatusNumeric[val];
            }
        },
        openImage(docId) {
            window.open(`/supplierInvoiceForm/?docId=` + docId + "&duplicateRecord=true");
        },

        async processSIClick(row) {
            let canContinue = true;
            if (row.version == 0) {
                return;
            }
            switch (row.supplierInvoiceCaptureStatus) {
                case Enum.supplierInvoiceStatusList.To_review:
                    // case Enum.supplierInvoiceStatusList.Reviewing:
                    // case Enum.supplierInvoiceStatusList.Importing:
                    this.$waiting.start("loading");
                    this.getValidationScreen(row);
                    break;
                case Enum.supplierInvoiceStatusList.Uploaded:
                case Enum.supplierInvoiceStatusList.Reviewing:
                case Enum.supplierInvoiceStatusList.Importing:
                    break;
                case Enum.CreditorInvoiceStatus.Deleted:
                    this.openImage(row.supplierInvoiceCaptureId);
                    break;
                case Enum.supplierInvoiceStatusList.Exported:
                    canContinue = await Api.checkLock(Enum.LockedTable.SupplierInvoiceCaptures, row.supplierInvoiceCaptureId);
                    if (canContinue) {
                        this.routeToForm(row.supplierInvoiceCaptureId, row.supplierInvoiceCaptureStatus);
                    }
                    break;
                case Enum.supplierInvoiceStatusList.Utkast:
                    canContinue = await Api.checkLock(Enum.LockedTable.CreditorInvoice, row.supplierInvoiceCaptureId);
                    if (canContinue) {
                        this.routeToForm(row.supplierInvoiceCaptureId, row.supplierInvoiceCaptureStatus);
                    }
                    break;
                default:
                    this.routeToForm(row.supplierInvoiceCaptureId, row.supplierInvoiceCaptureStatus);
                    break;
            }
        },

        routeToForm(id, status) {
            this.$router.push(`/supplierInvoiceForm/?docId=` + id + "&status=" + status);
        },

        async getValidationScreen(row) {
            var url = await Api.getValidationScreen(row.supplierInvoiceCaptureId);
            if (url) {
                window.location = url;
            }
        },

        goLogin() {
            Api.postRossumLogin();
        },

        uploadDocuments() {
            Api.getUploadDocuments();
        },

        getDueDate(date) {
            return moment
                .utc(date, "YYYY-MM-DD hh:mm")
                .local()
                .format("YYYY-MM-DD");
        },

        getAutoGiroStatus(val) {
            return Enum.BgAutogiroStatus[val];
        },

        getPainStatus(val) {
            return Enum.PainStatus[val];
        },

        filterNewVersion(list) {
            if (this.newVersion && list != undefined) {
                var newList = list.filter(l => {
                    return l.version == this.newVersion;
                });
                return newList;
            } else {
                return list;
            }
        },

        mapList(list) {
            var newList = this.filterNewVersion(list);
            return newList.map(item => ({
                supplierInvoiceCaptureId: item.supplierInvoiceCapture.supplierInvoiceCaptureId,
                filename: item.supplierInvoiceCapture.filename,
                supplierInvoiceCaptureStatus: item.supplierInvoiceCapture.supplierInvoiceCaptureStatus,
                dueDate: item.dueDate,
                invoiceNumber: item.invoiceNumber,
                supplierName: item.supplierName,
                clientName: item.clientName,
                amount: item.amount,
                isDuplicate: item.isDuplicate,
                duplicateRecordId: item.duplicateRecordId,
                isLocked: item.isLocked,
                lockedBy: item.lockedBy,
                currency: item.currency,
                autoGiroStatus: this.getAutoGiroStatus(item.autoGiroStatus),
                painStatus: this.getPainStatus(item.painStatus),
                version: item.version,
                creditNoteStatus: item.creditNoteStatus,
                uploadedByClient: item.uploadedByClient,
            }));
        },

        showCopyButton(doc) {
            if (
                doc.version == 1 &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Uploaded &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Importing &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Failed_import &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.To_review &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Reviewing &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Exporting &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Exported &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Failed_export &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Postponed &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Deleted &&
                doc.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.NotSet
            ) {
                return true;
            }
            return false;
        },
    },

    computed: {
        totalPages() {
            return this.totalCount / this.pageSize + 1;
        },

        isWaitingToPay() {
            if (this.content.name == Enum.supplierInvoiceTabList.WaitingToPay) {
                return true;
            }
            return false;
        },

        isCreditNote() {
            if (this.content.name == Enum.supplierInvoiceTabList.Creditnote) {
                return true;
            }
            return false;
        },

        sorted_list() {
            if (this.documentsList) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                return this.documentsList.sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1));
            } else return null;
        },

        selectedClientId() {
            return this.isGlobalClientSelected ? this.$store.state.topBar.selectedClient.value : this.selectedClient;
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },

        filteredList() {
            var list;
            if (this.documentsList != undefined) {
                switch (this.content.name) {
                    case Enum.supplierInvoiceTabList.Validate:
                        list = this.documentsList.filter(item => {
                            return (
                                item.supplierInvoiceCapture.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Exported &&
                                item.supplierInvoiceCapture.supplierInvoiceCaptureStatus != Enum.supplierInvoiceStatusList.Utkast
                            );
                        });
                        return this.mapList(list);
                    case Enum.supplierInvoiceTabList.Register:
                        list = this.documentsList.filter(item => {
                            return (
                                item.supplierInvoiceCapture.supplierInvoiceCaptureStatus == Enum.supplierInvoiceStatusList.Exported ||
                                item.supplierInvoiceCapture.supplierInvoiceCaptureStatus == Enum.supplierInvoiceStatusList.Utkast
                            );
                        });
                        return this.mapList(list);
                    case Enum.supplierInvoiceTabList.WaitingOnClient:
                        list = this.sorted_list;
                        return this.mapList(this.documentsList);
                    default:
                        return this.mapList(this.documentsList);
                }
            }
            return list;
        },
    },
};
</script>

<style scoped>
table tr td {
    font-size: 13.5px;
}
.set-pointer {
    cursor: pointer;
}
table tr:hover {
    background-color: #fbcccc;
}
</style>
