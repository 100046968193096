<template>
    <div class="card-tail card-f-h">
        <Stack :space="25">
            <div class="tabs is-boxed" v-bind:class="{ disabledbutton: isLoading }" style="margin-top: 20px">
                <ul>
                    <li v-for="tab in filteredTab" :key="tab.name" :class="{ 'is-active': tab.isActive }">
                        <div style="display: flex; flex-direction: row">
                            <a @click="selectTab(tab)">
                                {{ tab.name }}
                                <span v-if="isLoading && tab.isActive"><button class="button is-loading is-white is-small"/></span
                            ></a>
                        </div>
                    </li>
                </ul>
            </div>
            <component v-bind:is="currentTabComponent" :content="selectedTab" />
        </Stack>
    </div>
</template>
<script>
import SendEmail from "./sendEmail";
import SendMultipleEmail from "./sendMultipleEmail";
import Enum from "./email.enum.js";
import Stack from "../../components/layout/Stack.vue";

export default {
    data: function() {
        return {
            isLoading: false,
            currentTab: "",
            selectedTab: {},
            tabs: [
                { name: Enum.emailTabList.SingleClient, isActive: true, display: true },
                { name: Enum.emailTabList.MultipleClient, isActive: false, display: false },
            ],
        };
    },

    components: {
        Stack,
        SendEmail,
        SendMultipleEmail,
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Skicka epost");
        document.title = "Skicka epost";
    },

    methods: {
        selectTab(selectedTab) {
            if (selectedTab != undefined) {
                this.tabs.forEach(tab => {
                    tab.isActive = tab.name == selectedTab.name;
                });
                this.currentTab = selectedTab.name;
                this.selectedTab = selectedTab;
            }
        },
    },

    computed: {
        currentTabComponent() {
            if (!this.currentTab) {
                return "SendEmail";
            }
            switch (this.currentTab) {
                case Enum.emailTabList.SingleClient:
                    return "SendEmail";
                case Enum.emailTabList.MultipleClient:
                    return "SendMultipleEmail";
                default:
                    return "SendMultipleEmail";
            }
        },

        filteredTab() {
            return this.isGlobalClientSelected ? this.tabs.filter(tab => tab.display == true) : this.tabs;
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
