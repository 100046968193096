<template>
    <Stack :space="15" v-if="onboardClient">
        <Stack :space="5">
            <Columns align-y="center">
                <Column :width="15">
                    <Heading level="2" color="comp">Allmänt</Heading>
                </Column>
                <Column :width="25" />
                <Column :width="5" />
                <Column :width="10">
                    <Heading level="2" color="comp">
                        Från Checkbiz
                    </Heading>
                </Column>
                <Column :width="10">
                    <button class="button is-info is-small is-rounded" :disabled="isCompleted" @click="fetchCheckbiz()" :class="[{ 'is-loading': checkbizLoading }]">
                        Hämta checkbiz
                    </button>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15">
                    <TextBase size="medium" weight="bold">Företagstyp</TextBase>
                </Column>
                <Column :width="25">
                    <Dropdown
                        :options="companyTypeOptions"
                        :value="onboardClient.companyType"
                        v-model="onboardClient.companyType"
                        @on-change="value => (onboardClient.companyType = value)"
                        v-validate="'required|numeric|min_value:1'"
                        name="Företagstyp"
                        :error="errors.first('Företagstyp')"
                    />
                </Column>
                <Column :width="5" />
                <Column :width="10"> <TextBase size="medium" weight="bold">Företagstyp</TextBase></Column>
                <Column v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ getEnum.companyCode[checkBizInfo.cbCompanyCode] }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Företagsnamn</TextBase> </Column>
                <Column :width="25">
                    <Input v-validate="'required|max:250'" name="Företagsnamn" :error="errors.first('Företagsnamn')" :value="onboardClient.companyName" v-model="onboardClient.companyName" />
                </Column>
                <Column :width="5" />
                <Column :width="10"> <TextBase size="medium" weight="bold">Företagsnamn</TextBase> </Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ checkBizInfo.cbCompanyName }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Orgnummer</TextBase> </Column>
                <Column :width="25">
                    <Input
                        name="Orgnummer"
                        :error="errors.first('Orgnummer')"
                        :value="onboardClient.organizationNumber"
                        v-model="onboardClient.organizationNumber"
                        v-validate="{ required: true, regex: /^([0-9-]+)$/ }"
                    />
                </Column>
                <Column :width="5" />
                <Column :width="10"> <TextBase size="medium" weight="bold">Organisationsnummer</TextBase></Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ checkBizInfo.cbOrganizationNumber }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Telefonnummer</TextBase> </Column>
                <Column :width="25">
                    <Input
                        :value="onboardClient.phoneNumber"
                        v-model="onboardClient.phoneNumber"
                        v-validate="{ required: true, max: 20, regex: /^[0-9-\s()\+]+$/ }"
                        name="Telefonnummer"
                        :error="errors.first('Telefonnummer')"
                    />
                </Column>
                <Column :width="5" />
                <Column :width="10"> <TextBase size="medium" weight="bold">Telefonnummer</TextBase></Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ checkBizInfo.cbPhoneNumber }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">E-post</TextBase> </Column>
                <Column :width="25">
                    <Input v-validate="'required|email'" :value="onboardClient.email" v-model="onboardClient.email" name="E-post" :error="errors.first('E-post')" />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Adress</TextBase> </Column>
                <Column :width="25">
                    <Input v-validate="'required|max:200'" :value="onboardClient.address" v-model="onboardClient.address" name="Adress" :error="errors.first('Adress')" />
                </Column>
                <Column :width="5" /> <Column :width="10"> <TextBase size="medium" weight="bold">Adress</TextBase></Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ checkBizInfo.cbStreet }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Adress2</TextBase> </Column
                ><Column :width="25"><Input :value="onboardClient.address2" v-model="onboardClient.address2" /> </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Postnummer</TextBase> </Column>
                <Column :width="25">
                    <Input v-validate="'required|max:10'" :value="onboardClient.postalCode" v-model="onboardClient.postalCode" name="Postnummer" :error="errors.first('Postnummer')" />
                </Column>
                <Column :width="5" /> <Column :width="10"> <TextBase size="medium" weight="bold">Postnummer</TextBase></Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ checkBizInfo.cbZipcode }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Ort</TextBase> </Column>
                <Column :width="25">
                    <Input :value="onboardClient.city" v-model="onboardClient.city" v-validate="'required|max:200'" name="Ort" :error="errors.first('Ort')" />
                </Column>
                <Column :width="5" /><Column :width="10"> <TextBase size="medium" weight="bold">Ort</TextBase></Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ checkBizInfo.cbCity }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Hemsida</TextBase> </Column
                ><Column :width="25"><Input :value="onboardClient.website" v-model="onboardClient.website" /> </Column>
            </Columns>
        </Stack>
        <Stack :space="3">
            <Columns align-y="center">
                <Column :width="15">
                    <Heading level="2" color="comp"> Bankuppgifter</Heading>
                </Column>
            </Columns>

            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Bank status</TextBase> </Column>
                <Column :width="25">
                    <RadioButtonList @input="setBankDetailsByStatus" :entries="bankStatusOptions" v-model="onboardClient.corporateBankStatus" :horizontal="false" label="" />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Bank</TextBase> </Column>
                <Column :width="25">
                    <Input
                        :disabled="onboardClient.corporateBankStatus == 1"
                        v-if="isOldOnboardingClient"
                        :value="onboardClient.corporateBankName"
                        v-model="onboardClient.corporateBankName"
                        v-validate="bankNameValidation"
                        name="Bank"
                        :error="errors.first('Bank')"
                    />
                    <Dropdown
                        v-else
                        :options="banks"
                        :state="onboardClient.corporateBankStatus == 1 ? 'loading' : ''"
                        :value="onboardClient.corporateBankId"
                        v-model="onboardClient.corporateBankId"
                        @on-change="value => (onboardClient.corporateBankId = value)"
                        v-validate="validateBankNameDropDown"
                        data-vv-delay="1000"
                        name="Bank"
                        :error="errors.first('Bank')"
                    />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Clearingnummer</TextBase> </Column>
                <Column :width="25">
                    <Input
                        :disabled="onboardClient.corporateBankStatus == 1 || onboardClient.corporateBankStatus == 2"
                        :value="onboardClient.corporateBankCode"
                        v-model="onboardClient.corporateBankCode"
                        v-validate="clearingNumberValidation"
                        name="Clearingnummer"
                        :error="errors.first('Clearingnummer')"
                    />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Kontonummer</TextBase> </Column>
                <Column :width="25">
                    <Input
                        :disabled="onboardClient.corporateBankStatus == 1 || onboardClient.corporateBankStatus == 2"
                        :value="onboardClient.corporateBankAccountNumber"
                        v-model="onboardClient.corporateBankAccountNumber"
                        v-validate="accountNumberValidation"
                        name="Kontonummer"
                        :error="errors.first('Kontonummer')"
                    />
                </Column>
            </Columns>
        </Stack>
        <Stack :space="3">
            <Heading level="2" color="comp">
                Skatteuppgifter
            </Heading>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">F-skatt</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="YesNoOptions"
                        :value="onboardClient.isRegisteredFTax"
                        @on-change="value => (onboardClient.isRegisteredFTax = value)"
                        v-validate="'required|numeric|min_value:1'"
                        data-vv-delay="1000"
                        name="F-skatt"
                        :error="errors.first('F-skatt')"
                    />
                </Column>
                <Column :width="5" /><Column :width="10"> <TextBase size="medium" weight="bold">F-skatt</TextBase></Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ getEnum.CorporateTax[checkBizInfo.cbCorporateTax] }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Moms</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="YesNoOptions"
                        :value="onboardClient.isRegisteredVat"
                        @on-change="value => (onboardClient.isRegisteredVat = value)"
                        v-validate="'required|numeric|min_value:1'"
                        data-vv-delay="1000"
                        name="Moms"
                        :error="errors.first('Moms')"
                    />
                </Column>
                <Column :width="5" /> <Column :width="10"> <TextBase size="medium" weight="bold">Moms?</TextBase></Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ getEnum.VatStatus[checkBizInfo.cbVatStatus] }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center" v-if="onboardClient.isRegisteredVat == 2">
                <Column :width="15"> <TextBase size="medium" weight="bold">Moms redovisningsperiod</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="vatAccountingPeriodOptions"
                        :value="onboardClient.vatPeriod"
                        @on-change="value => (onboardClient.vatPeriod = value)"
                        v-validate="'required|numeric|min_value:1'"
                        data-vv-delay="1000"
                        name="Moms redovisningsperiod"
                        :error="errors.first('Moms redovisningsperiod')"
                    />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Registerad som arbetsgivare</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="YesNoOptions"
                        :value="onboardClient.isRegisteredEmployer"
                        @on-change="value => (onboardClient.isRegisteredEmployer = value)"
                        v-validate="'required|numeric|min_value:1'"
                        data-vv-delay="1000"
                        name="Registerad som arbetsgivare"
                        :error="errors.first('Registerad som arbetsgivare')"
                    />
                </Column>
                <Column :width="5" />
                <Column :width="10"> <TextBase size="medium" weight="bold">Arbetsgivare?</TextBase></Column>
                <Column :width="10" v-if="checkBizInfo">
                    <TextBase size="small" weight="light">{{ getEnum.employerStatus[checkBizInfo.cbEmployerStatus] }}</TextBase>
                </Column>
            </Columns>
            <Columns align-y="center" v-if="onboardClient.isRegisteredEmployer == 2">
                <Column :width="15"> <TextBase size="medium" weight="bold">Antal anställda</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="employerOptions"
                        :value="onboardClient.numberOfEmployees"
                        @on-change="value => (onboardClient.numberOfEmployees = value)"
                        v-validate="'required|numeric|min_value:1'"
                        data-vv-delay="1000"
                        name="Antal anställda"
                        :error="errors.first('Antal anställda')"
                    />
                </Column>
            </Columns>
        </Stack>
        <Stack :space="3">
            <Heading level="2" color="comp">
                Övrigt
            </Heading>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Nästa fakturanummer</TextBase> </Column>
                <Column :width="25">
                    <Input
                        :value="onboardClient.nextInvoiceNumber"
                        v-model="onboardClient.nextInvoiceNumber"
                        v-validate="'required|max:250'"
                        name="Nästa fakturanummer"
                        :error="errors.first('Nästa fakturanummer')"
                    />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Tidigare bokföring</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="previousAccountingOptions"
                        :value="onboardClient.previousAccounting"
                        @on-change="value => (onboardClient.previousAccounting = value)"
                        v-validate="'required|numeric|min_value:1'"
                        data-vv-delay="1000"
                        name="Tidigare bokföring"
                        :error="errors.first('Tidigare bokföring')"
                    />
                </Column>
            </Columns>
        </Stack>
        <Stack :space="3">
            <Heading level="1" color="comp">
                Fylls i av kundansvarig
            </Heading>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Vem träffade klienten?</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="persons"
                        :value="onboardClient.metUserId"
                        @on-change="value => (onboardClient.metUserId = value)"
                        v-validate="'required|numeric|min_value:1'"
                        name="Vem träffade klienten"
                        :error="errors.first('Vem träffade klienten')"
                    />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">När träffade du klienten?</TextBase> </Column>
                <Column :width="25">
                    <Input
                        type="date"
                        :value="onboardClient.meetDate"
                        v-model="onboardClient.meetDate"
                        v-validate="'required'"
                        name="När träffade du klienten?"
                        :error="errors.first('När träffade du klienten?')"
                    />
                </Column>
            </Columns>
        </Stack>
        <Stack :space="3">
            <Heading level="2" color="comp">
                Pris
            </Heading>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Månadsavgift</TextBase> </Column>
                <Column :width="25">
                    <Input :value="checkBizInfo.monthlyFee" v-model="checkBizInfo.monthlyFee" v-validate="'required'" name="Månadsavgift" :error="errors.first('Månadsavgift')" />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Max tak</TextBase> </Column>
                <Column :width="25">
                    <Input :value="checkBizInfo.monthlyMaxProvision" v-model="checkBizInfo.monthlyMaxProvision" v-validate="'required'" name="Max Tak" :error="errors.first('Max tak')" />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Procent</TextBase> </Column>
                <Column :width="25">
                    <Input :value="checkBizInfo.provisionInvoicePercentage" v-model="checkBizInfo.provisionInvoicePercentage" v-validate="'required'" name="Procent" :error="errors.first('Procent')" />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Månadsavgift from.</TextBase> </Column>
                <Column :width="25">
                    <Input type="date" :value="checkBizInfo.commissionFrom" v-model="checkBizInfo.commissionFrom" />
                </Column>
            </Columns>
        </Stack>
        <Stack :space="3">
            <Heading level="2" color="comp">
                Räkenskapsår
            </Heading>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Startdatum/Registeringsdatum</TextBase> </Column>
                <Column :width="25">
                    <Input type="date" v-model="checkBizInfo.registrationDate" :value="checkBizInfo.registrationDate" v-validate="'required'" name="Startdatum" :error="errors.first('Startdatum')" />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Startmånad för räkenskapsår</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="months"
                        :value="checkBizInfo.accountingStartMonth"
                        @on-change="value => (checkBizInfo.accountingStartMonth = value)"
                        v-validate="'required|numeric|min_value:1'"
                        data-vv-delay="1000"
                        name="Startmånad för räkenskapsår"
                        :error="errors.first('Startmånad för räkenskapsår')"
                    />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">Typ av första räkenskapsår</TextBase> </Column>
                <Column :width="25">
                    <Dropdown
                        :options="firstFinancialYear"
                        :value="checkBizInfo.firstFinancialYearType"
                        @on-change="value => (checkBizInfo.firstFinancialYearType = value)"
                        v-validate="'required|numeric|min_value:0'"
                        name="Typ av första räkenskapsår"
                        :error="errors.first('Typ av första räkenskapsår')"
                    />
                </Column>
            </Columns>
            <Columns align-y="center">
                <Column :width="15"> <TextBase size="medium" weight="bold">OCR-nummer SKV</TextBase> </Column>
                <Column :width="25">
                    <Input v-model="checkBizInfo.ocrNumberSkv" :value="checkBizInfo.ocrNumberSkv" v-validate="'required'" name="OCR-nummer SKV" :error="errors.first('OCR-nummer SKV')" />
                </Column>
            </Columns>
        </Stack>
        <Columns :space="20">
            <button class="button is-info is-small is-rounded" @click="goback()">
                Tillbaka
            </button>
            <button class="button is-success is-small is-rounded" :disabled="!adminExists || isCompleted" @click="save()" :class="[{ 'is-loading': saveLoading }]">
                Spara
            </button>
            <button class="button is-success is-small is-rounded" @click="createClient()" :disabled="isCompleted" :class="[{ 'is-loading': clientLoading }]">
                Skapa klient
            </button>
            <div :key="key + 'B'" v-for="(option, key) in customError" :value="option.value" v-show="option.display">
                <p class="button is-danger is-small is-light">
                    <span class="icon is-small"> <i class="fas fa-info-circle" /> </span> <span>{{ option.errorMessage }}</span>
                </p>
            </div>
        </Columns>
    </Stack>
</template>
<script>
import moment from "moment";
import "moment/locale/se";
import Api from "../onboarding.api.js";
import Enum from "../onboarding.enum.js";
import TextBase from "../../../components/content/Text.vue";
import Stack from "../../../components/layout/Stack.vue";
import Columns from "../../../components/layout/Columns.vue";
import Heading from "../../../components/content/Heading.vue";
import Column from "../../../components/layout/Column.vue";
import Input from "../../../components/baseInput";
import Dropdown from "../../../components/dropdown.vue";
import RadioButtonList from "../../../components/content/RadioButtonList.vue";
export default {
    props: ["onboardClientId", "companyInfo", "banks"],

    components: {
        TextBase,
        Stack,
        Columns,
        Column,
        Heading,
        Input,
        Dropdown,
        RadioButtonList,
    },
    watch: {
        companyInfo: {
            immediate: true,
            async handler() {
                if (this.companyInfo.onboardingClients) {
                    this.checkIfOldOnBoardingClient();

                    if (!this.isOldOnboardingClient) {
                        this.initializeBankAccountStatus();
                    }
                    if (this.companyInfo.onboardingClients.meetDate != null) {
                        this.companyInfo.onboardingClients.meetDate = moment(String(this.companyInfo.onboardingClients.meetDate)).format("YYYY-MM-DD");
                    }
                    this.checkBizInfo = this.companyInfo.onboardClientAdmin;
                    if (this.checkBizInfo != null) {
                        this.formatDate();
                    } else {
                        this.checkBizInfo = {};
                    }
                }
            },
        },
    },

    data() {
        return {
            checkBizInfo: {},
            checkbizLoading: false,
            saveLoading: false,
            isOldOnboardingClient: false,
            clientLoading: false,
            persons: [
                { name: "Erik Myrlöv", value: 1 },
                // { name: "Mats Pallin", value: 2 },
                { name: "Markus Ogenborg", value: 3 },
                { name: "Olle Ahlin", value: 4 },
                { name: "Martin Petterson", value: 5 },
            ],
            bankStatusOptions: [
                { value: "Har ingen företagsbank ännu", id: 1 },
                { value: "Har ansökt om en företagsbank men inte fått uppgifter", id: 2 },
                { value: "Har redan en företagsbank", id: 3 },
            ],
            successMessages: [
                {
                    name: "SaveSuccess",
                    successMessage: "Spara lyckades",
                    display: false,
                },
            ],
            customError: [
                {
                    name: "SaveError",
                    errorMessage: "Fel när informationen sparades",
                    display: false,
                },
                {
                    name: "CheckbizEmpty",
                    errorMessage: "Vänligen få information om checkbiz innan du sparar",
                    display: false,
                },
                {
                    name: "CreateClientError",
                    errorMessage: "",
                    display: false,
                },
                {
                    name: "CM1Owner",
                    errorMessage: "Kontrollera cm1 för huvudman",
                    display: false,
                },
                {
                    name: "OwnerEmail",
                    errorMessage: "Ange ägarnas e-postadress",
                    display: false,
                },
                {
                    name: "MissingClientAdmin",
                    errorMessage: "Det borde finnas minst en klientadministratör närvarande",
                    display: false,
                },
            ],
            localonboardId: this.onboardId,
            YesNoOptions: [
                { value: 1, name: "Nej" },
                { value: 2, name: "Ja" },
            ],
            companyTypeOptions: [
                { value: 1, name: "Enskild firma" },
                { value: 5, name: "Aktiebolag" },
            ],
            turnOverOptions: [
                { value: 1, name: "Under en halv miljon kronor" },
                { value: 2, name: "Mellan en halv miljon och en miljon kronor" },
                { value: 3, name: "Mellan en miljon och tre miljoner kronor" },
                { value: 4, name: "Över tre miljoner kronor" },
            ],
            vatAccountingPeriodOptions: [
                { name: "Årsvis i anslutning till inkomstdeklaration", value: 1 },
                { name: "Årsvis (handel med andra länder)", value: 2 },
                { name: "Kvartalsvis", value: 3 },
                { name: "Månadsvis", value: 4 },
            ],
            employerOptions: [
                { name: "1", value: 1 },
                { name: "2-4", value: 2 },
                { name: "5 eller fler", value: 3 },
            ],
            previousAccountingOptions: [
                { name: "Jag har bokfört själv i eget bokföringsprogram", value: 1 },
                { name: "En redovisningskonsult/revisor har hjälpt mig", value: 2 },
                { name: "Jag har bara skickat fakturor som inte bokförts", value: 3 },
                { name: "Aldrig använt mitt företag", value: 4 },
                { name: "Vet ej", value: 5 },
            ],
            months: [
                { name: "Januari", value: 1 },
                { name: "Februari", value: 2 },
                { name: "Mars", value: 3 },
                { name: "April", value: 4 },
                { name: "May", value: 5 },
                { name: "Juni", value: 6 },
                { name: "Juli", value: 7 },
                { name: "August", value: 8 },
                { name: "September", value: 9 },
                { name: "October", value: 10 },
                { name: "November", value: 11 },
                { name: "December", value: 12 },
            ],
            firstFinancialYear: [
                { name: "Normalt", value: 0 },
                { name: "Förlängt", value: 1 },
                { name: "Förkortat", value: 2 },
            ],
        };
    },

    methods: {
        checkIfOldOnBoardingClient() {
            if (this.onboardClient) {
                if (this.onboardClient.corporateBankStatus === 0) {
                    this.isOldOnboardingClient = true;
                }
            }
        },
        initializeBankAccountStatus() {
            if (this.onboardClient.corporateBankStatus === 1 || this.onboardClient.corporateBankStatus === 2) {
                this.companyInfo.onboardClientAdmin.hasNoBankAccount = true;
            } else {
                this.companyInfo.onboardClientAdmin.hasNoBankAccount = false;
            }
        },
        setBankDetailsByStatus(status) {
            if (status === 1) {
                this.onboardClient.corporateBankCode = "";
                this.onboardClient.corporateBankAccountNumber = "";
                this.onboardClient.corporateBankId = null;
                this.onboardClient.corporateBankName = "";
                this.companyInfo.onboardClientAdmin.hasNoBankAccount = true;
            }
            if (status === 2) {
                this.onboardClient.corporateBankCode = "";
                this.onboardClient.corporateBankAccountNumber = "";
                this.companyInfo.onboardClientAdmin.hasNoBankAccount = true;
            }
            if (status === 3) {
                this.companyInfo.onboardClientAdmin.hasNoBankAccount = false;
            }
        },
        createClient() {
            this.$validator.validateAll().then(isValid => {
                if (isValid && this.errors.items.length == 0) {
                    this.companyInfo.onboardingClients.onboardOwners.forEach(owner => {
                        owner.isPEPCm1 == null || owner.isSanctionCm1 == null ? (this.customError[3].display = true) : "";
                    });
                    this.companyInfo.onboardingClients.onboardOwners.forEach(owner => {
                        owner.email == null || owner.email == "" ? (this.customError[4].display = true) : "";
                    });
                    var clientAdminExists = this.companyInfo.onboardingClients.onboardOwners.some(o => o.isClientAdmin == true);
                    if (!clientAdminExists) {
                        this.customError[5].display = true;
                    }

                    if (!this.customError[3].display && !this.customError[4].display && !this.customError[5].display) this.createClientAsync();
                }
            });
        },

        async createClientAsync() {
            this.clientLoading = true;
            var result = await Api.createClient(this.companyInfo);
            this.$notify.success({ title: "Utfört" });
            if (!result.isSuccess) {
                this.clientLoading = false;
                this.customError[2].display = true;
                this.customError[2].errorMessage = result.errorMessage;
            } else {
                this.clientLoading = false;
                this.$router.push(`/onboardingList/`);
            }
        },

        goback() {
            this.$router.push(`/onboardingList/`);
        },

        resetError() {
            this.customError.forEach(tab => {
                tab.display = false;
            });
        },

        async save() {
            this.resetError();
            this.saveLoading = true;
            if (this.companyInfo.onboardingClients.meetDate == "Invalid date") {
                this.companyInfo.onboardingClients.meetDate = null;
            }
            if (this.checkBizInfo.commissionFrom == "Invalid date") {
                this.checkBizInfo.commissionFrom = null;
            }
            if (this.checkBizInfo.registrationDate == "Invalid date") {
                this.checkBizInfo.registrationDate = null;
            }
            var result = await Api.saveInfo(this.companyInfo);
            this.$notify.success({ title: "Utfört" });
            if (result) {
                this.saveLoading = false;
            } else {
                this.saveLoading = false;
                this.customError[0].display = true;
            }
        },

        async fetchCheckbiz() {
            this.checkbizLoading = true;
            this.checkBizInfo = await Api.fetchCheckbiz(this.onboardClient.organizationNumber, this.onboardClientId);
            this.companyInfo.onboardClientAdmin = this.checkBizInfo;
            if (this.checkBizInfo != null) {
                this.formatDate();
                this.checkbizLoading = false;
            }
            if (!this.isOldOnboardingClient) {
                this.initializeBankAccountStatus();
            }
        },

        formatDate() {
            if (this.checkBizInfo.registrationDate != null) this.checkBizInfo.registrationDate = moment(String(this.checkBizInfo.registrationDate)).format("YYYY-MM-DD");

            if (this.checkBizInfo.commissionFrom != null) this.checkBizInfo.commissionFrom = moment(String(this.checkBizInfo.commissionFrom)).format("YYYY-MM-DD");
        },
    },

    computed: {
        bankNameValidation() {
            if (this.onboardClient.corporateBankStatus === 1) {
                return "";
            }
            return "required|max:150";
        },
        validateBankNameDropDown() {
            if (this.onboardClient.corporateBankStatus === 1) {
                return "";
            }
            return "required|numeric|min_value:1";
        },

        clearingNumberValidation() {
            if (this.checkBizInfo.hasNoBankAccount || this.onboardClient.corporateBankStatus === 1 || this.onboardClient.corporateBankStatus === 2) {
                return "";
            }
            return "required|numeric|max: 5";
        },

        accountNumberValidation() {
            if (this.checkBizInfo.hasNoBankAccount || this.onboardClient.corporateBankStatus === 1 || this.onboardClient.corporateBankStatus === 2) {
                return "";
            }
            return "required: true, max: 15";
        },

        adminExists() {
            if (Object.keys(this.checkBizInfo).length == 0) {
                return false;
            }
            return true;
        },

        isCompleted() {
            if (this.companyInfo.onboardingClients.onboardingStatus == 2) {
                return true;
            }
            return false;
        },

        getEnum() {
            return Enum;
        },

        onboardClient() {
            return this.companyInfo.onboardingClients;
        },
    },
};
</script>
