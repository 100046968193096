<template>
    <div>
        <div class="card-tail flex flex-row justify-between">
            <div class="flex flex-row">
                <ClientsSearch @on-selected="clientSelected" :selected="selectedClientProp" style="width: 350px;" />
                <button v-if="selectedClientProp.value !== undefined || selectedClient" @click="clearClientSelected"><i class="fa-regular fa-circle-xmark cursor-pointer pl-2" /></button>
            </div>
            <el-date-picker
                v-if="selectedClient !== null && selectedClient !== undefined"
                v-model="dateRange"
                @change="datePickerChange"
                type="daterange"
                range-separator="-"
                start-placeholder="Startdatum"
                end-placeholder="Slutdatum"
            />
        </div>
        <div v-if="selectedClient === null || selectedClient === undefined" class="card-tail">
            <div class="flex flex-row justify-between">
                <h1 class="font-bold mb-2">Shopify synkstatus</h1>
                <el-checkbox label="Visa test företag" v-model="showTestCompanies" @change="showTestChanged" />
            </div>
            <el-table
                :data="syncedClientsData"
                style="width: 100%"
                @sort-change="sortChange"
                :default-sort="{ prop: 'syncedDate', order: 'descending' }"
                :row-class-name="rowClassName"
                @row-click="rowClick"
                v-loading="$waiting.is('loading')"
            >
                <el-table-column
                    prop="syncedStatus"
                    label="Status"
                    width="100"
                    :filters="[
                        { text: 'Synkad', value: true },
                        { text: 'Ej synkad', value: false },
                    ]"
                    :filter-method="filterSyncedStatus"
                    filter-placement="bottom-end"
                >
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.syncedStatus === true ? 'success' : 'danger'" disable-transitions>{{ scope.row.syncedStatus === true ? "Synkad" : "Ej synkad" }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="clientNumber" label="Klient" width="70" show-overflow-tooltip />
                <el-table-column prop="clientName" width="250" show-overflow-tooltip />
                <el-table-column prop="shopName" label="Shop" width="300" show-overflow-tooltip />
                <el-table-column prop="syncedDate" label="Synkdatum" width="150" sortable show-overflow-tooltip>
                    <template slot-scope="scope">
                        <p>
                            {{ formatDate(scope.row.syncedDate) }}
                        </p>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-else-if="selectedClient !== null" class="flex w-full">
            <div class="flex flex-col w-full">
                <div class="card-tail">
                    <div class="flex flex-row justify-between">
                        <h1 class="font-bold mb-2">Shopify ordrar</h1>
                        <el-button @click="clearFilter">Återställ alla filter</el-button>
                    </div>
                    <div class="mt-10">
                        <div v-if="clientSelected">
                            <el-table
                                :data="tableData"
                                :default-sort="{ prop: 'processedAt', order: 'descending' }"
                                style="width: 100%"
                                :row-class-name="tableRowExpand"
                                ref="filterTable"
                                @sort-change="sortChange"
                                @sortTypeChanged="syncDataWithQueryParameters"
                                v-loading="$waiting.is('loading')"
                            >
                                <el-table-column prop="" type="expand" width="20">
                                    <template slot-scope="props">
                                        <div v-if="props.row.oss === 'OSS' && props.row.type === 'Orders'">
                                            <h1 class="font-bold mb-2">Försäljning OSS</h1>
                                            <div class="flex flex-row space-x-10 pl-4">
                                                <div class="flex flex-col">
                                                    <h1 class="font-bold mb-2">Land</h1>
                                                    <p>{{ props.row.country }}</p>
                                                </div>
                                                <div class="flex flex-col">
                                                    <h1 class="font-bold mb-2">Moms</h1>
                                                    <p>{{ props.row.vatRate }}</p>
                                                </div>
                                                <div class="flex flex-col">
                                                    <h1 class="font-bold mb-2">Momsbelopp</h1>
                                                    <p>{{ props.row.vatAmount | swedishNumberFormat() }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="displayCompanyExpand(props.row)">
                                            <h1 class="font-bold mb-2">Försäljning till företag inom EU</h1>
                                            <div class="flex flex-row space-x-10 pl-4">
                                                <div class="flex flex-col">
                                                    <h1 class="font-bold mb-2">Land</h1>
                                                    <p>{{ props.row.country }}</p>
                                                </div>
                                                <div class="flex flex-col">
                                                    <h1 class="font-bold mb-2">Momsregistrerings nr</h1>
                                                    <p>{{ props.row.vatNumberCompanyEu }}</p>
                                                </div>
                                                <div class="flex flex-col">
                                                    <h1 class="font-bold">Ändra momsr. nr</h1>
                                                    <!-- VAT INPUT HERE -->
                                                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                                                        <el-form-item prop="vatNumberCompanyEu">
                                                            <el-input size="mini" v-model="formInline.vatNumberCompanyEu" />
                                                        </el-form-item>
                                                        <el-form-item>
                                                            <el-button type="primary" @click="save(props.row)">Spara</el-button>
                                                        </el-form-item>
                                                    </el-form>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="orderNumber" label="Order nr" width="80" show-overflow-tooltip />
                                <el-table-column
                                    prop="type"
                                    label="Typ"
                                    width="80"
                                    show-overflow-tooltip
                                    :filters="[
                                        { text: 'Orders', value: 'Orders' },
                                        { text: 'Payouts', value: 'Payouts' },
                                    ]"
                                    :filter-method="filterType"
                                    filter-placement="bottom-end"
                                />
                                <el-table-column
                                    prop="paymentStatus"
                                    label="Status"
                                    width="100"
                                    show-overflow-tooltip
                                    :filters="[
                                        { text: 'Betald', value: 'Paid' },
                                        { text: 'Återbetald', value: 'Refunded' },
                                        { text: 'Delvis betald', value: 'PartiallyPaid' },
                                        { text: 'Delvis återbetald', value: 'PartiallyRefunded' },
                                        { text: 'Inväntar betalning', value: 'Pending' },
                                    ]"
                                    :filter-method="filterPaymentStatus"
                                    filter-placement="bottom-end"
                                />
                                <el-table-column label="Summa" width="80" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.grossAmount | swedishNumberFormat() }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="totalRefundAmount" label="Återbetald" width="90" show-overflow-tooltip />
                                <el-table-column prop="currency" label="Valuta" width="60" />
                                <el-table-column
                                    prop="company"
                                    label="Företag"
                                    width="80"
                                    show-overflow-tooltip
                                    :filters="[
                                        { text: 'Företag', value: 'company' },
                                        { text: 'Privatperson', value: 'privateCustomer' },
                                    ]"
                                    :filter-method="filterCompany"
                                    filter-placement="bottom-end"
                                />
                                <el-table-column
                                    prop="oss"
                                    label="OSS"
                                    width="60"
                                    :filters="[
                                        { text: 'OSS', value: 'OSS' },
                                        { text: 'Ej OSS', value: true },
                                    ]"
                                    :filter-method="filterOss"
                                    filter-placement="bottom-end"
                                />
                                <el-table-column
                                    prop="country"
                                    label="Land"
                                    width="80"
                                    show-overflow-tooltip
                                    :filters="[
                                        { text: 'Sverige', value: 'Sweden' },
                                        { text: 'EU', value: 'isEuCountry' },
                                        { text: 'Utanför EU', value: 'outsideEu' },
                                    ]"
                                    :filter-method="filterCountry"
                                    filter-placement="bottom-end"
                                />
                                <el-table-column prop="processedAt" label="Datum" width="90" sortable show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <p>
                                            {{ formatDate(scope.row.processedAt) }}
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column width="40">
                                    <template slot-scope="scope">
                                        <el-button type="primary" class="p-[2px]" @click="viewVoucher(scope.row.voucherId)">
                                            <i class="far fa-paperclip" />
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalOrdersNumber" :page-number-prop="pageNumber" />
                </div>
                <div class="card-tail">
                    <div class="flex flex-col">
                        <h1 class="font-bold mb-2">Ej bokförd</h1>
                        <el-table
                            :data="errorBookingListData"
                            style="width: 100%"
                            @sort-change="sortChange"
                            :default-sort="{ prop: 'processedAt', order: 'descending' }"
                            v-loading="$waiting.is('loading')"
                        >
                            <el-table-column prop="orderNumber" label="Order nr" width="80" show-overflow-tooltip />
                            <el-table-column
                                prop="type"
                                label="Typ"
                                width="80"
                                show-overflow-tooltip
                                :filters="[
                                    { text: 'Orders', value: 'Orders' },
                                    { text: 'Payouts', value: 'Payouts' },
                                ]"
                                :filter-method="filterType"
                                filter-placement="bottom-end"
                            />
                            <el-table-column
                                prop="paymentStatus"
                                label="Status"
                                width="100"
                                show-overflow-tooltip
                                :filters="[
                                    { text: 'Betald', value: 'Paid' },
                                    { text: 'Återbetald', value: 'Refunded' },
                                    { text: 'Delvis betald', value: 'PartiallyPaid' },
                                    { text: 'Delvis återbetald', value: 'PartiallyRefunded' },
                                    { text: 'Inväntar betalning', value: 'Pending' },
                                ]"
                                :filter-method="filterPaymentStatus"
                                filter-placement="bottom-end"
                            />
                            <el-table-column label="Summa" width="80" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ scope.row.grossAmount | swedishNumberFormat() }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="totalRefundAmount" label="Återbetald" width="90" show-overflow-tooltip />
                            <el-table-column prop="currency" label="Valuta" width="60" />
                            <el-table-column
                                prop="company"
                                label="Företag"
                                width="80"
                                show-overflow-tooltip
                                :filters="[
                                    { text: 'Företag', value: 'company' },
                                    { text: 'Privatperson', value: 'privateCustomer' },
                                ]"
                                :filter-method="filterCompany"
                                filter-placement="bottom-end"
                            />
                            <el-table-column
                                prop="oss"
                                label="OSS"
                                width="60"
                                :filters="[
                                    { text: 'OSS', value: 'OSS' },
                                    { text: 'Ej OSS', value: true },
                                ]"
                                :filter-method="filterOss"
                                filter-placement="bottom-end"
                            />
                            <el-table-column
                                prop="country"
                                label="Land"
                                width="80"
                                show-overflow-tooltip
                                :filters="[
                                    { text: 'Sverige', value: 'Sweden' },
                                    { text: 'EU', value: 'isEuCountry' },
                                    { text: 'Utanför EU', value: 'outsideEu' },
                                ]"
                                :filter-method="filterCountry"
                                filter-placement="bottom-end"
                            />
                            <el-table-column prop="processedAt" label="Datum" width="90" sortable show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <p>
                                        {{ formatDate(scope.row.processedAt) }}
                                    </p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-full">
                <div class="card-tail ml-3">
                    <div v-if="displayVoucher" class="flex flex-col">
                        <VouchersView :key="voucherViewLoadAgain" :voucher-id="selectedVoucherId" :client-id="selectedVouchedClientId" />
                        <el-button @click="displayVoucher = false">Stäng</el-button>
                    </div>
                    <div v-else class="flex flex-col items-center py-20">
                        <img src="@/assets/images/no_data.svg" class="w-32" />
                        <p class="mt-4">Ingen data har valts</p>
                    </div>
                </div>
                <div class="card-tail ml-3">
                    <div class="flex flex-col">
                        <h1 class="font-bold mb-2">Momsregistreringsnummer företag EU</h1>
                        <div style="max-height: 500px; overflow-y: auto;">
                            <el-table
                                :data="vatNumberListData"
                                style="width: 100%"
                                @sort-change="sortChange"
                                :default-sort="{ prop: 'processedAt', order: 'descending' }"
                                v-loading="$waiting.is('loading')"
                            >
                                <el-table-column prop="vatNumberCompanyEu" type="expand" width="20">
                                    <template slot-scope="props">
                                        <div v-if="displayCompanyExpand(props.row)">
                                            <div class="flex flex-row space-x-10 pl-4">
                                                <div class="flex flex-col">
                                                    <h1 class="font-bold">Ändra momsr. nr</h1>
                                                    <el-form :inline="true" :model="formInline" class="demo-form-inline">
                                                        <el-form-item prop="vatNumberCompanyEu">
                                                            <el-input size="mini" v-model="formInline.vatNumberCompanyEu" />
                                                        </el-form-item>
                                                        <el-form-item>
                                                            <el-button type="primary" @click="save(props.row)">Spara</el-button>
                                                        </el-form-item>
                                                    </el-form>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="company" label="Företag" width="150" show-overflow-tooltip />
                                <el-table-column prop="orderNumber" label="Order nr" width="80" show-overflow-tooltip />
                                <el-table-column prop="paymentStatus" label="Status" width="100" show-overflow-tooltip />
                                <el-table-column label="Summa" width="80" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.grossAmount | swedishNumberFormat() }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="country" label="Land" width="80" />
                                <el-table-column prop="vatNumberCompanyEu" label="Momsr.nr" width="120" />
                                <el-table-column prop="processedAt" label="Datum" width="90" sortable show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <p>
                                            {{ formatDate(scope.row.processedAt) }}
                                        </p>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="card-tail ml-3">
                    <div class="flex flex-col">
                        <div class="flex justify-between">
                            <h1 class="font-bold mb-2">Ordrar OSS</h1>
                            <el-button @click="toggleData" type="warning" class="mb-2">
                                <div class="flex flex-row">
                                    <i class="fa-solid fa-layer-group mr-2" />
                                    <p v-if="!this.useAlternativeData">Gruppera länder</p>
                                    <p v-else>Visa alla ordrar</p>
                                </div>
                            </el-button>
                        </div>
                        <div style="max-height: 500px; overflow-y: auto;">
                            <el-table
                                v-if="!this.useAlternativeData"
                                :data="ossListData"
                                style="width: 100%"
                                @sort-change="sortChange"
                                :default-sort="{ prop: 'processedAt', order: 'descending' }"
                                :summary-method="getSummaries"
                                show-summary
                                v-loading="$waiting.is('loading')"
                            >
                                <el-table-column prop="country" label="Land" width="85" show-overflow-tooltip />
                                <el-table-column width="90" label="Skattesats">
                                    <template slot-scope="scope"> {{ scope.row.vatRate * 100 + "%" }} </template>
                                </el-table-column>
                                <el-table-column prop="netAmount" label="Netto" width="100" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.netAmount | swedishNumberFormat() }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="vatAmount" label="Moms" width="90" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.vatAmount | swedishNumberFormat() }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="sek" label="" width="70" />
                                <el-table-column prop="processedAt" label="Datum" width="100" sortable show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <p>
                                            {{ formatDate(scope.row.processedAt) }}
                                        </p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="paymentStatus"
                                    label="Status"
                                    width="80"
                                    show-overflow-tooltip
                                    :filters="[
                                        { text: 'Betald', value: 'Paid' },
                                        { text: 'Återbetald', value: 'Refunded' },
                                        { text: 'Delvis betald', value: 'PartiallyPaid' },
                                        { text: 'Delvis återbetald', value: 'PartiallyRefunded' },
                                        { text: 'Inväntar betalning', value: 'Pending' },
                                    ]"
                                    :filter-method="filterPaymentStatus"
                                    filter-placement="bottom-end"
                                />
                                <el-table-column prop="orderNumber" label="Order nr" width="80" show-overflow-tooltip />
                            </el-table>
                            <el-table v-else :data="alternativeData" style="width: 100%" :summary-method="getSummaries" show-summary>
                                <el-table-column prop="country" label="Land" width="270" show-overflow-tooltip />
                                <el-table-column prop="vatrate" label="Skattesats" width="100" />
                                <el-table-column prop="netAmount" label="Beskattningsunderlag" width="170" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.netAmount | swedishNumberFormat() }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="vatAmount" label="Skatt" width="100" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        {{ scope.row.vatAmount | swedishNumberFormat() }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="sek" label="" width="80" />
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import queryString from "query-string";
import Api from "./shopify.api";
import moment from "moment";

export default {
    components: {
        VouchersView: () => import("@/components/vouchersTable/v1/VouchersView.vue"),
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
        Pagination: () => import("../invoices/components/Pagination.vue"),
    },
    data() {
        return {
            displayVoucher: false,
            tableData: [],
            selectedClient: null,
            selectedClientProp: {},
            formInline: {},
            selectedVoucherId: null,
            selectedVouchedClientId: null,
            ossListData: [],
            dateFrom: "",
            dateTo: "",
            dateRange: [],
            totalOrdersNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            vatNumberListData: [],
            errorBookingListData: [],
            displayCompany: false,
            displayOss: false,
            syncedClientsData: [],
            showTestCompanies: false,
            alternativeData: [],
            useAlternativeData: false,
            periodSelectedData: [],
            shopifyOrderList: {},
        };
    },
    watch: {
        selectedClient(newValue, oldValue) {
            if (oldValue !== null || this.$route.meta.title == "Shopify") {
                this.$router.push("/shopify/" + this.selectedClient);
            }
        },
        showTestCompanies: {
            handler: "showTestChanged",
            immediate: true,
        },
    },
    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Shopify");
        document.title = "Shopify";
    },
    async created() {
        this.initializeSelectedClientProp();
        this.initializeDateRangeFromQuery();
        await this.getSyncedClients();
    },
    methods: {
        initializeSelectedClientProp() {
            this.selectedClientProp = {
                value: this.$route.params.clientId,
            };
        },
        initializeDateRangeFromQuery() {
            const { dateFrom, dateTo } = queryString.parse(window.location.search, { sort: false });
            if (dateFrom && dateTo) {
                this.dateRange = [dateFrom, dateTo];
            }
        },
        async getSyncedClients() {
            this.$waiting.start("loading");
            const syncedClients = await Api.getAllSyncedClients();
            const [clientsArray, testClientsArray] = await Promise.all([
                Promise.resolve(syncedClients.filter(clientObj => !clientObj.isTestCompany)),
                Promise.resolve(syncedClients.filter(clientObj => clientObj.isTestCompany)),
            ]);
            this.syncedClientsData = this.showTestCompanies ? testClientsArray : clientsArray;
            this.$waiting.end("loading");
        },
        showTestChanged() {
            setTimeout(async () => {
                await this.getSyncedClients();
            }, 100);
        },

        async clientSelected(data) {
            this.selectedClient = data.value;
            await this.getPaginatedOrders(data);
        },
        usePaginatedOrders() {
            this.getPaginatedOrders({ value: this.selectedClient, pageNumber: this.pageNumber, pageSize: this.pageSize, dateFrom: this.dateFrom, dateTo: this.dateTo });
        },
        async pageQuery() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = this.pageNumber;
            query.pageSize = this.pageSize;
            await this.$router.replace({ query }).catch(() => {});
        },
        async getPaginatedOrders(data) {
            this.$waiting.start("loading");
            const orders = await Api.getPaginatedShopifyOrders(data.value, this.pageNumber, this.pageSize, this.dateFrom, this.dateTo);
            this.tableData = orders.data;

            if (this.dateFrom && this.dateTo) {
                this.periodSelectedData = await Api.getPeriodShopifyOrders(data.value, this.dateFrom, this.dateTo);
            }

            await this.getAllOrderLists({ client: data.value, dateFrom: this.dateFrom, dateTo: this.dateTo });
            this.pageQuery();
            this.totalOrdersNumber = parseInt(orders.headers["x-pagination-totalcount"]);

            this.$waiting.end("loading");
        },

        async getAllOrderLists(data) {
            this.$waiting.start("loading");
            let orderList = await Api.getShopifyOrderLists(data.client);
            this.shopifyOrderList = orderList;

            if (data.dateFrom && data.dateTo) {
                console.log("target periodSelectedData", this.periodSelectedData);
                this.shopifyOrderList = this.periodSelectedData.data;
            }

            this.vatNumberListData = this.shopifyOrderList.companyEuOrders;
            this.ossListData = this.shopifyOrderList.ossOrders;
            this.errorBookingListData = this.shopifyOrderList.unbookedOrders;

            this.getOssListGroupedData();
            this.$waiting.end("loading");
        },

        formatDate(date) {
            if (date == null) return "";
            return moment(date).format("YYYY-MM-DD");
        },
        tableRowExpand({ row }) {
            if (row.isEuCountry && row.country !== "Sweden" && row.vatTitle !== "SE Moms") {
                if (row.company === null || row.company === "") {
                    row.oss = "OSS";
                }
            }
        },
        clearFilter() {
            this.$refs.filterTable.clearFilter();
        },
        filterOss(value, row) {
            return row.oss === value;
        },
        filterType(value, row) {
            return row.type === value;
        },
        filterPaymentStatus(value, row) {
            return row.paymentStatus === value;
        },
        filterCompany(value, row) {
            if (value === "company") {
                return row.company !== null && row.company !== "";
            }
            return (value === "privateCustomer" && row.company === null) || row.company === "";
        },
        filterCountry(value, row) {
            if (value === "isEuCountry") {
                return row.isEuCountry && row.country !== "Sweden";
            }
            if (value === "outsideEu") {
                return !row.isEuCountry;
            }
            return row.country === value;
        },
        viewVoucher(voucherId) {
            this.selectedVoucherId = voucherId;
            this.selectedVouchedClientId = this.$route.params.clientId;
            if (!this.displayVoucher) {
                this.displayVoucher = true;
            }
        },
        datePickerChange() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = this.pageNumber;
            query.pageSize = this.pageSize;
            query.dateFrom = this.dateRange ? moment(this.dateRange[0]).format("YYYY-MM-DD") : "";
            query.dateTo = this.dateRange ? moment(this.dateRange[1]).format("YYYY-MM-DD") : "";
            this.$router.replace({ query }).catch(() => {});
            this.dateFrom = query.dateFrom;
            this.dateTo = query.dateTo;
            this.usePaginatedOrders();
        },
        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            const dateFrom = event.order === "descending" ? this.dateRange[1] : this.dateRange[0];
            const dateTo = event.order === "descending" ? this.dateRange[0] : this.dateRange[1];
            query.dateFrom = dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : undefined;
            query.dateTo = dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },
        updateUrlAndGetAll() {
            const { pageNumber, pageSize, dateFrom, dateTo } = queryString.parse(window.location.search, { sort: false });
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.dateFrom = dateFrom || "";
            this.dateTo = dateTo || "";
            this.usePaginatedOrders();
        },
        syncDataWithQueryParameters() {
            if (this.isGlobalClientSelected) {
                const { pageNumber } = queryString.parse(location.search);
                this.pageNumber = pageNumber || this.pageNumber;
            } else {
                this.pageNumber = 1;
            }
            setTimeout(() => {
                this.$refs.paginationComponent.updateUrl(+this.pageNumber);
                this.updateUrlAndGetAll();
            }, 200);
        },
        async save(row) {
            const clientId = this.selectedClient;
            const shopifyOrderId = row.shopifyOrderId;
            await Api.saveShopifyOrdersVatNumber({ clientId, shopifyOrderId, data: this.formInline });
            this.getAllOrderLists({ value: this.selectedClient });
            this.usePaginatedOrders();
            this.$notify.success({ title: "Sparat" });
        },
        rowClassName({ row }) {
            if (row.hasUnbookedOrders == true) {
                return "red-row cursor-pointer-row";
            } else {
                return "cursor-pointer-row";
            }
        },
        filterSyncedStatus(value, row) {
            return row.syncedStatus === value;
        },
        rowClick(row) {
            this.selectedClientProp = { value: row.clientId };
            this.clientSelected({ value: row.clientId });
        },
        swedishNumberFormat(value) {
            if (value == null) return "";
            return new Intl.NumberFormat("sv-SE", { style: "decimal" }).format(value);
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "Summa";
                    return;
                }
                if (column.property === "orderNumber") {
                    sums[index] = "";
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    const sum = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + value;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] = this.swedishNumberFormat(sum.toFixed(2));
                } else {
                    sums[index] = "";
                }
            });

            return sums;
        },
        formatToTwoDecimals(row, column, cellValue) {
            return cellValue != null ? cellValue.toFixed(2) : "";
        },
        toggleData() {
            this.useAlternativeData = !this.useAlternativeData;
        },
        getOssListGroupedData() {
            const groupedOssListData = this.ossListData.reduce((acc, curr) => {
                const existingCountry = acc.find(item => item.country === curr.country);
                if (existingCountry) {
                    existingCountry.netAmount = (parseFloat(existingCountry.netAmount) + parseFloat(curr.netAmount)).toFixed(2);
                    existingCountry.vatAmount = (parseFloat(existingCountry.vatAmount) + parseFloat(curr.vatAmount)).toFixed(2);
                } else {
                    acc.push({
                        country: curr.country,
                        netAmount: parseFloat(curr.netAmount).toFixed(2),
                        vatAmount: parseFloat(curr.vatAmount).toFixed(2),
                        vatrate: curr.vatRate * 100 + "%",
                        sek: "SEK",
                    });
                }
                return acc;
            }, []);
            this.alternativeData = groupedOssListData;
        },
        clearClientSelected() {
            this.clientId = null;
            this.selectedClient = undefined;
            this.selectedClientProp.value = undefined;
            this.$store.commit("topBar/clearSelectedClient");
            this.header = "";
            if (this.$route.path !== "/shopify") {
                this.$router.push({ path: `/shopify` });
            }
        },
    },
    computed: {
        voucherViewLoadAgain() {
            return `${this.selectedVoucherId}_${this.selectedVouchedClientId}`;
        },
        displayCompanyExpand() {
            return row => {
                return row.company !== null && row.company !== "" && row.isEuCountry === true && row.country !== "Sweden";
            };
        },
        currentData() {
            return this.useAlternativeData ? this.alternativeData : this.ossListData;
        },
    },
};
</script>

<style>
.el-table .red-row {
    background: rgb(255, 225, 225);
}
.cursor-pointer-row {
    cursor: pointer;
}
</style>
