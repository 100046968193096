<template>
    <div class="field">
        <label class="label">{{ label }}</label>
        <div class="control">
            <input class="input" type="date" placeholder="Text input" v-bind="$attrs" v-bind:value="value" v-on="inputListeners" />
        </div>
    </div>
</template>

<script>
export default {
    props: ["error", "value", "rightText", "rightIcon", "warning", "label"],
    computed: {
        inputListeners: function() {
            var vm = this;
            return Object.assign({}, this.$listeners, {
                input: function(event) {
                    vm.$emit("input", event.target.value);
                },
            });
        },
    },
};
</script>
<style scoped>
.field:not(:last-child) {
    margin-bottom: 0rem;
}
</style>
