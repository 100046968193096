import Vue from "vue";
export default {
    getAll: async (pageNumber, pageSize, search, search2, sortBy, sortByType) => {
        try {
            const response = await Vue.axios.get(`suppliers`, {
                params: {
                    pageNumber,
                    pageSize,
                    search,
                    search2,
                    sortBy,
                    sortType: sortByType,
                },
            });
            return response;
        } catch (error) {
            console.log("🚀 ~ file: suppliers.api.js ~ getAll: ~ error", error);
            return [];
        }
    },

    getCountryList: async () => {
        try {
            const { data } = await Vue.axios.get(`suppliers/countryList`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: suppliers.api.js ~ getCountryList: ~ e", e);
            return [];
        }
    },

    getSupplierDetails: async id => {
        try {
            const { data } = await Vue.axios.get(`suppliers/${id}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: suppliers.api.js ~ getCountryList: ~ e", e);
            return [];
        }
    },

    mergeSuppliers: async mergeSuppliersDto => {
        try {
            var response = await Vue.axios.post(`suppliers`, mergeSuppliersDto);
        } catch (e) {
            console.log("🚀 ~ file: suppliers.api.js ~ mergeSuppliers: ~ e", e);
            return [];
        }
        return response.data;
    },

    updateSupplier: async suppliersDto => {
        try {
            var response = await Vue.axios.post(`suppliers/id`, suppliersDto);
        } catch (e) {
            console.log("🚀 ~ file: suppliers.api.js ~ updateSupplier: ~ e", e);
            return [];
        }
        return response.data;
    },

    getSupplierClientsInfo: async id => {
        try {
            const { data } = await Vue.axios.get(`suppliers/clientsInfo/${id}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: suppliers.api.js ~ getSupplierClientsInfo: ~ e", e);
            return [];
        }
    },

    showInvoicePdf(id) {
        var token = localStorage.getItem("token");
        var headers = "?token=" + token;
        var base = Vue.axios.defaults.baseURL;
        var url = `/suppliers/invoicePdf/${id}`;
        window.open(base + url + headers, `_blank`, `width=800, height=800`);
    },
};
