<template>
    <div>
        <p class="control has-icons-right">
            <input class="input" v-bind="$attrs" v-bind:value="value" v-on="inputListeners" />
            <span class="icon is-right">{{ rightText }} </span>
            <span class="icon is-small is-right">
                <i :class="rightIcon" />
            </span>
        </p>
        <p class="help is-danger">{{ error }}</p>
        <p class="button is-warning is-small is-light" v-if="warning != undefined">
            <span class="icon is-small"> <i class="fas fa-info-circle" /> </span> <span>{{ warning }}</span>
        </p>
    </div>
</template>
<script>
export default {
    props: ["error", "value", "rightText", "rightIcon", "warning"],
    computed: {
        inputListeners: function() {
            var vm = this;
            // `Object.assign` merges objects together to form a new object
            return Object.assign(
                {},
                // We add all the listeners from the parent
                this.$listeners,
                // console.log("value=>", this.$listeners),
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                {
                    // This ensures that the component works with v-model
                    input: function(event) {
                        vm.$emit("input", event.target.value);
                    },
                },
            );
        },
    },
};
</script>
