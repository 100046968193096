import DocumentsList from "./DocumentsList.vue";
import Document from "./Document.vue";

const routes = [
    {
        path: "/documents",
        name: "documents",
        component: DocumentsList,
        meta: {
            forAuth: true,
            title: "Dokument",
        },
    },
    {
        path: "/document/:documentId",
        name: "document",
        component: Document,
        meta: {
            forAuth: true,
            title: "Dokument",
        },
    },
];
export default routes;
