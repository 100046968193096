<template>
    <div>
        <div class="card-tail">
            <el-button type="primary" @click="switchToJSON" v-if="!jsonFormat">
                JSON Format
            </el-button>
            <el-button type="primary" @click="switchToText" v-if="jsonFormat">
                Text Format
            </el-button>
            <el-button type="primary" @click="goBack">
                Tillbaka
            </el-button>
        </div>
        <div class="card-tail">
            <div v-if="!loading && !jsonFormat" style="padding-top: 0px; width: 100%">
                <div ref="messageBoxScroll" class="message-box-scroll">
                    <div v-for="(message, index) in messagesText" :key="index" class="message-box">
                        <div v-if="message.role === 'assistant'" class="assistant-block">
                            <div class="assistant-title">Chatbot(Test):</div>
                            <div class="assistant-message">
                                <div class="assistant-message-content">
                                    <!-- <div style="white-space: pre-line;">{{ message.content }}</div> -->
                                    <div><MarkdownViewer :markdown="message.content" /></div>
                                </div>
                                <div class="thumbs-buttons">
                                    <i v-if="message.like === true" class="fa fa-thumbs-up fa-lg" style="color: pink;" />
                                    <i v-else-if="message.like === false" class="fa fa-thumbs-down fa-lg" style="color: grey;" />
                                </div>
                            </div>
                        </div>
                        <div v-if="message.role === 'user'" class="user-message">
                            <div>{{ message.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!loading && jsonFormat" style="padding-top: 0px; width: 100%">
                <div ref="messageBoxScroll" class="message-box-scroll">
                    <div v-for="(message, index) in messages" :key="index" class="message-box">
                        <pre class="json-message">{{ JSON.stringify(message, null, 2) }}</pre>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../chatAgent.api";
import MarkdownViewer from "./MarkdownViewer.vue";

export default {
    components: {
        MarkdownViewer,
    },
    data() {
        return {
            messages: [],
            chatConversationId: 0,
            loading: true,
            jsonFormat: false,
        };
    },

    async created() {
        this.chatConversationId = this.$route.params.id || this.chatConversationId;
        this.messages = await Api.getChatMessagesList(this.chatConversationId);
        this.loading = false;
    },

    computed: {
        messagesText() {
            var results = [];
            for (var i = 0; i < this.messages.length; i++) {
                if (this.messages[i].role === "FunctionCall" && this.messages[i].content !== null) {
                    results.push({ role: "assistant", content: this.messages[i].content, like: this.messages[i].like });
                } else if (this.messages[i].role === "assistant" || this.messages[i].role === "user") {
                    results.push(this.messages[i]);
                }
            }
            return results;
        },
    },

    methods: {
        switchToJSON() {
            this.jsonFormat = true;
        },
        switchToText() {
            this.jsonFormat = false;
        },
        goBack() {
            this.$router.go(-1);
        },
    },
};
</script>
<style>
.message-box-scroll {
    height: 100%;
    overflow-y: scroll;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}
.message-box {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}
.assistant-block {
    width: 100%;
}
.assistant-title {
    font-weight: bold;
    color: #333;
}
.assistant-message {
    display: flex;
    width: 100%;
}
.assistant-message-content {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    text-align: left;
    max-width: 80%;
    display: inline-block;
}
.thumbs-buttons {
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
}
.user-message {
    background-color: #415770;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    text-align: left;
    max-width: 80%;
    display: inline-block;
    margin-left: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.json-message {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
</style>
