import ClientList from "./ClientList.vue";
import ClientDetails from "./ClientDetailsPage.vue";

const routes = [
    {
        path: "/clientList",
        name: "clientList",
        component: ClientList,
        meta: {
            forAuth: true,
            title: "Client List",
        },
    },
    {
        path: "/clientDetails/:clientId",
        name: "clientDetails",
        component: ClientDetails,
        meta: {
            forAuth: true,
        },
        children: [
            {
                path: "general",
                component: () => import(/* webpackChunkName: "Details" */ "./components/ClientDetails.vue"),
                meta: {
                    activeTab: "general",
                },
            },
            {
                path: "finances",
                component: () => import(/* webpackChunkName: "ClientFinance" */ "./components/ClientFinance.vue"),
                meta: {
                    activeTab: "finances",
                },
            },
            {
                path: "bank",
                component: () => import(/* webpackChunkName: "ClientBank" */ "./components/ClientBank.vue"),
                meta: {
                    activeTab: "bank",
                },
            },
            {
                path: "people",
                component: () => import(/* webpackChunkName: "Persons" */ "./components/Persons.vue"),
                meta: {
                    activeTab: "people",
                },
            },
        ],
    },
];
export default routes;
