<template>
    <div>
        <FactoringList v-if="activeTab === 'clientlist'" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            totalClientNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            sortBy: "",
            sortByType: "",
            query: "",
            clientStatus: "active",
            activeTab: "clientlist",
            searchQuery: "",
            status: "",
            clientId: "",
            clientName: "",
            clientNr: null,
        };
    },

    components: {
        FactoringList: () => import(/* webpackChunkName: "FactoringList" */ "./components/FactoringList.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
        document.title = "Klienter";
    },

};
</script>
