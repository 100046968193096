<template>
    <el-popover placement="left" width="800" v-model="visible" class="popOver">
        <div class="infoTextContainer">
            <p class="infoText" v-html="infoText" />
        </div>
        <i class="fa-solid fa-circle-exclamation" style="color: #415770" slot="reference" @click="showInfoText()" />
    </el-popover>
</template>
<script>
import { ref } from "@vue/composition-api";

export default {
    props: {
        infoText: String,
    },

    data: function() {
        return {
            infoTextVisible: false,
            buttonRef: ref(),
        };
    },

    methods: {
        showInfoText() {
            console.log(this.infoText, "text");
            this.infoTextVisible = !this.infoTextVisible;
        },
    },
};
</script>

<style scoped>
.infoText {
    color: white;
    font-size: 13px;
}

.el-popover::v-deep .infoTextContainer .infoText {
    word-break: normal;
}

.infoTextContainer {
    background-color: #415770;
    padding: 10px;
    border-radius: 4px;
}

.infoTextContainer .infoText {
    word-break: normal !important;
}
</style>
