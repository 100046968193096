import { render, staticRenderFns } from "./Heading.vue?vue&type=template&id=45415c46&scoped=true&"
import script from "./Heading.vue?vue&type=script&lang=js&"
export * from "./Heading.vue?vue&type=script&lang=js&"
import style0 from "./Heading.vue?vue&type=style&index=0&id=45415c46&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45415c46",
  null
  
)

export default component.exports