<template>
    <div tabindex="0" @focus="handleFocus" @blur="handleBlur" @click="onClick" class="radio-button-container control" :class="[{ error: error, disabled: disabled, defaultCursor: disabled }]">
        <div class="radio-button-circle">
            <div v-if="checked" class="radio-button-dot" />
        </div>
        <div :class="{ disabledLabel: disabled }" class="radio-button-label">{{ label }}</div>
    </div>
</template>

<script>
export default {
    props: ["label", "checked", "value", "error", "disabled"],
    methods: {
        onClick() {
            if (!this.disabled) {
                this.$emit("change", this.value);
            }
        },
        toggleValue(e) {
            if (!this.disabled) {
                if (e.keyCode === 32 || e.keyCode === 13) {
                    e.preventDefault();
                    this.onClick();
                }
            }
        },
        handleFocus() {
            if (!this.disabled) {
                window.addEventListener("keypress", this.toggleValue);
            }
        },
        handleBlur() {
            if (!this.disabled) {
                window.removeEventListener("keypress", this.toggleValue);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.defaultCursor {
    cursor: initial !important;
}

.disabledLabel {
    color: darkgrey;
}

.disabled {
    .radio-button-circle {
        pointer-events: none;
    }
    .radio-button-circle {
        margin-right: 10px;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        border: solid 2px #bababa;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .radio-button-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #bababa;
    }
    .disabled .radio-button-container {
        cursor: initial;
    }
}
.radio-button-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    /* margin-bottom: 25px; */

    &:focus {
        outline: none;
        color: #2eb0f5;
        .radio-button-circle {
            background: #f7f9fb;
        }
    }
}
.error {
    .radio-button-circle {
        background: #fff8f8;
        border-color: #ffbaba;
        color: #ff7878;
    }
    .radio-button-label {
        color: #ff7878;
    }
}
.radio-button-circle {
    margin-right: 10px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 2px #0085c7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.radio-button-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #14abf4;
}
.radio-button-label {
    font-size: 15px;
    /* font-weight: 500; */
}

.mobile {
    .radio-button-label {
        font-size: 15px;
    }
}
</style>
