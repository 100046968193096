<template>
    <div class="kyc-by-client-new-sni-codes">
        <SniCard title="Aktuella SNI-Koder" :sni-codes="sniCodes.current" />
        <SniCard title="Syna SNI-Koder" :sni-codes="sniCodes.syna" />
    </div>
</template>

<script>
import SniCard from "./kycByClientNewSniCard.vue";

export default {
    name: "KycByClientNewSniCodes",
    components: {
        SniCard,
    },
    props: {
        sniCodes: {
            type: Object,
            default: () => ({
                current: [],
                syna: [],
            }),
        },
    },
};
</script>

<style scoped>
.kyc-by-client-new-sni-codes {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
}
</style>
