export default {
    namespaced: true,
    state: { selectedClient: {} },
    mutations: {
        setSelectedClient(state, value) {
            state.selectedClient = value;
        },
        clearSelectedClient(state) {
            state.selectedClient = {};
        },
    },
    actions: {},
    getters: {
        isClientSelected: state => {
            return Object.keys(state.selectedClient).length === 0 ? false : true;
        },
    },
};
