import CustomerAccountsLedger from "./customerAccountsLedgerPage.vue";

const routes = [
    {
        path: "/customerAccountsLedger",
        name: "customerAccountsLedger",
        component: CustomerAccountsLedger,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
