import Vue from "vue";

const state = Vue.observable({
    data: "",
    active: false,
});

const open = data => {
    state.data = data;
    state.active = true;
};

const reset = () => {
    state.data = "";
    state.active = false;
};

// -----------------------------------
// Public interface
// -----------------------------------

const drawer = {
    get state() {
        return state;
    },
    show(data) {
        open(data);
    },
    hide() {
        reset();
    },
};
Vue.prototype.$drawer = drawer;
