<template>
    <div class="card-tail card-f-h">
        <DropDown style="margin-top: 50px; width: 30%" :options="kycByClientStatus" @on-change="filterList" request-text="Välj status ..." state="ready" />
        <div v-if="filteredList" style="margin-top: 50px">
            <my-list :items="filteredList" :headers="headers" :scroll="true" :search="false" @click="forwardToForm">
                <template slot-scope="doc">
                    <td>{{ doc.clientNumber }}</td>
                    <td>{{ doc.clientName }}</td>
                    <td>{{ doc.createDate }}</td>
                    <td>{{ getStatus(doc.clientStatus) }}</td>
                </template>
            </my-list>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import "moment/locale/se";
import DropDown from "../../components/dropdown.vue";
import Api from "../kycByClient/kycByClient.api";
import List from "../../components/list";
import Enum from "../kycByClient/kycByClient.enum";

export default {
    data: function() {
        return {
            originalkycByClientList: [],
            kycByClientList: undefined,
            kycByClientStatus: [
                { name: "Alla", value: -1 },
                { name: "Utkast", value: 0 },
                { name: "Inskickad", value: 1 },
                { name: "Godkänd", value: 2 },
                { name: "Ingen åtgärd krävs", value: 3 },
                { name: "Ej påbörjad", value: 4 },
            ],
            headers: [
                { name: "Klient nummer", prop: "clientNumber", width: "120px" },
                { name: "Företag namn", prop: "clientName", width: "200px" },
                { name: "Skapa datum", prop: "createDate", width: "120px" },
                { name: "Status", prop: "clientStatus", width: "120px" },
            ],
        };
    },

    components: {
        "my-list": List,
        DropDown,
    },
    watch: {
        isGlobalClientSelected: {
            immediate: true,
            async handler() {
                if (!this.isGlobalClientSelected) {
                    this.getKycByClientList();
                } else {
                    this.getListByClient(this.$store.state.topBar.selectedClient.value);
                }
            },
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Känn din kund");
        document.title = "Känn din kund";
    },

    // created() {
    //     this.getKycByClientList();
    // },

    methods: {
        async getKycByClientList() {
            this.kycByClientList = await Api.getKycByClientList();
            this.originalkycByClientList = JSON.parse(JSON.stringify(this.kycByClientList));
        },

        async getListByClient(clientId) {
            this.kycByClientList = await Api.getListByClientId(clientId);
            this.originalkycByClientList = JSON.parse(JSON.stringify(this.kycByClientList));
        },

        getStatus(val) {
            return Enum.kycByClientStatus[val];
        },

        forwardToForm(row) {
            if (row.clientStatus == 1 || row.clientStatus == 2) this.$router.push(`/kycForm?Id=` + row.clientId);
        },

        mapList(list) {
            return list.map(item => ({
                clientId: item.clientId,
                clientName: item.clientName,
                clientNumber: item.clientNumber,
                clientStatus: item.clientStatus,
                createDate: moment(String(item.createDate)).format("YYYY-MM-DD HH:mm"),
            }));
        },

        filterList(status) {
            if (status == -1) this.kycByClientList = this.originalkycByClientList;
            else {
                this.kycByClientList = this.originalkycByClientList.filter(item => {
                    return item.clientStatus == status;
                });
            }
        },
    },

    computed: {
        filteredList() {
            if (this.kycByClientList != undefined) return this.mapList(this.kycByClientList);
            return null;
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
