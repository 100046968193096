<template>
    <div class="card-tail card-f-h">
        <h1>Dashboard in progress</h1>
    </div>
</template>
<script>
export default {
    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Dashboard");
        document.title = "Dashboard";
    },
};
</script>
