<template>
    <div>
        <div class="kyc-modal-footer-container">
            <el-card class="box-card">
                <div slot="header" class="clearfix risk-grade-card-header">
                    <span>Riskklass</span>
                    <el-button @click="handleRiskClassDialog" type="primary">Ändra riskklass</el-button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th scope="col">Typ</th>
                            <th scope="col">Tidigare Riskklass</th>
                            <th scope="col">Ny Riskklass</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Autobedömning</td>
                            <td>{{ riskInfo.oldRiskGradeAuto }}</td>
                            <td>{{ riskInfo.newRiskGradeAuto }}</td>
                        </tr>
                        <tr>
                            <td>Fastställd risk</td>
                            <td title="Tidigare satt riskklass för klient, har inte en ny riskklass blivit satt så är det den fastställda">
                                {{ riskGradeBeforeChange || riskInfo.riskGradeManual }}
                            </td>
                            <td>{{ updatedRiskGrade }}</td>
                        </tr>
                    </tbody>
                </table>
            </el-card>
            <div class="kyc-modal-footer-buttons">
                <el-button type="success" @click="kycEventExecute('approveClientKyc')">Godkänn</el-button>
                <el-button type="warning" @click="kycEventExecute('updateKycStatus', 'toSupplement')">Begär Komplettering</el-button>
                <el-button type="danger" @click="kycEventExecute('updateKycStatus', 'needExtraCheck')">Krävs Extra Check</el-button>
                <el-button type="danger" @click="kycEventExecute('updateKycStatus', 'denied')">Neka</el-button>
                <el-button type="default" @click="cancelKyc">Avbryt</el-button>
            </div>
        </div>
        <kyc-risk-class-dialog
            :client-name="clientName"
            :client-id="clientId"
            @close="handleRiskClassDialog"
            @riskGradeChanged="handleRiskGradeChanged"
            :risk-class="currentRiskClass"
            :risk-class-dialog-visible.sync="showRiskClassDialog"
        />
    </div>
</template>

<script>
import kycByClientRiskClassDialog from "./kycByClientRiskClassDialog.vue";
import Api from "../kycByClient.api.js";
export default {
    components: {
        "kyc-risk-class-dialog": kycByClientRiskClassDialog,
    },
    props: {
        clientName: {
            type: String,
            default: "",
        },
        riskClass: {
            type: Number,
            default: 0,
        },
        riskInfo: {
            type: Object,
            default: () => ({}),
        },
        clientId: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            showRiskClassDialog: false,
            updatedRiskGrade: undefined,
            riskGradeBeforeChange: undefined,
        };
    },
    methods: {
        handleRiskClassDialog() {
            this.showRiskClassDialog = !this.showRiskClassDialog;
        },
        handleRiskGradeChanged(newRiskGrade) {
            this.showRiskClassDialog = !this.showRiskClassDialog;
            this.riskGradeBeforeChange = this.currentRiskClass;
            this.updatedRiskGrade = newRiskGrade;
        },
        async kycEventExecute(event, status = undefined) {
            try {
                if (status === undefined && event === "approveClientKyc") {
                    await Api[event](this.clientId);
                    this.$notify.success({ title: "Kyc godkänd" });
                } else {
                    await Api[event](this.clientId, status);
                    this.$notify.success({ title: `Kyc uppdaterad` });
                }
            } catch (error) {
                this.$notify.error({ title: `Ett fel uppstod vid kyc uppdatering` });
            }
            this.$emit("kycEventExecute");
        },
        cancelKyc() {
            this.$emit("cancelKyc");
        },
    },
    computed: {
        currentRiskClass() {
            return this.updatedRiskGrade || this.riskInfo.riskGradeManual;
        },
    },
};
</script>

<style scoped>
.box-card {
    min-width: 30%;
    border-radius: 18px;
}
div >>> .el-card__body {
    padding: 0 20px 3em 20px !important;
}

div >>> .el-card__header {
    padding-bottom: 2em !important;
    border-bottom: none !important;
}

thead {
    border-bottom: 1px solid #b4c8e0;
}
table {
    width: 100%;
    overflow: hidden;
}

tbody tr {
    border-bottom: 1px solid #b4c8e0;
    transition: background-color 0.2s ease;
}

td,
th {
    font-size: 12px;
    white-space: pre-line;
    text-wrap: nowrap;
    padding-bottom: 1em;
    padding-right: 1em;
}

td {
    padding-top: 1em;
    font-size: 12px;
    color: #1d3f67;
    white-space: pre-line;
}

tr > th {
    font-weight: bold;
    color: #1d3f67;
    font-size: 14px;
}

.kyc-modal-footer-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
    padding: 1rem;
}
.kyc-modal-footer-buttons {
    padding-left: 1em;
    padding-top: 1em;
    padding-right: 1em;
    border-top: 1px solid #ebeef5;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
}
.risk-grade-card-header {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #1d3f67;
    font-size: 18px;
}

.change-risk-class-button {
    cursor: pointer;
    font-weight: normal;
    color: #b4c8e0;
    font-size: 14px;
}
</style>
