export default {
    namespaced: true,
    state: {
        isAuthenticated: false,
        showMenu: false,
        userName: "",
    },
    mutations: {
        setUserName(state, value) {
            state.userName = value;
        },
        setAuth(state, value) {
            state.isAuthenticated = value;
        },
        setShowMenu(state, value) {
            state.showMenu = value;
        },
    },
    actions: {},
    getters: {},
};
