import Kvitton from "./Kvitton.vue";
import Receipt from "./Receipt.vue";

const routes = [
    {
        path: "/kvitton",
        name: "kvitton",
        component: Kvitton,
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/receipts/:receiptId",
        name: "Receipt",
        component: Receipt,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
