const routes = [
    {
        path: "/att_atgarda",
        name: "att_atgarda",
        component: () => import(/* webpackChunkName: "AtAtgardaView" */ "./AttAtgarda.vue"),
        meta: {
            forAuth: true,
        },
    },
];

export default routes;
