import TaxAccount from "./taxAccountPage.vue";

const routes = [
    {
        path: "/taxAccount",
        name: "taxAccount",
        component: TaxAccount,
        meta: {
            title: "Skattekonto",
            forAuth: true,
        },
    },
];
export default routes;
