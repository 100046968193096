<template>
    <div>
        <div>
            <label v-if="label" class="input-label">
                <span> {{ label }} </span>
            </label>
        </div>
        <div :class="{ horizontalRadioList: horizontal }" class="space-between">
            <RadioButton
                :error="error"
                :key="entry.id"
                :horizontal="horizontal"
                v-for="entry in entries"
                :class="horizontal == true ? 'horizontalRadioListFixup' : 'space-between'"
                :checked="value == entry.id"
                @change="changeId(entry.id)"
                :value="entry.id"
                :label="getEntries(entry.value)"
                :disabled="disabled || entry.disabled"
            />
        </div>
        <p :class="{ errorTextVertical: !horizontal, errorTextHorizontal: horizontal }" v-if="error">
            {{ $t("misc.radioButtonError") }}
        </p>
    </div>
</template>

<script>
import RadioButton from "@/components/content/RadioButtonBase";
export default {
    props: ["entries", "horizontal", "value", "error", "label", "disabled"],
    components: { RadioButton },
    methods: {
        changeId(id) {
            const entry = this.entries.find(o => o.id == id);
            if (!entry.disabled) {
                this.$emit("input", id);
            }
        },
        getEntries(entry) {
            var findEntry = entry;
            return findEntry;
        },
    },
};
</script>

<style lang="postcss" scoped>
.errorTextVertical {
    font-size: 14px;
    color: #ff7878;
    margin-top: -15px;
    font-weight: 500;
}
.errorTextHorizontal {
    font-size: 14px;
    color: #ff7878;
    margin-top: 5px;
    font-weight: 500;
}
.space-between {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 10px;
    }
}
.input-label {
    margin-top: 0px;
    color: #415770;
    font-weight: 500;
    margin-bottom: 5px;
    display: inline-block;
}
.horizontalRadioList {
    margin-left: -10px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    .radio-button {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}
.horizontalRadioListFixup {
    margin-left: 10px;
    &:first-child {
        margin-bottom: 0px;
    }
}
</style>
