<template>
    <div>
        <div class="card-tail">
            <ClientsQuickSearch @on-selected="onClientChange" :selected="preselectedClient" />
        </div>
        <div class="card-tail card-f-h">
            <Stack>
                <div class="flex w-full justify-between">
                    <div>
                        <h3 v-show="clientInfo.bank">{{ "Bank: " + clientInfo.bank + ", Kontonummer: " + clientInfo.bankAccountNumber }}</h3>
                    </div>
                    <InfoButton :info-text="infoText" />
                </div>
                <el-upload v-if="clientInfo.bank" class="flex w-full justify-end" accept=".csv" action="#" :before-upload="handleFileUpload" :show-file-list="false">
                    <el-button type="primary">Ladda upp fil</el-button>
                </el-upload>
            </Stack>
            <div>
                <div class="container" v-if="parsed" style="padding: 20px; margin-top: 20px;">
                    <p>Välj bank:</p>
                    <el-select v-if="parsed" v-model="selectedBank" style="width: 20%; margin-top: 10px; margin-bottom: 20px;" @change="handleBankSelection">
                        <el-option v-for="bank in filteredBankMappings" :key="bank.value" :label="bank.label" :value="bank" />
                    </el-select>
                    <div class="headers-tables-container" style="display: flex; margin-bottom: 20px; font-size: 13px;">
                        <el-select v-if="parsed" v-model="selectedHeader" style="width: 20%; margin-right: 20px;">
                            <el-option v-for="(headerIndex, index) in filteredHeaders" :key="'header-' + index" :label="content.meta.fields[headerIndex]" :value="headerIndex" />
                        </el-select>
                        <el-select v-if="parsed" v-model="selectedDatabaseColumn" style="width: 20%; margin-right: 20px;">
                            <el-option v-for="column in filteredColumns" :key="column" :label="column" :value="column" />
                        </el-select>
                        <div class="mapping-button" style="display:flex">
                            <el-button type="primary" @click="createMappingForHeaderAndColumn">Skapa mappning</el-button>
                        </div>
                    </div>
                    <div class="selected-mappings" style="margin-bottom: 20px; padding: 20px;">
                        <ul>
                            <li v-for="(mapping, index) in selectedMappings" :key="index">
                                {{ mapping.headerIndex }} {{ mapping.headerName }} => {{ mapping.databaseColumn }}
                                <el-button class="delete-mapping" style="display:inline; font-weight: bold; margin-left: 20px; border: 1px solid black;" @click="removeMapping(index)">X</el-button>
                            </li>
                        </ul>
                    </div>
                    <div style="display: flex; margin-bottom:30px" v-if="selectedMappings.length > 0">
                        <el-button type="success" @click="previewChanges">Förhandsgranska ändringar</el-button>
                        <el-button @click="showOriginalTable" v-if="showPreview || previewData.length == 0">Visa ursprunglig tabell</el-button>
                    </div>

                    <div class="table-container">
                        <el-table v-if="parsed && !showPreview" :data="content.data" style="font-size: 13px;">
                            <el-table-column
                                v-for="(header, key) in content.meta.fields"
                                :key="'selected-header-' + key"
                                :prop="content.meta.fields[key]"
                                :label="content.meta.fields[key]"
                                :width="'160px'"
                                align="left"
                            />
                        </el-table>
                        <div v-if="showPreview" class="preview-table">
                            <el-table :data="previewData" style="font-size: 13px; margin-top: 20px;">
                                <el-table-column
                                    v-for="(mapping, index) in selectedMappings"
                                    :key="'selected-header-' + index"
                                    :prop="mapping.databaseColumn"
                                    :label="mapping.databaseColumn"
                                    :width="'150px'"
                                />
                            </el-table>
                            <el-button type="success" @click="uploadCSVFileToDb(previewData)">Spara</el-button>
                            <el-button v-if="removeMapping" @click="showOriginalTable">Visa ursprunglig tabell</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Stack from "../../components/layout/Stack.vue";
import Papa from "papaparse";
import queryString from "query-string";
import Api from "./account_files.api";
import Iconv from "iconv-lite";
import JsChardet from "jschardet";
import InfoButton from "../../components/InfoButton.vue";

export default {
    components: {
        Stack,
        ClientsQuickSearch: () => import(/* webpackChunkName: "ClientsQuickSearch" */ "@/components/clientsSearch.vue"),
        InfoButton,
    },
    data: function() {
        return {
            clientInfo: {},
            parsed: false,
            clients: [],
            preselectedClient: {},
            clientId: queryString.parse(window.location.search).clientId,
            selectedHeader: "",
            selectedDatabaseColumn: "",
            selectedMappings: [],

            databaseColumns: ["BookingDate", "CreationDate", "AmountValue", "BalanceValue", "Text", "Misc"],

            previewData: [],
            showPreview: false,
            bankMappings: [
                { label: "Swedbank", value: "swedbank" },
                { label: "Swedbank företag", value: "swedbank2" },
                { label: "Swedbank 3", value: "swedbank3" },
                { label: "Swedbank 4", value: "swedbank4" },
                { label: "Swedbank 5", value: "swedbank5" },
                { label: "SEB Företag", value: "sebföretag" },
                { label: "SEB Privat", value: "sebprivat" },
                { label: "Sparbanken", value: "sparbanken" },
                { label: "Handelsbanken", value: "handelsbanken" },
                { label: "Nordea Företag", value: "nordeaföretag" },
                { label: "Nordea Privat", value: "nordeaprivat" },
                { label: "Länsförsäkringar", value: "länsförsäkringar" },
                { label: "Svea Bank ", value: "sveabank" },
                { label: "RedFlag", value: "redflag" },
                { label: "Danske Bank", value: "danskebank" },
            ],
            selectedBank: "",
            infoText:
                "Denna CSV-parser låter dig importera och spara kontoutdrag i CSV-format. " +
                "Sök efter klient i sökfältet och få specifik info gällande bank och kontonummer för klienten. " +
                "Ladda upp din önskade CSV-fil. Filen analyseras automatiskt och visas i en tabell med respektive rubrik och tillhörande data. " +
                "Välj bank i listan utifrån den bank som tillhör klienten. När en bank väljs tillämpas fördefinierade mappningar mellan CSV-rubriker och databaskolumner automatiskt, vilket förenklar mappningsprocessen för dig. " +
                "Notis: Om du väljer en bank som inte matchar csv-filen kommer du få en pop-up med information om att du valt fel bank. " +
                "Du kan också mappa kolumnerna i den uppladdade CSV-filen till specifika databaskolumner utan att göra ett förval av bank. " +
                "Denna mappning gör det möjligt för systemet att förstå innebörden av varje kolumn, t.ex. att mappa en kolumn i csv-filen för Bokföringsdag till en databaskolumn för BookingDate. " +
                "Efter att mappningar har tillämpats kan du förhandsgranska dina ändringar i en ny tabell nedan, där du kan se hur datan kommer att lagras i databasen. " +
                "Därefter kan du växla mellan den ursprungliga CSV-tabellen och den förhandsgranskade datan om du vill göra ytterligare ändringar, alternativt radera mappningar. " +
                "När du är nöjd med mappningarna och datan i förhandsgranskningen trycker du på spara. Detta steg lagrar bankkontoutdragsdatan permanent.",
            infoTextVisible: false,
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Kontoutdrag");
        document.title = "Kontoutdrag";
    },

    created() {
        if (!this.isGlobalClientSelected) {
            //this.getClientBankStatementInfo;
        } else {
            const { clientId } = queryString.parse(window.location.search);
            this.preselectedClient = {
                value: clientId,
            };
        }
    },

    methods: {
        async onClientChange(client) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.clientId = client.value;
            this.clientId = client.value;
            this.$router.replace({ query }).catch(() => {});

            this.preselectedClient = client;
            await this.getClientBankStatementInfo(query.clientId);
        },

        parseFile() {
            const reader = new FileReader();

            reader.onload = event => {
                const charset = JsChardet.detect(event.target.result);
                const fileContent = Iconv.decode(event.target.result, charset.encoding);

                const splitlines = fileContent.split(/\r?\n/);

                const delimiterPattern = /^(?:[^;,\t]*[,;\t]){3}/; //kollar om det fnns minst 3 förekomster komma eller kolon på minst en rad

                let headerRowIndex = -1;
                for (let i = 0; i < splitlines.length; i++) {
                    if (delimiterPattern.test(splitlines[i])) {
                        headerRowIndex = i;
                        break;
                    }
                }

                if (headerRowIndex > -1) {
                    const csvContent = splitlines.slice(headerRowIndex, splitlines.length + 1).join("\n");

                    Papa.parse(csvContent, {
                        header: true,
                        skipEmptyLines: true,
                        complete: results => {
                            this.content = results;
                            this.parsed = true;
                        },
                    });
                } else {
                    console.error("No delimiter pattern found in the file.");
                }
            };
            reader.readAsBinaryString(this.file);
        },

        resetPreviousData() {
            this.content = {};
            this.parsed = false;
            this.selectedMappings = [];
            this.selectedBank = "";
        },

        handleFileUpload(file) {
            this.resetPreviousData();
            this.showPreview = false;
            this.file = file;
            this.parseFile();
            return false;
        },

        createMappingForHeaderAndColumn(headerName, column) {
            const selectedHeaderIndex = headerName.target !== undefined ? this.content.meta.fields.indexOf(this.selectedHeader) : this.content.meta.fields.indexOf(headerName);
            // const mappingHeader = selectedHeaderIndex;
            const mappingColumn = column === undefined ? this.selectedDatabaseColumn : column;
            const mappingBank = this.selectedBank.value;

            // this.content.data.forEach(row => {
            //     row[mappingColumn] = row[mappingHeader];
            // });

            const newMapping = {
                headerName: headerName.target !== undefined ? this.selectedHeader : headerName,
                index: selectedHeaderIndex,
                databaseColumn: mappingColumn,
                bank: mappingBank,
            };
            if (this.selectedMappings.some(mapping => mapping.bank !== mappingBank)) {
                this.selectedMappings = [];
            }

            this.selectedMappings.push(newMapping);
            this.selectedHeader = "";
            this.selectedDatabaseColumn = "";

            return newMapping;
        },

        createMappingForBank(bankName, mappings) {
            for (const mapping of mappings) {
                if (this.content.meta.fields.indexOf(mapping.headerName) === -1) {
                    alert("Uppladdad fil matchar inte med vald bank. Kontrollera att rätt bank är vald!");
                    this.selectedBank = "";
                    break;
                }
                this.createMappingForHeaderAndColumn(mapping.headerName, mapping.column);
            }
        },

        handleBankSelection() {
            const bankMappings = {
                swedbank: [
                    { headerName: "Bokföringsdag", column: "BookingDate" },
                    { headerName: "Transaktionsdag", column: "CreationDate" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Beskrivning", column: "Misc" },
                    { headerName: "Referens", column: "Text" },
                    { headerName: "Bokfört saldo", column: "BalanceValue" },
                ],
                swedbank2: [
                    { headerName: "Bokfdag", column: "BookingDate" },
                    { headerName: "Transdag", column: "CreationDate" },
                    { headerName: "Referens", column: "Text" },
                    { headerName: "Text", column: "Misc" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Saldo", column: "BalanceValue" },
                ],
                swedbank3: [
                    { headerName: "Bokf dag", column: "BookingDate" },
                    { headerName: "Transdag", column: "CreationDate" },
                    { headerName: "Referens", column: "Text" },
                    { headerName: "Text", column: "Misc" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Saldo", column: "BalanceValue" },
                ],
                swedbank4: [
                    { headerName: "Bokförings datum", column: "BookingDate" },
                    { headerName: "Transaktions datum", column: "CreationDate" },
                    { headerName: "Transaktionstyp", column: "Misc" },
                    { headerName: "Referens", column: "Text" },
                    { headerName: "Belopp (SEK)", column: "AmountValue" },
                    { headerName: "Bokfört saldo", column: "BalanceValue" },
                ],
                swedbank5: [
                    { headerName: "Booked date", column: "BookingDate" },
                    { headerName: "Transaction date", column: "CreationDate" },
                    { headerName: "Transaction type", column: "Misc" },
                    { headerName: "Reference", column: "Text" },
                    { headerName: "Amount", column: "AmountValue" },
                    { headerName: "Booked balance", column: "BalanceValue" },
                ],
                sebföretag: [
                    { headerName: "Bokföringsdatum", column: "BookingDate" },
                    { headerName: "Valutadatum", column: "CreationDate" },
                    { headerName: "Text/mottagare", column: "Text" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Saldo", column: "BalanceValue" },
                ],
                sebprivat: [
                    { headerName: "Bokförd", column: "BookingDate" },
                    { headerName: "Valutadatum", column: "CreationDate" },
                    { headerName: "Text", column: "Text" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Saldo", column: "BalanceValue" },
                ],
                sparbanken: [
                    { headerName: "Datum", column: "BookingDate" },
                    { headerName: "Text", column: "Text" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Saldo", column: "BalanceValue" },
                ],
                nordeaföretag: [
                    { headerName: "Datum", column: "BookingDate" },
                    { headerName: "Text", column: "Text" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Saldo", column: "BalanceValue" },
                ],
                nordeaprivat: [
                    { headerName: "Bokföringsdag", column: "BookingDate" },
                    { headerName: "Meddelande", column: "Text" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Saldo", column: "BalanceValue" },
                ],
                lansförsäkringar: [
                    { headerName: "Bokföringsdatum", column: "BookingDate" },
                    { headerName: "Transaktionsdatum", column: "CreationDate" },
                    { headerName: "Meddelande", column: "Text" },
                    { headerName: "Belopp", column: "AmountValue" },
                ],
                sveabank: [
                    { headerName: "Datum", column: "BookingDate" },
                    { headerName: "Meddelande", column: "Text" },
                    { headerName: "Belopp", column: "AmountValue" },
                    { headerName: "Saldo", column: "BalanceValue" },
                ],
                handelsbanken: [
                    { headerName: "Bokföringsdag", column: "BookingDate" },
                    { headerName: "Reskontradag", column: "CreationDate" },
                    { headerName: "Referens", column: "Text" },
                    { headerName: "Insättning/Uttag", column: "AmountValue" },
                    { headerName: "Bokfört saldo", column: "BalanceValue" },
                ],
                redflag: [
                    { headerName: "Timestamp", column: "BookingDate" },
                    { headerName: "Description", column: "Text" },
                    { headerName: "Amount", column: "AmountValue" },
                    { headerName: "Balance", column: "BalanceValue" },
                ],
                danskebank: [
                    { headerName: "Bokföringsdag", column: "BookingDate" },
                    { headerName: "Text", column: "Text" },
                    { headerName: "Belopp i SEK", column: "AmountValue" },
                    { headerName: "Bokfört saldo i SEK", column: "BalanceValue" },
                ],
            };

            const selectedBankMappings = bankMappings[this.selectedBank.value];
            if (selectedBankMappings) {
                this.createMappingForBank(this.selectedBank.label, selectedBankMappings);
            } else {
                console.error(`Mappings not found for ${this.selectedBank.label}`);
            }
        },

        removeMapping(index) {
            this.selectedMappings.splice(index, 1);
        },

        previewChanges() {
            const previewData = this.content.data.map(row => {
                const newRow = {};
                this.selectedMappings.forEach(mapping => {
                    if (mapping.databaseColumn == "AmountValue" || mapping.databaseColumn == "BalanceValue") {
                        newRow[mapping.databaseColumn] = row[mapping.headerName]
                            .replaceAll(" ", "")
                            .replaceAll("−", "-")
                            .replaceAll(" ", "")
                            .replaceAll(",", ".");
                    } else {
                        newRow[mapping.databaseColumn] = row[mapping.headerName];
                    }
                });
                return newRow;
            });
            this.previewData = previewData;
            this.showPreview = true;
        },

        showOriginalTable() {
            this.showPreview = false;
        },

        async uploadCSVFileToDb(previewData) {
            this.$waiting.start("uploading");
            const responseData = await Api.savePreviewData(this.clientId, previewData);
            this.$notify.success({ title: `${responseData.length} transaktioner sparade` });
            this.$waiting.end("uploading");
        },

        async getClientBankStatementInfo(clientId) {
            this.$waiting.start("loading");
            const response = await Api.getClientBankStatementInfo(clientId);
            this.clientInfo = response;
            this.$waiting.end("loading");
        },
    },

    computed: {
        filteredHeaders() {
            const headers = this.content.meta.fields.filter(headerName => {
                // Filter out headers that have been mapped already
                return !this.selectedMappings.some(mapping => mapping.headerName === headerName);
            });
            return headers;
        },

        filteredColumns() {
            const columns = this.databaseColumns.filter(column => {
                // Filter out columns that have been mapped already
                return !this.selectedMappings.some(mapping => mapping.databaseColumn === column);
            });
            return columns;
        },

        filteredBankMappings() {
            const mapping = this.bankMappings.filter(bank => {
                return !this.bankMappings.some(map => map.bankMappings === bank);
            });
            return mapping;
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>

<style>
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-container h3 {
    flex-grow: 1;
}
</style>
