<template>
    <Stack :space="30" v-if="kycTempByClient.kycByClientDetails">
        <Columns>
            <Column>
                <Stack :space="10">
                    <TextBase size="medium" weight="bold">Uppskattad omsättning 12 mån.</TextBase>
                    <TextBase size="small" weight="light">{{ getEnum.turnOverOptions[kycTempByClient.kycByClientDetails.expectedTurnover] }}</TextBase>
                </Stack>
            </Column>
            <Column align="right">
                <Stack :space="10">
                    <TextBase size="medium" weight="bold">Svaren inhämtade:</TextBase><TextBase size="small" weight="light">{{ kycTempByClient.kycByClientDetails.createDate }}</TextBase>
                </Stack>
            </Column>
        </Columns>
        <Stack :space="10">
            <TextBase size="medium" weight="bold">Verksamhetsbeskrivning</TextBase>
            <TextBase size="small" weight="light">{{ kycTempByClient.kycByClientDetails.businessDescription }}</TextBase>
        </Stack>
        <Stack :space="10">
            <TextBase size="medium" weight="bold">Var och vilka är företagskunder</TextBase>
            <TextBase size="small" weight="light" :key="key + 'B'" v-for="(option, key) in customerTypes" :value="option.value" v-show="option.show">{{ option.name }}</TextBase>
            <TextBase size="small" weight="light" v-bind:key="Id + option.Id + 'C'" v-for="(option, Id) in getCustomerCountries"> -{{ option.name }} </TextBase>
        </Stack>
        <Stack :space="10">
            <TextBase size="medium" weight="bold">Hur säljs produkterna/tjänsterna</TextBase>
            <TextBase size="small" weight="light" v-bind:key="Id" v-for="(option, Id) in sellingOptions" v-show="option.show">
                {{ option.name }}
            </TextBase>
            <TextBase size="small" weight="light">{{ kycTempByClient.kycByClientDetails.sellingMethodDescription }}</TextBase>
        </Stack>
        <Stack :space="10">
            <TextBase size="medium" weight="bold">Kontanthantering</TextBase>
            <TextBase size="small" weight="light">{{ getEnum.yesNoOption[kycTempByClient.kycByClientDetails.cashHandling] }}</TextBase>
        </Stack>
        <Stack :space="10">
            <TextBase size="medium" weight="bold">I vilka länder finns leverantörerna.</TextBase>
            <TextBase size="small" weight="light">{{ getEnum.yesNoOption[kycTempByClient.kycByClientDetails.supplierInSweden] }}</TextBase>
            <TextBase size="small" weight="light" v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in getsSupplierCountries"> -{{ option.name }} </TextBase>
        </Stack>
        <Stack :space="10" v-if="sniCode">
            <Columns :space="5" align-y="top">
                <Column :width="5"><TextBase size="small" weight="bolder">Sni</TextBase></Column>
                <Column :width="20"><TextBase size="small" weight="bolder">Beskrivning</TextBase></Column>
                <Column :width="10"><TextBase size="small" weight="bolder">Risk</TextBase></Column>
                <Column :width="10"><TextBase size="small" weight="bolder">Kontantintensiv</TextBase></Column>
            </Columns>
            <Control v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in sniCode">
                <Columns :space="5" align-y="top">
                    <Column :width="5">
                        <TextBase size="small" weight="light">{{ option.sniCode }}</TextBase>
                    </Column>
                    <Column :width="20">
                        <TextBase size="small" weight="light">{{ option.description }}</TextBase>
                    </Column>
                    <Column :width="10">
                        <TextBase size="small" weight="light">{{ getEnum.riskYesOrNo[option.risk] }}</TextBase>
                    </Column>
                    <Column :width="10">
                        <TextBase size="small" weight="light">{{ option.cashIntensive }}</TextBase>
                    </Column>
                </Columns>
            </Control>
        </Stack>
        <Columns :space="20">
            <button class="button is-info is-small is-rounded" @click="goback()">
                Tillbaka
            </button>
        </Columns>
    </Stack>
</template>
<script>
import Enum from "../../pages/onboarding/onboarding.enum";
import Api from "../kycByClient/kycByClient.api";
import Stack from "../../components/layout/Stack.vue";
import Columns from "../../components/layout/Columns.vue";
import Column from "../../components/layout/Column.vue";
import Control from "../../components/layout/Control.js";
import TextBase from "../../components/content/Text.vue";
import moment from "moment";
import "moment/locale/se";
export default {
    data() {
        return {
            clientId: undefined,
            kycTempByClient: {},
            sniCode: [],
        };
    },
    components: {
        Stack,
        Columns,
        Column,
        TextBase,
        Control,
    },
    async created() {
        this.clientId = this.$route.query.Id;
        if (this.clientId) {
            this.kycTempByClient = await Api.kycByClientDetails(this.clientId);
            this.kycTempByClient.kycByClientDetails.createDate = moment(String(this.kycTempByClient.kycByClientDetails.createDate)).format("YYYY-MM-DD HH:mm");
            this.sniCode = await Api.snicode(this.clientId);
        }
    },
    methods: {
        goback() {
            this.$router.push(`/kycByClientList/`);
        },
    },
    computed: {
        getsSupplierCountries() {
            let supplierArr = [];
            if (this.kycTempByClient != undefined && this.kycTempByClient.kycTempByClientCountries != undefined) {
                this.kycTempByClient.kycTempByClientCountries.forEach(country => {
                    if (country.type == 1) {
                        var obj = {};
                        obj["Id"] = country.countryId;
                        obj["name"] = country.countryName;
                        supplierArr.push(obj);
                    }
                });
            }
            return supplierArr;
        },
        getEnum() {
            return Enum;
        },
        customerTypes() {
            let a = [
                { name: "Privatpersoner i Sverige", show: this.kycTempByClient.kycByClientDetails.customerSwedenPrivatePerson },
                { name: "Privatpersoner inom EU/ESS", show: this.kycTempByClient.kycByClientDetails.customerPrivateWithinEu },
                { name: "Privatpersoner utanför EU/ESS", show: this.kycTempByClient.kycByClientDetails.customerPrivateOutsideEu },
                { name: "Företag i Sverige", show: this.kycTempByClient.kycByClientDetails.customerCompanyInSweden },
                { name: "Företag inom EU-ESS", show: this.kycTempByClient.kycByClientDetails.customerCompanyWithinEu },
                { name: "Företag utanför EU/ESS", show: this.kycTempByClient.kycByClientDetails.customerCompanyOutsideEu },
            ];
            return a;
        },
        sellingOptions() {
            let sellingOptions = [
                { name: "Personlig kontakt", show: this.kycTempByClient.kycByClientDetails.sellingMethodShop },
                { name: "Hemsida", show: this.kycTempByClient.kycByClientDetails.sellingMethodInternet },
                { name: "Annat", show: this.kycTempByClient.kycByClientDetails.sellingMethodOther },
            ];
            return sellingOptions;
        },
        getCustomerCountries() {
            let customerArr = [];
            if (this.kycTempByClient.kycTempByClientCountries != undefined) {
                this.kycTempByClient.kycTempByClientCountries.forEach(country => {
                    if (country.type == 2) {
                        var obj = {};
                        obj["Id"] = country.countryId;
                        obj["name"] = country.countryName;
                        customerArr.push(obj);
                    }
                });
            }
            return customerArr;
        },
    },
};
</script>
