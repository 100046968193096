import Vue from "vue";

export default {
    getAllSyncedClients: async () => {
        try {
            const response = await Vue.axios.get(`clients/allSynced`);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: shopify.api.js ~ line 4 ~ getShopifyOrders: ~ error", error);
            return [];
        }
    },
    getPaginatedShopifyOrders: async (clientId, pageNumber, pageSize, dateFrom, dateTo) => {
        try {
            const response = await Vue.axios.get(`clients/${clientId}/shopifyOrders`, {
                params: {
                    pageNumber,
                    pageSize,
                    startDate: dateFrom,
                    endDate: dateTo,
                },
            });
            return response;
        } catch (error) {
            console.log("🚀 ~ file: shopify.api.js ~ line 4 ~ getShopifyOrders: ~ error", error);
            return [];
        }
    },
    getShopifyOrderLists: async clientId => {
        try {
            const response = await Vue.axios.get(`clients/${clientId}/shopifyOrderList`);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: shopify.api.js ~ line 4 ~ getShopifyOrders: ~ error", error);
            return [];
        }
    },
    getPeriodShopifyOrders: async (clientId, dateFrom, dateTo) => {
        try {
            const response = await Vue.axios.get(`clients/${clientId}/shopifyOrders/period`, {
                params: {
                    startDate: dateFrom,
                    endDate: dateTo,
                },
            });
            return response;
        } catch (error) {
            console.log("🚀 ~ file: shopify.api.js ~ line 4 ~ getShopifyOrders: ~ error", error);
            return [];
        }
    },
    saveShopifyOrdersVatNumber: async ({ clientId, shopifyOrderId, data }) => {
        try {
            const response = await Vue.axios.post(`clients/${clientId}/shopifyOrders/save`, { shopifyOrderId, ...data });
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: shopify.api.js ~ line 29 ~ saveShopifyOrdersVatNumber: ~ error", error);
            return [];
        }
    },
};
