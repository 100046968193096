import BankAccountOverview from "./BankAccountOverview.vue";
import ClientApproved from "./ClientApprovedPage.vue";
import ClientFactoringList from "./ClientFactoringList.vue";
import ClientFactoringInvoiceOverview from "./FactoringInvoiceOverview.vue";
import FactoringSettings from "./FactoringSettings.vue";
import RedFlagCustomers from "./RedFlagCustomers.vue";
import SalesLedger from "./SalesLedger.vue";

const routes = [
    {
        path: "/clientFactoringList",
        name: "clientFactoringList",
        component: ClientFactoringList,
        meta: {
            forAuth: true,
            title: "Client Factoring List",
        },
    },
    {
        path: "/factoringInvoiceOverview",
        name: "factoringInvoiceOverview",
        component: ClientFactoringInvoiceOverview,
        meta: {
            forAuth: true,
            title: "Client Factoring Invoices",
        },
    },
    {
        path: "/salesLedger",
        name: "salesLedger",
        component: SalesLedger,
        meta: {
            forAuth: true,
            title: "Sales Ledger",
        },
    },
    {
        path: "/bankAccountOverview",
        name: "bankAccountOverview",
        component: BankAccountOverview,
        meta: {
            forAuth: true,
            title: "Bank Account Overview",
        },
    },
    {
        path: "/redFlagCustomers",
        name: "redFlagCustomers",
        component: RedFlagCustomers,
        meta: {
            forAuth: true,
            title: "Red Flag Customers",
        },
    },
    {
        path: "/factoringSettings",
        name: "factoringSettings",
        component: FactoringSettings,
        meta: {
            forAuth: true,
            title: "Factoring Settings",
        },
    },
    {
        path: "/clientApproved/:clientId",
        name: "clientApproved",
        component: ClientApproved,
        meta: {
            forAuth: true,
        },
        children: [
            {
                path: "credit",
                component: () => import(/* webpackChunkName: "ClientApproved" */ "./components/ClientApproved.vue"),
                meta: {
                    activeTab: "credit",
                },
            },
            {
                path: "invoices",
                component: () => import(/* webpackChunkName: "ClientFinance" */ "./ClientFactoringInvoices.vue"),
                meta: {
                    activeTab: "invoices",
                },
            },
            {
                path: "getDraftInvoiceInfo/:invoiceId",
                component: () => import(/* webpackChunkName: "CustomerApproved" */ "./CustomerApproved.vue"),
                meta: {
                    activeTab: "invoices",
                },
            },
            {
                path: "getInvoiceInfo/:invoiceId",
                component: () => import(/* webpackChunkName: "CustomerApproved" */ "./CustomerApproved.vue"),
                meta: {
                    activeTab: "invoices",
                },
            },
            {
                path: "reskontra",
                component: () => import(/* webpackChunkName: "ClientBank" */ "./ClientSalesLedger.vue"),
                meta: {
                    activeTab: "reskontra",
                },
            },
            {
                path: "notes",
                component: () => import(/* webpackChunkName: "ClientNotes" */ "./ClientNotes.vue"),
                meta: {
                    activeTab: "notes",
                },
            },
            {
                path: "settings",
                component: () => import(/* webpackChunkName: "ClientSettings" */ "./ClientFactoringSettings.vue"),
                meta: {
                    activeTab: "settings",
                },
            },
        ],
    },
];
export default routes;
