import store from "./store";
export default function(Vue) {
    Vue.auth = {
        // set token
        setToken(token, expiration) {
            localStorage.setItem("token", token);
            localStorage.setItem("expiration", expiration);
            Vue.axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            store.commit("login/setAuth", true);
        },
        // get token
        getToken() {
            var token = localStorage.getItem("token");
            var expiration = localStorage.getItem("expiration");

            if (!token || !expiration) return null;
            if (Date.now() > expiration) {
                this.destroyToken();
                return null;
            } else {
                return token;
            }
        },
        // destroy token
        destroyToken() {
            //Vue.$store.commit("auth/login", response.data);
            localStorage.removeItem("token");
            localStorage.removeItem("expiration");
            store.commit("login/setAuth", false);

            ////      store.commit("auth/setAuth", false);
        },
        // isAuthenticated
        isAuthenticated() {
            if (this.getToken()) return true;
            else {
                ////store.commit("auth/setAuth", false);
                ////store.commit("menu/setLoggedIn", false);
                return false;
            }
        },
    };

    let token = Vue.auth.getToken();
    if (token != null) Vue.axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    Object.defineProperties(Vue.prototype, {
        $auth: {
            get() {
                return Vue.auth;
            },
        },
    });
}
