import Vue from "vue";

export default {
    getPayrolls: async () => {
        const response = await Vue.axios.get(`payrolls/clients`);
        return response.data;
    },
    getEarners: async clientId => {
        const response = await Vue.axios.get(`payrolls/clients/${clientId}`);
        return response.data;
    },
    getClient: async clientId => {
        const response = await Vue.axios.get(`payrolls/client/${clientId}`);
        return response.data;
    },
    savePreparation: async (clientId, individualClientId, dto) => {
        const response = await Vue.axios.post(`payrolls/clients/${clientId}/employees/${individualClientId}`, dto);
        return response.data;
    },
};
