<template>
    <div class="card-tail card-f-h">
        <DropDown style="margin-top: 50px; width: 30%" :options="onboardingStatus" request-text="Välj status ..." @on-change="filterList" state="ready" />
        <div v-if="filteredList" style="margin-top: 50px">
            <my-list :items="filteredList" :headers="headers" :scroll="true" :search="false" @click="forwardToForm">
                <template slot-scope="doc">
                    <td>{{ doc.onboardingId }}</td>
                    <td>{{ doc.companyName }}</td>
                    <td>{{ doc.personalNumber }}</td>
                    <td>{{ getStatus(doc.status) }}</td>
                    <td v-if="doc.status == 0 || doc.status == 1" class="w-1" style="color:salmon" v-on:click.stop="" @click="triggerDialogue(doc)">
                        <i class="fa-regular fa-trash-can" />
                    </td>
                </template>
            </my-list>
        </div>
    </div>
</template>
<script>
import DropDown from "../../../components/dropdown.vue";
import List from "../../../components/list";
import Api from "../onboarding.api";
import Enum from "../onboarding.enum";
export default {
    components: {
        "my-list": List,
        DropDown,
    },

    data: function() {
        return {
            originalOnboardingList: [],
            onboardingList: undefined,
            onboardingStatus: [
                { name: "Alla", value: -1 },
                { name: "Inital", value: 0 },
                { name: "Klar att granska", value: 1 },
                { name: "Godkänd", value: 2 },
                { name: "Nekad", value: 3 },
            ],
            headers: [
                { name: "Id", prop: "onboardClientId", width: "120px" },
                { name: "Företag namn", prop: "companyName", width: "200px" },
                { name: "Personnummer", prop: "personalNumber", width: "120px" },
                { name: "Status", prop: "status", width: "120px" },
            ],
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Onboarding");
        document.title = "Onboarding";
    },

    created() {
        this.getOnboardingClientList();
    },

    methods: {
        triggerDialogue(doc) {
            const self = this;
            this.$dialog
                .confirm("Är du säker på att du vill ta bort denna pågående onboarding-klient?")
                .then(function(isSuccess) {
                    if (isSuccess == true) {
                        self.deleteInProgressClient(doc);
                    }
                })
                .catch(function() {});
        },

        async deleteInProgressClient(doc) {
            var result = await Api.deleteInProgressClient(doc.onboardingId);
            if (result) {
                const type = result == true ? "success" : "error";
                var messsage = result == true ? "Klienten raderas." : "Det uppstod ett fel vid borttagning av den pågående klienten.";
                if (result == true) {
                    this.getOnboardingClientList();
                }
                this.$toasted.show(messsage, {
                    theme: "bubble",
                    duration: null,
                    position: "bottom-center",
                    type: type,
                    keepOnHover: true,
                    action: {
                        text: "Cancel",
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
        },

        async getOnboardingClientList() {
            this.onboardingList = await Api.getOnbaordingClientList();
            this.originalOnboardingList = JSON.parse(JSON.stringify(this.onboardingList));
        },

        getStatus(val) {
            return Enum.onboardingStatus[val];
        },

        forwardToForm(row) {
            this.$router.push(`/onboardingForm/?onboardingId=` + row.onboardingId);
        },

        mapList(list) {
            return list.map(item => ({
                onboardingId: item.onboardClientId,
                companyName: item.companyName,
                personalNumber: item.personalNumber,
                status: item.onboardingStatus,
            }));
        },

        filterList(status) {
            if (status == -1) this.onboardingList = this.originalOnboardingList;
            else {
                this.onboardingList = this.originalOnboardingList.filter(item => {
                    return item.onboardingStatus == status;
                });
            }
        },
    },

    computed: {
        filteredList() {
            if (this.onboardingList != undefined) return this.mapList(this.onboardingList);
            return null;
        },
    },
};
</script>
