<template>
    <div v-if="companyInfo">
        <header>
            <div class="main-container-column">
                <el-card class="status-card">
                    <div slot="header" class="clearfix kyc-card-header">
                        <span>Status</span>
                    </div>
                    <div class="align-column">
                        <div v-for="(value, key) in generalInfo" :key="key" class="info-item">
                            <span class="info-label">{{ formatLabel(key) }}:</span>
                            <span class="info-value">{{ value }}</span>
                        </div>
                    </div>
                </el-card>
                <el-card class="company-info-card">
                    <div slot="header" class="clearfix kyc-card-header">
                        <span>Allmän Företagsinformation</span>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Aktuell</th>
                                <th>Ny</th>
                                <th>Syna</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span style="display: block;">{{ companyInfoComparison.current.address }}</span>
                                    <span style="display: block;">{{ companyInfoComparison.current.address2 }}</span>
                                    <span style="display: block;">{{ companyInfoComparison.current.city }}</span>
                                </td>
                                <td>
                                    <span style="display: block;">{{ companyInfoComparison.new.address }}</span>
                                    <span style="display: block;">{{ companyInfoComparison.new.address2 }}</span>
                                    <span style="display: block;">{{ companyInfoComparison.new.city }}</span>
                                </td>
                                <td>
                                    <span style="display: block;">{{ companyInfoComparison.syna.address }}</span>
                                    <span style="display: block;">{{ companyInfoComparison.syna.address2 }}</span>
                                    <span style="display: block;">{{ companyInfoComparison.syna.city }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ companyInfoComparison.current.corporateTax }}</td>
                                <td />
                                <td>{{ companyInfoComparison.syna.corporateTax }}</td>
                            </tr>
                            <tr>
                                <td>{{ companyInfoComparison.current.vatStatus }}</td>
                                <td />
                                <td>{{ companyInfoComparison.syna.vatStatus }}</td>
                            </tr>
                            <tr>
                                <td>{{ companyInfoComparison.current.employerStatus }}</td>
                                <td />
                                <td>{{ companyInfoComparison.syna.employerStatus }}</td>
                            </tr>
                        </tbody>
                    </table>
                </el-card>
            </div>
        </header>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        companyInfo: {
            type: Object,
            default: () => ({}),
        },
        clientId: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        companyInfoComparison() {
            return {
                current: {
                    address: this.companyInfo.oldCompanyInfo.address,
                    address2: this.companyInfo.oldCompanyInfo.address2,
                    city: this.companyInfo.oldCompanyInfo.postalCode + " " + this.companyInfo.oldCompanyInfo.city,
                    corporateTax: this.companyInfo.currentTaxStatus.corporateTax,
                    vatStatus: this.companyInfo.currentTaxStatus.vatStatus,
                    employerStatus: this.companyInfo.currentTaxStatus.employerStatus,
                },
                new: {
                    address: this.companyInfo.newCompanyInfo.address,
                    address2: this.companyInfo.newCompanyInfo.address2,
                    city: this.companyInfo.newCompanyInfo.postalCode + " " + this.companyInfo.newCompanyInfo.city,
                    corporateTax: this.companyInfo.synaTaxStatus.corporateTax,
                    vatStatus: this.companyInfo.synaTaxStatus.vatStatus,
                    employerStatus: this.companyInfo.synaTaxStatus.employerStatus,
                },
                syna: {
                    address: this.companyInfo.actualCompanyInfo.address,
                    address2: this.companyInfo.actualCompanyInfo.address2,
                    city: this.companyInfo.actualCompanyInfo.postalCode + " " + this.companyInfo.actualCompanyInfo.city,
                    corporateTax: this.companyInfo.synaTaxStatus.corporateTax,
                    vatStatus: this.companyInfo.synaTaxStatus.vatStatus,
                    employerStatus: this.companyInfo.synaTaxStatus.employerStatus,
                },
            };
        },
        generalInfo() {
            return {
                status: this.status || "-",
                // sentBy: "-",
                sentDate: this.formatDate(this.companyInfo.submittedTime),
            };
        },
    },
    methods: {
        formatDate(unformattedDate) {
            const date = unformattedDate;
            return moment(date).format("YYYY-MM-DD");
        },
        formatLabel(key) {
            const labels = {
                status: "Status",
                // sentBy: "Inskickat av",
                sentDate: "Inskickat av klient",
            };
            return labels[key] || key;
        },
    },
};
</script>

<style scoped>
thead {
    border-bottom: 1px solid #b4c8e0;
}
table {
    width: 100%;
    overflow: hidden;
}

tbody tr {
    border-bottom: 1px solid #b4c8e0;
    transition: background-color 0.2s ease;
}

td,
th {
    white-space: pre-line;
    text-wrap: nowrap;
    padding-bottom: 1em;
    padding-right: 2em;
}

td {
    padding-top: 1em;
    font-size: 12px;
    color: #1d3f67;
    white-space: pre-line;
    word-wrap: nowrap;
}

tr > th {
    font-weight: bold;
    color: #1d3f67;
    font-size: 14px;
}

.status-card {
    width: 50%;
}
.company-info-card {
    min-width: 350px;
}
.main-container-column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.kyc-card-header {
    font-weight: bold;
    color: #1d3f67;
    font-size: 18px;
}
div >>> .el-card__body {
    padding: 0 20px 3em 20px !important;
}

div >>> .el-card__header {
    padding-bottom: 2em !important;
    border-bottom: none !important;
}
.main-container-column >>> .el-card {
    border-radius: 18px;
    border: none;
}
.alignment {
    display: flex;
    flex: 1;
}

.align-left-to-right {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
}

.align-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.info-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    width: 100%;
    font-size: 12px;
}

.info-label {
    color: #1d3f67;
    font-weight: bold;
}

.info-value {
    color: #1d3f67;
    font-size: 12px;
}
</style>
