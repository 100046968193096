import AnnualReportSubmenu from "./AnnualReportSubmenu.vue";

const routes = [
    {
        path: "/annualReport",
        name: "annualReport",
        component: AnnualReportSubmenu,
        meta: {
            forAuth: true,
        },
        children: [
            {
                path: "start",
                component: () => import("./start/AnnualReportStart.vue"),
                meta: {
                    activeTab: "start",
                },
            },
            {
                path: "companyInformation/:annualReportId",
                component: () => import("./companyInfo/CompanyInformation.vue"),
                meta: {
                    activeTab: "companyInformation",
                },
                props: true,
            },
            {
                path: "coverSheet/:annualReportId",
                component: () => import("./coverSheet/CoverSheet.vue"),
                meta: {
                    activeTab: "coverSheet",
                },
                props: true,
            },
            {
                path: "managementReport/:annualReportId",
                component: () => import("./managementStatement/ManagementReport.vue"),
                meta: {
                    activeTab: "managementReport",
                },
                props: true,
            },
            {
                path: "incomeStatement/:annualReportId",
                component: () => import("./incomeStatement/IncomeStatementForm.vue"),
                meta: {
                    activeTab: "incomeStatement",
                },
                props: true,
            },
            {
                path: "balanceSheet/:annualReportId",
                component: () => import("./balanceSheet/BalanceSheet.vue"),
                meta: {
                    activeTab: "balanceSheet",
                },
                props: true,
            },
            {
                path: "accountingPrinciples/:annualReportId",
                component: () => import("./accountingPrinciples/AccountingPrinciples.vue"),
                meta: {
                    activeTab: "accountingPrinciples",
                },
                props: true,
            },
            {
                path: "notes/:annualReportId",
                component: () => import("./notes/NotesPage.vue"),
                meta: {
                    activeTab: "notes",
                },
                props: true,
            },
            {
                path: "signatures/:annualReportId",
                component: () => import("./signatures/Signatures.vue"),
                meta: {
                    activeTab: "signatures",
                },
                props: true,
            },
            {
                path: "annualGeneralMeetingMinutes/:annualReportId",
                component: () => import("./annualMeetingProtocol/AnnualGeneralMeetingMinutes.vue"),
                meta: {
                    activeTab: "annualGeneralMeetingMinutes",
                },
                props: true,
            },
            {
                path: "certificationOfApproval/:annualReportId",
                component: () => import("./certificationOfApproval/CertificationOfApproval.vue"),
                meta: {
                    activeTab: "certificationOfApproval/:annualReportId",
                },
                props: true,
            },
            {
                path: "incomeDeclaration2/:annualReportId",
                component: () => import("./incDeclaration2/IncomeDeclaration2.vue"),
                meta: {
                    activeTab: "incomeDeclaration2",
                },
                props: true,
            },
        ],
    },
];
export default routes;
