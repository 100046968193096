<template>
    <div>
        <div class="card-tail flex">
            <check-box @change="toggleNewVersion" label="visa bara nya verisonfakturor" />
            <ClientsSearch class="ml-5" @on-selected="clientSelected" v-if="displayClientFilter(selectedTab.name)" />
        </div>
        <div class="card-tail">
            <div class="tabs is-boxed" v-bind:class="{ disabledbutton: isLoading }" style="margin-top: 20px">
                <ul>
                    <li v-for="tab in filteredTabs" :key="tab.name" :class="{ 'is-active': tab.isActive }">
                        <div style="display: flex; flex-direction: row">
                            <span class="pl-1 pr-1 py-1 cursor-pointer">
                                <a @click="selectTab(tab)">
                                    {{ tab.name }}
                                    <span v-if="isLoading && tab.isActive"><button class="button is-loading is-white is-small"/></span>
                                </a>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div style="margin-top: 35px">
                <component v-bind:is="currentTabComponent" :content="selectedTab" @errorLoading="errorLoading" @loading="checkloading" :new-version="newVersion" :selected-client="selectedClient" />
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../supplierInvoice.api";
import Enum from "../../supplierInvoice.enum.js";
import { mapState } from "vuex";

export default {
    data: function() {
        return {
            isLoading: false,
            docId: 0,
            selectedTab: {},
            newVersion: false,
            selectedClient: "",
            tabs: [
                { name: Enum.supplierInvoiceTabList.Upload, isActive: true, display: false },
                { name: Enum.supplierInvoiceTabList.Validate, isActive: false, display: false },
                { name: Enum.supplierInvoiceTabList.Register, isActive: false, display: false },
                { name: Enum.supplierInvoiceTabList.Creditnote, isActive: false, display: true },
                { name: Enum.supplierInvoiceTabList.ReceiptCreated, isActive: false, display: true },
                { name: Enum.supplierInvoiceTabList.WaitingOnClient, isActive: false, display: true },
                { name: Enum.supplierInvoiceTabList.WaitingToPay, isActive: false, display: true },
                { name: Enum.supplierInvoiceTabList.Paid, isActive: false, display: true },
                { name: Enum.supplierInvoiceTabList.Denied, isActive: false, display: true },
                { name: Enum.supplierInvoiceTabList.AdminDenied, isActive: false, display: true },
                { name: Enum.supplierInvoiceTabList.Deleted, isActive: false, display: true },
            ],
        };
    },

    components: {
        supplierInvoiceUpload: () => import("./supplierInvoiceUpload"),
        supplierInvoiceUploadedList: () => import("./supplierInvoiceUploadedList"),
        "check-box": () => import("../../../../components/checkbox.vue"),
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Leverantörsfaktura");
        document.title = "Leverantörsfaktura";
    },

    async created() {
        //await Api.postRossumLogin();
        if (this.isGlobalClientSelected) {
            this.$store.commit("supplierInvoice/setCurrentTab", Enum.supplierInvoiceTabList.Creditnote);
        }
        var temptab = this.tabs.filter(item => {
            return item.name == this.currentTab;
        });
        this.selectTab(temptab[0]);
    },

    methods: {
        errorLoading() {
            this.isLoading = false;
        },

        async clientSelected(data) {
            if (data) {
                this.selectedClient = data.value;
            } else {
                this.selectedClient = "";
            }
        },

        displayClientFilter(selectedTab) {
            var temptab = this.tabs.filter(item => {
                return item.name == selectedTab;
            });
            if (temptab[0]) return temptab[0].display;
            else return true;
        },

        toggleNewVersion() {
            this.newVersion = !this.newVersion;
        },

        checkloading(value) {
            if (value != undefined) {
                this.isLoading = false;
            }
        },

        selectTab(selectedTab) {
            if (selectedTab != undefined) {
                document.title = "Leverantörsfaktura - " + selectedTab.name;
                this.tabs.forEach(tab => {
                    tab.isActive = tab.name == selectedTab.name;
                });
                this.$store.commit("supplierInvoice/setCurrentTab", selectedTab.name);
                if (selectedTab != this.selectedTab) {
                    this.setLoadingForTab(selectedTab);
                }
                this.selectedTab = selectedTab;
            }
        },

        setLoadingForTab(selectedTab) {
            if (selectedTab.name == Enum.supplierInvoiceTabList.Upload || selectedTab == undefined) {
                this.isLoading = false;
            } else {
                this.isLoading = true;
            }
        },

        async removeLock(lockTable, Id) {
            await Api.removeLock(lockTable, Id);
        },
    },
    computed: {
        ...mapState({
            currentTab: state => state.supplierInvoice.currentTab,
        }),

        currentTabComponent() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.docId = this.$route.query.docId;

            if (!this.currentTab && !this.isGlobalClientSelected) {
                return "supplierInvoiceUpload";
            } else if (!this.currentTab && this.isGlobalClientSelected) {
                return "supplierInvoiceUploadedList";
            }

            switch (this.currentTab) {
                case Enum.supplierInvoiceTabList.Upload:
                    return "supplierInvoiceUpload";
                case Enum.supplierInvoiceTabList.Validate:
                case Enum.supplierInvoiceTabList.Register:
                    if (this.docId != null && this.docId != "") {
                        this.removeLock(Enum.LockedTable.SupplierInvoiceCaptures, this.docId);
                    }
                    return "supplierInvoiceUploadedList";
                default:
                    return "supplierInvoiceUploadedList";
            }
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },

        filteredTabs() {
            return this.isGlobalClientSelected ? this.tabs.filter(tab => tab.display == true) : this.tabs;
        },
    },
};
</script>
<style scoped>
li {
    font-size: 14px;
    font-weight: bold;
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}
</style>
