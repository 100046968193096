import Vue from "vue";
import axios from "axios";
import { Notification } from "element-ui";

import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

const hostname = location.hostname;
console.log("🚀 - file: axios.js - line 9 - hostname", hostname);

let baseUrl = "";
if (hostname === "localhost") {
    baseUrl = process.env.VUE_APP_URL;
} else if (hostname === "elsa-test.bolageriet.se") {
    baseUrl = "https://app-elsa-test-01.azurewebsites.net/api";
} else baseUrl = "/api";
const showErrors = error => {
    Notification.error({
        title: error.Message || "Error",
        message: error.StackTraceString || error,
        duration: 0,
    });
};
const showWarning = message => {
    Notification.warning({
        title: "Info",
        message: message,
        duration: 0,
    });
};

console.log("🚀 - file: axios.js - line 9 - baseUrl", baseUrl);
axios.defaults.baseURL = baseUrl;
axios.defaults.timeout = 120000;

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status == 409) {
            showWarning(error.response.data);
        } else {
            showErrors(error.response.data);
        }
        return Promise.reject(error);
    },
);
