<template>
    <el-row>
        <el-col :span="12" v-if="!loading">
            <DocumentsEvents style="width: 95%" :events="events" />
            <table style="width: 95%">
                <tr>
                    <td>Typ av dokument</td>
                    <td>
                        <el-select autoselect class="w-full" size="medium" v-model="labels['documentType']" placeholder="Betalnings typ">
                            <el-option v-for="item in typeOfDocuments" :key="item.value" :label="item.name" :value="item.value >= 0 ? item.name : ''" />
                        </el-select>
                    </td>
                </tr>
                <tr>
                    <td>Klient från Tuoyuan</td>
                    <td>
                        <el-input @focus="onFocusInput($event, '')" placeholder="SupplierName" type="text" size="medium" :value="labels['customerName']" disabled />
                    </td>
                </tr>
                <tr>
                    <td>Klient</td>
                    <td>
                        <ClientsSearch :exclude-archived-clients="true" @on-selected="getClientSelectedValue" :selected="clientSearchOption.selected" size="medium" />
                    </td>
                </tr>
                <tr>
                    <td>Leverantör från Tuoyuan</td>
                    <td>
                        <el-input
                            @focus="onFocusInput($event, '')"
                            placeholder="SupplierName"
                            type="text"
                            size="medium"
                            :value="labels['supplierName'] + ' (' + labels['supplierRegistrationNumber'] + ')'"
                            disabled
                        />
                    </td>
                </tr>
                <tr>
                    <td>Leverantör</td>
                    <td>
                        <SupplierSearch @on-selected="getSupplierSelectedValue" :selected-name="selectedSupplierName" @iconClick="updateSupplier = !updateSupplier" required="true" />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="createSupplier = !createSupplier" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-circle-plus fa-xl" style="margin-top:18px" title="Registera Leverantör" />
                        </span>
                    </td>
                </tr>
                <tr v-if="consumableCreditorInvoices.length > 0">
                    <td>Utnytja kreditfaktura {{ consumeCreditorInvoiceId }}</td>
                    <td>
                        <el-select autoselect class="w-full" size="medium" v-model="consumeCreditorInvoiceId" placeholder="Betalnings typ">
                            <el-option
                                v-for="item in consumableCreditorInvoices"
                                :key="item.creditorInvoiceId"
                                :label="'Faktura nummer: ' + item.invoiceNumber + ', Belopp: ' + item.belopp + ' ' + item.currency"
                                :value="item.creditorInvoiceId"
                            />
                        </el-select>
                    </td>
                </tr>
                <tr v-if="updateSupplier">
                    <td colspan="2">
                        <UpdateSupplier :pre-selected-supplier="labels['supplierSearchOption']" @success="refreshSupplierList" @close="updateSupplier = false" />
                    </td>
                </tr>
                <tr v-if="createSupplier">
                    <td colspan="2">
                        <CreateSupplier
                            @success="refreshSupplierList"
                            @close="createSupplier = false"
                            :org-no="labels['supplierRegistrationNumber']"
                            :name="labels['supplierName']"
                            :email="labels['supplierEmail']"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Betaltyp</td>
                    <td>
                        <el-select autoselect class="w-full" size="medium" v-model="labels['paymentType']" placeholder="Betalnings typ">
                            <el-option v-for="item in paymentType" :key="item.value" :label="item.name" :value="item.value > 0 ? item.value : ''" @change="$validator.validate()" />
                        </el-select>
                    </td>
                </tr>
                <tr v-show="labels.paymentType == 1">
                    <td>Bankgiro</td>
                    <td>
                        <input-text
                            @focus="onFocusInput($event, 'bankGiro')"
                            v-validate="labels.paymentType == 1 ? 'required' : ''"
                            name="bankGiro"
                            :error="errors.first('bankGiro')"
                            placeholder="Bankgiro"
                            type="text"
                            size="medium"
                            v-model="labels['bankGiro']"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.bankGiro)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr v-show="labels.paymentType == 2">
                    <td>Plusgiro</td>
                    <td>
                        <input-text
                            @focus="onFocusInput($event, 'plusGiro')"
                            v-validate="labels.paymentType == 2 ? 'required' : ''"
                            name="plusGiro"
                            :error="errors.first('plusGiro')"
                            placeholder="Plusgiro"
                            type="text"
                            size="medium"
                            v-model="labels['plusGiro']"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.plusGiro)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr v-show="labels.paymentType == 3">
                    <td>Clearingnummer</td>
                    <td>
                        <input-text
                            @focus="onFocusInput($event, 'clearing')"
                            v-validate="labels.paymentType == 3 ? 'required' : ''"
                            name="clearing"
                            :error="errors.first('clearing')"
                            placeholder="Clearingnummer"
                            type="text"
                            size="medium"
                            v-model="labels['clearing']"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.clearing)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr v-show="labels.paymentType == 3">
                    <td>Bankkonto</td>
                    <td>
                        <input-text
                            @focus="onFocusInput($event, 'bankAccountNumber')"
                            v-validate="labels.paymentType == 3 ? 'required' : ''"
                            name="bankAccountNumber"
                            :error="errors.first('bankAccountNumber')"
                            placeholder="Bankkonto"
                            type="text"
                            size="medium"
                            v-model="labels['bankAccountNumber']"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.bankAccountNumber)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr v-show="labels.paymentType == 4">
                    <td>IBAN</td>
                    <td>
                        <input-text
                            @focus="onFocusInput($event, 'iban')"
                            v-validate="labels.paymentType == 4 ? 'required' : ''"
                            name="iban"
                            :error="errors.first('iban')"
                            placeholder="IBAN"
                            type="text"
                            size="medium"
                            v-model="labels['iban']"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.iban)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr v-if="labels.paymentType == 4">
                    <td>BIC</td>
                    <td>
                        <el-input @focus="onFocusInput($event, 'bicSwift')" placeholder="BIC" type="text" size="medium" v-model="labels['bicSwift']" />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.bicSwift)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Fakturadatum</td>
                    <td>
                        <DateInputField
                            size="medium"
                            @focus="event => onFocusInput(event, 'invoiceDate')"
                            placeholder="Fakturadatum"
                            :pre-selected-value="formatDate(labels['invoiceDate'])"
                            @change="labels['invoiceDate'] = $event"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.invoiceDate)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Förfallodatum</td>
                    <td>
                        <DateInputField
                            size="medium"
                            @focus="event => onFocusInput(event, 'dueDate')"
                            placeholder="Förfallodatum"
                            :pre-selected-value="formatDate(labels['dueDate'])"
                            @change="labels['dueDate'] = $event"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.dueDate)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Ocr nummer</td>
                    <td>
                        <input-text
                            @focus="onFocusInput($event, 'ocrNumber')"
                            v-validate="labels['invoiceNumber'] == '' ? 'required' : ''"
                            name="ocrNumber"
                            :error="errors.first('ocrNumber')"
                            placeholder="Ocr nummer"
                            type="text"
                            size="medium"
                            v-model="labels['ocrNumber']"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.ocrNumber)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Fakturanummer</td>
                    <td>
                        <input-text
                            @focus="onFocusInput($event, 'invoiceNumber')"
                            v-validate="labels['ocrNumber'] == '' ? 'required' : ''"
                            name="invoiceNumber"
                            :error="errors.first('invoiceNumber')"
                            placeholder="Fakturanummer"
                            type="text"
                            size="medium"
                            v-model="labels['invoiceNumber']"
                        />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.invoiceNumber)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Leverantör momsnummer</td>
                    <td>
                        <el-input @focus="onFocusInput($event, 'supplierVatNumber')" placeholder="momsnummer" type="text" size="medium" v-model="labels['supplierVatNumber']" />
                    </td>
                    <td>
                        <span class="cursor-pointer" @click="copyToClipboard(labels.supplierVatNumber)" style="color: rgba(65, 87, 112)">
                            <i class="fa-solid fa-paperclip" style="margin-top:12px" title="Kopiera" />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>Valuta</td>
                    <td>
                        <el-select autoselect class="w-full" size="medium" v-model="labels['currency']" placeholder="Betalnings typ" @change="syncCurrency">
                            <el-option v-for="item in currencies" :key="item.value" :label="item.name" :value="item.name" />
                        </el-select>
                    </td>
                </tr>
                <tr v-show="labels['currency'] != 'SEK'">
                    <td>Orginalbelopp</td>
                    <td>
                        <input-calc
                            placeholder="Orginalbelopp"
                            v-model="labels['originalGrossAmount']"
                            :right-text="labels['currency']"
                            v-validate="labels['currency'] != 'SEK' ? 'required' : ''"
                            data-vv-delay="1000"
                            name="originalGrossAmount"
                            :error="errors.first('originalGrossAmount')"
                            @focus="onFocusInput($event, 'originalGrossAmount')"
                            @change="syncCurrency"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Belopp</td>
                    <td>
                        <input-calc
                            placeholder="Belopp"
                            v-model="labels['grossAmount']"
                            right-text="SEK"
                            v-validate="'required'"
                            data-vv-delay="1000"
                            name="Belopp"
                            :error="errors.first('Belopp')"
                            @focus="onFocusInput($event, 'grossAmount')"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Ex Moms</td>
                    <td>
                        <input-calc
                            placeholder="Ex moms"
                            v-model="labels['netAmount']"
                            right-text="SEK"
                            v-validate="'required'"
                            data-vv-delay="1000"
                            name="netAmount"
                            :error="errors.first('netAmount')"
                            @focus="onFocusInput($event, 'netAmount')"
                        />
                    </td>
                </tr>
                <tr v-if="labels['reverseConstruction'] == 0 && labels['supplierCountry'] == 1">
                    <td>Moms</td>
                    <td>
                        <input-calc
                            placeholder="Ex moms"
                            v-model="labels['vatAmount']"
                            right-text="SEK"
                            v-validate="'required'"
                            data-vv-delay="1000"
                            name="vatAmount"
                            :error="errors.first('vatAmount')"
                            @focus="onFocusInput($event, 'vatAmount')"
                        />
                    </td>
                </tr>
                <tr v-else>
                    <td>Omvänd Moms</td>
                    <td>
                        <input-calc
                            placeholder="Ex moms"
                            v-model="labels['vatAmount']"
                            right-text="SEK"
                            v-validate="'required'"
                            data-vv-delay="1000"
                            name="vatAmount2"
                            :error="errors.first('vatAmount2')"
                            @focus="onFocusInput($event, 'vatAmount')"
                        />
                    </td>
                </tr>
                <tr>
                    <td />
                    <td>
                        <check-box v-model="labels['carVat']" @change="applyBilmoms()" label="Bilmoms" />
                    </td>
                </tr>
                <tr>
                    <td>Avrundning</td>
                    <td>
                        <input-calc
                            placeholder="Ex moms"
                            v-model="labels['roundingAmount']"
                            right-text="SEK"
                            v-validate="'required'"
                            data-vv-delay="1000"
                            name="roundingAmount"
                            :error="errors.first('roundingAmount')"
                            @focus="onFocusInput($event, 'roundingAmount')"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Påminnelseavgift</td>
                    <td>
                        <input-calc
                            placeholder="Ex moms"
                            v-model="labels['reminderFee']"
                            right-text="SEK"
                            v-validate="'required'"
                            data-vv-delay="1000"
                            name="reminderFee"
                            :error="errors.first('reminderFee')"
                            @focus="onFocusInput($event, 'reminderFee')"
                        />
                    </td>
                </tr>

                <tr>
                    <td>Inköpsland</td>
                    <td>
                        <RadioButton
                            :entries="supplierCountryEntries"
                            :horizontal="true"
                            @change="(labels['supplierCountry'] = $event.value), syncRadioButtons()"
                            :selected-id="labels['supplierCountry']"
                        />
                    </td>
                </tr>
                <tr v-if="labels['supplierCountry'] == 1">
                    <td>Omvändmoms</td>
                    <td>
                        <RadioButton
                            :entries="reverseConstructionEntries"
                            :horizontal="true"
                            @change="(labels['reverseConstruction'] = $event.value), syncRadioButtons()"
                            :selected-id="labels['reverseConstruction']"
                        />
                    </td>
                </tr>
                <tr v-if="labels['supplierCountry'] != 1">
                    <td>Momsnummer</td>
                    <td>
                        <RadioButton
                            tabindex="-1"
                            :entries="momsNumberEntries"
                            :horizontal="true"
                            @change="(labels['momsNumber'] = $event.value), syncRadioButtons()"
                            :selected-id="labels['momsNumber']"
                        />
                    </td>
                </tr>
                <tr v-if="labels['momsNumber'] == 1 || labels['reverseConstruction'] == 1">
                    <td>Moms</td>
                    <td>
                        <RadioButton :entries="vatRateEntries" :horizontal="true" @change="(labels['vatRate'] = $event.value), syncRadioButtons()" :selected-id="labels['vatRate']" />
                    </td>
                </tr>
                <tr>
                    <td>Periodisera</td>
                    <td>
                        <check-box v-model="periodize" />
                    </td>
                </tr>
                <tr>
                    <td>Konton från ML</td>
                    <td>
                        <p v-for="(bookAccountNum, index) in labels.bookAccountNums" :key="index">
                            {{ bookAccountNum.bookingNumber }}
                        </p>
                    </td>
                </tr>
            </table>

            <div style="width: 90%; margin-top: 19px">
                <VouchersTable
                    @rowsUpdated="rowsUpdated"
                    ref="vTable"
                    :voucher-rows="voucherRows"
                    :form-data="labels"
                    :selected-company-type="selectedCompanyType"
                    :account-plan-items="accountPlanItems"
                    :financial-years="financialYears"
                    :source-date="labels.invoiceDate"
                    :periodize="periodize"
                />
            </div>
            <div v-if="labels['supplierSearchOption']">
                <a class="text-xs" @click="updateAccount = !updateAccount">Ändra standardkonto för leverantören</a>
                <UpdateDefaultAccount
                    v-if="updateAccount"
                    :account-plan-items="accountPlanItems"
                    :pre-selected-ltd-account="isParsed(labels['supplierSearchOption'].limitedDefaultAccount)"
                    :pre-selected-private-account="isParsed(labels['supplierSearchOption'].individualDefaultAccount)"
                    :supplier-id="parseInt(labels['supplierSearchOption'].value)"
                    @success="updateDefaultAccounts"
                    @close="updateAccount = false"
                />
            </div>

            <table style="width: 95%;">
                <tr>
                    <td class="pt-3">Credit note</td>
                    <td class="pt-3"><check-box v-model="labels['creditNote']" /></td>
                </tr>
                <tr>
                    <td>Kommentar</td>
                    <td><el-input type="textarea" placeholder="Kommentar" v-model="labels['comment']" /></td>
                </tr>
            </table>

            <div class="pt-5 mb-5">
                <el-button size="mini" @click="$router.go(-1)">Tillbaka</el-button>
                <el-button size="mini" :loading="delBtnLoading" type="danger" @click="deleteDocument()">Radera</el-button>
                <el-button size="mini" v-if="labels.documentType !== 'Kreditfaktura'" :disabled="cantCreateVoucher" :loading="sendBtnLoading" type="primary" @click="sendForAttest()">
                    Skicka för attest
                </el-button>
                <el-button size="mini" v-if="labels.documentType == 'Kreditfaktura'" :disabled="cantCreateVoucher" :loading="sendBtnLoading" type="primary" @click="createCreditorInvoice()">
                    Skapa kreditfaktura
                </el-button>
                <el-button size="mini" :loading="sendBtnLoading" type="primary" @click="createReceipt()">Skapa kvitto</el-button>
                <el-button size="mini" :loading="sendBtnLoading" type="warning" :disabled="!this.labels.clientId" @click="supplementModal = true">Skicka för komplettering</el-button>
                <el-button size="mini" :loading="sendBtnLoading" type="success" @click="downloadPdf()">Ladda ner pdf</el-button>
            </div>
        </el-col>
        <el-col :span="12" v-if="!loading" style="position: sticky; top: 15px;">
            <div id="canvas-container" style="overflow:auto;height:900px; overflow-x: hidden;">
                <DocumentPage
                    class="scrollbar-item"
                    v-for="(image, index) in mlResult.images"
                    :key="index"
                    :image="image"
                    :ml-result="mlResult"
                    :ocr-result="ocrResult"
                    :index="index"
                    @updateLabel="updateLabel"
                    :focused-label="focusedLabel"
                />
            </div>
        </el-col>
        <el-dialog v-model="supplementModal" :visible="supplementModal" title="Begär komplettering">
            <el-row>
                <el-col :span="16">
                    <el-input resize="none" :rows="11" type="textarea" placeholder="Kommentar" v-model="supplementComment" />
                </el-col>
                <!-- <el-col :span="8">
                    <el-scrollbar :height="200">
                        <div class="flex flex-col space-y-2 items-stretch">
                            <el-tooltip v-for="(commentTemplate, index) in commentTemplates" :key="index" class="box-item" effect="dark" :content="commentTemplate.content" placement="top-start">
                                <el-button class="ml-4" type="primary" @click="comment = commentTemplate.content">{{ commentTemplate.title }}</el-button>
                            </el-tooltip>
                        </div>
                    </el-scrollbar>
                </el-col> -->
            </el-row>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="supplementModal = false">Avbryt</el-button>
                    <el-button type="primary" @click="sendForSupplement" :loading="loadingSupplementButton">
                        Skicka
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </el-row>
</template>

<script>
import Api from "./documents.api";
import { mapState } from "vuex";
import Enum from "./documents.enum.js";
import moment from "moment";

export default {
    data() {
        return {
            comment: "",
            supplementModal: false,
            delBtnLoading: false,
            sendBtnLoading: false,
            voucherRows: [],
            supplierCountryEntries: [
                { value: Enum.supplierCountry.Sweden, name: "Sverige", disabled: false },
                { value: Enum.supplierCountry.Europe, name: "Eu", disabled: false },
                { value: Enum.supplierCountry.Foreign, name: "Utanför Eu", disabled: false },
                { value: Enum.supplierCountry.SwedenForeign, name: "Sverige/Utländskt (Ruta 23)", disabled: false },
            ],
            reverseConstructionEntries: [
                { value: Enum.reverseConstruction.No, name: "Nej", disabled: false },
                { value: Enum.reverseConstruction.Yes, name: "Ja", disabled: false },
            ],
            momsNumberEntries: [
                { value: Enum.momsNumber.Reported, name: "Angivet", disabled: false },
                { value: Enum.momsNumber.NotReported, name: "Ej angivet", disabled: false },
            ],
            vatRateEntries: [
                { value: Enum.vatRate.TwentyFive, name: "25%", disabled: false },
                { value: Enum.vatRate.Twelve, name: "12%", disabled: false },
                { value: Enum.vatRate.Six, name: "6%", disabled: false },
                { value: Enum.vatRate.Zero, name: "0%", disabled: false },
                { value: Enum.vatRate.Mixed, name: "Blandad", disabled: false },
            ],
            vatAmountPreCarVat: 0,
            typeOfDocument: "Leverantörsfaktura",
            defaultAccount: false,
            updateSupplier: false,
            createSupplier: false,
            documentId: 0,
            mlResult: [],
            supplierAccountValues: [],
            clientSearchOption: [],
            loading: true,
            typeOfDocuments: [
                { value: 0, name: "Faktura" },
                { value: 1, name: "Kreditfaktura" },
                { value: 2, name: "Kvitto" },
                { value: 3, name: "Påminnelse" },
                { value: 4, name: "Kontantfaktura" },
                { value: 5, name: "Annan" },
            ],
            paymentType: [
                { value: 1, name: "BankGiro" },
                { value: 2, name: "PlusGiro" },
                { value: 3, name: "BankAccountNumber" },
                { value: 4, name: "Iban" },
            ],
            currencies: [
                { value: 1, name: "SEK" },
                { value: 2, name: "DKK" },
                { value: 3, name: "EUR" },
                { value: 4, name: "USD" },
                { value: 5, name: "GBP" },
                { value: 6, name: "NOK" },
                { value: 7, name: "CHF" },
            ],
            labels: {
                bookAccountNums: [],
                supplierSearchOption: {},
                clientId: "",
                accountNumber: "",
                invoiceNumber: "",
                ocrNumber: "",
                currency: "",
                supplierRegistrationNumber: "",
                supplierVatNumber: "",
                invoiceDate: "",
                dueDate: "",
                supplierName: "",
                customerName: "",
                supplierAddress: "",
                bankGiro: "",
                iban: "",
                bicSwift: "",
                clearing: "",
                plusGiro: "",
                bankAccountNumber: "",
                documentType: "",
                endDate: "",
                feNumber: "",
                paymentTerms: "",
                supplierEmail: "",
                supplierNationality: "",
                yourReference: "",
                comment: "",
                creditorId: null,
                originalGrossAmount: 0,
                originalNetAmount: 0,
                originalVatAmount: 0,
                grossAmount: 0,
                netAmount: 0,
                vatAmount: 0,
                reminderFee: 0,
                roundingAmount: 0,
                paymentType: 1,
                supplierCountry: Enum.supplierCountry.Sweden,
                reverseConstruction: Enum.reverseConstruction.No,
                momsNumber: Enum.momsNumber.NotReported,
                vatRate: Enum.vatRate.TwentyFive,
                creditNote: false,
                carVat: false,
            },
            updateAccount: false,
            invoiceDateWarning: undefined,
            dueDateWarning: undefined,
            customError: [],
            radioValues: undefined,
            isInvoiceSek: true,
            isReported: false,
            reverseVat: undefined,
            selectionMode: false,
            selectedCompanyType: 0,
            ocrResult: [],
            focusedLabel: "",
            focusedElement: null,
            drawColor: "green",
            isNewSupplier: false,
            connection: null,
            selectedCurrency: null,
            isMounted: false,
            cantCreateVoucher: false,
            consumableCreditorInvoices: [],
            consumeCreditorInvoiceId: null,
            pdf: null,
            enterTime: null,
            supplementComment: "",
            events: [],
            loadingSupplementButton: false,
            financialYears: [],
            periodize: false,
        };
    },
    components: {
        "input-calc": () => import(/* webpackChunkName: "BaseInputNumericCalc" */ "@/components/baseInputNumericCalc.vue"),
        "input-text": () => import(/* webpackChunkName: "BaseInput" */ "@/components/baseInput.vue"),
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
        DocumentsEvents: () => import(/* webpackChunkName: "DocumentVouchersTable" */ "./DocumentsEvents.vue"),
        VouchersTable: () => import(/* webpackChunkName: "DocumentVouchersTable" */ "./DocumentsVoucherTable.vue"),
        SupplierSearch: () => import(/* webpackChunkName: "SupplierSearch" */ "./supplierSearch"),
        "check-box": () => import(/* webpackChunkName: "CheckBox" */ "@/components/content/CheckBox"),
        RadioButton: () => import(/* webpackChunkName: "RadioButtonList" */ "@/components/radioButtonList.vue"),
        CreateSupplier: () => import(/* webpackChunkName: "CreateSupplier" */ "./createSupplier.vue"),
        UpdateSupplier: () => import(/* webpackChunkName: "UpdateSupplier" */ "./updateSupplier.vue"),
        DocumentPage: () => import(/* webpackChunkName: "DocumentPage" */ "./DocumentPage.vue"),
        UpdateDefaultAccount: () => import(/* webpackChunkName: "UpdateDefaultAccount" */ "./updateDefaultAccount.vue"),
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
    },
    beforeRouteLeave: async function(to, from, next) {
        await this.connection.invoke("unlock", this.$route.params.documentId, 4);
        //     this.connection.stop();
        next();
    },
    watch: {
        $route() {
            this.init();
        },
    },
    created() {
        window.addEventListener("beforeunload", this.leaving);
        this.init();
    },
    methods: {
        async initSignalR() {
            const url = process.env.VUE_APP_SIGR_URL + "lockinghub";
            this.isMounted = true;
            const signalR = require("@microsoft/signalr");
            var connection = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Error)
                .withUrl(url)
                .build();
            await connection
                .start()
                .then(() => {
                    console.log("Connected!");
                })
                .catch(function(err) {
                    return console.error(err.toString());
                });
            await connection.invoke("lock", this.$route.params.documentId, this.userInfo.userName, 4);
            this.connection = connection;
        },
        async init() {
            Object.assign(this.$data, this.$options.data.call(this));
            await this.initSignalR();

            this.loading = true;
            this.documentId = this.$route.params.documentId;
            this.mlResult = await Api.getDocumentById(this.documentId);
            this.events = await Api.getEvents(this.documentId);
            this.pdf = this.mlResult.pdf;
            this.ocrResult = this.mlResult.ocrResult;
            this.enterTime = moment.utc().format("YYYY-MM-DDTHH:mm:ss");
            this.loading = false;
            this.labels["supplierSearchOption"] = {};
            this.connectLabelTexts();
            this.labels["originalGrossAmount"] = this.labels["grossAmount"];
            this.labels["originalNetAmount"] = this.labels["netAmount"];
            this.labels["originalVatAmount"] = this.labels["vatAmount"];
            const paymentType = this.paymentType.find(p => p.name == this.labels["paymentType"]);
            if (paymentType) this.labels["paymentType"] = paymentType.value;

            var clientName = this.mlResult.labels["customerName"] ? this.mlResult.labels["customerName"].text : "";
            var invoiceNumber = this.mlResult.labels["invoiceNumber"] ? this.mlResult.labels["invoiceNumber"].text : "";
            var ocrNumber = this.mlResult.labels["ocrNumber"] ? this.mlResult.labels["ocrNumber"].text : "";
            var client;

            if (this.mlResult.clientId) {
                client = this.clientsInfo.find(c => c.value == this.mlResult.clientId);
                this.getClientSelectedValue(client);
            } else if (clientName) {
                client = this.clientsInfo.find(c => c.name.toLowerCase() == clientName.toLowerCase());
                if (client) {
                    this.getClientSelectedValue(client);
                }
            }
            if (client != undefined) {
                const duplicateText = await Api.checkForDuplicates(client.value, invoiceNumber, ocrNumber);
                if (duplicateText) await this.$dialog.html().alert(duplicateText);
            }
            var suppliers = await Api.getSuppliersList("");
            if (this.mlResult.labels["supplierName"]) {
                var supplier = suppliers.find(s => s.originalName.toLowerCase() == this.mlResult.labels["supplierName"].text.toLowerCase());
                if (supplier) {
                    await this.getSupplierSelectedValue(supplier);
                }
            }
            this.currenciesList = await Api.getCurrenciesAsync();
            this.syncCurrency();
        },
        async sendForSupplement() {
            this.loadingSupplementButton = true;
            var response = await Api.SendForSupplement(this.documentId, this.labels.clientId, "supplierInvoice", this.supplementComment);
            if (response.status == 200) {
                this.loadingSupplementButton = false;
                this.$notify.success({ title: "Komplettering förfrågan skickad" });
                await this.removeDocumentLock();
                this.goToNextDocument();
            }
            this.supplementModal = false;
        },
        async goToNextDocument() {
            var nextDocument = await Api.GetNextDocument(this.documentId);
            if (nextDocument != -1) {
                this.$router.replace("/document/" + nextDocument);
                this.$forceUpdate();
            } else {
                this.$notify.info({ title: "Detta är det sista dokumentet" });
                this.$router.push("/documents?pageNumber=1&pageSize=15");
            }
        },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);
            this.$toasted.show(text + " sparat till urklipp", {
                theme: "bubble",
                duration: 4000,
                position: "bottom-center",
                type: "success",
                keepOnHover: false,
            });
        },
        downloadPdf() {
            const linkSource = `data:application/pdf;base64,${this.pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = this.mlResult.labels["customerName"].text + " : " + this.labels.invoiceNumber + ".pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        rowsUpdated(rows) {
            const debitSummary = rows.reduce((total, amount) => {
                const result = total + amount.debit;
                return result.roundToEven2();
            }, 0);
            const creditSummary = rows.reduce((total, amount) => {
                const result = total + amount.credit;
                return result.roundToEven2();
            }, 0);

            this.cantCreateVoucher = debitSummary === 0 || creditSummary === 0 || debitSummary !== creditSummary;
        },
        async deleteDocument() {
            const confirmed = await this.$dialog
                .title("Radera dokument")
                .html()
                .confirm("Vill du radera dokumentet?");
            if (confirmed) {
                this.delBtnLoading = true;
                await Api.deleteDocument(this.documentId);
                await this.removeDocumentLock();
                this.delBtnLoading = false;
                this.$notify.success({ title: "Dokument raderad" });
                this.goToNextDocument();
            }
        },
        formatDate(date) {
            return date
                ? moment
                      .utc(date)
                      .local()
                      .format("YYYY-MM-DD")
                : "";
        },
        async removeDocumentLock() {
            await this.connection.invoke("remove", this.$route.params.documentId, 4);
        },
        async createCreditorInvoice() {
            const duplicateText = await Api.checkForDuplicates(this.labels.clientId, this.labels.invoiceNumber, this.labels.ocrNumber);
            var confirmed = true;

            if (duplicateText) {
                confirmed = await this.$dialog
                    .title("Dubblet")
                    .html()
                    .confirm(duplicateText);
            }

            if (confirmed) {
                const confirmCredit = await this.$dialog
                    .title("Kreditfaktura")
                    .html()
                    .confirm("Är du säker på att du vill skapa kreditfakturan?");

                if (!confirmCredit) return;

                this.sendBtnLoading = true;
                const booking = this.$refs.vTable.GetVoucherRowsFiltered();
                const response = await Api.createCreditorInvoice(this.documentId, this.labels, booking);
                this.sendBtnLoading = false;
                if (response.status == 200) {
                    await Api.RegisterBookingTime(this.documentId, this.enterTime, "supplierInvoiceCreditNote", "booked");
                    this.$notify.success({ title: "Kreditfaktura skapad" });
                    this.$router.go(-1);
                }
            }
        },
        validateForAttest() {
            this.$validator.validateAll();
            if (this.labels.paymentType == 1 && this.labels.bankGiro == "") {
                this.$notify.error({ title: "Bankgiro måste anges" });
                this.sendBtnLoading = false;
                return false;
            } else if (this.labels.paymentType == 2 && this.labels.plusGiro == "") {
                this.$notify.error({ title: "Plusgiro måste anges" });
                this.sendBtnLoading = false;
                return false;
            } else if (this.labels.paymentType == 3 && (this.labels.clearing == "" || this.labels.bankAccountNumber == "")) {
                this.$notify.error({ title: "Clearing & bankkontonummer måste anges" });
                this.sendBtnLoading = false;
                return false;
            } else if (this.labels.paymentType == 4 && this.labels.iban == "") {
                this.$notify.error({ title: "Iban-nummer måste anges" });
                this.sendBtnLoading = false;
                return false;
            } else if (this.labels.invoiceNumber == "" && this.labels.ocrNumber == "") {
                this.$notify.error({ title: "Fakturanummer eller ocr måste anges" });
                this.sendBtnLoading = false;
                return false;
            }
            return true;
        },

        async sendForAttest() {
            if (this.validateForAttest() == false) return;
            const duplicateText = await Api.checkForDuplicates(this.labels.clientId, this.labels.invoiceNumber, this.labels.ocrNumber);
            var confirmed = true;

            if (duplicateText) {
                confirmed = await this.$dialog
                    .title("Dubblet")
                    .html()
                    .confirm(duplicateText);
            }

            if (confirmed) {
                this.sendBtnLoading = true;
                const booking = this.$refs.vTable.GetVoucherRowsFiltered();

                if (this.labels.bankGiro == "" && this.labels.plusGiro == "" && this.labels.bankAccountNumber == "" && this.labels.iban == "") {
                    this.$notify.error({ title: "Bankgiro, Plusgiro eller Bankkontonummer måste anges" });
                    this.sendBtnLoading = false;
                    return;
                }

                if (this.labels.grossAmount >= 250000) {
                    const aml = await this.$dialog
                        .title("AML")
                        .html()
                        .confirm("Leverantörsfakturan kommer skickas för godkännande");

                    if (!aml) return;
                }

                const response = await Api.sendForAttest(this.documentId, this.labels, booking);

                if (response.status == 200) {
                    if (this.consumeCreditorInvoiceId != null) {
                        await Api.ConsumeCreditedInvoice(response.data.creditorInvoiceId, this.consumeCreditorInvoiceId);
                    }
                    await Api.RegisterBookingTime(this.documentId, this.enterTime, "supplierInvoice", "booked");
                    this.$notify.success({ title: "Leverantörsfaktura skickad för attest" });
                    await this.removeDocumentLock();
                    this.goToNextDocument();
                }
                this.sendBtnLoading = false;
            }
        },
        async createReceipt() {
            const confirmReceipt = await this.$dialog
                .title("Kvitto")
                .html()
                .confirm("Är du säker på att du vill skapa kvittot?");

            if (!confirmReceipt) return;

            this.sendBtnLoading = true;
            const booking = this.$refs.vTable.GetVoucherRowsFiltered();
            const response = await Api.CreateReceipt(this.documentId, this.labels, booking);
            this.sendBtnLoading = false;
            if (response.status == 200) {
                this.$notify.success({ title: "Kvitto skapat" });
                await this.removeDocumentLock();
                this.$router.go(-1);
            }
        },
        syncCurrency() {
            this.getCurrencies(this.labels["currency"]);
            if (this.labels["currency"] != "SEK") {
                this.labels["grossAmount"] = this.convertToSek(this.labels["originalGrossAmount"]);
                this.labels["netAmount"] = this.convertToSek(this.labels["originalNetAmount"]);
                this.labels["vatAmount"] = this.convertToSek(this.labels["originalVatAmount"]);
            } else {
                this.labels["grossAmount"] = this.labels["originalGrossAmount"];
                this.labels["netAmount"] = this.labels["originalNetAmount"];
                this.labels["vatAmount"] = this.labels["originalVatAmount"];
            }
        },
        getCurrencies(currencyValue) {
            if (currencyValue != "SEK") {
                if (this.currenciesList) {
                    this.selectedCurrency = this.currenciesList.filter(c => c.value.toUpperCase() === currencyValue.toUpperCase())[0];
                }
            }
        },
        convertToSek(amount) {
            if (this.selectedCurrency && this.labels["currency"] != "SEK") {
                var tempAmount = amount * parseFloat(this.selectedCurrency.amount) * 100;
                tempAmount = tempAmount / 100;
                return parseFloat(tempAmount).toFixed(2);
            }
            return parseFloat(amount).toFixed(2);
        },
        isParsed(value) {
            let parsed = parseInt(value);
            if (isNaN(parsed)) return 0;
            return parsed;
        },
        applyBilmoms() {
            if (this.labels["carVat"]) {
                this.vatAmountPreCarVat = Number(this.labels["vatAmount"]);
                this.labels["vatAmount"] = (this.labels["vatAmount"] / 2).toFixed(2);
            } else {
                this.labels["vatAmount"] = this.vatAmountPreCarVat;
            }
            this.labels["netAmount"] = (this.labels["grossAmount"] - this.labels["vatAmount"]).toFixed(2);
        },
        updateDefaultAccounts(value) {
            this.labels["supplierSearchOption"].individualDefaultAccount = value.privateDefaultAccount;
            this.labels["supplierSearchOption"].limitedDefaultAccount = value.ltdDefaultAcc;
            this.updateAccount = false;
        },
        updateLabel(newValue) {
            this.labels[this.focusedLabel] = newValue;
            this.focusedElement.focus();
        },
        async leaving(event) {
            await this.connection.invoke("unlock", this.$route.params.documentId, 4);
            this.connection.stop();
            event.preventDefault();
            event.returnValue = "";
        },
        async refreshSupplierList(value) {
            if (this.createSupplier) {
                this.isNewSupplier = true;
            }
            this.createSupplier = false;
            this.updateSupplier = false;
            await this.getSuppliers(value.value, value.name);
        },
        async getSuppliers(supplierValue = "", supplierName = "") {
            var suppliers = {
                Name: supplierName || this.labels["supplierName"],
                OrgNo: this.labels["supplierRegistrationNumber"],
                Email: this.labels["supplierEmail"],
                Country: this.labels["supplierNationality"],
                Value: supplierValue,
            };
            this.labels["supplierSearchOption"] = await Api.getSuppliers(suppliers);
            this.getSupplierSelectedValue(this.labels["supplierSearchOption"].selected);
            this.getDefaultAccount();
        },
        syncRadioButtons() {
            if (this.labels["supplierCountry"] == Enum.supplierCountry.Sweden) this.labels["momsNumber"] = Enum.momsNumber.NotReported;
            if (this.labels["supplierCountry"] != Enum.supplierCountry.Sweden) this.labels["reverseConstruction"] = Enum.reverseConstruction.No;

            if (this.labels["momsNumber"] == Enum.momsNumber.Reported || this.labels["reverseConstruction"] == Enum.reverseConstruction.Yes) {
                if (this.labels["vatRate"] == Enum.vatRate.TwentyFive) this.labels["vatAmount"] = this.labels["grossAmount"] * 0.25;
                else if (this.labels["vatRate"] == Enum.vatRate.Twelve) this.labels["vatAmount"] = this.labels["grossAmount"] * 0.12;
                else if (this.labels["vatRate"] == Enum.vatRate.Six) this.labels["vatAmount"] = this.labels["grossAmount"] * 0.06;
                else if (this.labels["vatRate"] == Enum.vatRate.Zero) this.labels["vatAmount"] = 0;

                this.labels["netAmount"] = this.labels["grossAmount"];
            }
        },
        async getClientSelectedValue(value) {
            if (value === "" || value === undefined) {
                // this.addError("Klient saknas");
                this.clientSearchOption.selected = {};
            } else {
                // this.removeError("Klient saknas");
                this.clientSearchOption.selected = value;
                this.labels["clientId"] = this.clientSearchOption.selected.value;
                if (this.labels.creditorId) {
                    this.consumableCreditorInvoices = await Api.GetConsumableCreditorInvoices(this.labels.clientId, this.labels.creditorId, this.labels.grossAmount);
                }

                const duplicateText = await Api.checkForDuplicates(this.labels.clientId, this.labels.invoiceNumber, this.labels.ocrNumber);
                if (duplicateText) await this.$dialog.html().alert(duplicateText);
                this.financialYears = await Api.GetOpenFinancialYears(this.labels["clientId"]);
                if (value.companyType == Enum.companyType.EnskildFirma) {
                    this.selectedCompanyType = Enum.AccountCompanyType.EnskildFirma;
                } else if (value.companyType == Enum.companyType.Aktiebolag) {
                    this.selectedCompanyType = Enum.AccountCompanyType.Aktiebolag;
                } else if (value.companyType == Enum.companyType.Handelsbolag) {
                    this.selectedCompanyType = Enum.AccountCompanyType.Handelsbolag;
                }
                this.getDefaultAccount();
            }
        },
        async getSupplierSelectedValue(value) {
            this.updateSupplier = false;
            this.defaultAccount = false;
            if (value === "" || value === null || value === undefined) {
                this.labels["supplierSearchOption"] = {};
                //this.addError("Leverantör saknas");
            } else {
                //this.removeError("Leverantör saknas");
                this.labels["supplierSearchOption"] = value;
                this.supplierAccountValues = [];
                this.labels["creditorId"] = Number(this.labels["supplierSearchOption"].value);
                this.getDefaultAccount();
                this.consumableCreditorInvoices = await Api.GetConsumableCreditorInvoices(this.labels.clientId, this.labels.creditorId, this.labels.grossAmount);
            }
        },
        async getDefaultAccount() {
            if (this.labels["supplierSearchOption"]) {
                if (this.clientSearchOption.selected && (this.clientSearchOption.selected.value != null || this.clientSearchOption.selected.value != undefined)) {
                    if (this.selectedCompanyType == Enum.AccountCompanyType.EnskildFirma || this.selectedCompanyType == Enum.AccountCompanyType.Handelsbolag) {
                        if (this.labels["supplierSearchOption"].individualDefaultAccount) {
                            this.defaultAccount = this.labels["supplierSearchOption"].individualDefaultAccount;
                        } else {
                            this.findDefaultAccount();
                        }
                    } else if (this.selectedCompanyType == Enum.AccountCompanyType.Aktiebolag) {
                        if (this.labels["supplierSearchOption"].limitedDefaultAccount) {
                            this.defaultAccount = this.labels["supplierSearchOption"].limitedDefaultAccount;
                        } else {
                            this.findDefaultAccount();
                        }
                    }
                }
            }
        },
        async findDefaultAccount() {
            var searchAccountObj = {
                supplierId: this.labels["supplierSearchOption"].value,
                clientId: this.clientSearchOption.selected.value,
                companyType: this.selectedCompanyType,
            };
            var data = await Api.postSupplierAccount(searchAccountObj);
            this.defaultAccount = data;
        },
        onFocusInput(event, label) {
            this.focusedLabel = label;
            this.focusedElement = event.target;
            this.drawColor = Enum.labelColors[label];
        },
        connectLabelTexts() {
            Object.keys(this.mlResult.labels).forEach(label => {
                if (this.mlResult.labels[label]) {
                    this.labels[label] = this.mlResult.labels[label].text;
                }
            });
            if (this.mlResult.labels.bookAccountNums) this.labels.bookAccountNums = this.mlResult.labels.bookAccountNums;
        },
    },
    computed: {
        ...mapState({
            clientsInfo: state => state.clientList,
            accountsInfo: state => state.accountPlans,
            userInfo: state => state.login,
        }),

        accountPlanItems() {
            if (this.selectedCompanyType == 2) return this.stockCompanyAccountPlanItems;
            else return this.privateFirmAccountPlanItems;
        },

        stockCompanyAccountPlanItems() {
            var stockAccountPlans = this.accountsInfo.filter(a => a.companyType == 2)[0].accounts;
            return stockAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    assetAccount: item.assetAccount,
                    debtAccount: item.debtAccount,
                };
            });
        },

        privateFirmAccountPlanItems() {
            var privateFirmAccountPlans = this.accountsInfo.filter(a => a.companyType == 1)[0].accounts;
            return privateFirmAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    assetAccount: item.assetAccount,
                    debtAccount: item.debtAccount,
                };
            });
        },

        selectedSupplierName() {
            return this.labels["supplierSearchOption"] != undefined ? this.labels["supplierSearchOption"].name : "";
        },
    },
};
</script>

<style scoped>
table tr td {
    padding: 4px;
    font-size: 14px;
}
table td {
    border: 0px !important;
}
tr td:first-child {
    width: 124px;
}

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}
</style>
