<script>
export default {
    props: {
        space: {
            type: [String, Array, Number],
            default() {
                return ["small", "medium"];
            },
        },
        collapseBelow: { type: String, default: "laptop" },
        align: { type: String, default: "left" },
        alignY: { type: String, default: "bottom" },
    },
    created() {
        this.spaces = [
            { name: "xxsmall", value: 4 },
            { name: "xsmall", value: 8 },
            { name: "small", value: 12 },
            { name: "medium", value: 20 },
            { name: "gutter", value: 24 },
            { name: "large", value: 32 },
            { name: "xlarge", value: 48 },
            { name: "xxlarge", value: 96 },
        ];
        this.alignsY = [
            { name: "top", value: "flex-start" },
            { name: "center", value: "center" },
            { name: "bottom", value: "flex-end" },
        ];
        this.aligns = [
            { name: "left", value: "flex-start" },
            { name: "center", value: "center" },
            { name: "right", value: "flex-end" },
        ];
    },
    render(h) {
        let space = this.getSpace(this.space, this.$mq);
        // const align = this.aligns.find(item => item.name == this.align).value;
        let alignY = this.alignsY.find(item => item.name == this.alignY).value;
        // console.log("align=" + align, "alignY=" + alignY);
        let spacesTop = 0;
        let spacesLeft = 0;
        let flexDirection = "row";
        spacesLeft = space;

        const alignStyle = {
            flexDirection: flexDirection,
            alignItems: alignY,
        };

        const slots = this.$slots.default;
        let x = 1;
        let children =
            slots != undefined
                ? slots.map(child => {
                      if (child.tag != undefined) {
                          if (x++ > 1) {
                              if (!child.data) child.data = {};
                              if (!child.data.style) child.data.style = {};
                              child.data.style = {
                                  ...child.data.style,
                                  marginLeft: spacesLeft + "px",
                                  marginTop: spacesTop + "px",
                              };
                          }
                      }
                      return child;
                  })
                : "";

        const wrapper = h("div", { class: "columns-wrapper-inner", style: alignStyle }, children);

        let vdom = h("div", { class: "columns-wrapper" }, [wrapper]);
        return vdom;
    },
    methods: {
        getPixels(value) {
            if (isNaN(value)) {
                const result = this.spaces.find(item => item.name === value);
                return result == undefined ? 0 : result.value;
            } else {
                return Number(value);
            }
        },
        getSpace(value, mq) {
            let result;
            if (!Array.isArray(value)) {
                const pix = this.getPixels(value);
                result = pix;
            } else {
                if (value.length === 1) {
                    const pix = this.getPixels(value[0]);
                    result = pix;
                } else if (value.length === 2) {
                    if (mq === "mobile" || mq === "tablet") {
                        result = this.getPixels(value[0]);
                    } else {
                        result = this.getPixels(value[1]);
                    }
                } else if (value.length === 4) {
                    if (mq === "mobile") {
                        result = this.getPixels(value[0]);
                    } else if (mq === "tablet") {
                        result = this.getPixels(value[1]);
                    } else if (mq === "laptop") {
                        result = this.getPixels(value[2]);
                    } else {
                        result = this.getPixels(value[3]);
                    }
                }
            }
            return result;
        },
    },
};
</script>

<style lang="postcss" scoped>
.columns-wrapper {
    flex: 1 0;
    width: 100%;
}
.mobile,
.tablet {
    .columns-wrapper-inner > *:empty {
        margin-top: 0 !important;
    }
}
.columns-wrapper-inner {
    display: flex;
}
</style>
