<template>
    <object :data="url" type="application/pdf" :width="width" :height="height">
        <p>
            pdf not supported:
            <a :href="url" download>"Download pdf"</a>
        </p>
    </object>
</template>

<script>
export default {
    props: ["file", "height", "width"],

    data: function() {
        return {
            url: undefined,
        };
    },
    watch: {
        file: {
            handler() {
                const cleanFile = this.file.replace(/\s/g, "");
                const binary = atob(cleanFile);
                const length = binary.length;
                const view = new Uint8Array(length);
                for (var i = 0; i < length; i++) {
                    view[i] = binary.charCodeAt(i);
                }
                const blob = new Blob([view], { type: "application/pdf" });
                this.url = window.URL.createObjectURL(blob);
            },
            immediate: true,
        },
    },
};
</script>
