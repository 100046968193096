import Conciliation from "./conciliationPage.vue";

const routes = [
    {
        path: "/conciliation",
        name: "conciliation",
        component: Conciliation,
        meta: {
            title: "Bank",
            forAuth: true,
        },
    },
];
export default routes;
