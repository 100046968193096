<template>
    <div class="card-tail card-f-h">
        <Stack>
            <!-- <SearchClient class="is-half" placeholder="Sök klient..." :options="clients" @on-selected="onClientChange" style="width: 300px" /> -->
            <ClientsSearch @on-selected="onClientChange" />
            <Columns>
                <InputDate label="Startdatum" v-model="dates.startDate" />
                <InputDate label="Slutdatum" v-model="dates.endDate" />
            </Columns>
            <CheckBox label="Visa endast restbelopp" v-model="showOnlyReceivable" />
            <CheckBox label="Visa endast vår kundreskontra" v-model="showOnlyOurs" />
            <CheckBox label="Visa kreditfakturor separat" v-model="showCreditNotes" />
            <button class="button is-success" @click.prevent="update">
                <span class="icon">
                    <i class="fa fa-sync" />
                </span>
                <span>Uppdatera</span>
            </button>

            <List :items="invoices" :scroll="true" :search="false" :headers="headers" @click="clickRow" style="font-size: 13px;height: 500px;" />
        </Stack>
    </div>
</template>
<script>
import List from "./customerAccountsLedgerList.vue";
import Api from "./customerAccountsLedger.api.js";
// import SearchClient from "../../components/searchClient.vue";
import Stack from "../../components/layout/Stack.vue";
import Columns from "../../components/layout/Columns.vue";
import CheckBox from "../../components/content/CheckBox.vue";
import InputDate from "@/components/content/InputDate.vue";

export default {
    components: {
        List,
        // SearchClient,
        Stack,
        Columns,
        CheckBox,
        InputDate,
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
    },

    data: function() {
        return {
            invoices: [],
            clients: [],
            clientId: undefined,
            state: "",
            showOnlyReceivable: true,
            showCreditNotes: false,
            showOnlyOurs: true,
            dates: [],
            headers: [
                { name: "Rader", prop: "count", width: "50px" },
                { name: "Typ", prop: "type", width: "150px" },
                { name: "Fakturanummer", prop: "invoiceNumber", width: "150px" },
                { name: "Kund", prop: "customer" },
                { name: "", prop: "voucherText" },
                { name: "Fakturadatum", prop: "date", align: "right", width: "150px" },
                { name: "Fakturabelopp", prop: "amount", align: "right" },
                { name: "Fordran", prop: "rest", align: "right" },
            ],
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Kundreskontra");
        document.title = "Kundreskontra";
    },

    async created() {
        this.clients = await Api.getClients();
        //    this.invoices = await Api.getInvoices("74001ee9-ba6b-47bc-a5c7-be3ec4ff6ac8");
    },

    methods: {
        clickRow(row) {
            let newValue = true;
            if (this.invoices[row].open == undefined) {
                this.$set(this.invoices[row], "open", newValue);
            } else {
                newValue = !this.invoices[row].open;
                this.$set(this.invoices[row], "open", newValue);
            }
        },

        async onClientChange(client) {
            document.title = "Kundreskontra - " + client.name.split(", ")[1];
            this.clientId = client.value;
            // await this.update();
        },

        async update() {
            this.invoices = [];
            this.invoices = await Api.getInvoices(this.clientId, {
                showOnlyReceivable: this.showOnlyReceivable,
                showCreditNotes: this.showCreditNotes,
                showOnlyOurs: this.showOnlyOurs,
                startDate: this.dates.startDate,
                endDate: this.dates.endDate,
            });
        },

        async createNewLedger() {
            this.state = "loading";
            await Api.updateLedger("74001ee9-ba6b-47bc-a5c7-be3ec4ff6ac8");
            this.invoices = await Api.getInvoices("74001ee9-ba6b-47bc-a5c7-be3ec4ff6ac8");
            this.state = "";
        },
    },
};
</script>
