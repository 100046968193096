<template>
    <main class="card">
        <form class="card-content" style="width: 400px;height: 300px;">
            <p class="title">
                Bolageriet AB
            </p>
            <div class="field">
                <label class="label">Ange epostadress</label>
                <div class="control">
                    <input
                        aria-label="Ange e-postadress"
                        v-model="emailLocal"
                        name="username"
                        class="input"
                        type="text"
                        ref="input"
                        placeholder="epostadress"
                        autocapitalize="off"
                        autocorrect="off"
                        spellcheck="false"
                        @keydown.enter.prevent="next"
                    />
                </div>
                <p class="help" />
            </div>
            <div class="field">
                <p class="control">
                    <button class="button is-success" @click.prevent="next">
                        Fortsätt
                    </button>
                </p>
            </div>
        </form>
    </main>
</template>

<script>
export default {
    props: ["email"],
    data: function() {
        return {
            emailLocal: this.email,
        };
    },
    mounted: function() {
        this.$refs.input.focus();
    },
    methods: {
        next: function() {
            if (this.emailLocal !== null && this.emailLocal !== "") {
                this.$emit("on-next", this.emailLocal);
            }
        },
    },
};
</script>
