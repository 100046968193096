import Enum from "../supplierInvoice/supplierInvoice.enum";
export default {
    namespaced: true,
    state: {
        files: [],
        currentTab: Enum.supplierInvoiceTabList.Upload,
        accountsInfo: undefined,
        saveBtnLoading: false,
        denyBtnLoading: false,
        sendBtnLoading: false,
        receiptBtnLoading: false,
        deleteBtnLoading: false,
    },
    mutations: {
        clearDeleteBtnLoading(state) {
            state.deleteBtnLoading = false;
        },
        clearReceiptBtnLoading(state) {
            state.receiptBtnLoading = false;
        },
        clearSaveBtnLoading(state) {
            state.saveBtnLoading = false;
        },
        clearDenyBtnLoading(state) {
            state.denyBtnLoading = false;
        },
        clearSendBtnLoading(state) {
            state.sendBtnLoading = false;
        },
        setReceiptBtnLoading(state, value) {
            state.receiptBtnLoading = value;
        },
        setDeleteBtnLoading(state, value) {
            state.deleteBtnLoading = value;
        },
        setSaveBtnLoading(state, value) {
            state.saveBtnLoading = value;
        },
        setDenyBtnLoading(state, value) {
            state.denyBtnLoading = value;
        },
        setSendBtnLoading(state, value) {
            state.sendBtnLoading = value;
        },
        setAccountsInfo(state, value) {
            state.accountsInfo = value;
        },
        setCurrentTab(state, value) {
            state.currentTab = value;
        },
        clearCurrentTab(state, value) {
            state.currentTab = value;
        },
        addFiles(state, value) {
            state.files.push(value);
        },
        removeFiles(state, value) {
            state.files.splice(value, 1);
        },
        resetFiles(state) {
            state.files = [];
        },
    },
    actions: {},
    getters: {},
};
