import Loggar from "./Loggar.vue";
const routes = [
    {
        path: "/loggar",
        name: "loggar",
        component: Loggar,
        props: route => ({ query: route.query }),
        meta: {
            title: "Loggar",
            forAuth: true,
        },
    },
];
export default routes;
