import Vue from "vue";
require("./../prototypes.js");

Vue.filter("swedishNumberFormat", (value, skipDecimals = false) => {
    if (value === undefined) {
        return "00,00";
    } else if (value == 0) {
        return "0,00";
    } else if (value == "") {
        return "";
    } else if (value === null) {
        return "";
    }
    value = parseFloat(value.toString(), 10);
    value = value.roundToEven();
    value = value.toString();
    return prettifyNumber(value, skipDecimals);
});

const prettifyNumber = (value, skipDecimals) => {
    value = value.toString().replace(".", ",");
    value = addCommas(value);

    if (value.indexOf(",") > -1) {
        value = value.substring(value.toString().indexOf(",") + 1).length >= 2 ? value : `${value}0`;
    } else {
        value = `${value},00`;
    }

    if (skipDecimals) {
        return value.substring(0, value.length - 3);
    } else {
        return value;
    }
};

const addCommas = nStr => {
    nStr += "";
    let x = nStr.split(",");
    let x1 = x[0];
    let x2 = x.length > 1 ? "," + x[1] : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + " " + "$2");
    }
    return x1 + x2;
};
