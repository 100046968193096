const routes = [
    {
        path: "/invoices",
        name: "invoices",
        component: () => import(/* webpackChunkName: "InvoicesView" */ "./Invoices.vue"),
        meta: {
            title: "Fakturor",
            forAuth: true,
        },
    },
    {
        path: "/invoices/:invoiceId",
        name: "invoice_details",
        component: () => import(/* webpackChunkName: "InvoiceDetailsView" */ "./InvoiceDetails.vue"),
        meta: {
            forAuth: true,
        },
    },
];

export default routes;
