import KycByClientList from "./kycByClientList.vue";
import OwnerInformation from "./ownerInformation.vue";
import KycForm from "./kycByClientForm.vue";
import Operations from "./operations.vue";
import KycByClientNew from "./kycByClientNew/kycByClientNew.vue";

const routes = [
    {
        path: "/kycByClientList",
        name: "kycByClientList",
        component: KycByClientList,
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/kycByClientNew",
        name: "kycByClientNew",
        component: KycByClientNew,
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/ownerInformation",
        name: "ownerInformation",
        component: OwnerInformation,
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/kycForm",
        name: "kycForm",
        component: KycForm,
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/operations",
        name: "operations",
        component: Operations,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
