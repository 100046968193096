import Vue from "vue";

export default {
    getClients: async () => {
        const response = await Vue.axios.get(`closingBooks`);
        return response.data;
    },
    getAnswers: async id => {
        const response = await Vue.axios.get(`closingBooks/${id}`);
        return response.data;
    },
    createClosingBook: async data => {
        const response = await Vue.axios.post(`closingBooks`, data);
        return response.data;
    },
    deleteClosingBook: async data => {
        const response = await Vue.axios.delete(`closingBooks`, { data });
        return response.data;
    },
    updateStatusClosingBook: async (status, data) => {
        const response = await Vue.axios.put(`closingBooks/status/${status}`, data);
        return response.data;
    },
    updateClosingBook: async data => {
        const response = await Vue.axios.put(`closingBooks/${data.questionnaireClosingBookId}`, data);
        return response.data;
    },
    showRegistrationCertificate(id) {
        var token = localStorage.getItem("token");
        var headers = "?token=" + token;
        var base = Vue.axios.defaults.baseURL;
        var url = `/closingBooks/registrationCertificate/${id}`;
        window.open(base + url + headers, `_blank`, `width=800, height=800`);
    },
    showBankStatement(id) {
        var token = localStorage.getItem("token");
        var headers = "?token=" + token;
        var base = Vue.axios.defaults.baseURL;
        var url = `/closingBooks/bankStatement/${id}`;
        window.open(base + url + headers, `_blank`, `width=800, height=800`);
    },
};
