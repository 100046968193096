import Vue from "vue";
export default {
    sendEmail: async sendEmailDto => {
        const response = await Vue.axios.post(`email/sendEmail`, sendEmailDto);
        return response.data;
    },
    getClientList: async () => {
        const response = await Vue.axios.get(`email/clientList`);
        return response.data;
    },
    getClients: async () => {
        const response = await Vue.axios.get(`bankstatements/getClients`);
        return response.data;
    },
    getClientEmail: async clientId => {
        const reposnse = await Vue.axios.get(`email/${clientId}`);
        return reposnse.data;
    },
};
