const Enum = {
    companyTypes:{
        PrivateFirm: "EnskildFirma",
        StockCompany: "Aktiebolag",
    },
    accounts: {
        PrivateExpense: 2820,
        CompanyAccount: 1930,
        CompanyCreditCard: 2419,
        IncommingVat: 2641,
        EuVatDebet: 2645,
        EuVatCredit25: 2614,
        EuVatCredit12: 2624,
        EuVatCredit6: 2634,
        Deductable: 6071,
        NonDeductable: 6072,
        PrivateFirmPrivateExpense: 2018,
    },
    receiptBuyTypes: {
        PrivateExpense: 1,
        CompanyAccount: 2,
        CompanyCreditCard: 3,
    },
    vat: {
        "25%": 1,
        "12%": 2,
        "6%": 3,
        "0%": 4,
        Mixed: 5,
    },
    typesOfRepresentation: {
        BreakfastSnack: 1,
        LunchDinner: 2,
        NoFood: 3,
        DemonstrationViewing: 4,
    },
    countryOfPurchase: {
        Sweden: 0,
        EU: 20,
        OutsideEU: 22,
        Customs: 24,
    },
    maxDeductablePerParticipant: {
        BreakfastSnack: 60,
        LunchDinner: 90,
        DemonstrationViewing: 60,
        NoFood: 0,
    },
};
export default Enum;
