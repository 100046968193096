<template>
    <div>
        <div class="card-tail">
            <TopMenu :selected-clients-data="selectedClients" @searchParametersChanged="syncDataWithQueryParameters" />
        </div>
        <div v-loading="$waiting.is('loading')" class="card-tail">
            <el-table @row-click="clickKycClient" :data="tableData" @selection-change="changeRowsSelection">
                <el-table-column type="selection" width="45" :selectable="isSelectable" />
                <el-table-column prop="clientNumber" label="Klient Nummer" sortable />
                <el-table-column prop="clientName" label="Klient" sortable />
                <el-table-column prop="lastUpdate" label="Senaste Kontroll" :formatter="formatDate" sortable />
                <el-table-column prop="kycByClientStatus" label="Status" sortable :formatter="formatStatusInteger" />
            </el-table>
            <kycByClientModal @eventExecuted="kycEventExecuted" :status="selectedClientStatusString" :client-id="selectedClient" @close="closeClientKyc" :dialog-visible.sync="showClientKyc" />
        </div>
    </div>
</template>

<script>
import KycByClientNewTopMenu from "./KycByClientNewTopMenu.vue";
import queryString from "query-string";
import Api from "../kycByClient.api.js";
import Enum from "../kycByClient.enum.js";
import moment from "moment";
import kycByClientModal from "./kycByClientModal.vue";
export default {
    data() {
        return {
            showClientKyc: false,
            selectedClient: null,
            selectedClientStatusString: "",
            tableData: [],
            kycStatusReverseMap: {},
            selectedClients: [],
            buttonsDisabled: false,
            selectableStatuses: ["notSent", "noActionRequired", "toSupplement", "supplemented", "denied", "needExtraCheck"],
        };
    },
    components: {
        TopMenu: KycByClientNewTopMenu,
        kycByClientModal,
    },
    created() {
        this.updateUrlAndGetAll();
        this.kycStatusLookupMap();
    },
    methods: {
        kycEventExecuted() {
            this.closeClientKyc();
            this.updateUrlAndGetAll();
        },
        clickKycClient(row) {
            this.showClientKyc = !this.showClientKyc;
            this.selectedClient = row.clientId;
            const translatedString = this.translateStatusString(this.kycStatusReverseMap[row.kycByClientStatus]);
            this.selectedClientStatusString = translatedString;
        },
        closeClientKyc() {
            this.showClientKyc = false;
            this.selectedClient = null;
        },
        isDisabledOpenKyc(row) {
            return (
                row.kycByClientStatus === Enum.kycByClientStatusNew.noActionRequired ||
                row.kycByClientStatus === Enum.kycByClientStatusNew.sentToClient ||
                row.kycByClientStatus === Enum.kycByClientStatusNew.notSent ||
                row.kycByClientStatus === Enum.kycByClientStatusNew.toSupplement
            );
        },
        changeRowsSelection(rows) {
            this.selectedClients = rows.map(item => item.clientId);
        },
        isSelectable(row) {
            const statusInt = row.kycByClientStatus;
            const statusString = this.kycStatusReverseMap[statusInt];
            return this.selectableStatuses.includes(statusString);
        },
        formatDate(row) {
            const date = row.lastUpdate;
            return moment(date).format("YYYY-MM-DD");
        },
        kycStatusLookupMap() {
            // Reverses the Enum.kycByClientStatusNew object, for easier lookup using integer coming from backend
            this.kycStatusReverseMap = Object.keys(Enum.kycByClientStatusNew).reduce((acc, key) => {
                acc[Enum.kycByClientStatusNew[key]] = key;
                return acc;
            }, {});
        },
        formatStatusInteger(row, column, cellValue) {
            const translatedString = this.translateStatusString(this.kycStatusReverseMap[cellValue]);
            return translatedString;
        },
        translateStatusString(string) {
            const translation = {
                notSent: "Ej utskickade",
                sentToClient: "Utkast",
                inProgress: "Pågående",
                submitted: "Inskickad",
                noActionRequired: "Ingen åtgärd krävs",
                toSupplement: "Komplettering",
                supplemented: "Kompletterad",
                needExtraCheck: "Extra Check",
                denied: "Nekad",
                blocked: "Blockerad",
            };

            return translation[string];
        },

        syncDataWithQueryParameters() {
            setTimeout(() => {
                this.updateUrlAndGetAll();
            }, 200);
        },
        updateUrlAndGetAll() {
            const { search, status } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            // this.pageNumber = pageNumber || this.pageNumber;
            // this.pageSize = pageSize || this.pageSize;
            // this.sortBy = sortBy || "";
            // this.sortByType = sortByType || "";
            //this.status = status && status !== "totalClients" ? status : "";
            this.status = status && status !== "all" ? status : "all";
            this.getKycListData();
        },
        async getKycListData() {
            this.$waiting.start("loading");
            var response = await Api.getListByStatus(this.status);
            this.tableData = response;
            // // var response = await Api.getKycByClientListNew(this.pageNumber, this.pageSize, this.query, this.sortBy, this.sortByType, this.clientName, this.clientNr, this.status);
            // // this.localclientsFactoringList = [...response.data.clients];
            // // this.totalUsedCred = response.data.totalUsedCred;
            // // this.totalOverdueCred = response.data.totalOverdueCred;
            // this.totalClientNumber = parseInt(response.headers["x-pagination-totalcount"]);
            this.$waiting.end("loading");
        },
    },
};
</script>

<style scoped>
/* Your custom styles go here */
</style>
