<template>
    <div class="card-tail">
        <table class="w-full mb-10">
            <tr>
                <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Datum</th>
                <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Händelse</th>
                <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Belopp</th>
                <th class="font-semibold px-2 pb-1 text-sm text-gray-600">Saldo</th>
            </tr>
            <tr>
                <td class="border px-2 py-1 text-xs">2022-09-25</td>
                <td class="border px-2 py-1 text-xs">Utbetalning</td>
                <td class="border px-2 py-1 text-xs">15000</td>
                <td class="border px-2 py-1 text-xs">0</td>
            </tr>
        </table>
    </div>
</template>
<script>
import Api from "./factoring.api";

export default {
    data() {
        return {
            activeComponent: "ClientSalesLedger",
            header: "",
            isSwitchOn: true,
            decisions: [],
            selectedFactoringDecisionId: null,
        };
    },

    watch: {
        $route: function() {
            this.activeComponent = this.$route.meta.activeTab;
            this.getClientHeader();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
    },

    async created() {
        this.getClientHeader();
        this.activeComponent = this.$route.meta.activeTab;
        this.decisions = await Api.getClientsFactoringDecisionsList(this.$route.params.clientId);
        this.selectedFactoringDecisionId = this.decisions[0].factoringDecisionId;
    },

    methods: {
        async getClientHeader() {
            this.header = await Api.getClientHeader(this.$route.params.clientId);
        },

        tabClick(event) {
            this.$router.push({ path: "/clientApproved/" + this.$route.params.clientId + "/" + event.name });
        },
        onSwitchChange(value) {
            this.isSwitchOn = value;
        },
    },
};
</script>

<style scoped>
.switch-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.headerAndSwitch {
    display: flex;
    justify-content: space-between;
}

.switch-on {
    --el-switch-on-color: #13ce66;
    --el-switch-off-color: #ff4949;
}

.switch-off {
    --el-switch-on-color: #ff4949;
    --el-switch-off-color: #13ce66;
}
</style>