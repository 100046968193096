<template>
    <div class="card-tail" v-loading="$waiting.is('loading')">
        <div class="button-container">
            <el-button type="primary" @click="showAddCustomerDialog">Lägg till kund</el-button>
        </div>
        <el-dialog title="Lägg till kund" :visible.sync="dialogVisible" width="25%">
            <el-form :model="newCustomerForm" label-width="160px">
                <el-form-item label="Kund">
                    <el-input v-model="newCustomerForm.customerName" />
                </el-form-item>
                <el-form-item label="Organisationsnummer">
                    <el-input v-model="newCustomerForm.registrationNumber" />
                </el-form-item>
                <el-form-item label="Kommentar">
                    <el-input v-model="newCustomerForm.comment" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Avbryt</el-button>
                <el-button type="primary" @click="saveNewCustomer" :loading="loadingSave">Spara</el-button>
            </div>
        </el-dialog>

        <el-table :data="redFlagCustomers" size="medium">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-table :data="scope.row.remarks" size="small" :row-class-name="tableRowClassName" :header-cell-style="{ background: '#e5e5e5' }">
                        <el-table-column prop="redFlagType" label="Typ" width="200" />
                        <el-table-column prop="comment" label="Kommentar" />
                        <el-table-column prop="created" label="Skapad" width="100" />
                        <el-table-column prop="userName" label="Rapportör" align="right" />
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column prop="customerName" label="Kund" width="300" />
            <el-table-column prop="registrationNumber" label="Organisationsnummer" width="300" />
            <el-table-column prop="totalNumberOfRemarks" label="Anmärkningar" align="right" />
        </el-table>
    </div>
</template>

<script>
import Api from "./factoring.api";

export default {
    data() {
        return {
            redFlagCustomers: [],
            dialogVisible: false,
            newCustomerForm: {
                customerName: "",
                registrationNumber: "",
                comment: "",
            },
            loadingSave: false,
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
    },

    async created() {
        this.$waiting.start("loading");

        this.redFlagCustomers = await Api.getRedFlagCustomers();
        console.log("🚀 ~ file: RedFlagCustomers.vue:41 ~ created ~ this.redFlagCustomers:", this.redFlagCustomers);

        this.redFlagCustomers.forEach(row => {
            this.$set(row, "expanded", false);
        });
        this.$waiting.end("loading");
    },

    methods: {
        async saveNewCustomer() {
            this.loadingSave = true;

            try {
                await Api.saveCustomer(this.newCustomerForm);

                this.redFlagCustomers = await Api.getRedFlagCustomers();

                this.dialogVisible = false;

                this.clearForm();
                this.$notify.success({ title: "Sparat!" });
            } catch (error) {
                console.error("Error saving new customer:", error);
            } finally {
                this.loadingSave = false;
            }
        },

        clearForm() {
            this.newCustomerForm.customerName = "";
            this.newCustomerForm.registrationNumber = "";
            this.newCustomerForm.comment = "";
        },

        showAddCustomerDialog() {
            this.dialogVisible = true;
        },
        tableRowClassName() {
            console.log("🚀 - file: BankFilesSubTable.vue:97 - tableRowClassName - tableRowClassName:");
            return "header-row-default";
        },
    },
};
</script>

<style>
.button-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}
.header-row-default {
    background-color: #f0f0f0 !important;
}
</style>
