<template>
    <div>
        <div class="card-tail">
            <div class="headerAndSwitch mb-4">
                <div class="switch-container">
                    <header class="text-l font-semibold">{{ header }}</header>
                    <!-- <el-select v-if="showSwitch" style="margin-left: 20px" v-model="selectedFactoringDecisionId" @change="handleDecisionChange">
                        <el-option v-for="(decision, index) in decisions" :key="index" :label="decision.decisionDate" :value="decision.factoringDecisionId" />
                    </el-select> -->
                </div>
                <div v-if="showSwitch && !isLoading" class="switch-container">
                    <p v-if="switchCheck" style="font-size: 15px; font-weight: 600;">Fakturaköp aktivt för klient</p>
                    <p v-else style="font-size: 15px; font-weight: 600;">Fakturaköp inaktivt för klient</p>

                    <el-switch
                        v-model="switchCheck"
                        style="margin-left: 10px"
                        :class="{ 'switch-on': switchCheck, 'switch-off': !switchCheck }"
                        active-color="#13ce66"
                        inactive-color="#919191"
                        @change="onSwitchChange"
                    />
                </div>
            </div>
            <el-tabs v-model="activeComponent" @tab-click="tabClick">
                <el-tab-pane label="Kredit" name="credit" />
                <el-tab-pane label="Fakturor" name="invoices" />
                <el-tab-pane label="Reskontra" name="reskontra" />
                <el-tab-pane label="Noteringar" name="notes" />
                <el-tab-pane label="Inställningar" name="settings" />
            </el-tabs>
        </div>

        <div class="card-tail" v-if="this.$route.meta.activeTab === 'credit'">
            <div>
                <el-button type="success" @click.prevent="confirmUpdate">
                    <span class="icon">
                        <i class="fa fa-sync" />
                    </span>
                    <span style="margin-left: 4px; font-size:15px">Nytt beslut</span>
                </el-button>
            </div>

            <div style="margin-top: 10px; display:flex;">
                <p style="margin-top: 5px;">Samtliga fakturabeslut:</p>
                <el-select v-if="showSwitch" style="margin-left: 20px" v-model="selectedFactoringDecisionId" @change="handleDecisionChange">
                    <el-option v-for="(decision, index) in decisions" :key="index" :label="decision.decisionDate" :value="decision.factoringDecisionId" />
                </el-select>
            </div>
        </div>

        <!-- <router-view @factoring-enabled="handleFactoringEnabled" ref="routerViewRef" :class="{ inactive: factoringEnabled && activeComponent === 'credit' }" /> -->
        <router-view @factoring-enabled="handleFactoringEnabled" ref="routerViewRef" />
    </div>
</template>
<script>
import Api from "./factoring.api";
import { Switch } from "element-ui";

export default {
    data() {
        return {
            switchCheck: false,
            activeComponent: "ClientApproved",
            header: "",
            factoringEnabled: false,
            decisions: [],
            selectedFactoringDecisionId: null,
            selectedIndex: null,
            forceRerender: false, // Add this line
            selectedDecision: "",
            isLoading: true,
        };
    },

    watch: {
        $route: function() {
            this.activeComponent = this.$route.meta.activeTab;
            this.getClientHeader();
        },
    },

    computed: {
        showSwitch() {
            return this.$route.meta.activeTab === "credit";
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
    },

    async created() {
        this.isLoading = true;

        this.$on("factoring-enabled", this.handleFactoringEnabled);
        this.getClientHeader();
        this.activeComponent = this.$route.meta.activeTab;
        this.decisions = await Api.getClientsFactoringDecisionsList(this.$route.params.clientId);

        if (this.decisions && this.decisions.length > 0 && this.decisions[0]) {
            this.selectedFactoringDecisionId = this.decisions[0].factoringDecisionId;
        }
    },

    methods: {
        confirmUpdate() {
            if (confirm("Är du säker på att du vill ta ett nytt kreditbeslut för denna klient?")) {
                this.updateDataAndCallFunction();
            }
        },
        updateDataAndCallFunction() {
            console.log("CLICKED");
            this.$emit("update-client-approved");
        },
        handleDecisionChange() {
            if (this.selectedFactoringDecisionId !== null) {
                this.selectedIndex = this.decisions.findIndex(decision => decision.factoringDecisionId === this.selectedFactoringDecisionId);
            }

            this.selectedDecision = this.decisions[this.selectedIndex].factoringDecisionId;
            this.$refs.routerViewRef.loadData(this.selectedDecision);
        },

        async getClientHeader() {
            this.header = await Api.getClientHeader(this.$route.params.clientId);
        },

        tabClick(event) {
            this.$router.push({ path: "/clientApproved/" + this.$route.params.clientId + "/" + event.name });
        },
        async onSwitchChange(value) {
            console.log("🚀 ~ onSwitchChange ~ value:", value);

            if (value === true) {
                alert("Fakturaköp är redan inaktiverat för denna klient!");
                this.switchCheck = false;
            } else if (value === false) {
                try {
                    if (confirm("Är du säker på att du vill inaktivera fakturaköp för denna klient?")) {
                        await Api.updateClientOverruled(this.$route.params.clientId, this.switchCheck);
                        this.$notify.success({ title: "Fakturaköp är nu inaktiverat för klient!" });
                        location.reload();
                    } else {
                        this.switchCheck = true;
                    }
                } catch (error) {
                    console.log("Error:", error.message);
                }
            }
        },

        handleFactoringEnabled(enabled) {
            console.log("🚀 ~ handleFactoringEnabled ~ enabled:", enabled);
            this.switchCheck = enabled;
            console.log("🚀 ~ handleFactoringEnabled ~ this.switchCheck:", this.switchCheck);
            this.isLoading = false;
        },
    },
    components: {
        "el-switch": Switch,
    },
};
</script>

<style scoped>
.switch-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.headerAndSwitch {
    display: flex;
    justify-content: space-between;
}

.switch-on {
    --el-switch-on-color: #13ce66;
    --el-switch-off-color: #ff4949;
}

.switch-off {
    --el-switch-on-color: #ff4949;
    --el-switch-off-color: #13ce66;
}

.inactive {
    pointer-events: none; /* Disable interaction with content when isSwitchOn is false */
}
</style>
