<template>
    <div>
        <div class="card-tail">
            <TopBar @searchInputChange="searchInputChange" @searchParametersChanged="syncDataWithQueryParameters" />
        </div>
        <div class="card-tail">
            <el-table
                :default-sort="preSelectedSort"
                :data="chatConversationData"
                @sort-change="sortChange"
                v-loading="$waiting.is('loading')"
                @selection-change="handleSelectionChange"
                @row-click="goToMessages"
                size="mini"
            >
                <el-table-column prop="clientName" label="Klient" sortable="custom" show-overflow-tooltip />
                <el-table-column prop="individualName" label="Användare" sortable="custom" show-overflow-tooltip />
                <el-table-column prop="topic" label="Ämne" sortable="custom" width="250" />
                <el-table-column prop="createTime" label="Skapad tid" sortable="custom" width="150">
                    <template slot-scope="scope">
                        <p>
                            {{ formatDate(scope.row.createTime) }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="estimatedCost" label="Beräknad kostnad(kr)" width="170">
                    <template slot-scope="scope">
                        <p>
                            {{ formatDecimal(scope.row.estimatedCost) }}
                        </p>
                    </template>
                </el-table-column>
            </el-table>
            <Pagination ref="paginationComponent" @change="paginationChangePage" :total-amount="totalConversationsNumber" :page-number-prop="pageNumber" />
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
import Api from "../chatAgent.api";

export default {
    components: {
        TopBar: () => import(/* webpackChunkName: "InvoicesTopBar" */ "./TopBar.vue"),
        Pagination: () => import(/* webpackChunkName: "InvoicesPagination" */ "../../invoices/components/Pagination.vue"),
    },

    data() {
        return {
            chatConversationData: [],
            totalConversationsNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            query: "",
            sortBy: "",
            sortByType: "",
            startDate: "",
            endDate: "",
            clientId: "",
            individualId: "",
            showDeleted: false,
            showTest: false,
            loading: true,
            key: 0,
            apiCallPromise: null,
        };
    },

    created() {
        const key = Math.random();
        this.key = key;
        this.getAll(key);
        this.assignInitValues();
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },

    methods: {
        paginationChangePage() {
            const key = Math.random();
            this.key = key;
            this.updateUrlAndGetAll(key);
        },
        searchInputChange() {
            this.$refs.paginationComponent.updateUrl(1);
            const key = Math.random();
            this.key = key;
            this.updateUrlAndGetAll(key);
        },
        syncDataWithQueryParameters() {
            if (this.isGlobalClientSelected) {
                const { pageNumber } = queryString.parse(location.search);
                this.pageNumber = pageNumber || this.pageNumber;
            } else {
                this.pageNumber = 1;
            }
            setTimeout(() => {
                this.$refs.paginationComponent.updateUrl(+this.pageNumber);
                const key = Math.random();
                this.key = key;
                this.updateUrlAndGetAll(key);
            }, 200);
        },
        async getAll(key) {
            this.$waiting.start("loading");
            const response = await Api.getChatConversationsList(
                this.pageNumber,
                this.pageSize,
                this.query,
                this.sortBy,
                this.sortByType,
                this.startDate,
                this.endDate,
                this.clientId,
                this.individualId,
                this.showDeleted,
                this.showTest,
            );
            if (this.key == key) {
                this.chatConversationData = response.data;
                this.totalConversationsNumber = parseInt(response.headers["x-pagination-totalcount"]);
                this.$waiting.end("loading");
            }
        },
        async updateUrlAndGetAll(key) {
            const { pageNumber, pageSize, search, sortBy, sortByType, startDate, endDate, clientId, individualId, showDeleted, showTest } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.sortBy = sortBy || "";
            this.sortByType = sortByType || "";
            this.startDate = startDate || "";
            this.endDate = endDate || "";
            this.clientId = clientId || "";
            this.individualId = individualId || "";
            this.showDeleted = showDeleted || false;
            this.showTest = showTest || false;
            await this.getAll(key);
        },
        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },
        formatDate(date) {
            if (date == null) return "";
            return moment(date).format("YYYY-MM-DD");
        },
        formatDecimal(value) {
            if (value == null) return "";
            return value.toFixed(2);
        },
        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.syncDataWithQueryParameters();
        },
        handleSelectionChange() {},
        goToMessages(row) {
            this.$router.push(`/chatconversations/${row.chatConversationId}`);
        },
    },
};
</script>
<style>
.el-popover__title {
    color: #fff;
}
</style>
