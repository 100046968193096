<template>
    <div class="h-full min-h-full flex justify-center items-center" style="background-color: #1D3F67">
        <LoginEmail v-if="state === 0" :email="email" @on-next="onNext" @go-back="goBack" />
        <LoginPassword v-if="state === 1" :email="email" @on-login="onLogin" @go-back="goBack" />
        <LoginYubikey v-if="state === 2" :email="email" @on-login="onLogin2" @go-back="goBack" :error="error" />
    </div>
</template>
<script>
import Vue from "vue";
import Api from "./login.api";
import LoginEmail from "./ui/login.email";
import LoginPassword from "./ui/login.password";
import LoginYubikey from "./ui/login.yubikey";

export default {
    components: {
        LoginEmail,
        LoginPassword,
        LoginYubikey,
    },

    data: function() {
        return {
            email: this.$cookie.get("username"),
            password: "",
            yubikey: "",
            error: "",
            state: 1,
        };
    },

    mounted() {
        document.title = "Logga in";
        this.userName = this.$cookie.get("username");
    },

    methods: {
        onNext(email) {
            this.$cookie.set("username", email, 365);
            this.email = email;
            this.state = 1;
        },

        onLogin(password) {
            this.password = password;
            this.state = 2;
        },

        onLogin2(yubikey) {
            this.yubikey = yubikey;
            this.goLogin({ userName: this.email, password: this.password, yubikey: this.yubikey });
        },

        goBack() {
            this.state = 0;
            this.error = "";
        },

        async getClientsData() {
            const clientsData = await Api.getClients();
            this.$store.commit("setClientList", clientsData);
        },

        async goLogin(loginDto) {
            try {
                this.error = "";
                let result = await Api.sendLogin(loginDto);
                Vue.auth.setToken(result.token, result.expiration + Date.now());
                this.$store.commit("login/setUserName", loginDto.userName);
                this.getClientsData();
                const accountPlans = await Api.getAccountPlans();
                this.$store.commit("setAccountPlans", accountPlans);
                const featureFlags = await Api.getFeatureFlags();
                this.$store.commit("setFeatureFlags", featureFlags);
                this.$router.push("dashboard");
            } catch (error) {
                this.error = "Din epost, lösen eller yubikey är felaktig, försök igen!";
            }
        },
    },
};
</script>
<style>
html,
body {
    min-height: 100%;
    height: 100%;
}
</style>
