/* eslint-disable vue/valid-v-on */
<template>
    <div class="table-container" :class="{ 'table-scroll': scroll }">
        <table class="table is-narrow is-striped is-hoverable is-fullwidth" v-if="headers">
            <thead>
                <tr v-if="search">
                    <th :class="{ 'sticky-header': scroll }" :colspan="headers.length">Sök: <input type="text" /></th>
                </tr>
                <tr>
                    <th :class="{ 'sticky-header': scroll, 'sticky-header-top': search }" v-for="(header, key) in headers" :key="key" :style="setHeaderWidth(header)" :title="header.title">
                        <div class="header-sort" @click.prevent="onSort(key)">
                            <span v-if="header.align != 'right'">{{ header.name }}</span>
                            <span class="icon is-small">
                                <i v-if="key == sortIndex && !sortDescendingn" class="fas fa-caret-down" />
                                <i v-if="key == sortIndex && sortDescendingn" class="fas fa-caret-up" />
                            </span>
                            <span v-if="header.align == 'right'">{{ header.name }}</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <Control v-for="item in itemsSorted" :key="item.id" class="clickable-row">
                    <tr @click="onClickRow(item)" class="clickable-row" :class="{ green: item.done }">
                        <td>{{ item.vacationYearStartDate }}</td>
                        <td>{{ item.valueDate }}</td>
                        <td style="text-align: right;">{{ item.days | swedishNumberFormat }}</td>
                        <td style="text-align: right;">{{ item.vacationSalaryDebt | swedishNumberFormat }}</td>
                        <td>{{ item.vacationType }}</td>
                        <td>{{ item.comment }}</td>
                        <td>{{ item.openingBalance }}</td>
                        <!-- <td style="text-align: right;">{{ item.days | swedishNumberFormat }}</td> -->
                    </tr>
                </Control>
            </tbody>
        </table>
    </div>
</template>
<script>
import Control from "../../components/layout/Control.js";
export default {
    components: {
        Control,
    },
    props: ["items", "headers", "scroll", "search", "getrowclass"],
    data() {
        return {
            sortKey: "",
            sortIndex: 0,
            sortDescendingn: false,
        };
    },
    methods: {
        edit() {},
        save(item) {
            this.$emit("save", item);
        },
        localGetrowclass(item) {
            if (this.getrowclass != undefined) {
                return this.getrowclass(item);
            }
        },
        // localGetrowclass(item) {
        //   if (this.getrowclass != undefined) {
        //     return this.getrowclass(item);
        //   }
        // },
        onClickRow: function(item) {
            this.$emit("click", item);
        },
        onSort: function(key) {
            if (key == this.sortIndex) {
                this.sortDescendingn = !this.sortDescendingn;
            } else {
                this.sortDescendingn = false;
            }
            this.sortIndex = key;
            this.sortKey = this.headers[key].prop;
        },
        setHeaderWidth(header) {
            return `width: ${header.width};` + `color: ${header.color};` + `font-weight: ${header.fontWeight};` + `text-align: ${header.align};` + `title: ${header.title};`;
        },
        dynamicSort: function(property, sortDescendingn) {
            const sortOrder = sortDescendingn ? -1 : 1;
            return function(a, b) {
                if (a[property] == undefined) return 0;
                if (a[property] == null) a[property] = "";
                if (b[property] == null) b[property] = "";
                let result = 0;
                if (typeof a[property] == "number") {
                    result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
                } else {
                    result = a[property].toString().localeCompare(b[property].toString(), "se", { sensitivity: "base", numeric: true });
                }
                return result * sortOrder;
            };
        },
        previousEarnedRestDaysText: function(item) {
            const value = item.previousEarnedRestDays;
            const valueRounded = Math.ceil(value);
            return item.done ? `${valueRounded}` : `(${value}) ${valueRounded} --> Betalda akuellt år`;
        },
        previousPaidNotTakenDaysText: function(item) {
            const paid = item.previousPaidDays;
            const taken = item.previousPaidTakenDays;
            const rest = paid - taken;
            const preText = `(${paid}-${taken})`;
            const postText = rest <= 5 && rest > 0 ? "--> Sparade" : "";
            return item.done ? `${paid}` : `${preText} = ${rest} ${postText}`;
        },
    },
    computed: {
        itemsSorted: function() {
            if (this.items != undefined) {
                let list = [...this.items];
                return list.sort(this.dynamicSort(this.sortKey, this.sortDescendingn));
            } else return null;
        },
    },
};
</script>
<style scoped>
.green {
    background-color: lightgreen;
}
.fa-save,
.fa-edit {
    color: #415770;
}
table td,
table th {
    border: 0px !important;
}
/* table tr td {
   min-width: 100px;
} */

/* .table-container {
  overflow: unset !important;
} */
.table-scroll {
    overflow-y: scroll;
}
.sticky-header {
    background-color: white;
    position: sticky;
    top: 0;
}
.sticky-header-top {
    top: 29px !important;
}
.header-sort {
    cursor: pointer;
}
.clickable-row {
    cursor: pointer;
}
</style>
