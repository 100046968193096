<script>
export default {
    created() {
        this.spaces = [
            { name: "xxsmall", value: 4 },
            { name: "xsmall", value: 8 },
            { name: "small", value: 12 },
            { name: "medium", value: 20 },
            { name: "gutter", value: 24 },
            { name: "large", value: 32 },
            { name: "xlarge", value: 48 },
            { name: "xxlarge", value: 96 },
        ];
        this.aligns = [
            { name: "left", value: "flex-start" },
            { name: "center", value: "center" },
            { name: "right", value: "flex-end" },
        ];
    },
    props: {
        space: {
            type: [String, Array, Number],
            default() {
                return ["small", "medium"];
            },
        },
        align: {
            type: [String],
            default: "left",
        },
    },
    render(h) {
        let space = this.getSpace(this.space, this.$mq);
        const align = this.aligns.find(item => item.name == this.align).value;
        const alignStyle = {
            "align-items": align,
        };

        const slots = this.$slots.default;
        let x = 1;
        let children =
            slots != undefined
                ? slots.map(child => {
                      if (child.tag != undefined) {
                          if (x++ > 1) {
                              if (!child.data) child.data = {};
                              if (!child.data.style) child.data.style = {};
                              child.data.style = { ...child.data.style, marginTop: space + "px" };
                          }
                      }
                      return child;
                  })
                : "";

        const wrapper = h("div", { class: "stack-wrapper-inner", style: alignStyle }, children);

        let vdom = h("div", { class: "stack-wrapper" }, [wrapper]);
        return vdom;
    },
    methods: {
        getPixels(value) {
            if (isNaN(value)) {
                const result = this.spaces.find(item => item.name === value);
                return result == undefined ? 0 : result.value;
            } else {
                return Number(value);
            }
        },
        getSpace(value, mq) {
            let result;
            if (!Array.isArray(value)) {
                const pix = this.getPixels(value);
                result = pix;
            } else {
                if (value.length === 1) {
                    const pix = this.getPixels(value[0]);
                    result = pix;
                } else if (value.length === 2) {
                    if (mq === "mobile" || mq === "tablet") {
                        result = this.getPixels(value[0]);
                    } else {
                        result = this.getPixels(value[1]);
                    }
                } else if (value.length === 4) {
                    if (mq === "mobile") {
                        result = this.getPixels(value[0]);
                    } else if (mq === "tablet") {
                        result = this.getPixels(value[1]);
                    } else if (mq === "laptop") {
                        result = this.getPixels(value[2]);
                    } else {
                        result = this.getPixels(value[3]);
                    }
                }
            }
            return result;
        },
    },
};
</script>
<style scoped>
.stack-wrapper-inner {
    z-index: -99999;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.stack-wrapper {
    width: 100%;
}
</style>
