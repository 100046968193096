<template>
    <el-card v-if="owners" class="kyc-owners-card">
        <div slot="header" class="clearfix kyc-owners-card-header">
            <span>Verkliga huvudmän</span>
        </div>
        <div v-loading="loading" class="kyc-owners-container">
            <div class="kyc-owners-category">
                <div class="kyc-owners-category-header">Aktuell</div>
                <OwnerCard v-for="(owner, index) in paddedOldOwners" :key="index" :owner="owner" owner-card-type="current" />
            </div>
            <div class="kyc-owners-category">
                <div class="kyc-owners-category-header">Ny</div>
                <OwnerCard v-for="(owner, index) in paddedNewOwners" :key="index" :owner="owner" owner-card-type="new" />
            </div>
            <div class="kyc-owners-category">
                <div class="kyc-owners-category-header">Från Bokningsystem</div>
                <OwnerCard v-for="(owner, index) in paddedThirdPartyOwners" :key="index" :owner="owner" owner-card-type="third-party" />
            </div>
        </div>
        <div class="handle-owners-container">
            <el-button type="primary" @click="handleManageOwnersDialog()">Lägg till delägare</el-button>
            <div class="owner-information-handling-buttons">
                <div style="display: flex; width: 100%; justify-content: flex-start">
                    <el-button @click="commentOwners()">Kommentera verkliga huvudmän</el-button>
                </div>
                <div style="display: flex; width: 100%; justify-content: flex-end">
                    <el-button @click="addOrgTree()">Bifoga org träd<i class="el-icon-upload el-icon-right ml-1"/></el-button>
                </div>
            </div>
        </div>
        <div v-if="owners" class="owner-statistics">
            <div class="statistic-container" v-for="(ownerList, index) in [owners.oldOwnersList, owners.newOwnersList]" :key="index">
                <div>
                    <label class="statistic-label">Summering av andelar: </label>
                    <span class="statistic-value">{{ calculateShareTotal(ownerList) }}%</span>
                </div>
                <div>
                    <label class="statistic-label">Differens: </label>
                    <span class="statistic-value">{{ calculateShareDifferens(ownerList) }}%</span>
                </div>
            </div>
        </div>
        <kyc-manage-owners-dialog
            :company-type="companyType"
            :client-id="clientId"
            @close="handleManageOwnersDialog"
            @newOwnerAdded="refreshListData"
            :owners-handling-dialog-visible.sync="showManageOwnersDialog"
        />
    </el-card>
</template>

<script>
import Api from "../kycByClient.api.js";
import OwnerCard from "./kycByClientNewOwnerCard.vue";
import kycByClientNewOwnersDialog from "./kycByClientNewOwnersDialog.vue";
export default {
    components: {
        OwnerCard,
        "kyc-manage-owners-dialog": kycByClientNewOwnersDialog,
    },
    name: "KycByClientNewOwners",
    // Add your component logic here
    data() {
        return {
            owners: [],
            loading: false,
            showManageOwnersDialog: false,
            companyType: undefined,
        };
    },
    async created() {
        this.loading = true;
        this.owners = await Api.getClientKycOwners(this.clientId);
        this.companyType = this.owners.newOwnersList[0].companyType;
        this.loading = false;
    },
    props: {
        clientId: {
            type: String,
            required: true,
        },
    },
    methods: {
        async refreshListData() {
            this.handleManageOwnersDialog();
            this.loading = true;
            this.owners = await Api.getClientKycOwners(this.clientId);
            this.loading = false;
        },
        handleManageOwnersDialog() {
            this.showManageOwnersDialog = !this.showManageOwnersDialog;
        },
        calculateShareDifferens(owners) {
            const ownersTotal = this.calculateShareTotal(owners);
            const oneHundred = 100;

            return Math.ceil(oneHundred - ownersTotal);
        },
        calculateShareTotal(owners) {
            return owners.reduce((acc, owner) => acc + owner.ownerShare, 0);
        },
        padList(list) {
            const placeholder = { isPlaceholder: true };
            return [...list, ...Array(this.maxOwnersLength - list.length).fill(placeholder)];
        },
    },
    computed: {
        maxOwnersLength() {
            return Math.max(this.owners.oldOwnersList.length, this.owners.newOwnersList.length, this.owners.thirdPartyOwnersList.length);
        },
        paddedOldOwners() {
            return this.padList(this.owners.oldOwnersList);
        },
        paddedNewOwners() {
            return this.padList(this.owners.newOwnersList);
        },
        paddedThirdPartyOwners() {
            return this.padList(this.owners.thirdPartyOwnersList);
        },
    },
};
</script>

<style scoped>
.owner-statistics {
    display: flex;
    gap: 1rem;
    width: 66%;
}
.statistic-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1em;
    color: #1d3f67;
    background-color: #fafafa;
    border-radius: 5px;
    flex: 1;
}
.outer-container {
    border: 2px solid #415770;
    border-radius: 10px;
    padding: 2em;
    box-sizing: border-box;
}
.handle-owners-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em 0em;
}
.owner-information-handling-buttons {
    margin-top: 5rem;
    display: flex;
    width: 33%;
    padding: 1em;
}

.kyc-owners-container {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    align-items: stretch; 
}

.kyc-owners-category {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
}

.kyc-owners-category-header {
    font-size: 16px;
    color: #1d3f67;
    font-weight: bold;
}
div >>> .el-card__body {
    padding: 0 20px 3em 20px !important;
}

.kyc-owners-card-header {
    font-size: 18px;
    font-weight: bold;
    color: #1d3f67;
}

div >>> .el-card__header {
    padding-bottom: 2em !important;
    border-bottom: none !important;
}
.kyc-owners-card {
    margin-top: 2rem;
    border-radius: 18px;
    border: none;
}
.statistic-label {
    color: #1d3f67;
    font-weight: bold;
}
.statistic-value {
    color: #1d3f67;
}
</style>
