import Vue from "vue";

export default {
    async savePreviewData(clientId, payload) {
        try {
            const response = await Vue.axios.post(`clients/${clientId}/bankStatements`, payload);
            return response.data;
        } catch (error) {
            console.error("API error:", error);
        }
    },

    getClientBankStatementInfo: async clientId => {
        try {
            const response = await Vue.axios.get(`clients/${clientId}/bankStatements/clientInfo`);
            return response.data;
        } catch (error) {
            console.error("API error:", error);
            return {};
        }
    },
};
