import Dashboard from "./dashboard";

const routes = [
    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
