<template>
    <div>
        <List :items="transactions" :scroll="true" :search="false" :headers="headers" @click="click" style="font-size: 13px;" />
    </div>
</template>

<script>
import List from "../../components/list2";
export default {
    components: {
        List,
    },
    props: ["transactions"],
    data: function() {
        return {
            headers: [
                { name: "", prop: "check", width: "20px", check: true },
                { name: "Nr", prop: "number", width: "50px" },
                { name: "Företag", prop: "name" },
                { name: "Typ", prop: "companyType" },
                { name: "Admin", prop: "adminPerson" },
                { name: "Klart vecka", prop: "dueWeek" },
                { name: "EF", prop: "hasPrivateFirm" },
                { name: "Revisor", prop: "hasAccountant" },
                { name: "K3", prop: "hasK3" },
                { name: "Lager", prop: "hasInventory" },
                { name: "Invest.", prop: "hasInvestments" },
                { name: "K10", prop: "k10" },
                { name: "K10 skapad", prop: "wantK10Done" },
                { name: "K10 inskickad", prop: "k10SentIn" },
                { name: "Kontoutdrag", prop: "hasBankStatement" },
                { name: "Avstämt", prop: "isReconciled" },
                { name: "Reg cert", prop: "hasRegistrationCertificate" },
                { name: "Årsred.", prop: "isEstablished" },
                { name: "Å signed", prop: "isSigned" },
                { name: "Å reg", prop: "hasRegisteredSwedishCompaniesRegistrationOffice" },
                { name: "Dekl inl.", prop: "isDeclarationSubmitted" },
                { name: "Utdelning", prop: "dividendDone" },
                { name: "Löpande ers.", prop: "hasOngoingCompensation" },
                { name: "Fakturerat", prop: "isInvoiced" },
                { name: "Start", prop: "startDate", width: "100px" },
                { name: "Slut", prop: "endDate", width: "100px" },
            ],
            options: [],
        };
    },
    methods: {
        click(row) {
            this.$emit("click", row);
        },
    },
    computed: {},
};
</script>
<style scoped>
.linkcolorok {
    background-color: #ccddcc !important;
}
.linkcolordiff {
    background-color: #f7d3ab !important;
}
.linkcolorreceipt {
    background-color: #abccf7 !important;
}
.selected {
    border-style: solid !important;
    border-width: 2px !important;
    border-color: #000000 !important;
}
th {
    background: white;
    position: sticky;
    top: 0;
}
</style>
