<template>
    <div class="card-tail card-f-h" v-loading="$waiting.is('loading')">
        <ModalAdd :visible="modalAddVisible" :selected-plan-id="selectedAccountPlan" @close="modalAddVisible = false" @refresh="getAccountsByPlan" />
        <ModalEdit :visible="modalEditVisible" :selected-plan-id="selectedAccountPlan" :selected-item-id="selectedItemId" @close="modalEditVisible = false" @refresh="getAccountsByPlan" />

        <div class="flex justify-between border-b mb-3 pb-3">
            <el-button type="primary" @click="showModalAdd">Lägg till</el-button>
            <el-select v-model="selectedAccountPlan" placeholder="" @change="getAccountsByPlan">
                <el-option v-for="plan in accountsPlans" :label="plan.name" :value="plan.accountPlanId" :key="plan.accountPlanId" />
            </el-select>
        </div>
        <Table :table-data="accountsData" @edit="showModalEdit" @delete="handleDelete" />
    </div>
</template>
<script>
import Api from "./accountPlans.api";
export default {
    components: {
        Table: () => import("./AccountPlansTable.vue"),
        ModalAdd: () => import("./AccountPlansModalAdd.vue"),
        ModalEdit: () => import("./AccountPlansModalEdit.vue"),
    },

    data() {
        return {
            accountsPlans: [],
            accountsData: [],
            selectedAccountPlan: null,
            modalAddVisible: false,
            selectedItemId: null,
            modalEditVisible: false,
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Kontoplan");
        document.title = "Kontoplan";
    },

    created() {
        this.getAccountPlans();
    },

    methods: {
        async getAccountPlans() {
            this.$waiting.start("loading");
            this.accountsPlans = await Api.getAccountPlans();
            this.selectedAccountPlan = this.accountsPlans[0].accountPlanId;
            this.getAccountsByPlan();
        },

        async getAccountsByPlan() {
            this.accountsData = [];
            this.$waiting.start("loading");
            this.accountsData = await Api.getAccountsByPlan(this.selectedAccountPlan);
            this.$waiting.end("loading");
        },

        showModalEdit(id) {
            this.selectedItemId = id;
            this.modalEditVisible = true;
        },

        async handleDelete(id) {
            const confirm = await this.$dialog.title("Bekräftelse").confirm("Är du säker?");
            if (!confirm) return;
            await Api.deleteItem({
                planId: this.selectedAccountPlan,
                itemId: id,
            });
            this.getAccountsByPlan();
        },

        showModalAdd() {
            this.modalAddVisible = true;
        },
    },
};
</script>
