import Vue from "vue";

export default {
    getAll: async (pageNumber, pageSize, search, sortBy, sortByType, status, dateFrom, dateTo, clientId) => {
        try {
            const response = await Vue.axios.get(`inpayments`, {
                params: {
                    pageNumber,
                    pageSize,
                    search,
                    sortBy,
                    sortType: sortByType,
                    status,
                    startDate: dateFrom,
                    endDate: dateTo,
                    clientId,
                },
            });
            return response;
        } catch (error) {
            console.log("🚀 ~ file: inpayments.api.js ~ getAll: ~ error", error);
            return [];
        }
    },
    getById: async obTransactionId => {
        try {
            const { data } = await Vue.axios.get(`inpayments/${obTransactionId}`);
            return data;
        } catch (error) {
            return [];
        }
    },
    getCounts: async (search, clientId, dateFrom, dateTo) => {
        try {
            const { data } = await Vue.axios.get(`inpayments/counts`, {
                params: {
                    search,
                    clientId,
                    startDate: dateFrom,
                    endDate: dateTo,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: inpayments.api.js ~ line 38 ~ getCounts: ~ error", error);
            return [];
        }
    },
    GetEvents: async obTransactionId => {
        try {
            const { data } = await Vue.axios.get(`inpayments/getEvents/${obTransactionId}`);
            return data;
        } catch (error) {
            return [];
        }
    },
    supplementInpayment: async (obTransactionId, comment) => {
        try {
            const { data } = await Vue.axios.post(`inpayments/supplement/${obTransactionId}`, {
                comment,
            });
            return data;
        } catch (error) {
            return [];
        }
    },
    getSeries: async () => {
        try {
            const { data } = await Vue.axios.get(`booking/series`);
            return data;
        } catch (error) {
            console.log("🚀 ~ inpayments.api.js ~ line 55 ~ getSeries: ~ error", error);
            return [];
        }
    },
    getDocuments: async obTransactionId => {
        try {
            const { data } = await Vue.axios.get(`inpayments/${obTransactionId}/documents`);
            return data;
        } catch (error) {
            console.log("🚀 ~ inpayments.api.js ~ line 64 ~ getSeries: ~ error", error);
        }
    },
    deleteInpayments: async (obTransactionId, data) => {
        try {
            const response = await Vue.axios.delete(`inpayments/${obTransactionId}`, {
                data,
            });
            return response.data;
        } catch (error) {
            console.log("🚀 ~ inpayments.api.js ~ line 64 ~ getSeries: ~ error", error);
        }
    },
    getOpenFinancialYears: async clientId => {
        var response = await Vue.axios.get(`clients/${clientId}/financialyears`);
        return response.data;
    },
    bookInpayment: async (bookingDate, serie, voucherText, booking, clientId, obTransactionId) => {
        var response = await Vue.axios.post(`inpayments/book/${clientId}/${obTransactionId}`, {
            bookingDate,
            serie,
            voucherText,
            booking,
        });
        return response.data;
    },
    getNextInpayment: async (obTransactionId, search, status, dateFrom, dateTo, clientId) => {
        try {
            const response = await Vue.axios.get(`inpayments/${obTransactionId}/next`, {
                params: {
                    search,
                    status,
                    startDate: dateFrom,
                    endDate: dateTo,
                    clientId,
                },
            });
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: invoices.api.js ~ getNextReceipt: ~ error", error);
            return [];
        }
    },
};
