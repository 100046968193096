import YearEndDisposition from "./YearEndDisposition.vue";
import YearEndDispositionDetails from "./YearEndDispositionDetails.vue";

const routes = [
    {
        path: "/yearEndDisposition",
        name: "yearEndDisposition",
        component: YearEndDisposition,
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/yearEndDisposition/:id",
        name: "yearEndDispositionDetails",
        component: YearEndDispositionDetails,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
