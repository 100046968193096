<template>
    <div class="column-wrapper" :style="getStyle" :class="{ 'right-align': align == 'right', 'left-align': align == 'left', 'c-w-w': width != 'content' }">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        align: { type: String, default: "left" },
        width: { type: Number, String, default: null },
    },
    created() {
        this.percents = [
            { name: "1/2", value: 50 },
            { name: "1/3", value: 100 / 3 },
            { name: "1/4", value: 25 },
            { name: "1/5", value: 20 },
        ];
    },
    computed: {
        getStyle() {
            if (this.width === "content") return {};
            let width = 0;
            if (this.width != null) {
                let percent = this.percents.find(item => item.name == this.width);
                if (percent == undefined) {
                    if (!isNaN(this.width)) {
                        width = parseInt(this.width);
                    }
                } else {
                    width = percent.value;
                }
                if (width > 0) {
                    return {
                        flex: "0 0 " + width + "%",
                    };
                }
            }
            return {
                width: "100%",
            };
        },
    },
};
</script>
<style lang="postcss" scoped>
.right-align {
    justify-content: flex-end;
}
.left-align {
    justify-content: flex-start;
}
.c-w-w {
    width: 100%;
}
.column-wrapper {
    display: flex;
    flex-direction: column;
}
.width {
    flex: 0 0 50%;
}
</style>
