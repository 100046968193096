var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card-tail"},[_c('ClientsSearch',{on:{"on-selected":_vm.clientSelected}})],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$waiting.is('loading')),expression:"$waiting.is('loading')"}],staticClass:"p-4 rounded",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"row-style":_vm.tableRowClassName},on:{"current-change":_vm.routeToForm}},[_c('el-table-column',{attrs:{"prop":"clientNumber","width":"110","label":"Klientnummer"}}),_c('el-table-column',{attrs:{"prop":"clientName","label":"Klient","width":"220"}}),_c('el-table-column',{attrs:{"prop":"supplierName","label":"Leverantör","width":"220"}}),_c('el-table-column',{attrs:{"prop":"invoiceNumber","label":"Fakturanummer"}}),_c('el-table-column',{attrs:{"prop":"amount","width":"120","label":"Belopp"}}),_c('el-table-column',{attrs:{"prop":"invoiceDate","width":"140","label":"Fakturadatum","sortable":""}}),_c('el-table-column',{attrs:{"prop":"registeredBy","width":"140","label":"Registrerad av"}}),_c('el-table-column',{attrs:{"prop":"registeredDate","width":"140","label":"Registreringsdatum"}}),_c('el-table-column',{attrs:{"prop":"approvedBy","width":"120","label":"Godkänd av"}}),_c('el-table-column',{attrs:{"prop":"approvedDate","width":"120","label":"Godkänd datum"}}),_c('el-table-column',{attrs:{"prop":"status","label":"Status","filters":[
                { text: 'Utkast', value: 'Utkast' },
                { text: 'InväntarAMLAttest', value: 'InväntarAMLAttest' },
                { text: 'Attesteras', value: 'Attesteras' },
                { text: 'VäntarBokförd', value: 'VäntarBokförd' },
                { text: 'VäntarBetalning', value: 'VäntarBetalning' },
                { text: 'Betald', value: 'Betald' },
                { text: 'Nekad', value: 'Nekad' },
                { text: 'Kompleteras', value: 'Kompleteras' },
                { text: 'Kompleterad', value: 'Kompleterad' },
                { text: 'Deleted', value: 'Deleted' },
                { text: 'AdminNekad', value: 'AdminNekad' },
                { text: 'KvittoSkapat', value: 'KvittoSkapat' } ],"filter-method":_vm.filterByStatus}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }