<template>
    <div>
        <div class="card-tail">
            <TopBar @searchParametersChanged="searchParametersChanged" />
            <el-select v-model="filterQuery.groupTypes" multiple collapse-tags placeholder="Välj kategori" style="width: 200px; padding-left:12px; margin-right:20px;" @change="updateFilter">
                <el-option v-for="category in categories" :key="category.value" :label="category.label" :value="category.value" />
            </el-select>
            <el-select v-model="filterQuery.sourceTypes" multiple collapse-tags placeholder="Välj systemtyp" style="width: 200px;" @change="updateFilter">
                <el-option v-for="systemSource in systemSources" :key="systemSource.value" :label="systemSource.label" :value="systemSource.value" />
            </el-select>
        </div>
        <div class="card-tail">
            <el-table :data="logs" size="mini" v-loading="$waiting.is('loading')" @row-click="handleRowClick">
                <el-table-column prop="clientName" label="Klientnamn" width="320" />
                <el-table-column prop="createdAt" label="Utfört" width="150" />
                <el-table-column prop="groupType" label="Kategori" width="130" />
                <el-table-column prop="systemSource" label="Systemtyp" width="130" />
                <el-table-column prop="text" label="Text" />
                <el-table-column prop="adminUserName" label="Admin" align="right" width="200" />
            </el-table>
            <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalLogNumber" />
        </div>
        <el-dialog v-if="selectedLog" :visible.sync="isChangesModalVisible" width="50%">
            <template v-slot:title>
                <div class="client-name">{{ selectedLog.clientName }}</div>
            </template>
            <div>
                <div class="log-detail">
                    <span class="label">Utfört:</span> <span>{{ selectedLog.createdAt }}</span>
                </div>
                <div class="log-detail">
                    <span class="label">Systemtyp:</span> <span>{{ selectedLog.systemSource }}</span>
                </div>
                <div class="log-detail">
                    <span class="label">IP-adress:</span> <span>{{ selectedLog.ipAddress }}</span>
                </div>
                <div class="log-detail">
                    <span class="label">AdminUser:</span> <span>{{ selectedLog.adminUserName }}</span>
                </div>
                <div class="log-detail">
                    <span class="label">ClientUser:</span> <span>{{ selectedLog.clientUserName }}</span>
                </div>
                <div class="log-detail">
                    <span class="label">Kategori:</span> <span>{{ selectedLog.groupType }}</span>
                </div>
                <div class="log-detail">
                    <span class="label">Text:</span>
                    <div class="text-column">{{ selectedLog.text }}</div>
                </div>
            </div>
            <div v-if="hasChanges">
                <div v-if="!isCreated">
                    <h4 class="label" style="margin-top:10px;">Före:</h4>
                    <pre>{{ formatValues(selectedLog.oldValues) }}</pre>
                    <h4 class="label" style="margin-top:10px;">Efter:</h4>
                    <pre>{{ formatValues(selectedLog.newValues) }}</pre>
                </div>
                <div v-else>
                    <pre>{{ formatValues(selectedLog.newValues) }}</pre>
                </div>
            </div>
            <div v-else style="font-weight: 600; margin-top:10px;">
                <p>Sparat utan ändringar/Inga ändringar gjorda</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Api from "./loggar.api.js";
import queryString from "query-string";

export default {
    data() {
        return {
            totalLogNumber: 0,
            logs: [],
            selectedLog: null,
            query: "",
            dateFrom: "",
            dateTo: "",
            pageNumber: 1,
            pageSize: 15,
            isChangesModalVisible: false,
            isCreated: false,
            hasChanges: false,
            filterQuery: {
                groupTypes: [],
                sourceTypes: [],
            },
            clientId: "",
            categories: [
                { label: "Odefinierad", value: 0 },
                { label: "Fakturaköp", value: 1 },
                { label: "Kvitton", value: 2 },
                { label: "Fakturor", value: 3 },
                { label: "Leverantörsfakturor", value: 4 },
                { label: "Artiklar", value: 5 },
                { label: "Klienter", value: 6 },
                { label: "Kunder", value: 13 },
                { label: "Bank", value: 7 },
                { label: "Bokföring", value: 8 },
                { label: "E-mail", value: 9 },
                { label: "Löner", value: 10 },
                { label: "Dokument", value: 11 },
                { label: "Övrigt", value: 12 },
            ],
            systemSources: [
                { label: "App", value: 1 },
                { label: "Elsa", value: 2 },
                { label: "Alan", value: 3 },
                { label: "FuncPaymentBooking", value: 4 },
                { label: "FuncInvoice", value: 5 },
            ],
        };
    },

    components: {
        TopBar: () => import(/* webpackChunkName: "LoggingTopBar" */ "./components/TopBar.vue"),
        Pagination: () => import(/* webpackChunkName: "LoggingPagination" */ "./components/Pagination.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Loggar");
        document.title = "Loggar";
    },

    created() {
        this.updateUrlAndGetAll();
    },

    methods: {
        async fetchAuditLogs() {
            try {
                const response = await Api.getAuditLogs(
                    this.dateFrom,
                    this.dateTo,
                    this.pageNumber,
                    this.pageSize,
                    this.query,
                    this.filterQuery.groupTypes,
                    this.filterQuery.sourceTypes,
                    this.clientId,
                );
                this.logs = response.data;
                this.totalLogNumber = parseInt(response.headers["x-pagination-totalcount"]);
            } catch (error) {
                console.error("Error fetching audit logs:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },

        searchParametersChanged() {
            this.$refs.paginationComponent.updateUrl(1);
            this.updateUrlAndGetAll();
        },

        updateUrlAndGetAll() {
            this.$waiting.start("loading");
            const { pageNumber, pageSize, search, dateFrom, dateTo, clientId } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.dateFrom = dateFrom || "";
            this.dateTo = dateTo || "";
            this.clientId = clientId || "";
            this.fetchAuditLogs();
        },

        updateFilter() {
            this.$refs.paginationComponent.updateUrl(1);
            this.fetchAuditLogs();
        },

        async handleRowClick(row) {
            const response = await Api.getAuditLog(row.auditLogId);
            this.selectedLog = response.data;

            this.hasChanges = !!this.selectedLog.oldValues || !!this.selectedLog.newValues;
            this.isCreated = !this.selectedLog.oldValues;
            this.isChangesModalVisible = true;
        },

        formatValues(values) {
            if (!values) return "";
            try {
                const parsedValues = JSON.parse(values);
                return JSON.stringify(parsedValues, null, 4);
            } catch (error) {
                console.error("Error parsing values:", error);
                return "Error parsing values";
            }
        },
    },
};
</script>

<style scoped>
.log-detail {
    display: flex;
    align-items: center;
}
.text-column {
    white-space: normal;
    word-break: break-word;
}
.client-name {
    font-size: 1.4em;
    font-weight: 600;
    padding-left: 10px;
}
.label {
    width: 130px;
    font-weight: 500;
    margin-bottom: 3px !important;
    padding-left: 10px;
}
</style>
