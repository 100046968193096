<template>
    <div>
        <div class="card-tail">
            <div class="flex flex-row">
                <ClientsSearch @on-selected="clientSelected" style="width: 350px;" />
                <button v-if="isClientSelected" @click="clearClientSelected"><i class="fa-regular fa-circle-xmark cursor-pointer pl-2" /></button>
            </div>
        </div>
        <div v-if="isClientSelected">
            <div class="card-tail">
                <header class="text-l font-semibold mb-2">{{ header }}</header>
                <header class="text-sm text-header-blue font-semibold mb-2" v-if="activeComponent !== 'start'">{{ financialYear }}</header>
                <div v-else style="height: 22px" />
                <el-tabs v-model="activeComponent" @tab-click="tabClick">
                    <el-tab-pane label="Start" name="start" />
                    <el-tab-pane label="Företagsuppgifter" name="companyInformation" :disabled="activeComponent === 'start'" />
                    <!-- <el-tab-pane label="Försättsblad" name="coverSheet" :disabled="activeComponent === 'start'" /> -->
                    <el-tab-pane label="Förvaltningsberättelse" name="managementReport" :disabled="activeComponent === 'start'" />
                    <el-tab-pane label="Resultaträkning" name="incomeStatement" :disabled="activeComponent === 'start'" />
                    <el-tab-pane label="Balansräkning" name="balanceSheet" :disabled="activeComponent === 'start'" />
                    <el-tab-pane label="Redovisningsprinciper" name="accountingPrinciples" :disabled="activeComponent === 'start'" />
                    <el-tab-pane label="Noter" name="notes" :disabled="activeComponent === 'start'" />
                    <el-tab-pane label="Underskrifter" name="signatures" :disabled="activeComponent === 'start'" />
                    <el-tab-pane label="Årsstämmoprotokoll" name="annualGeneralMeetingMinutes" :disabled="activeComponent === 'start'" />
                    <el-tab-pane label="Fastställelseintyg" name="certificationOfApproval" :disabled="activeComponent === 'start'" />
                    <el-tab-pane label="Inkomstdeklaration 2" name="incomeDeclaration2" :disabled="activeComponent === 'start'" />
                </el-tabs>
            </div>
            <router-view :client-info="clientInfo" />
        </div>
        <div class="card-tail" v-else>Välj en klient för att fortsätta</div>
    </div>
</template>
<script>
import Api from "../clients/clients.api";
import Api2 from "./annualReport.api";

export default {
    props: {
        selectedFinancialYear: {
            type: String,
        },
    },
    components: {
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
    },
    data() {
        return {
            activeComponent: "AnnualReport",
            header: "",
            clientId: "",
            isClientSelected: false,
            financialYearLoaded: false,
            annualReportId: null,
            financialYear: "",
            clientInfo: {},
            selectedClientProp: {},
            hide: false,
        };
    },

    watch: {
        $route: async function() {
            this.activeComponent = this.$route.meta.activeTab;
            if (this.activeComponent !== "start") {
                this.annualReportId = this.$route.params.annualReportId;
                const response = await Api2.getCompanyInfo(this.clientId, this.annualReportId);
                if (response.clientCompanyDetails != null) {
                    this.clientInfo = response.clientCompanyDetails;
                    this.financialYear = `${this.clientInfo.financialYearStart} - ${this.clientInfo.financialYearEnd}`;
                }
            } else {
                this.financialYear = "";
            }
            await this.getClientHeader();
        },
        isGlobalClientSelected(newValue) {
            if (!newValue) {
                this.isClientSelected = false;
            }
        },
        isComponentCompanyInformation() {
            this.$route.meta.activeTab = "companyInformation";
        },
    },
    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Årsredovisning NY");
    },
    async created() {
        this.clientId = this.$store.state.topBar.selectedClient.value;
        await this.getClientHeader();
        this.$router.push({ path: "/annualReport/start" });
        this.activeComponent = "start";
    },
    methods: {
        async getClientHeader() {
            if (this.clientId) {
                this.header = await Api.getClientHeader(this.clientId);
                this.isClientSelected = true;
            }
        },

        async tabClick(event) {
            if (event.name === "start") {
                this.$router.push({ path: `/annualReport/start` });
            } else {
                this.annualReportId = this.$route.params.annualReportId;
                this.$router.push({ path: `/annualReport/${event.name}/${this.annualReportId}` });
                await Api2.getCompanyInfo(this.clientId, this.annualReportId);
            }
        },

        clientSelected(client) {
            if (client && client.value) {
                this.clientId = client.value;
                this.isClientSelected = true;
                this.$store.commit("topBar/setSelectedClient", client);
                this.getClientHeader();
            } else {
                this.isClientSelected = false;
                this.$store.commit("topBar/clearSelectedClient");
            }
        },
        clearClientSelected() {
            this.clientId = null;
            this.isClientSelected = false;
            this.$store.commit("topBar/clearSelectedClient");
            this.header = "";
            if (this.$route.path !== "/annualReport/start") {
                this.$router.push({ path: `/annualReport/start` });
            }
        },
    },
    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
