<template>
    <div class="card-tail card-f-h" v-loading="$waiting.is('loading')">
        <div v-if="listView" class="columns">
            <div class="column">
                <div style="display: flex;">
                    <RadioButtonListOld :selected-id="filter" :entries="viewOptions" label="Filtrera" :horizontal="true" @change="onChangeViewOption" style="margin-bottom:20px;" />
                </div>
                <TransactionList :transactions="transactionsFiltered" @click="rowClicked" />
                <i style="margin: 10px 0 0 8px;" class="fas fa-flip-horizontal fa-level-up-alt" />
                <button v-if="filter == 'NotExist'" style="margin: 10px 0 0 10px;" class="button is-info is-outlined is-small" @click="create">
                    Skapa frågeformulär
                </button>
                <button v-if="filter == 'Draft'" style="margin: 10px 0 0 10px;" class="button is-info is-outlined is-small" @click="deleteElements">
                    Ta bort frågeformulär
                </button>
                <button v-if="filter != 'Draft' && filter != 'NotExist'" style="margin: 10px 0 0 10px;" class="button is-info is-outlined is-small" @click="moveTo('Draft')">
                    Flytta till utkast
                </button>
                <button v-if="filter != 'NotExist' && filter != 'BeingProcessed'" style="margin: 10px 0 0 10px;" class="button is-info is-outlined is-small" @click="moveTo('BeingProcessed')">
                    Flytta till pågående
                </button>
                <button v-if="filter != 'NotExist' && filter != 'SentIn'" style="margin: 10px 0 0 10px;" class="button is-info is-outlined is-small" @click="moveTo('SentIn')">
                    Flytta till inskickad
                </button>
                <button v-if="filter != 'NotExist' && filter != 'Done'" style="margin: 10px 0 0 10px;" class="button is-info is-outlined is-small" @click="moveTo('Done')">
                    Flytta till klar
                </button>
                <button v-if="filter != 'DoneEarlier'" style="margin: 10px 0 0 10px;" class="button is-info is-outlined is-small" @click="moveTo('DoneEarlier')">
                    Flytta till gjord tidigare
                </button>
            </div>
        </div>
        <div v-else>
            <Stack space="40" align-y="top" v-if="!answer.questionnaireClosingBookId">
                <Stack v-if="NotFoundMessage != ''">
                    {{ NotFoundMessage }}
                </Stack>
                <Stack v-else>
                    <Stack>
                        <TextBase size="xlarge" weight="bolder">FE{{ answer.number }}, {{ answer.name }} </TextBase>
                    </Stack>
                    <Stack space="12">
                        <TextBase size="medium" weight="bold">Räkenskapsår</TextBase>
                        <TextBase size="small" weight="light">{{ answer.startDate }} - {{ answer.endDate }}</TextBase>
                    </Stack>
                    <Stack space="12">
                        <TextBase size="medium" weight="bold">Status</TextBase>
                        <TextBase size="small" weight="light"> {{ viewOptions.filter(item => item.value == "NotExist")[0].name }}</TextBase>
                    </Stack>
                    <Columns>
                        <button v-if="!isGlobalClientSelected" class="button is-info is-small is-rounded" @click="goback">
                            Tillbaka
                        </button>
                        <button class="button is-info is-small is-rounded" :class="[{ 'is-loading': loadingCopyBtn }]" @click="createDraft(answer)">
                            Skapa frågeformulär
                        </button>
                    </Columns>
                </Stack>
            </Stack>
            <Columns align-y="top" v-else>
                <Column :width="50">
                    <Stack space="30">
                        <Stack space="5">
                            <TextBase size="xlarge" weight="bolder">FE{{ answer.number }}, {{ answer.name }} ({{ answer.registrationNumber }})</TextBase>
                        </Stack>
                        <Stack space="10">
                            <TextBase size="medium" weight="bold">Räkenskapsår</TextBase>
                            <TextBase size="small" weight="light">{{ answer.startDate }} - {{ answer.endDate }}</TextBase>
                        </Stack>
                        <Stack space="10">
                            <TextBase size="medium" weight="bold">Inlämmnat av</TextBase>
                            <TextBase size="small" weight="light">{{ answer.individualName }}, {{ answer.updated }}</TextBase>
                        </Stack>
                        <Control v-if="answer.companyType == 5">
                            <Stack space="10">
                                <TextBase size="medium" weight="bold">
                                    Har verksamheten i bolaget ändrats på något sätt under räkenskapsåret, jämfört med tidigare år? T.ex. försäljning av varor eller tjänster av annan typ än tidigare,
                                    försäljning till nya länder etc.
                                </TextBase>
                                <TextBase size="small" weight="light">{{ answer.hasTheBusinessChanged }}</TextBase>
                            </Stack>
                            <Stack space="10">
                                <TextBase size="medium" weight="bold">Hur upplever du/ni att det gått försäljnings-/resultatmässigt för bolaget under räkenskapsåret?</TextBase>
                                <TextBase size="small" weight="light">{{ answer.salesEarningsResults }}</TextBase>
                            </Stack>
                            <Stack space="10">
                                <TextBase size="medium" weight="bold">Hur bedömer du/ni att framtiden för bolaget ser ut, försäljnings-/resultatmässigt?</TextBase>
                                <TextBase size="small" weight="light">{{ answer.futureOfTheCompany }}</TextBase>
                            </Stack>
                            <Stack space="10">
                                <TextBase size="medium" weight="bold">
                                    Är det någon ändring av ägarandelar i bolaget nu, jämfört med hur det var när du/ni skrev under årsredovisningen föregående gång
                                </TextBase>
                                <TextBase size="small" weight="light">{{ answer.hasTheBusinessChanged }}</TextBase>
                            </Stack>
                            <Stack space="10">
                                <TextBase size="small" weight="bold">Ägarandelar</TextBase>
                                <div v-for="(owner, index) in answer.owners" :key="index">
                                    <TextBase size="small" weight="light">{{ owner.name }}, {{ owner.percentage }}%</TextBase>
                                </div>
                            </Stack>
                        </Control>
                        <Stack space="10">
                            <TextBase size="medium" weight="bold">Övrigt meddelande till oss på Bolageriet</TextBase>
                            <TextBase size="small" weight="light">{{ answer.otherMessage }}</TextBase>
                        </Stack>
                        <Control v-if="answer.companyType == 5">
                            <RadioButtonList
                                :disabled="true"
                                :entries="yesNoDontKnow"
                                v-model="answer.dividend"
                                :horizontal="true"
                                label="Har du/ni som är ägare i bolaget tänkt att göra utdelning?"
                            />
                            <RadioButtonList
                                :disabled="true"
                                :entries="yesNo"
                                v-model="answer.k10"
                                :horizontal="true"
                                label="I samband med er privata deklaration måste ni rapportera in bilaga K10 till Skatteverket. Vill ni att vi hjälper er med denna samt räknar fram ert utdelningsbara belopp enligt 3:12 reglerna? Kostnaden för detta är 1500 kr/ per K10:a"
                            />
                        </Control>
                        <Checkbox label="Har ni registrerat alla kvitton för räkenskapsåret?" :disabled="true" v-model="allReceipts" />
                        <Stack space="15">
                            <TextBase size="medium" weight="bold">Bankkontoutdrag</TextBase>
                            <Stack :space="10">
                                <a v-for="(bankStatement, key) in answer.bankStatementDtos" :key="key" @click="onClickBankStatement(bankStatement.id)">
                                    <Columns space="10" align-y="center">
                                        <TextBase size="small" weight="light">{{ bankStatement.fileName }}</TextBase>
                                        <i class="far fa-file-pdf" />
                                    </Columns>
                                </a>
                            </Stack>
                        </Stack>
                        <Stack v-if="answer.companyType == 5" space="15">
                            <TextBase size="medium" weight="bold">Registreringsbevis</TextBase>
                            <Stack :space="10">
                                <a @click="onClickRegCert(answer.registrationCertificate.id)">
                                    <Columns v-if="answer.registrationCertificate" space="10" align-y="center">
                                        <TextBase size="small" weight="light">{{ answer.registrationCertificate.fileName }}</TextBase>
                                        <i class="far fa-file-pdf" />
                                    </Columns>
                                    <Columns v-else space="10" align-y="center">
                                        <TextBase size="small" weight="light">Ingen fil uppladdad!</TextBase>
                                    </Columns>
                                </a>
                            </Stack>
                        </Stack>

                        <Columns>
                            <button v-if="!isGlobalClientSelected" class="button is-info is-small is-rounded" @click="goback">
                                Tillbaka
                            </button>
                            <!-- <button class="button is-info is-small is-rounded" :disabled="disableCopyBtn" :class="[{ 'is-loading': loadingCopyBtn }]" @click="copyInformation()" style="margin-top:150px">
            kopiera
          </button> -->
                        </Columns>
                    </Stack>
                </Column>
                <Column>
                    <Columns align-y="top">
                        <Stack space="20">
                            <Checkbox v-if="answer.companyType == 5" label="Tidigare enskild firma" v-model="answer.hasPrivateFirm" @change="update" />
                            <RadioButtonList :entries="yesNo" v-model="answer.accountant" :horizontal="true" label="Har revisor" />
                            <Checkbox v-if="answer.companyType == 5" label="Revisor" v-model="answer.hasAccountant" @change="update" />
                            <Checkbox v-if="answer.companyType == 5" label="Årsbokslut K3" v-model="answer.hasK3" @change="update" />
                            <Checkbox v-if="answer.companyType == 5" label="Varulager" v-model="answer.hasInventory" @change="update" />
                            <Checkbox v-if="answer.companyType == 5" label="Investeringar" v-model="answer.hasInvestments" @change="update" />
                            <Stack space="10">
                                <TextBase size="medium" weight="bold">Adminansvarig</TextBase>
                                <DropDown v-model="answer.adminPerson" :options="admins" @input="update" />
                            </Stack>
                            <Stack space="10">
                                <TextBase size="medium" weight="bold">Ska vara klart vecka</TextBase>
                                <DropDown v-model="answer.dueWeek" :options="weeks" @input="update" />
                            </Stack>
                            <Stack space="10">
                                <TextBase size="medium" weight="bold">Status</TextBase>
                                <DropDown v-model="answer.status" :options="viewOptions" @input="update" />
                            </Stack>
                            <Stack space="10">
                                <TextBase size="medium" weight="bold">Admins anteckningar</TextBase>
                                <TextArea size="small" weight="light" v-model="answer.adminComments" style="width: 100%;" />
                            </Stack>
                            <Columns>
                                <button class="button is-info is-small is-rounded" :class="[{ 'is-loading': loadingCopyBtn }]" @click="update">
                                    Spara
                                </button>
                            </Columns>
                        </Stack>
                        <Stack space="20">
                            <Checkbox label="Bankkontoutdrag finns" v-model="answer.hasBankStatement" @change="update" />
                            <Checkbox v-if="answer.companyType == 5" label="Registreringsbevis finns" v-model="answer.hasRegistrationCertificate" @change="update" />
                            <Checkbox v-if="answer.k10 == 1" label="K10 skapad" v-model="answer.wantK10Done" @change="update" />
                            <Checkbox v-if="answer.k10 == 1" label="K10 inskickad" v-model="answer.k10SentIn" @change="update" />
                            <Checkbox label="Kontoutdrag avstämt" v-model="answer.isReconciled" @change="update" />
                            <Checkbox v-if="answer.companyType == 5" label="Årsredovisning Upprättad" v-model="answer.isEstablished" @change="update" />
                            <Checkbox v-if="answer.companyType == 5" label="Årsredovisning underskriven" v-model="answer.isSigned" @change="update" />
                            <Checkbox
                                v-if="answer.companyType == 5"
                                label="Årsredovisning registrerad hos Bolagsverket"
                                v-model="answer.hasRegisteredSwedishCompaniesRegistrationOffice"
                                @change="update"
                            />
                            <Checkbox label="Deklaration inskickad" v-model="answer.isDeclarationSubmitted" @change="update" />
                            <Checkbox label="Löpande ersättning" v-model="answer.hasOngoingCompensation" @change="update" />
                            <Checkbox label="Är fakturerad" v-model="answer.isInvoiced" @change="update" />
                        </Stack>
                    </Columns>
                </Column>
            </Columns>
        </div>
    </div>
</template>

<script>
import TransactionList from "./transactionList";
import RadioButtonList from "../../components/content/RadioButtonList";
import Checkbox from "../../components/content/CheckBox";
import RadioButtonListOld from "../../components/radioButtonList";
import Api from "./closingBook.api";
import Stack from "../../components/layout/Stack";
import Columns from "../../components/layout/Columns";
import Column from "../../components/layout/Column";
import TextBase from "../../components/content/Text";
import TextArea from "../../components/textArea";
import DropDown from "../../components/dropdown";
import Control from "../../components/layout/Control";

export default {
    components: {
        TransactionList,
        RadioButtonList,
        Checkbox,
        RadioButtonListOld,
        TextBase,
        TextArea,
        Stack,
        Columns,
        Column,
        DropDown,
        Control,
    },

    data: function() {
        return {
            listView: true,
            clients: [],
            answer: {},
            viewOptions: [
                { name: "Att skicka", value: "NotExist" },
                { name: "Utkast", value: "Draft" },
                { name: "Inskickad", value: "SentIn" },
                { name: "Pågående", value: "BeingProcessed" },
                { name: "Klar", value: "Done" },
                { name: "Gjord tidigare", value: "DoneEarlier" },
            ],
            yesNoDontKnow: [
                { id: 1, value: "Ja" },
                { id: 2, value: "Nej" },
                { id: 3, value: "Vet ej" },
            ],
            yesNo: [
                { id: 1, value: "Ja" },
                { id: 2, value: "Nej" },
            ],
            admins: [
                { value: "", name: "--- Välj ansvarig ---" },
                { value: "Christer Jungefeldt", name: "Christer Jungefeldt" },
                { value: "Erik Myrlöv", name: "Erik Myrlöv" },
                { value: "Fredrik Lindström", name: "Fredrik Lindström" },
                { value: "Isabella Silva", name: "Isabella Silva" },
                { value: "Jacob Fitger", name: "Jacob Fitger" },
                { value: "Jakob Myrlöv", name: "Jakob Myrlöv" },
                { value: "Markus Ogenborg", name: "Markus Ogenborg" },
                { value: "Martin Pettersson", name: "Martin Pettersson" },
            ],
            weeks: [
                { value: 0, name: "--- Välj vecka ---" },
                { value: 1, name: "Vecka 1 - 2/1" },
                { value: 2, name: "Vecka 2 - 9/1" },
                { value: 3, name: "Vecka 3 - 16/1" },
                { value: 4, name: "Vecka 4 - 23/1" },
                { value: 5, name: "Vecka 5 - 30/1" },
                { value: 6, name: "Vecka 6 - 6/2" },
                { value: 7, name: "Vecka 7 - 13/2" },
                { value: 8, name: "Vecka 8 - 20/2" },
                { value: 9, name: "Vecka 9 - 27/2" },
                { value: 10, name: "Vecka 10 - 6/3" },
                { value: 11, name: "Vecka 11 - 13/3" },
                { value: 12, name: "Vecka 12 - 20/3" },
                { value: 13, name: "Vecka 13 - 27/3" },
                { value: 14, name: "Vecka 14 - 3/4" },
                { value: 15, name: "Vecka 15 - 10/4" },
                { value: 16, name: "Vecka 16 - 17/4" },
                { value: 17, name: "Vecka 17 - 24/4" },
                { value: 18, name: "Vecka 18 - 1/5" },
                { value: 19, name: "Vecka 19 - 8/5" },
                { value: 20, name: "Vecka 20 - 15/5" },
                { value: 21, name: "Vecka 21 - 22/5" },
                { value: 22, name: "Vecka 22 - 29/5" },
                { value: 23, name: "Vecka 23 - 5/6" },
                { value: 24, name: "Vecka 24 - 12/6" },
                { value: 25, name: "Vecka 25 - 19/6" },
                { value: 26, name: "Vecka 26 - 26/6" },
                { value: 27, name: "Vecka 27 - 3/7" },
                { value: 28, name: "Vecka 28 - 10/7" },
                { value: 29, name: "Vecka 29 - 17/7" },
                { value: 30, name: "Vecka 30 - 24/7" },
                { value: 31, name: "Vecka 31 - 31/7" },
                { value: 32, name: "Vecka 32 - 7/8" },
                { value: 33, name: "Vecka 33 - 14/8" },
                { value: 34, name: "Vecka 34 - 21/8" },
                { value: 35, name: "Vecka 35 - 28/8" },
                { value: 36, name: "Vecka 36 - 4/9" },
                { value: 37, name: "Vecka 37 - 11/9" },
                { value: 38, name: "Vecka 38 - 18/9" },
                { value: 39, name: "Vecka 39 - 25/9" },
                { value: 40, name: "Vecka 40 - 2/10" },
                { value: 41, name: "Vecka 41 - 9/10" },
                { value: 42, name: "Vecka 42 - 16/10" },
                { value: 43, name: "Vecka 43 - 23/10" },
                { value: 44, name: "Vecka 44 - 30/10" },
                { value: 45, name: "Vecka 45 - 6/11" },
                { value: 46, name: "Vecka 46 - 13/11" },
                { value: 47, name: "Vecka 47 - 20/11" },
                { value: 48, name: "Vecka 48 - 27/11" },
                { value: 49, name: "Vecka 49 - 4/12" },
                { value: 50, name: "Vecka 50 - 11/12" },
                { value: 51, name: "Vecka 51 - 18/12" },
                { value: 52, name: "Vecka 52 - 25/12" },
            ],
            filter: "NotExist",
            allReceipts: true,
            loadingCopyBtn: false,
            NotFoundMessage: "",
        };
    },

    watch: {
        isGlobalClientSelected(newValue) {
            if (!newValue) {
                this.listView = true;
            }
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Årsredovisning");
        document.title = "Årsredovisning - Att skicka";
    },

    async created() {
        this.$waiting.start("loading");
        this.clients = await Api.getClients();
        this.$nextTick(() => {
            if (this.clients && this.isGlobalClientSelected) {
                this.rowClicked(this.selectedClient);
            }
        });
        this.$waiting.end("loading");
    },

    computed: {
        transactionsFiltered() {
            return this.clients.filter(item => item.status === this.filter);
        },

        selectedClient() {
            return this.clients.filter(item => item.clientId === this.$store.state.topBar.selectedClient.value)[0];
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },

    methods: {
        async create() {
            const data = this.transactionsFiltered.filter(item => item.check === true);
            await Api.createClosingBook(data);
            this.$toasted.show("Utkast skapade", {
                theme: "bubble",
                duration: 4000,
                position: "bottom-center",
                type: "success",
                keepOnHover: true,
                action: {
                    text: "Cancel",
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
            this.clients = [];
            this.clients = await Api.getClients();
        },

        async createDraft(client) {
            this.loadingCopyBtn = true;
            const data = this.transactionsFiltered.filter(item => item.clientId === client.clientId);
            var result = await Api.createClosingBook(data);
            this.loadingCopyBtn = false;
            this.$toasted.show("Utkast skapade", {
                theme: "bubble",
                duration: 1000,
                position: "bottom-center",
                type: "success",
                keepOnHover: true,
            });
            this.rowClicked(result[0]);
        },

        async deleteElements() {
            const data = this.transactionsFiltered.filter(item => item.check === true);
            if (confirm(`Är du säker att du vill ta bort ${data.length} förfrågningar?`)) {
                await Api.deleteClosingBook(data);
                this.$notify.success({ title: "Utfört" });
                this.clients = [];
                this.clients = await Api.getClients();
            }
        },

        async moveTo(status) {
            const data = this.transactionsFiltered.filter(item => item.check === true);
            await Api.updateStatusClosingBook(status, data);
            this.$notify.success({ title: "Utfört" });
            this.clients = [];
            this.clients = await Api.getClients();
        },

        onChangeViewOption(item) {
            document.title = "Årsredovisning - " + item.name;
            this.filter = item.value;
            this.clients.forEach(element => {
                element.check = false;
            });
        },

        async rowClicked(row) {
            if (row) {
                this.NotFoundMessage = "";
                if (row.questionnaireClosingBookId) {
                    this.answer = await Api.getAnswers(row.questionnaireClosingBookId);
                } else {
                    this.answer = {
                        number: row.number,
                        name: row.name,
                        startDate: row.startDate,
                        endDate: row.endDate,
                        status: row.status,
                        clientId: row.clientId,
                        financialYearId: row.financialYearId,
                    };
                }
            } else {
                this.NotFoundMessage = "Kan inte hittas";
            }

            this.listView = false;
        },

        async update() {
            this.loadingCopyBtn = true;
            await Api.updateClosingBook(this.answer);
            this.loadingCopyBtn = false;
            this.$toasted.show("Ändring sparad", {
                theme: "bubble",
                duration: 2000,
                position: "bottom-center",
                type: "success",
                keepOnHover: true,
            });
        },

        async goback() {
            this.clients = await Api.getClients();
            this.listView = true;
            this.answer = {};
        },

        onClickBankStatement(id) {
            Api.showBankStatement(id);
        },

        onClickRegCert(id) {
            Api.showRegistrationCertificate(id);
        },
    },
};
</script>
