<template>
    <main class="card">
        <div id="message" aria-live="assertive" />
        <form class="card-content" style="width: 400px;height: 300px;">
            <p class="title">
                Bolageriet AB
            </p>
            <div class="field">
                <button type="button" @click.prevent="back" @keydown.enter.prevent="back" aria-label="Gå tillbaka">
                    <span class="icon has-text-info">
                        <i class="fas fa-sm fa-arrow-left" aria-hidden="true" />
                    </span>
                </button>
                <span>{{ email }}</span>
            </div>
            <div class="field">
                <label class="label">Tryck på er Yubikey</label>
                <div class="control">
                    <input
                        aria-label="Tryck på er Yubi key"
                        class="input"
                        :class="{ 'is-danger': error.length > 0 }"
                        type="password"
                        id="yubikey"
                        name="yubikey"
                        ref="input"
                        v-model="yubikey"
                        autocomplete="off"
                        autocapitalize="off"
                        autocorrect="off"
                        spellcheck="false"
                        @keydown.enter.prevent="enter"
                        @keydown.esc.prevent="back"
                    />
                </div>
                <p class="help is-danger">{{ error }}</p>
            </div>
            <div class="field">
                <button class="button is-success" :disabled="showWaiting" @click.prevent="enter">
                    <span class="icon" :class="{ hide: !showWaiting }">
                        <i class="fas fa-spin fa-spinner" />
                        <label aria-live="assertive" aria-atomic="true" aria-label="Vänta du loggas in" />
                    </span>
                    <span>Logga in</span>
                </button>
            </div>
        </form>
    </main>
</template>

<script>
export default {
    props: ["email", "error"],
    data: function() {
        return {
            yubikey: "",
            //      errorText: "",
            waiting: false,
        };
    },
    mounted: function() {
        this.$refs.input.focus();
    },
    methods: {
        enter: async function() {
            if (this.yubikey !== "" && this.yubikey !== null) {
                this.waiting = true;
                this.$emit("on-login", this.yubikey);
            }
        },
        back: function() {
            this.$emit("go-back");
        },
    },
    computed: {
        showWaiting() {
            return this.waiting && this.error == "";
        },
    },
};
</script>
<style scoped>
.hide {
    display: none;
}
</style>
