<template>
    <div class="card-tail card-f-h">
        <el-tabs v-model="activeTab">
            <el-tab-pane label="Lista" name="list">
                <TaxAccountList v-if="activeTab === 'list'" />
            </el-tab-pane>
            <el-tab-pane label="Konto" name="detail" lazy>
                <TaxAccountDetail v-if="activeTab === 'detail'" />
            </el-tab-pane>
            <el-tab-pane label="Kommande" name="upcoming">
                <TaxAccountUpcoming v-if="activeTab === 'upcoming'" />
            </el-tab-pane>
            <el-tab-pane label="Kommande ny" name="upcoming_new">
                <TaxAccountUpcoming2 v-if="activeTab === 'upcoming_new'" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    components: {
        TaxAccountList: () => import("./taxAccountList/taxAccountList"),
        TaxAccountDetail: () => import("./taxAccountDetail/taxAccountDetail"),
        TaxAccountUpcoming: () => import("./taxAccountUpcoming/taxAccountUpcoming"),
        TaxAccountUpcoming2: () => import("./taxAccountUpcoming/taxAccountUpcoming2"),
    },

    data() {
        return {
            activeTab: "list",
        };
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Skattekonto");
        document.title = "Skattekonto";
    },
};
</script>
