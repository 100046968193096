<template>
    <div>
        <DateRangePickerModal :visible="dateRangePickerModalVisible" @selected="manualDateRangeSelected" @close="dateRangePickerModalVisible = false" :query="query" />

        <div class="card-tail print:hidden">
            <div class="flex justify-between border-b pb-4 mb-4">
                <div class="flex">
                    <ClientsSearch @on-selected="clientSelected" :selected="selectedClientProp" />
                    <router-link v-if="selectedClient" class="border-l pl-3 ml-3" :to="{ path: `/lopande_add/${selectedClient}`, query: $route.query }">
                        <el-button type="primary" @click="newVoucherButtonClicked">Nytt ver.</el-button>
                    </router-link>
                    <div v-else class="border-l pl-3 ml-3">
                        <el-button :disabled="true" type="primary">Nytt ver.</el-button>
                    </div>
                    <p v-if="bookingSystemType" class="ml-3">Bokföringsystem: {{ bookingSystemType }}</p>
                </div>

                <div class="flex">
                    <div class="flex items-center mr-4">
                        <p>
                            {{ selectedPeriodHeader }}
                        </p>
                    </div>
                    <div class="mr-4">
                        <Period1Select
                            ref="selectPeriod1"
                            @dateSelectionChanged="period1Selected"
                            @showDateRangePickerModal="showDateRangePickerModal"
                            :period-data="period1Data"
                            :query="query"
                            :disabled="!selectedClient"
                        />
                    </div>
                    <div>
                        <Period2Select ref="selectPeriod2" :period-data="period2Data" :disabled="!period1" />
                    </div>
                </div>
            </div>
            <div class="relative">
                <div v-if="!selectedClient" class="absolute flex w-full h-full bg-white/90" />
                <SubNavigationMenu :client-id="selectedClient" />
            </div>
        </div>
        <div v-if="selectedClient">
            <router-view :client-id="selectedClient" :selected-date="selectedDate" />
        </div>
        <div v-else class="flex flex-col items-center pt-72">
            <img src="@/assets/images/no_data.svg" class="w-32" />
            <p class="mt-4">Ingen data har valts</p>
        </div>
    </div>
</template>
<script>
import Api from "./lopande.api";
import moment from "moment";
import queryString from "query-string";

export default {
    props: {
        query: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        selectedDate() {
            return [this.query.startDate, this.query.endDate];
        },

        selectedPeriodHeader() {
            if (!this.query.startDate) return;
            return moment(this.query.startDate).format("YYYY-MM-DD") + " - " + moment(this.query.endDate).format("YYYY-MM-DD");
        },
    },

    data() {
        return {
            period1Data: [],
            period2Data: [],
            period1: null,
            period2: null,
            selectedClient: null,
            dateRangePickerModalVisible: false,
            customDateRange: [],
            bookingSystemType: null,
            projects: null,
            selectedClientProp: {},
        };
    },

    watch: {
        selectedClient(newValue, oldValue) {
            if (oldValue !== null || this.$route.meta.title == "Lopande") {
                this.$router.push("/lopande_rakenskapsar/" + this.selectedClient);
            }
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Lopande");
        document.title = "Lopande";
    },

    async created() {
        this.selectedClientProp = {
            value: this.$route.params.clientId,
        };

        this.selectedClient = this.$route.params.clientId;

        this.getPeriod1Data();
        if (this.$route.params.clientId) {
            this.bookingSystemType = await Api.getBookingSystemType(this.$route.params.clientId);
            this.projects = await Api.getProjects(this.$route.params.clientId);
        }

        this.checkPreselectedClient();
    },

    components: {
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
        Period1Select: () => import("./components/Period1Select.vue"),
        Period2Select: () => import("./components/Period2Select.vue"),
        DateRangePickerModal: () => import("./modals/LopandeDateRangePickerModal.vue"),
        SubNavigationMenu: () => import("./components/SubNavigationMenu.vue"),
    },

    methods: {
        checkPreselectedClient() {
            if (this.selectedClient) {
                return;
            }
            const { clientId } = queryString.parse(window.location.search, { sort: false });
            this.selectedClient = clientId;
        },

        newVoucherButtonClicked() {
            if (this.$route.name === "lopande_add") {
                this.$router.go(0);
            }
        },

        async getPeriod1Data() {
            this.period1Data = await Api.getPeriod1Data(this.selectedClient);

            // setTimeout(() => {
            //     this.getPeriod2Data();
            // }, 3000);
        },

        async getPeriod2Data() {
            this.period2Data = await Api.getPeriod2Data(this.selectedClient, this.selectedDate[0], this.selectedDate[1]);
        },

        async clientSelected(data) {
            this.selectedClient = data.value;
            this.bookingSystemType = await Api.getBookingSystemType(data.value);
            this.$refs.selectPeriod1.resetState();
            this.$refs.selectPeriod2.resetState();
            this.period1 = false;
            this.period1Data = [];
            this.period2Data = [];
            if (!data.value) {
                return;
            }
            this.getPeriod1Data();
        },

        period1Selected() {
            this.period1 = true;
            this.$refs.selectPeriod2.resetState();
            // setTimeout(() => {
            //     }, 3000);
            this.getPeriod2Data();
        },

        showDateRangePickerModal() {
            this.dateRangePickerModalVisible = true;
        },

        manualDateRangeSelected() {
            this.$refs.selectPeriod2.resetState();
        },
    },
};
</script>
