<template>
    <div>
        <label class="file file-label">
            <input class="file-input" type="file" name="resume" ref="files" @change="handleFileUploads()" />
            <Columns collapse-below="none" v-if="!showDeleteBtn">
                <a>Lägg till ID/LEG </a>
            </Columns>
        </label>
        <Columns v-if="showDeleteBtn">
            <div style="color: #000000; align-self: center; pointer-events: none; cursor: default;">
                {{ fileName }}
            </div>
            <div v-if="showDeleteBtn">
                <button class="button is-danger is-small is-light is-rounded" @click="DeleteImage()">
                    Ta bort
                </button>
            </div>
        </Columns>
        <span v-show="errorText != ''" class="error-text">{{ errorText }}</span>
    </div>
</template>
<script>
import Columns from "@/components/layout/Columns";
export default {
    props: {
        fileName: { type: String, default: "" },
        errorText: { type: String, default: "" },
    },
    data() {
        return {};
    },
    components: {
        Columns,
    },
    methods: {
        DeleteImage() {
            this.$emit("file-uploaded", undefined);
        },
        handleFileUploads() {
            let uploadedFiles = this.$refs.files.files;
            this.$emit("file-uploaded", uploadedFiles[0]);
        },
    },
    computed: {
        showDeleteBtn() {
            if (this.fileName) return true;
            return false;
        },
    },
};
</script>
<style lang="postcss" scoped>
.error-text {
    font-size: 14px;
    color: #ff7878;
    font-weight: 500;
    margin-top: 0px;
}
</style>
