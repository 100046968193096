import ClosingBook from "./closingBook.vue";

const routes = [
    {
        path: "/closingBook",
        name: "closingBook",
        component: ClosingBook,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
