<template>
    <div class="relative card-tail card-f-h">
        <el-tabs class="mt-4" v-model="activeTab" @tab-click="tabChanged">
            <el-tab-pane label="FAQ" name="questionTabContainer" lazy>
                <QuestionTab ref="questionTabContainer" v-if="activeTab === 'questionTabContainer'" />
            </el-tab-pane>
            <el-tab-pane label="Info" name="infoTabContainer" lazy>
                <InfoTab ref="infoTabContainer" v-if="activeTab === 'infoTabContainer'" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeTab: "questionTabContainer",
        };
    },
    components: {
        QuestionTab: () => import("./components/TabQuestionList"),
        InfoTab: () => import("./components/TabInfo.vue"),
    },
    methods: {
        tabChanged(event) {
            this.$refs[event.name]?.getAll();
        },
    },
};
</script>

<style>
.el-tabs__content {
    overflow: visible !important;
}
</style>
