import SalaryVacationPage from "./salaryVacationPage.vue";

const routes = [
    {
        path: "/salaryVacation",
        name: "salaryVacation",
        component: SalaryVacationPage,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
