<template>
    <div class="set-margin">
        <div>
            <label>
                <input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()" />
            </label>
            <div class="buttons">
                <button class="button is-info" v-on:click="addFiles()">Lägga till filer</button>
                <button class="button is-info" type="submit" v-on:click.prevent="submitFiles()">Ladda up</button>
                <div v-if="spinner" class="fas fa-spinner fa-pulse" />
                <div class="tag is-success" v-if="!isNextDisabled">
                    <span class="icon has-text-info">
                        <i class="fas fa-info-circle" />
                    </span>

                    Filer skickas in!
                </div>
            </div>
        </div>
        <div class="table-container">
            <table class="table is-striped is-narrow is-hoverable is-fullwidth" style="width: 50%; margin-top: 20px">
                <tr>
                    <th style="width: 100px">S.no</th>
                    <th style="width: 400px">Filnamn</th>
                    <th />
                </tr>
                <tr :key="key" v-for="(file, key) in files">
                    <td>{{ key + 1 }}</td>
                    <td>{{ file.name }}</td>
                    <td><span class="remove-file" v-on:click="removeFile(key)">Ta bort</span></td>
                </tr>
            </table>
        </div>
        <div class="next-btn" />
    </div>
</template>
<script>
import { mapState } from "vuex";
import Api from "../../supplierInvoice.api";

export default {
    data() {
        return {
            sNo: 1,
            isNextDisabled: true,
            spinner: false,
        };
    },

    beforeCreate() {
        this.$store.commit("supplierInvoice/resetFiles");
    },

    methods: {
        goNext() {
            this.$router.push(`/supplierInvoiceUploadedList/`);
        },

        removeFile(key) {
            this.$store.commit("supplierInvoice/removeFiles", key);
        },

        addFiles() {
            this.$refs.files.click();
        },

        handleFileUploads() {
            //validation for duplicate file select
            let uploadedFiles = this.$refs.files.files;
            for (var i = 0; i < uploadedFiles.length; i++) {
                this.$store.commit("supplierInvoice/addFiles", uploadedFiles[i]);
            }
        },

        submitFiles: async function() {
            this.spinner = true;
            let formData = new FormData();

            for (var i = 0; i < this.files.length; i++) {
                let file = this.files[i];

                formData.append("files[" + i + "]", file);
            }
            var result = await Api.postSupplierInvoiceFiles(formData);
            this.$notify.success({ title: "Utfört" });
            if (result) {
                this.spinner = false;
                this.isNextDisabled = false;
                this.$store.commit("supplierInvoice/resetFiles");
            }
        },
    },

    computed: {
        ...mapState({
            files: state => state.supplierInvoice.files,
        }),
    },
};
</script>
<style scoped>
.set-margin {
    margin-left: 3px;
}
.next-btn {
    margin-bottom: 20%;
    margin-right: 40%;
    right: 0;
    bottom: 0;
}
.remove-file {
    cursor: pointer;
    margin-left: 100px;
}
.file-details {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}
.add-file {
    display: flex;
    justify-content: space-between;
    width: 24%;
    margin-left: 40px;
    margin-bottom: 30px;
}
.page-header {
    font-size: 35px;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    margin-top: 20px;
}
input[type="file"] {
    position: absolute;
    top: -500px;
}
</style>
