<template>
    <div :style="getStyle" class="text-base">
        <slot />
    </div>
</template>
<script>
export default {
    props: {
        size: {
            type: [String, Array, Number],
            default() {
                return ["small", "medium"];
            },
        },
        weight: {
            type: [String, Array, Number],
            default() {
                return ["normal", "normal"];
            },
        },
        color: {
            type: String,
            default() {
                return "base";
            },
        },
    },
    created() {
        this.sizes = [
            { name: "xxsmall", value: 12 },
            { name: "xsmall", value: 14 },
            { name: "small", value: 16 },
            { name: "medium", value: 18 },
            { name: "large", value: 20 },
            { name: "xlarge", value: 25 },
            { name: "xxlarge", value: 35 },
        ];
        this.weights = [
            { name: "lighter", value: 300 },
            { name: "light", value: 400 },
            { name: "normal", value: 500 },
            { name: "bold", value: 600 },
            { name: "bolder", value: 700 },
        ];
    },
    computed: {
        getStyle() {
            const sizes = this.getSizeValues(this.size);
            const weights = this.getWeightsValues(this.weight);
            const color = this.getColor(this.color);

            let size = sizes.desktop;
            let weight = weights.desktop;

            switch (this.$mq) {
                case "mobile":
                    size = sizes.mobile;
                    weight = weights.mobile;
                    break;
                case "tablet":
                    size = sizes.tablet;
                    weight = weights.tablet;
                    break;
                case "laptop":
                    size = sizes.laptop;
                    weight = weights.laptop;
                    break;
                case "desktop":
                    size = sizes.desktop;
                    weight = weights.desktop;
                    break;
            }
            // console.log("size", size);
            return {
                "font-size": size + "px",
                "font-weight": weight,
                color: color,
            };
        },
    },
    methods: {
        getColor() {
            let color = "#415770";
            if (this.color === "blue") {
                color = "#14abf4";
            } else if (this.color === "comp") {
                color = "#ff7878";
            } else if (this.color === "base") {
                color = "#415770";
            } else if (this.color === "grey") {
                color = "#9ba5b1";
            } else if (this.color === "black") {
                color = "#000000";
            } else {
                return "";
            }
            return color;
        },
        getSizes(value) {
            if (isNaN(value)) {
                const result = this.sizes.find(item => item.name === value);
                return result == undefined ? 0 : result.value;
            } else {
                return Number(value);
            }
        },
        getWeights(value) {
            if (isNaN(value)) {
                const result = this.weights.find(item => item.name === value);
                return result == undefined ? 0 : result.value;
            } else {
                return Number(value);
            }
        },
        getSizeValues(value) {
            let result = { mobile: 0, tablet: 0, laptop: 0, desktop: 0 };
            if (!Array.isArray(value)) {
                const pix = this.getSizes(value);
                result.mobile = pix;
                result.tablet = pix;
                result.laptop = pix;
                result.desktop = pix;
            } else {
                if (value.length === 1) {
                    const pix = this.getSizes(value[0]);
                    result.mobile = pix;
                    result.tablet = pix;
                    result.laptop = pix;
                    result.desktop = pix;
                } else if (value.length === 2) {
                    const pix0 = this.getSizes(value[0]);
                    const pix1 = this.getSizes(value[1]);
                    result.mobile = pix0;
                    result.tablet = pix0;
                    result.laptop = pix1;
                    result.desktop = pix1;
                } else if (value.length === 4) {
                    result.mobile = this.getSizes(value[0]);
                    result.tablet = this.getSizes(value[1]);
                    result.laptop = this.getSizes(value[2]);
                    result.desktop = this.getSizes(value[3]);
                }
            }
            return result;
        },
        getWeightsValues(value) {
            let result = { mobile: 0, tablet: 0, laptop: 0, desktop: 0 };
            if (!Array.isArray(value)) {
                // console.log("getWeightsValuesd", value.length);
                const pix = this.getWeights(value);
                result.mobile = pix;
                result.tablet = pix;
                result.laptop = pix;
                result.desktop = pix;
            } else {
                if (value.length === 1) {
                    const pix = this.getWeights(value[0]);
                    result.mobile = pix;
                    result.tablet = pix;
                    result.laptop = pix;
                    result.desktop = pix;
                } else if (value.length === 2) {
                    const pix0 = this.getWeights(value[0]);
                    const pix1 = this.getWeights(value[1]);
                    result.mobile = pix0;
                    result.tablet = pix0;
                    result.laptop = pix1;
                    result.desktop = pix1;
                } else if (value.length === 4) {
                    result.mobile = this.getWeights(value[0]);
                    result.tablet = this.getWeights(value[1]);
                    result.laptop = this.getWeights(value[2]);
                    result.desktop = this.getWeights(value[3]);
                }
            }
            return result;
        },
    },
};
</script>
<style scoped>
.text-base {
    /* font-family: "futura-pt"; */
    margin-left: -0.07em;
    transform: translateY(0.41em);
}
.text-base:before {
    content: "";
    display: block;
    height: 0;
    margin-top: -0.84em;
}
</style>
