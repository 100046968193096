<template>
    <div>
        <div class="card-tail">
            <header class="text-l font-semibold mb-3">{{ header }}</header>
            <el-tabs v-model="activeComponent" @tab-click="tabClick">
                <el-tab-pane label="Allmänt" name="general" />
                <el-tab-pane label="Bokföring" name="finances" />
                <el-tab-pane label="Bank" name="bank" />
                <el-tab-pane label="Personer" name="people" />
            </el-tabs>
        </div>
        <router-view />
    </div>
</template>
<script>
import Api from "../clients/clients.api";

export default {
    data() {
        return {
            activeComponent: "ClientDetails",
            header: "",
        };
    },

    watch: {
        $route: function() {
            this.activeComponent = this.$route.meta.activeTab;
            this.getClientHeader();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
    },

    created() {
        this.getClientHeader();
        this.activeComponent = this.$route.meta.activeTab;
    },

    methods: {
        async getClientHeader() {
            this.header = await Api.getClientHeader(this.$route.params.clientId);
        },

        tabClick(event) {
            this.$router.push({ path: "/clientDetails/" + this.$route.params.clientId + "/" + event.name });
        },
    },
};
</script>
