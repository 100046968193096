<template>
    <el-row v-loading="$waiting.is('loading')">
        <el-col :span="12" v-if="data">
            <ReceiptEvents :events="events" />
            <table class="ml-2 mt-4" style="width:100%">
                <tr>
                    <td class="font-bold">Klient</td>
                    <td><el-input type="text" size="medium" v-model="data.clientName" disabled /></td>
                    <td style="width: 150px" />
                </tr>

                <tr>
                    <td class="font-bold">Kvittonummer</td>
                    <td><el-input type="text" size="medium" v-model="data.receiptNumber" disabled /></td>
                    <td />
                </tr>

                <tr>
                    <td class="font-bold">Betalningssätt</td>
                    <td>
                        <RadioButton :entries="paymentType" :horizontal="true" @change="changePaymentType" :selected-id="data.receiptBuyType" />
                    </td>
                    <td />
                </tr>

                <tr>
                    <td class="font-bold">Betalare</td>
                    <td>
                        <el-select v-model="data.individualId">
                            <el-option v-for="individual in individuals" :key="individual.value" :label="individual.label" :value="individual.value" />
                        </el-select>
                    </td>
                    <td>
                        <TextBase v-if="mlResult" size="small" weight="lighter">{{ "kort: " + mlResult.cardNumber }}</TextBase>
                    </td>
                </tr>

                <tr v-if="data.receiptBuyType == 1">
                    <td class="font-bold">Utbetald</td>
                    <td><check-box v-model="expensePaid" /></td>
                    <td />
                </tr>

                <tr v-if="expensePaid && data.receiptBuyType == 1">
                    <td class="font-bold">Betaldatum</td>
                    <td>
                        <DateInputField size="medium" placeholder="Kvittodatum" :pre-selected-value="formatDate(data.expensePaidDate)" @change="data.expensePaidDate = $event" />
                    </td>
                    <td />
                </tr>

                <tr>
                    <td class="font-bold">Kontoutdrag text</td>
                    <td><el-input type="text" v-model="data.bankStatementText" disabled /></td>
                    <td>
                        <TextBase v-if="mlResult" size="small" weight="lighter">{{ mlResult.supplierName }}</TextBase>
                    </td>
                </tr>

                <tr>
                    <td class="font-bold">Syfte</td>
                    <td><el-input type="textarea" placeholder="Syfte" v-model="data.purpose" /></td>
                    <td />
                </tr>

                <tr>
                    <td class="font-bold">Kvittodatum</td>
                    <td>
                        <DateInputField size="medium" placeholder="Kvittodatum" :pre-selected-value="formatDate(data.receiptDate)" @change="data.receiptDate = $event" />
                    </td>
                    <td>
                        <TextBase v-if="mlResult" size="small" weight="normal">{{ formatDate(mlResult.paidDate) }}</TextBase>
                    </td>
                </tr>

                <tr>
                    <td class="font-bold">Belopp</td>
                    <td><input-calc v-model="data.amount" @change="syncVoucherTable" /></td>
                    <td>
                        <TextBase v-if="mlResult" size="small" weight="normal">{{ mlResult.grossAmount + " " + mlResult.currency }}</TextBase>
                    </td>
                </tr>

                <tr>
                    <td class="font-bold">Nettobelopp</td>
                    <td><el-input type="text" value="" disabled /></td>
                    <td>
                        <TextBase v-if="mlResult" size="small" weight="normal">{{ mlResult.netAmount + " " + mlResult.currency }}</TextBase>
                    </td>
                </tr>

                <tr>
                    <td class="font-bold">Inköpsland</td>
                    <td>
                        <RadioButton :entries="countryOfPurchase" :horizontal="true" @change="changeCountryOfPurchase" :selected-id="data.receiptCountry" />
                    </td>
                    <td>
                        <TextBase v-if="mlResult" size="small" weight="normal">{{ mlResult.country }}</TextBase>
                    </td>
                </tr>

                <tr v-if="data.receiptCountry !== 24">
                    <td class="font-bold">Typ av inköp</td>
                    <td>
                        <RadioButton :entries="serviceOrObject" :horizontal="true" @change="data.costType = $event.value" :selected-id="data.costType" />
                    </td>
                    <td />
                </tr>

                <tr v-if="data.receiptCountry !== 24">
                    <td class="font-bold">{{ data.receiptCountry === 0 ? "Omvänd moms" : "Momsnummer" }}</td>
                    <td v-if="data.receiptCountry === 0">
                        <RadioButton :entries="yesNoEntries" :horizontal="true" @change="(data.reverseConstructionVat = $event.value), syncVoucherTable()" :selected-id="data.reverseConstructionVat" />
                    </td>
                    <td v-else>
                        <RadioButton :entries="reportedVat" :horizontal="true" @change="(data.receiptReportedVat = $event.value), syncVoucherTable()" :selected-id="data.receiptReportedVat" />
                    </td>
                    <td v-if="data.receiptCountry === 0">
                        <TextBase v-if="mlResult" size="small" weight="normal">{{ mlResult.reverseCharge }}</TextBase>
                    </td>
                    <td v-else />
                </tr>
                <tr>
                    <td class="font-bold">Moms</td>
                    <td>
                        <RadioButton :entries="vat" :horizontal="true" :selected-id="data.vat" @change="(data.vat = $event.value), syncVoucherTable()" />
                    </td>
                    <td>
                        <TextBase v-if="mlResult" size="small" weight="normal">{{ mlResult.vatAmount + " " + mlResult.currency }}</TextBase>
                    </td>
                </tr>

                <tr v-if="data.receiptCountry === 24">
                    <td class="font-bold">Deklarerat belopp</td>
                    <td><input-calc v-model="declaredAmount" @change="syncVoucherTable" /></td>
                    <td />
                </tr>

                <tr v-if="data.receiptCountry !== 24">
                    <td class="font-bold">Representation</td>
                    <td><check-box v-model="data.representationEnable" @change="syncVoucherTable, enableRepresentationCategory()" /></td>
                </tr>

                <tr v-if="data.representationEnable">
                    <td class="font-bold">Typ av representation</td>
                    <td>
                        <el-select class="w-full" size="medium" v-model="data.representation" @change="syncVoucherTable">
                            <el-option v-for="item in typesOfRepresentation" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                    </td>
                    <td />
                </tr>

                <tr v-if="data.representationEnable">
                    <td class="font-bold">Mat (inkl moms)</td>
                    <td><input-calc ref="mat" v-model="data.matInkMoms" @change="syncVoucherTable" /></td>
                    <td />
                </tr>
                <tr v-if="data.representationEnable">
                    <td class="font-bold">Starköl (inkl moms)</td>
                    <td><input-calc v-model="data.starkölInkMoms" @change="syncVoucherTable" /></td>
                    <td />
                </tr>
                <tr v-if="data.representationEnable">
                    <td class="font-bold">Vin/sprit (inkl moms)</td>
                    <td><input-calc v-model="data.vinSpritInkMoms" @change="syncVoucherTable" /></td>
                    <td />
                </tr>
                <tr v-if="data.representationEnable">
                    <td class="font-bold">Övrigt (inkl moms)</td>
                    <td><input-calc v-model="data.övrigtInkMoms" @change="syncVoucherTable" /></td>
                    <td />
                </tr>
                <tr v-if="data.representationEnable">
                    <td class="font-bold">Dricks</td>
                    <td><input-calc v-model="data.dricks" @change="syncVoucherTable" /></td>
                    <td />
                </tr>
                <tr v-if="data.representationEnable">
                    <td class="font-bold">Restvärde</td>
                    <td><input-calc v-model="restAmount" @change="syncVoucherTable" disabled /></td>
                    <td />
                </tr>

                <tr v-if="data.representationEnable">
                    <td class="font-bold">Deltagare</td>
                    <td>
                        <ReprensationTable :participants="participants" @delete="removeParticipant" />
                        <el-input class="mt-3" v-model="participant" placeholder="Lägg till fler deltagare" @keyup.enter.native="addParticipant">
                            <template #suffix>
                                <div class="el-input__icon cursor-pointer" style="color:green" @click="addParticipant">
                                    <i class="fa-solid fa-plus fa-xl bg-green" />
                                </div>
                            </template>
                        </el-input>
                    </td>
                    <td />
                </tr>
                <tr v-if="featureReceiptMl">
                    <td class="font-bold">Kategori</td>
                    <td>
                        <el-select
                            class="w-full"
                            size="large"
                            clearable
                            v-model="data.accountCategoryDetails.accountCategoryId"
                            placeholder="Select"
                            filterable
                            :filter-method="remoteMethod"
                            @visible-change="changeAccountCategory"
                            @change="updateCategoryVoucherRow"
                        >
                            <template v-if="data.accountCategoryDetails.accountCategoryId" slot="prefix">
                                <span :style="{ color: categoryIconColor }"><i :class="categoryIconCode" /> </span>
                            </template>

                            <el-option-group v-for="group in categoryOptions" :key="group.id" :label="group.name" @change="remoteMethod">
                                <el-option v-for="item in group.childList" :key="item.id" :label="item.name" :value="item.id">
                                    <span :style="{ color: group.icon.color }" class="relative float-left text-base pt-1 pr-2"><i :class="group.icon.code"/></span>
                                    <span>
                                        {{ item.name }}
                                        <span class="absolute right-5 text-sm text-gray-400" style="font-size: 13px">{{ item.accountPlanName }}</span>
                                    </span>
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </td>
                    <td>
                        <TextBase v-if="mlResult" size="small" weight="normal">{{ bookAccounts }}</TextBase>
                    </td>
                </tr>

                <tr>
                    <td class="font-bold">Periodisering</td>
                    <td><check-box v-model="periodize" @change="syncVoucherTable" /></td>
                </tr>

                <tr>
                    <td colspan="3" class="pt-3">
                        <VouchersTable
                            ref="vtable"
                            v-if="!isBooked"
                            :voucher-rows="voucherRowsFiltered"
                            :account-plan-items="accountPlanItems"
                            :write-comments="false"
                            :periodize="periodize"
                            :financial-years="financialYears"
                            :source-date="data.receiptDate"
                            @updateValues="updateVoucherRow"
                            @newItemCreation="createNewItemComponent"
                            @removeVoucherRow="removeVoucherRow"
                            @createVoucher="creatVoucherFromTable"
                        />

                        <div v-if="data.voucher" class="mb-2 py-10 border-b card-tail">
                            <div class="flex mb-4 pb-3 text-sm border-b">
                                <p class="w-[100px]">
                                    {{ data.voucher.number }}
                                </p>
                                <p class="w-[200px]">
                                    {{ formatDate(data.voucher.date) }}
                                </p>
                                <p class="w-[200px]">
                                    {{ formatDate(data.voucher.registerDate) }}
                                </p>
                                <p class="flex-1 wra">
                                    {{ data.voucher.text }}
                                </p>
                            </div>

                            <div class="flex bg-white sticky top-[102px] z-0">
                                <p class="font-semibold rounded w-[350px]">Konto</p>
                                <p class="font-semibold rounded w-[100px] text-right">Debet</p>
                                <p class="font-semibold rounded w-[100px] text-right mr-5">Kredit</p>
                                <p class="font-semibold rounded flex-1">Text</p>
                            </div>

                            <div v-for="item in data.voucher.transactions" :key="item.transactionId" class="flex text-sm">
                                <p class="w-[45px]">{{ item.account }}</p>
                                <p class="w-[305px]">{{ item.accountName }}</p>
                                <p class="w-[100px] text-right">
                                    {{ item.debit | swedishNumberFormat }}
                                </p>
                                <p class="w-[100px] text-right mr-2">
                                    {{ item.credit | swedishNumberFormat }}
                                </p>
                                <p class="w-[18px]">
                                    <span v-if="item.correction" class="flex items-center justify-center bg-company-blue w-4 h-4 mt-[2px] text-xs rounded-full text-white">K</span>
                                </p>
                                <p class="flex-1">
                                    {{ item.text }}
                                </p>
                            </div>
                        </div>
                    </td>
                    <td />
                </tr>

                <tr>
                    <td class="font-bold">Kommentar</td>
                    <td><el-input type="textarea" placeholder="Kommentar" v-model="data.comment" /></td>
                    <td />
                </tr>
            </table>
        </el-col>
        <el-col :span="12" style="position: sticky; top: 15px;">
            <ImageViewer :attatchments="attatchments" />
            <div style="margin-top: 15px;">
                <el-button v-if="!isBooked" size="mini" type="primary" @click="creatVoucherFromTable" :disabled="cantCreateVoucher">Registrera</el-button>
                <el-button size="mini" type="primary" @click="saveReceipt()">Spara</el-button>
                <el-button v-if="!isBooked" size="mini" type="warning" @click="supplementModal = true">Begär Komplettering</el-button>
                <el-button size="mini" type="danger" @click="discardModal = true">Makulera</el-button>
                <el-button v-if="!isBooked" size="mini" type="danger" @click="denyModal = true">Neka</el-button>
                <el-button size="mini" type="primary" @click="goToNextReceipt">Nästa</el-button>
                <el-button size="mini" type="primary" @click="goToReceiptList">Tillbaka</el-button>
            </div>
        </el-col>
        <ReceiptDialogs
            :supplement-modal="supplementModal"
            :discard-modal="discardModal"
            :deny-modal="denyModal"
            @book="bookReceipt"
            @supplement="supplementReceipt"
            @discard="discardReceipt"
            @deny="denyReceipt"
            @closeSupplementModal="supplementModal = false"
            @closeDiscardModal="discardModal = false"
            @closeDenyModal="denyModal = false"
        />
    </el-row>
</template>

<script>
import Api from "./receipts.api";
import moment from "moment";
import Enum from "./receipt.enums";
import TextBase from "../../components/content/Text.vue";
import Accruals from "@/utility/accruals";
import queryString from "query-string";
import { mapState } from "vuex";
import { calculateRepresentation } from "./receipt.representation";

export default {
    data: function() {
        return {
            reportedVat: [
                { value: 1, name: "Ja", disabled: false },
                { value: 2, name: "Nej", disabled: false },
            ],
            paymentType: [
                { value: 1, name: "Privat utlägg", disabled: false },
                { value: 2, name: "Företagskonto", disabled: false },
                { value: 3, name: "Företagskreditkort", disabled: false },
            ],
            countryOfPurchase: [
                { value: 0, name: "Sverige", disabled: false },
                { value: 20, name: "EU", disabled: false },
                { value: 22, name: "Utanför EU", disabled: false },
                { value: 24, name: "Tull", disabled: false },
            ],
            yesNoEntries: [
                { value: 0, name: "Nej", disabled: false },
                { value: 1, name: "Ja", disabled: false },
            ],
            vat: [
                { value: 1, name: "25%", disabled: false },
                { value: 2, name: "12%", disabled: false },
                { value: 3, name: "6%", disabled: false },
                { value: 4, name: "0%", disabled: false },
                { value: 5, name: "Blandad", disabled: false },
            ],
            serviceOrObject: [
                { value: 1, name: "Tjänst", disabled: false },
                { value: 2, name: "Vara", disabled: false },
            ],
            typesOfRepresentation: [
                { value: 1, label: "Frukost, fika" },
                { value: 2, label: "Lunch, middag" },
                { value: 3, label: "Ingen mat" },
                { value: 4, label: "Demonstration, visning" },
            ],
            vatSaved: false,
            events: [],
            mlResult: {},
            accountCategories: [],
            categoryOptions: [],
            lastCategorySelectedAccount: 0,
            expensePaid: false,
            data: undefined,
            contentUrl: undefined,
            imgType: 2,
            attatchments: [],
            participant: "",
            receiptId: undefined,
            declaredAmount: 0,
            selectedCompanyType: 0,
            deductable: 0,
            nonDeductable: 0,
            lastIndex: 0,
            individuals: [],
            supplementModal: false,
            discardModal: false,
            denyModal: false,
            comment: "",
            restAmount: 0,
            isBooked: false,
            voucherRows: [
                { id: 1, account: Enum.accounts.PrivateExpense, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 2, account: Enum.accounts.CompanyAccount, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 3, account: Enum.accounts.CompanyCreditCard, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 4, account: Enum.accounts.IncommingVat, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 5, account: Enum.accounts.EuVatDebet, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 6, account: Enum.accounts.EuVatCredit25, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 7, account: Enum.accounts.EuVatCredit12, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 8, account: Enum.accounts.EuVatCredit6, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 9, account: Enum.accounts.Deductable, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 10, account: Enum.accounts.NonDeductable, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 11, account: Enum.accounts.PrivateFirmPrivateExpense, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true, categoryId: null },
                { id: 12, account: null, debit: 0, credit: 0, text: "", readOnly: true, startDate: null, endDate: null, predefined: true },
            ],
            voucherRowsFiltered: [],
            categoryIconCode: "",
            categoryIconColor: "",
            enterTime: null,
            financialYears: [],
            periodize: false,
        };
    },

    components: {
        TextBase,
        "check-box": () => import(/* webpackChunkName: "CheckBox" */ "@/components/content/CheckBox"),
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
        VouchersTable: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/vouchersTable/v1/VouchersTable.vue"),
        RadioButton: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/radioButtonList.vue"),
        ImageViewer: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/ImageViewer.vue"),
        ReprensationTable: () => import(/* webpackChunkName: "RepresentationTable" */ "./RepresentationTable.vue"),
        ReceiptDialogs: () => import(/* webpackChunkName: "RepresentationTable" */ "./ReceiptDialogs.vue"),
        ReceiptEvents: () => import(/* webpackChunkName: "RepresentationTable" */ "./components/ReceiptEvents.vue"),
        "input-calc": () => import(/* webpackChunkName: "BaseInputNumericCalc" */ "@/components/baseInputNumericCalc.vue"),
    },
    beforeRouteLeave: async function(to, from, next) {
        await this.connection.invoke("unlock", this.$route.params.receiptId, 5);
        await this.connection.stop();
        next();
    },
    async created() {
        window.addEventListener("beforeunload", this.leaving);
        await this.initSignalR();
        this.enterTime = new Date();
        this.$waiting.start("loading");
        this.receiptId = this.$route.params.receiptId;
        this.data = await Api.getById(this.receiptId);
        this.vatSaved = this.data.vat != 0;
        this.mlResult = await Api.GetMlResult(this.receiptId);
        if (this.data.expensePaidDate !== null) this.expensePaid = true;
        this.attatchments = await Api.getAttatchments(this.receiptId);
        if (this.attatchments.length > 0 && this.attatchments[0].type == "image") {
            this.imgType = 2;
            this.contentUrl = "data:image/png;base64," + this.attatchments[0].data;
        } else if (this.attatchments.length > 0) {
            this.imgType = 1;
            this.contentUrl = this.attatchments[0].data;
        }
        this.assignMlResultOrDefaultValue();
        const individuals = await Api.getIndividuals(this.data.clientId);
        this.financialYears = await Api.GetOpenFinancialYears(this.data.clientId);
        this.individuals = individuals.map(individual => {
            return {
                value: individual.id,
                label: individual.name,
            };
        });
        if (this.data.costType === 0) this.data.costType = 1;
        if (!this.vatSaved) this.data.vat = this.getVat();
        if (this.data.receiptNumber) this.isBooked = true;

        this.syncVoucherTable();
        if (!this.data.representationEnable && this.featureReceiptMl && this.data.accountCategoryDetails.accountCategoryId) this.addCategoryAccount();
        this.createNewItemComponent();
        //if (this.voucherRowsFiltered[this.voucherRowsFiltered.length - 1].account != null) this.createNewItemComponent();
        this.events = await Api.GetEvents(this.receiptId);
        this.accountCategories = await Api.getAllCategories(this.data.clientCompanyType);
        this.categoryOptions = [...this.accountCategories];
        this.$waiting.end("loading");
        if (!this.data.receiptNumber) this.focusOnStartElement();
        this.showAccountNumsNotPresentInCategories();
    },

    methods: {
        async unlock() {
            await this.connection.invoke("unlock", this.$route.params.receiptId, 5);
        },
        async removeReceiptLock() {
            await this.connection.invoke("remove", this.$route.params.receiptId, 5);
        },
        async leaving(event) {
            await this.connection.invoke("unlock", this.$route.params.receiptId, 5);
            await this.connection.stop();
            event.preventDefault();
            event.returnValue = "";
        },
        async initSignalR() {
            const url = process.env.VUE_APP_SIGR_URL + "lockinghub";
            const signalR = require("@microsoft/signalr");
            var connection = new signalR.HubConnectionBuilder()
                .configureLogging(signalR.LogLevel.Error)
                .withUrl(url)
                .build();
            await connection
                .start()
                .then(() => {
                    console.log("Connected!");
                })
                .catch(function(err) {
                    return console.error(err.toString());
                });
            await connection.invoke("lock", this.$route.params.receiptId, this.userInfo.userName, 5);
            this.connection = connection;
        },
        showAccountNumsNotPresentInCategories() {
            const uniqueAccountNumbersArray = this.getUniqueCategoryAccountNums();
            if (this.mlResult.bookAccount1 == null || !this.featureReceiptMl) return "";
            const isBookAccount1Present = uniqueAccountNumbersArray.includes(this.mlResult.bookAccount1);
            const isBookAccount2Present = uniqueAccountNumbersArray.includes(this.mlResult.bookAccount2);
            const isBookAccount3Present = uniqueAccountNumbersArray.includes(this.mlResult.bookAccount3);

            let accountsNotPresent = "";

            if (!isBookAccount1Present && this.mlResult.bookAccount1 != "9999") {
                accountsNotPresent += this.mlResult.bookAccount1;
            }

            if (!isBookAccount2Present && this.mlResult.bookAccount2 != "9999") {
                if (accountsNotPresent !== "") {
                    accountsNotPresent += ", ";
                }
                accountsNotPresent += this.mlResult.bookAccount2;
            }

            if (!isBookAccount3Present && this.mlResult.bookAccount3 != "9999") {
                if (accountsNotPresent !== "") {
                    accountsNotPresent += ", ";
                }
                accountsNotPresent += this.mlResult.bookAccount3;
            }
        },

        getUniqueCategoryAccountNums() {
            const uniqueAccountNumbers = new Set();

            this.accountCategories.forEach(parent => {
                parent.childList.forEach(child => {
                    uniqueAccountNumbers.add(child.accountPlanNumber);
                });
            });

            return Array.from(uniqueAccountNumbers);
        },

        assignMlResultOrDefaultValue() {
            if (this.mlResult && this.mlResult.reverseCharge && this.data.reverseConstructionVat === -1) {
                this.data.reverseConstructionVat = this.mlResult.reverseCharge ? 1 : 0;
            } else if (this.data.reverseConstructionVat === -1) {
                this.data.reverseConstructionVat = 0;
            }

            if (this.mlResult && this.mlResult.country && this.data.receiptCountry === -1) {
                if (this.mlResult.country.toLowerCase() === "Sweden".toLowerCase()) {
                    this.data.receiptCountry = Enum.countryOfPurchase.Sweden;
                } else if (this.mlResult.country.toLowerCase() === "Eu".toLowerCase()) {
                    this.data.receiptCountry = Enum.countryOfPurchase.EU;
                } else if (this.mlResult.country.toLowerCase() === "Foreign".toLowerCase()) {
                    this.data.receiptCountry = Enum.countryOfPurchase.OutsideEU;
                }
            } else if (this.data.receiptCountry === -1) {
                this.data.receiptCountry = Enum.countryOfPurchase.Sweden;
            }
        },

        getVat() {
            if (this.data.accountCategoryDetails.accountCategoryName == "Saknas underlag") return Enum.vat["0%"];
            if (this.mlResult && this.mlResult.country == "Sweden") {
                var vat = (this.mlResult.vatAmount / this.mlResult.netAmount) * 100;
                vat = vat.toFixed(2);
                if (this.mlResult.vatAmount == 0) return Enum.vat["0%"];
                else if (vat == 25) return Enum.vat["25%"];
                else if (vat == 12) return Enum.vat["12%"];
                else if (vat == 6) return Enum.vat["6%"];
                else return Enum.vat["Mixed"];
            } else {
                return Enum.vat["25%"];
            }
        },
        changeAccountCategory() {
            this.categoryOptions = [...this.accountCategories];
        },

        enableRepresentationCategory() {
            const representationAccountCategory = this.categoryOptions.find(a => a.name == "Representation");
            const representationVal = representationAccountCategory.childList[0].id;
            this.updateCategoryVoucherRow(representationVal);
        },

        updateCategoryVoucherRow(val) {
            if (this.featureReceiptMl == true) {
                if (val === "") {
                    this.data.accountCategoryDetails.accountCategoryId = null;
                    this.voucherRowsFiltered = this.voucherRowsFiltered.filter(item => item.categoryId === null || item.categoryId === undefined);
                    return;
                }

                this.data.accountCategoryDetails.accountCategoryId = val;

                let selectedChild;
                for (const parent of this.accountCategories) {
                    const matchingChild = parent.childList.find(child => child.id === val);
                    if (matchingChild) {
                        selectedChild = matchingChild;
                        this.categoryIconCode = parent.icon.code;
                        this.categoryIconColor = parent.icon.color;
                        if (parent.name != "Representation") {
                            this.data.representationEnable = false;
                        }
                        break;
                    }
                }

                const account = selectedChild.accountPlanNumber;
                const credit = 0;
                const categoryId = val;
                let spliceCount = 0;

                this.syncVoucherTable();

                let index = this.voucherRowsFiltered.findIndex(row => row.categoryId !== null && row.categoryId !== undefined);
                if (index > -1) {
                    spliceCount = 1;
                } else {
                    for (let i = 0; i < this.voucherRowsFiltered.length; i++) {
                        if (!this.voucherRowsFiltered[i].predefined) {
                            index = i;
                            break;
                        }
                    }
                }
                this.handlePensionCategory();

                if (!this.data.representationEnable) {
                    let newLine = {
                        id: this.voucherRows.length + this.voucherRowsFiltered.length,
                        account: account,
                        debit: this.categoryAmount,
                        credit: credit,
                        text: "",
                        readOnly: true,
                        startDate: null,
                        endDate: null,
                        predefined: false,
                        categoryId: categoryId,
                    };

                    this.voucherRowsFiltered.splice(index, spliceCount, newLine);
                } else {
                    this.voucherRowsFiltered.splice(index, 1);
                }
            }
        },

        remoteMethod(query) {
            if (query !== "") {
                this.loading = true;
                setTimeout(() => {
                    this.categoryOptions = this.accountCategories
                        .map(parent => {
                            const matchingChildren = parent.childList.filter(child => {
                                return (
                                    child.name.toLowerCase().includes(query.toLowerCase()) ||
                                    child.accountPlanName.toLowerCase().includes(query.toLowerCase()) ||
                                    child.accountPlanNumber
                                        .toString()
                                        .toLowerCase()
                                        .includes(query.toLowerCase()) ||
                                    child.tags.some(tag => tag.toLowerCase().includes(query.toLowerCase()))
                                );
                            });

                            if (matchingChildren.length > 0) {
                                return {
                                    childList: matchingChildren,
                                    icon: parent.icon,
                                    id: parent.id,
                                    name: parent.name,
                                };
                            }
                        })
                        .filter(Boolean);
                }, 200);
            }
        },

        addCategoryAccount() {
            this.createNewVoucherRow({
                id: this.voucherRows.length + this.voucherRowsFiltered.length,
                account: this.data.accountCategoryDetails.account,
                debit: this.categoryAmount,
                readOnly: true,
                categoryId: this.data.accountCategoryDetails.accountCategoryId,
            });

            this.categoryIconCode = this.data.accountCategoryDetails.iconCode;
            this.categoryIconColor = this.data.accountCategoryDetails.iconColor;

            this.handlePensionCategory();
        },

        handlePensionCategory() {
            if (this.data.accountCategoryDetails.accountCategoryId != null && this.data.accountCategoryDetails.accountCategoryId === 82) {
                let vatAmount = this.calculatePensionVatPercentage(this.data.amount);

                this.createNewVoucherRow({
                    id: this.voucherRows.length + this.voucherRowsFiltered.length,
                    account: 7533,
                    debit: vatAmount,
                    categoryId: this.data.accountCategoryDetails.accountCategoryId,
                });

                this.createNewVoucherRow({
                    id: this.voucherRows.length + this.voucherRowsFiltered.length,
                    account: 2514,
                    credit: vatAmount,
                    categoryId: this.data.accountCategoryDetails.accountCategoryId,
                });

                this.voucherRowsFiltered.sort((a, b) => {
                    if (a.account === null) return 1;
                    if (b.account === null) return -1;
                    return 0;
                });
            } else {
                this.voucherRowsFiltered = this.voucherRowsFiltered.filter(row => row.account !== 2514 && row.account !== 7533);
            }
        },

        calculatePensionVatPercentage(amount) {
            return (amount * 24.26) / 100;
        },

        focusOnStartElement() {
            if (this.data.representationEnable) {
                this.$refs.mat.$el.firstChild.firstChild.focus();
            } else {
                this.$refs.vtable.$children[this.$refs.vtable.$children.length - 1].$children[0].$children[0].$el.firstChild.focus();
            }
        },

        async creatVoucherFromTable() {
            if (!this.cantCreateVoucher) {
                const confirm = await this.$dialog.title("Bekräftelse").confirm("Är du säker?");
                if (!confirm) return;
                this.bookReceipt();
            }
        },

        goToReceiptList() {
            const currentQuery = window.location.search; // Get the current query parameters
            const newURL = `/kvitton${currentQuery}`;
            window.location.href = newURL;
        },

        async goToNextReceipt() {
            const { search, status, currency, dateFrom, dateTo, clientId, test, category } = queryString.parse(window.location.search, { sort: false });
            const nextReceiptId = await Api.getNextReceipt(this.receiptId, search, status, currency, dateFrom, dateTo, clientId, test, category);
            if (nextReceiptId != -1) {
                const currentQuery = window.location.search; // Get the current query parameters
                const newURL = `/receipts/${nextReceiptId}${currentQuery}`;
                window.location.href = newURL;
            } else {
                this.goToReceiptList();
            }
        },

        async saveReceipt() {
            await Api.saveReceipt(this.receiptId, this.data);
            this.$notify.success({ title: "Kvitto sparat" });
            await this.unlock();
            await this.goToNextReceipt();
        },

        async bookReceipt() {
            this.$waiting.start("loading");

            await Api.RegisterReceipt(this.receiptId, this.data, this.voucherRowsFiltered);
            await this.removeReceiptLock();
            const sendTime = new Date();
            let duration = Math.abs(sendTime - this.enterTime) / 1000;
            await Api.RegisterHandlingTime(this.receiptId, "receiptBooked", duration);
            this.$notify.success({ title: "Kvitto registrerat" });
            this.$waiting.end("loading");
            await this.goToNextReceipt();
        },

        async supplementReceipt(comment) {
            this.supplementModal = false;
            await Api.SupplementReceipt(this.receiptId, comment);
            await this.removeReceiptLock();
            const sendTime = new Date();
            let duration = Math.abs(sendTime - this.enterTime) / 1000;
            await Api.RegisterHandlingTime(this.receiptId, "receiptSupplement", duration);
            this.$notify.success({ title: "Kompletterings förfrågan skickad" });
            await this.goToNextReceipt();
        },

        async denyReceipt(comment) {
            this.denyModal = false;
            await Api.DenyReceipt(this.receiptId, comment);
            await this.removeReceiptLock();
            const sendTime = new Date();
            let duration = Math.abs(sendTime - this.enterTime) / 1000;
            await Api.RegisterHandlingTime(this.receiptId, "receiptDenied", duration);
            this.$notify.success({ title: "Kvitto nekat" });
            await this.goToNextReceipt();
        },

        async discardReceipt(comment) {
            this.discardModal = false;
            await Api.DiscardReceipt(this.receiptId, comment);
            await this.removeReceiptLock();
            this.$notify.success({ title: "Kvitto Makulerat" });
            await this.goToNextReceipt();
        },

        addParticipant() {
            if (this.participant !== "") {
                this.data.participants.push(this.participant);
                this.participant = "";
                this.syncVoucherTable();
            }
        },

        removeParticipant(index) {
            this.data.participants.splice(index, 1);
            this.syncVoucherTable();
        },

        changePaymentType(event) {
            this.data.receiptBuyType = event.value;
            this.syncVoucherTable();
        },

        changeCountryOfPurchase(event) {
            this.data.receiptCountry = event.value;
            this.syncVoucherTable();
        },

        handleRadioButtonState() {
            if (this.data.receiptCountry === Enum.countryOfPurchase.Customs) {
                this.data.representationEnable = false;
            }

            if (this.data.receiptCountry !== Enum.countryOfPurchase.Sweden || this.data.reverseConstructionVat) {
                this.vat[4].disabled = true;
            } else {
                this.vat[4].disabled = false;
            }

            if (
                (this.data.receiptCountry === Enum.countryOfPurchase.EU && this.data.receiptReportedVat !== 1) ||
                (this.data.receiptCountry === Enum.countryOfPurchase.OutsideEU && this.data.receiptReportedVat !== 1)
            ) {
                this.vat.forEach(item => (item.disabled = true));
                this.data.vat = 4;
            } else {
                this.vat.forEach(item => (item.disabled = false));
                if (this.data.receiptCountry !== Enum.countryOfPurchase.Sweden) {
                    this.vat[4].disabled = true;
                }
            }

            if (this.data.receiptCountry === Enum.countryOfPurchase.Sweden && this.data.representationEnable) {
                this.data.vat = 4;
                this.vat.forEach(item => (item.disabled = true));
            }
        },

        syncVoucherTable() {
            this.handleRadioButtonState();
            this.data.amount = Number(this.data.amount);
            this.voucherRows.forEach(row => {
                row.credit = 0;
                row.debit = 0;
                row.readOnly = true;
            });

            if (this.data.receiptBuyType == Enum.receiptBuyTypes.PrivateExpense && this.data.clientCompanyType != Enum.companyTypes.StockCompany)
                this.voucherRows[10].credit = Number(this.data.amount.toFixed(2));
            else if (this.data.receiptBuyType == Enum.receiptBuyTypes.PrivateExpense) this.voucherRows[0].credit = Number(this.data.amount.toFixed(2));
            else if (this.data.receiptBuyType == Enum.receiptBuyTypes.CompanyAccount) this.voucherRows[1].credit = Number(this.data.amount.toFixed(2));
            else if (this.data.receiptBuyType == Enum.receiptBuyTypes.CompanyCreditCard) this.voucherRows[2].credit = Number(this.data.amount.toFixed(2));

            if (this.data.receiptCountry == Enum.countryOfPurchase.Customs) {
                if (this.data.vat == Enum.vat["25%"]) {
                    this.voucherRows[5].credit = Number((this.declaredAmount * 0.25).toFixed(2));
                    this.voucherRows[4].debit = Number((this.declaredAmount * 0.25).toFixed(2));
                } else if (this.data.vat == Enum.vat["12%"]) {
                    this.voucherRows[6].credit = Number((this.declaredAmount * 0.12).toFixed(2));
                    this.voucherRows[4].debit = Number((this.declaredAmount * 0.12).toFixed(2));
                } else if (this.data.vat == Enum.vat["6%"]) {
                    this.voucherRows[7].credit = Number((this.declaredAmount * 0.06).toFixed(2));
                    this.voucherRows[4].debit = Number((this.declaredAmount * 0.06).toFixed(2));
                }
            } else if (this.data.receiptCountry == Enum.countryOfPurchase.Sweden && !this.data.reverseConstructionVat) {
                if (this.data.vat == Enum.vat["25%"]) {
                    this.voucherRows[3].debit = Number((this.data.amount * 0.2).toFixed(2));
                    // this.voucherRows[11].debit = Number((this.data.amount * 0.8).toFixed(2));
                    // this.voucherRows[11].readOnly = false;
                } else if (this.data.vat == Enum.vat["12%"]) this.voucherRows[3].debit = Number((this.data.amount * (0.12 / 1.12)).toFixed(2));
                else if (this.data.vat == Enum.vat["6%"]) this.voucherRows[3].debit = Number((this.data.amount * (0.06 / 1.06)).toFixed(2));
                else if (this.data.vat == Enum.vat["0%"]) this.voucherRows[3].debit = 0;
                else if (this.data.vat == Enum.vat.Mixed) {
                    this.voucherRows[3].readOnly = false;
                    if (!this.vatSaved) {
                        this.voucherRows[3].debit = Number(this.mlResult.vatAmount);
                        // this.voucherRows[11].debit = Number(this.mlResult.netAmount);
                        // this.voucherRows[11].readOnly = false;
                    }
                }
            } else if (this.data.receiptCountry === Enum.countryOfPurchase.Sweden || this.data.reverseConstructionVat || this.data.receiptReportedVat == 1) {
                if (this.data.vat == Enum.vat["25%"]) {
                    this.voucherRows[5].credit = Number((this.data.amount * 0.25).toFixed(2));
                    this.voucherRows[4].debit = Number((this.data.amount * 0.25).toFixed(2));
                } else if (this.data.vat == Enum.vat["12%"]) {
                    this.voucherRows[6].credit = Number((this.data.amount * 0.12).toFixed(2));
                    this.voucherRows[4].debit = Number((this.data.amount * 0.12).toFixed(2));
                } else if (this.data.vat == Enum.vat["6%"]) {
                    this.voucherRows[7].credit = Number((this.data.amount * 0.06).toFixed(2));
                    this.voucherRows[4].debit = Number((this.data.amount * 0.06).toFixed(2));
                }
            }

            if (this.data.representationEnable) {
                let result = calculateRepresentation(
                    this.data.representation,
                    Number(this.data.övrigtInkMoms),
                    Number(this.data.matInkMoms),
                    Number(this.data.starkölInkMoms),
                    Number(this.data.vinSpritInkMoms),
                    Number(this.data.dricks),
                    this.participants.length,
                );

                this.voucherRows[8].debit = Number(result.deductable.toFixed(2));
                this.voucherRows[9].debit = Number(result.nonDeductable.toFixed(2));

                if (this.data.receiptCountry == Enum.countryOfPurchase.Sweden && !this.data.reverseConstructionVat) this.voucherRows[3].debit += Number(result.vat.toFixed(2));
                else if (this.data.receiptCountry === Enum.countryOfPurchase.Sweden || this.data.reverseConstructionVat || this.data.receiptReportedVat == 1) {
                    if (this.data.vat == Enum.vat["25%"]) {
                        this.voucherRows[5].credit += Number(result.vat.toFixed(2));
                        this.voucherRows[4].debit += Number(result.vat.toFixed(2));
                    } else if (this.data.vat == Enum.vat["12%"]) {
                        this.voucherRows[6].credit += Number(result.vat.toFixed(2));
                        this.voucherRows[4].debit += Number(result.vat.toFixed(2));
                    } else if (this.data.vat == Enum.vat["6%"]) {
                        this.voucherRows[7].credit += Number(result.vat.toFixed(2));
                        this.voucherRows[4].debit += Number(result.vat.toFixed(2));
                    }
                } else {
                    this.voucherRows[9].debit += Number(result.vat.toFixed(2));
                }
            }

            this.restAmount =
                this.data.amount - (Number(this.data.dricks) + Number(this.data.vinSpritInkMoms) + Number(this.data.övrigtInkMoms) + Number(this.data.starkölInkMoms) + Number(this.data.matInkMoms));

            let customRows = JSON.parse(JSON.stringify(this.voucherRowsFiltered)).filter(item => item.predefined === false);
            customRows = Accruals.getAccruals(customRows, this.voucherRowsFiltered.length, this.accountPlanItems, this.data.receiptDate);

            this.voucherRowsFiltered = JSON.parse(JSON.stringify(this.voucherRows)).filter(item => item.credit > 0 || item.debit > 0 || !item.readOnly);

            customRows.forEach(item => {
                this.voucherRowsFiltered.push(item);
            });

            let categoryIndex = this.voucherRowsFiltered.findIndex(row => row.categoryId !== null && row.categoryId !== undefined);
            if (categoryIndex != -1) {
                this.voucherRowsFiltered[categoryIndex].debit = this.categoryAmount;
            }
        },

        updateVoucherRow({ index, account, debit, credit, comment, startDate, endDate, categoryId }) {
            const oldStartDate = this.voucherRowsFiltered[index].startDate;
            const oldEndDate = this.voucherRowsFiltered[index].endDate;

            this.voucherRowsFiltered[index].account = account;
            this.voucherRowsFiltered[index].debit = debit;
            this.voucherRowsFiltered[index].credit = credit;
            this.voucherRowsFiltered[index].text = comment;
            this.voucherRowsFiltered[index].startDate = startDate;
            this.voucherRowsFiltered[index].endDate = endDate;
            this.voucherRowsFiltered[index].categoryId = categoryId;

            if ((startDate && endDate && (oldStartDate != startDate || oldEndDate != endDate)) || (oldStartDate && oldEndDate)) {
                this.syncVoucherTable();
            }
        },

        createNewVoucherRow({ id, account, debit = 0, credit = 0, comment = "", readOnly = false, predefined = false, startDate = null, endDate = null, categoryId }) {
            this.voucherRowsFiltered.push({
                id: id,
                account: account,
                debit: debit,
                credit: credit,
                text: comment,
                readOnly: readOnly,
                startDate: startDate,
                endDate: endDate,
                predefined: predefined,
                categoryId: categoryId,
            });
        },

        createNewItemComponent() {
            this.voucherRowsFiltered.push({
                id: this.voucherRows.length + this.voucherRowsFiltered.length,
                account: null,
                debit: 0,
                credit: 0,
                text: "",
                readOnly: false,
                startDate: null,
                endDate: null,
                predefined: false,
                categoryId: null,
            });
        },

        removeVoucherRow(index) {
            let row = this.voucherRowsFiltered[index];

            if (row.categoryId !== null && row.categoryId !== undefined) {
                this.data.accountCategoryDetails.accountCategoryId = null;
            }

            let newItems = this.voucherRowsFiltered;
            const lastRow = index === newItems.length - 1;
            newItems.splice(index, 1);
            this.voucherRowsFiltered = newItems;

            if (this.voucherRowsFiltered.filter(item => !item.readOnly).length === 0 || lastRow) {
                this.voucherRowsFiltered.push({
                    id: this.voucherRows.length + this.voucherRowsFiltered.length,
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                    readOnly: false,
                    startDate: null,
                    endDate: null,
                    predefined: false,
                });
            }
            this.syncVoucherTable();
        },

        formatDate(date) {
            return date
                ? moment
                      .utc(date)
                      .local()
                      .format("YYYY-MM-DD")
                : "";
        },
    },
    computed: {
        ...mapState({
            accountsInfo: state => state.accountPlans,
            userInfo: state => state.login,
        }),

        featureReceiptMl() {
            return this.$store.state.featureFlags.includes("receipt-ml");
        },

        categoryAmount() {
            const customRows = JSON.parse(JSON.stringify(this.voucherRowsFiltered)).filter(item => (item.readOnly === true && item.categoryId == null) || item.account == 2641);
            let debitSum = 0;
            let creditSum = 0;
            for (let i = 0; i < customRows.length; i++) {
                if (customRows[i].debit != 0) {
                    debitSum += customRows[i].debit;
                }
                if (customRows[i].credit != 0) {
                    creditSum += customRows[i].credit;
                }
            }
            return creditSum - debitSum;
        },

        participants() {
            const mappedParticipants = this.data.participants.map(participant => {
                return { name: participant };
            });
            return mappedParticipants;
        },

        accountPlanItems() {
            if (this.data.clientCompanyType == Enum.companyTypes.StockCompany) return this.stockCompanyAccountPlanItems;
            else return this.privateFirmAccountPlanItems;
        },

        stockCompanyAccountPlanItems() {
            var stockAccountPlans = this.accountsInfo.filter(a => a.companyType == 2)[0].accounts;
            return stockAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    assetAccount: item.assetAccount,
                    debtAccount: item.debtAccount,
                };
            });
        },

        privateFirmAccountPlanItems() {
            var privateFirmAccountPlans = this.accountsInfo.filter(a => a.companyType == 1)[0].accounts;
            return privateFirmAccountPlans.map(item => {
                return {
                    name: item.name,
                    value: item.value,
                    accruable: item.accruable,
                    assetAccount: item.assetAccount,
                    debtAccount: item.debtAccount,
                };
            });
        },

        debitSummary() {
            const summary = this.voucherRowsFiltered.reduce((total, amount) => {
                const result = total + amount.debit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        creditSummary() {
            const summary = this.voucherRowsFiltered.reduce((total, amount) => {
                const result = total + amount.credit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        cantCreateVoucher() {
            return this.debitSummary === 0 || this.creditSummary === 0 || this.debitSummary !== this.creditSummary;
        },
        bookAccounts() {
            if (this.mlResult.bookAccount1 == null) return "";
            return this.mlResult.bookAccount1 + ", " + this.mlResult.bookAccount2 + ", " + this.mlResult.bookAccount3;
        },
    },
};
</script>

<style scoped>
.el-input__prefix span {
    position: absolute;
    top: 9px;
    left: 3px;
}
table tr td {
    padding: 6px;
    font-size: 14px;
}
table td {
    border: 0px !important;
}
</style>
