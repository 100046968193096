const Enum = {
    labelColors: {
        accountNumber: "red",
        invoiceNumber: "yellow",
        ocrNumber: "brown",
        currency: "green",
        supplierRegistrationNumber: "green",
        supplierVatNumber: "green",
        invoiceDate: "green",
        dueDate: "purple",
        grossAmount: "blue",
        netAmount: "green",
        vatAmount: "green",
        supplierName: "green",
        customerName: "green",
        supplierAddress: "green",
        paymentType: "green",
    },
    accounts: {
        SupplierDebt: 2440,
        IncommingVat: 2641,
        EuVatDebet: 2645,
        EuVatCredit: 2614,
        RoundingAccount: 3740,
        ReminderAccount: 6991,
    },
    supplierCountry: {
        Sweden: 1,
        Europe: 20,
        Foreign: 22,
        SwedenForeign: 23,
    },
    reverseConstruction: {
        No: 0,
        Yes: 1,
    },
    momsNumber: {
        Reported: 1,
        NotReported: 2,
    },
    vatRate: {
        TwentyFive: 1,
        Twelve: 2,
        Six: 3,
        Zero: 4,
        Mixed: 5,
    },
    AccountCompanyType: {
        EnskildFirma: 1,
        Handelsbolag: 1,
        Aktiebolag: 2,
    },
    companyType: {
        None: 0,
        EnskildFirma: 1,
        Handelsbolag: 2,
        EkonomiskFörening: 3,
        Komanditbolag: 4,
        Aktiebolag: 5,
        AllmäntVissTid: 6,
        EndastFakturering: 7,
    },
    supplierInvoiceStatus: {
        0: "Utkast",
        21: "Uploaded",
        22: "Importing",
        23: "Failed_import",
        24: "To_review",
        25: "Reviewing",
        26: "Exporting",
        27: "Exported",
        28: "Failed_export",
        29: "Postponed",
        30: "Deleted",
        31: "Purged",
    },
    supplierInvoiceStatusList: {
        Utkast: 0,
        Uploaded: 21,
        Importing: 22,
        Failed_import: 23,
        To_review: 24,
        Reviewing: 25,
        Exporting: 26,
        Exported: 27,
        Failed_export: 28,
        Postponed: 29,
        Deleted: 30,
        Purged: 31,
        Confirmed: 32,
        NotSet: 100,
    },
    supplierInvoiceTabList: {
        Upload: "Ladda upp fakturor",
        Validate: "Validera",
        Register: "Fakturor att bokföra",
        WaitingOnClient: "Väntar på attest",
        WaitingToPay: "Väntar att betalas",
        Paid: "Betalda",
        Denied: "Nekade av kund",
        Deleted: "Raderade",
        AdminDenied: "Nekade av admin",
        Creditnote: "Kreditfakturor",
        ReceiptCreated: "Kvitto skapat",
    },
    CreditorInvoiceStatusNumeric: {
        0: "Utkast",
        1: "Attesteras",
        2: "VäntarBokförd",
        3: "VäntarBetalning",
        4: "Betald",
        5: "Nekad",
        6: "Kompleteras",
        7: "Kompleterad",
        8: "Deleted",
        9: "AdminNekad",
        10: "KvittoSkapat",
        11: "InväntarAMLAttest",
    },
    CreditorInvoiceStatus: {
        Utkast: 0,
        Attesteras: 1,
        VäntarBokförd: 2,
        VäntarBetalning: 3,
        Betald: 4,
        Nekad: 5,
        Kompleteras: 6,
        Kompleterad: 7,
        Deleted: 8,
        AdminNekad: 9,
        KvittoSkapat: 10,
        InväntarAMLAttest: 11,
    },
    LockedTable: {
        CreditorInvoice: 1,
        SupplierInvoiceCaptures: 2,
    },
    BgAutogiroStatus: {
        0: "Skapad",
        1: "Skickad",
        2: "Utförd",
        3: "SaknarTeckning",
        4: "Makulerad",
        5: "VäntarAttSkickas",
        6: "Avslagen",
        9: "Saknas",
        10: "Fördröjd",
    },
    PainStatus: {
        0: "Väntar",
        1: "Skickad",
        2: "Utförd",
        3: "Nekad",
        4: "Avbruten",
        99: "",
    },
};
export default Enum;
