<template>
    <main class="card">
        <form class="card-content form-card">
            <p class="title">Bolageriet AB</p>
            <div class="field">
                <button type="button" @click.prevent="back" @keydown.enter.prevent="back" aria-label="Gå tillbaka">
                    <span class="icon has-text-info">
                        <i class="fas fa-sm fa-arrow-left" aria-hidden="true" />
                    </span>
                </button>
                <span>{{ email }}</span>
            </div>
            <div class="field">
                <div class="labels-row">
                    <label class="label" for="sdfsdf" aria-label="Ange lösenord">Ange lö</label>
                    <label class="label" for="sdfsdf">senord</label>
                </div>
                <div class="control">
                    <Password id="sdfsdf" v-model="password" @on-enter="login" @on-esc="back" :focus="true" aria-labelledby="sdfsdf" />
                </div>
            </div>
            <div class="field">
                <p class="control">
                    <button class="button is-success" @click.prevent="login" aria-label="Logga in">
                        Logga in
                    </button>
                </p>
            </div>
        </form>
    </main>
</template>

<script>
import Password from "./Password";
export default {
    props: {
        email: {
            type: String,
            required: true,
        },
    },
    components: {
        Password,
    },
    data: function() {
        return {
            password: "",
        };
    },
    methods: {
        login() {
            if (this.password !== "") {
                this.$emit("on-login", this.password);
            }
        },
        back() {
            this.$emit("go-back");
        },
    },
};
</script>
<style scoped>
.form-card {
    width: 400px;
    height: 300px;
}
.labels-row {
    display: flex;
    flex-direction: row;
}
</style>
