import Shopify from "./shopify.vue";

const routes = [
    {
        path: "/shopify/:clientId?",
        name: "shopify",
        component: Shopify,
        props: route => ({ query: route.query }),
        meta: {
            forAuth: true,
            title: "Shopify",
        },
    },
];
export default routes;
