import Vue from "vue";

export default {
    getClientsList: async (pageNumber, pageSize, sortBy, sortByType, search, clientStatus, isTest) => {
        try {
            const response = await Vue.axios.get(`clients`, {
                params: {
                    pageNumber,
                    pageSize,
                    sortBy,
                    sortType: sortByType,
                    search,
                    clientStatus,
                    isTest,
                },
            });
            return response;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getClientsList: ~ e", e);
            return [];
        }
    },
    getClientHeader: async clientId => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/header`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getClientHeader: ~ e", e);
            return [];
        }
    },
    getDetails: async clientId => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getDetails: ~ e", e);
            return [];
        }
    },
    getFinance: async clientId => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/finance`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getFinance: ~ e", e);
            return [];
        }
    },
    getBank: async clientId => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/bank`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getBank: ~ e", e);
            return [];
        }
    },
    getPersons: async clientId => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/persons`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getPersons: ~ e", e);
            return [];
        }
    },
    getPersonDetails: async personId => {
        try {
            const { data } = await Vue.axios.get(`clients/persons/${personId}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getPersonDetails: ~ e", e);
            return [];
        }
    },
    getMunicipalOptions: async () => {
        try {
            const { data } = await Vue.axios.get(`clients/persons/MunicipalOptions`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getMunicipalOptions: ~ e", e);
            return [];
        }
    },
    getCountryList: async () => {
        try {
            const { data } = await Vue.axios.get(`clients/countryList`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getCountryList: ~ e", e);
            return [];
        }
    },
    getCountryOptions: async () => {
        try {
            const { data } = await Vue.axios.get(`clients/countries`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getCountryOptions: ~ e", e);
            return [];
        }
    },
    getRecommendationOptions: async () => {
        try {
            const { data } = await Vue.axios.get(`clients/recommenders`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ getRecommendationOptions: ~ e", e);
            return [];
        }
    },
    updatePerson: async ({ clientId, dto, id }) => {
        try {
            let response = await Vue.axios.put(`clients/${clientId}/persons/${id}`, {
                ...dto,
            });
            return response.status === 200 ? true : false;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ updatePerson: ~ e", e);
            throw e;
        }
    },
    saveAndCreateLogin: async ({ clientId, dto, id }) => {
        try {
            let response = await Vue.axios.put(`clients/${clientId}/saveAndCreateLogin/${id}`, {
                ...dto,
            });
            return response.status === 200 ? true : false;
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ updatePerson: ~ e", e);
            throw e;
        }
    },
    updateClientDetails: async ({ clientId, clientDetails }) => {
        try {
            await Vue.axios.put(`clients/${clientId}`, {
                ...clientDetails,
            });
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ updateClientDetails: ~ e", e);
            throw e;
        }
    },
    updateClientFinance: async ({ clientId, clientDetails }) => {
        try {
            await Vue.axios.put(`clients/${clientId}/finance`, {
                ...clientDetails,
            });
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ updateClientFinance: ~ e", e);
            throw e;
        }
    },
    updateClientBank: async ({ clientId, clientDetails }) => {
        try {
            await Vue.axios.put(`clients/${clientId}/bank`, {
                ...clientDetails,
            });
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ updateClientBank: ~ e", e);
            throw e;
        }
    },
    updateClientPermission: async (clientId, permission) => {
        try {
            await Vue.axios.put(`clients/${clientId}/autogiroPermission?permission=${permission}`);
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ updateClientPermission: ~ e", e);
            throw e;
        }
    },
    updateInvoiceNumber: async (clientId, invoiceNumber) => {
        try {
            await Vue.axios.put(`clients/${clientId}/invoiceNumber?invoiceNumber=${invoiceNumber}`);
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ updateInvoiceNumber: ~ e", e);
            throw e;
        }
    },
    createPermission: async ({ clientId, permissionDto }) => {
        try {
            return await Vue.axios.put(`clients/${clientId}/permission`, {
                ...permissionDto,
            });
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ createPermission: ~ e", e);
            throw e;
        }
    },
    deleteAuthorization: async clientId => {
        try {
            return await Vue.axios.delete(`clients/${clientId}/openBankingAuthorization`);
        } catch (e) {
            console.log("🚀 ~ file: clients.api.js ~ deleteAuthorization: ~ e", e);
            throw e;
        }
    },
};
