import VacationDebtPage from "./vacationDebtPage.vue";

const routes = [
    {
        path: "/vacationDebt",
        name: "vacationDebt",
        component: VacationDebtPage,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
