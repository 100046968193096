import SendEmail from "./sendEmail.vue";
import SendMultipleEmail from "./sendMultipleEmail.vue";
import EmailType from "./emailType.vue";
const routes = [
    {
        path: "/sendemail",
        name: "sendEmail",
        component: SendEmail,
        meta: {
            title: "Skicka epost",
            forAuth: true,
        },
    },
    {
        path: "/sendMultipleEmail",
        name: "sendMultipleEmail",
        component: SendMultipleEmail,
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/emailType",
        name: "emailType",
        component: EmailType,
        meta: {
            title: "Skicka epost",
            forAuth: true,
        },
    },
];
export default routes;
