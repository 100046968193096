import ChatConversationList from "./list/ChatConversationList.vue";
import ChatMessages from "./messages/ChatMessages.vue";

const routes = [
    {
        path: "/chatconversations",
        name: "ChatConversations",
        component: ChatConversationList,
        meta: {
            forAuth: true,
            title: "Chatbot Conversations",
        },
    },
    {
        path: "/chatconversations/:id",
        name: "ChatMessages",
        component: ChatMessages,
        meta: {
            forAuth: true,
            title: "Chatbot Messages",
        },
    },
];
export default routes;
