import Vue from "vue";
export default {
    getChatConversationsList: async (pageNumber, pageSize, search, sortBy, sortByType, startDate, endDate, clientId, individualId, showDeleted, showTest) => {
        const response = await Vue.axios.get(`chatConversations`, {
            params: {
                pageNumber,
                pageSize,
                search,
                sortBy,
                sortByType,
                startDate,
                endDate,
                clientId,
                individualId,
                showDeleted,
                showTest,
            },
        });
        return response;
    },

    getChatMessagesList: async chatConversationId => {
        const response = await Vue.axios.get(`chatConversations/${chatConversationId}`);
        return response.data;
    },
};
