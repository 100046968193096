<template>
    <div>
        <div ref="viewer" />
    </div>
</template>

<script>
import { Editor } from "@toast-ui/editor";
import "@toast-ui/editor/dist/toastui-editor.css";

export default {
    props: {
        markdown: {
            type: String,
            default: "",
        },
    },
    mounted() {
        this.viewer = Editor.factory({
            el: this.$refs.viewer,
            viewer: true,
            initialValue: this.markdown,
        });
    },
    watch: {
        markdown(newVal) {
            if (this.viewer) {
                this.viewer.setMarkdown(newVal);
            }
        },
    },
};
</script>

<style>
.toastui-editor-contents {
    p,
    blockquote,
    pre,
    del,
    table,
    strong,
    em {
        color: #415770;
        font-size: 16px;
    }
    ol > li::before {
        color: #415770 !important;
        font-weight: bold !important;
        font-size: 16px !important;
        padding-top: 3px !important;
    }
    ul > li::before {
        background-color: #415770 !important;
        font-weight: bold !important;
        font-size: 16px !important;
        margin-top: 10px !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        border-bottom: none !important;
        color: #415770;
    }
    table th {
        background-color: inherit !important;
        color: #415770 !important;
        font-weight: bold !important;
    }
    overflow-x: auto;
}

.toastui-editor-contents th p {
    font-weight: bold !important;
}
.toastui-editor-contents table td {
    vertical-align: middle !important;
    white-space: nowrap !important;
}
</style>
