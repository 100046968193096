<template>
    <Stack :space="40">
        <Stack :space="20">
            <TextBase size="xxlarge">{{ client.client }}</TextBase>
            <TextBase size="large">Organisationsnummer: {{ client.registrationNumber }}</TextBase>
            <TextBase size="large">Föregående semesterår: {{ client.previousVacationYearStartDate }} - {{ client.previousVacationYearEndDate }}</TextBase>
            <TextBase size="large">Aktuellt semesterår: {{ client.actualVacationYearStartDate }} - {{ client.actualVacationYearEndDate }}</TextBase>
        </Stack>

        <Stack :space="10">
            <TextBase size="large">Anställda:</TextBase>
            <ClientList :items="earnersMaped" :scroll="true" :search="false" :headers="headers" style="font-size: 13px;height: 500px;width: 100%;" @click="clickRow" @save="save" />
        </Stack>
    </Stack>
</template>
<script>
import Stack from "@/components/layout/Stack.vue";
import TextBase from "@/components/content/Text.vue";
import ClientList from "./salaryVacationClientList.vue";
import Api from "./salaryVacation.api.js";
export default {
    components: {
        ClientList,
        Stack,
        TextBase,
    },
    props: ["clientId"],
    data: function() {
        return {
            earners: [],
            client: {},
            pageState: 0,
            headers: [
                { name: "Namn", prop: "name" },
                { name: "Personnummer", prop: "civicNumber" },
                { name: "Intjänade föregående år", prop: "previousEarnedDays" },
                { name: "Outnyttjade föregående år", prop: "previousPaidNotTakenDays" },
            ],
        };
    },
    async created() {
        this.client = await Api.getClient(this.clientId);
        this.earners = await Api.getEarners(this.clientId);
    },
    methods: {
        async save(item) {
            const dto = {
                clientId: item.clientId,
                individualClientId: item.individualClientId,
                actualPaidDays: item.previousEarnedRestDays,
                actualSavedDays: item.previousPaidRestDays,
                actualVacationYearStartDate: this.client.actualVacationYearStartDate,
            };
            await Api.savePreparation(this.clientId, item.individualClientId, dto);
            this.$notify.success({ title: "Utfört" });
        },
        clickRow() {},
        // movePaidText(paid, taken) {
        //   const rest = paid - taken;
        //   const preText = `(${paid}-${taken})`;
        //   const postText = rest <= 5 && rest > 0 ? "--> Sparade" : "";
        //   return `${preText} = ${rest} ${postText}`;
        // },
    },
    computed: {
        earnersMaped() {
            return this.earners.map(item => ({
                name: item.name,
                clientId: item.clientId,
                individualClientId: item.individualClientId,
                civicNumber: item.civicNumber,
                previousEarnedDays: item.previousEarnedDays,
                previousEarnedTakenDays: item.previousEarnedTakenDays,
                // previousEarnedRestDaysText: item.done ? item.previousEarnedDays : `${item.previousEarnedDays - item.previousEarnedTakenDays} --> Betalda akuellt år`,
                previousEarnedRestDays: item.previousEarnedDays - item.previousEarnedTakenDays,
                previousPaidRestDays: item.previousPaidDays - item.previousPaidTakenDays,
                //previousPaidNotTakenDaysText: item.done ? item.previousPaidDays : this.movePaidText(item.previousPaidDays, item.previousPaidTakenDays),
                previousPaidDays: item.previousPaidDays,
                previousPaidTakenDays: item.previousPaidTakenDays,
                savedDays: item.savedDays,
                paidDays: item.paidDays,
                done: item.done,
                vacationDays: item.vacationDays,
            }));
        },
    },
};
</script>
