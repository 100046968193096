const routes = [
    {
        path: "/avstamning",
        name: "avstamning",
        component: () => import(/* webpackChunkName: "AvstamningView" */ "./Avstamning.vue"),
        meta: {
            forAuth: true,
        },
    },
];

export default routes;
