<template>
    <div v-if="show">
        <div v-if="contentUrl && imageType == 1">
            <Pdf :file="contentUrl" height="900px" width="100%" />
        </div>
        <div v-else>
            <zoom-img :size="2" :imageurl="contentUrl" />
        </div>
    </div>
</template>
<script>
import Pdf from "../components/pdf";
import Zooming from "../components/zoomImg";
export default {
    props: {
        contentUrl: { type: String, default: "" },
        imageType: { type: Number, required: true, default: 0 },
        show: { type: Boolean, default: false },
    },
    components: {
        Pdf,
        "zoom-img": Zooming,
    },
    created() {
        if (!this.contentUrl) {
            return "No content";
        }
    },
};
</script>
