import Vue from "vue";

export default {
    getClients: async () => {
        const response = await Vue.axios.get(`customerAccountsLedgers/clients`);
        return response.data;
    },
    getInvoices: async (clientId, params) => {
        const response = await Vue.axios.get(`customerAccountsLedgers/clients/${clientId}/invoices`, { params });
        return response.data;
    },
    getDates: async clientId => {
        const response = await Vue.axios.get(`customerAccountsLedgers/clients/${clientId}/dates`);
        return response.data;
    },
    updateLedger: async clientId => {
        const response = await Vue.axios.post(`customerAccountsLedgers/clients/${clientId}/updateLedger`);
        return response.data;
    },
};
