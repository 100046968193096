<template>
    <Stack :space="40">
        <Stack :space="20">
            <TextBase size="xxlarge">{{ employeeDebts.client }}</TextBase>
            <TextBase size="large">Anställd: {{ employeeDebts.name }}</TextBase>
            <TextBase size="large">Personnummer: {{ employeeDebts.civicNumber }}</TextBase>
        </Stack>

        <Stack :space="10">
            <TextBase size="large">Semesterskuldslista:</TextBase>
            <EmployeeList :items="employeeDebts.debts" :scroll="true" :search="false" :headers="headers" style="font-size: 13px;height: 400px;width: 100%;" />
        </Stack>
        <button class="button is-outlined" @click.prevent="back">Tillbaka</button>
    </Stack>
</template>

<script>
import Api from "./vacationDebt.api";
import Stack from "@/components/layout/Stack.vue";
import TextBase from "@/components/content/Text.vue";
import EmployeeList from "./vacationDebtEmployeeList.vue";

export default {
    props: ["clientId", "individualClientId"],
    components: {
        Stack,
        TextBase,
        EmployeeList,
    },
    data: function() {
        return {
            employeeDebts: {},
            headers: [
                { name: "Semesterår", prop: "vacationYearStartDate" },
                { name: "Datum", prop: "valueDate" },
                { name: "Dagar", prop: "days", align: "right" },
                { name: "Löneskuld", prop: "vacationSalaryDebt", align: "right" },
                { name: "Typ", prop: "vacationType" },
                { name: "Kommentar", prop: "comment" },
                { name: "Ingående balans", prop: "openingBalance" },
            ],
        };
    },
    async created() {
        this.employeeDebts = await Api.getEmployeeDebts(this.clientId, this.individualClientId);
    },
    methods: {
        back() {
            this.$emit("back");
        },
    },
};
</script>
