import Vue from "vue";

export default {
    getAll: async () => {
        try {
            const { data } = await Vue.axios.get(`yearEndDispositions`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: yearEndDisposition.api.js ~ getAll: ~ e", e);
            return [];
        }
    },

    getDetails: async id => {
        try {
            const { data } = await Vue.axios.get(`yearEndDispositions/${id}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: yearEndDisposition.api.js ~ e", e);
            return [];
        }
    },

    book: async (id, formData) => {
        try {
            await Vue.axios.post(`yearEndDispositions/${id}/book`, formData);
        } catch (e) {
            console.log("🚀 ~ file: yearEndDisposition.api.js ~ book: ~ e", e);
        }
    },
    done: async (id, formData) => {
        try {
            await Vue.axios.post(`yearEndDispositions/${id}/done`, formData);
        } catch (e) {
            console.log("🚀 ~ file: yearEndDisposition.api.js ~ book: ~ e", e);
        }
    },

    getAccounts: async clientUuid => {
        try {
            const { data } = await Vue.axios.get(`booking/accounts/${clientUuid}`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: yearEndDisposition.api.js ~ getAccounts: ~ error", error);
            return [];
        }
    },
};
