<template>
    <div>
        <div class="card-tail">
            <SearchBar @searchInputChange="searchInputChange" />
        </div>
        <div class="card-tail card-f-h">
            <List :clients-list="clientsList" @sortTypeChanged="syncDataWithQueryParameters" :client-status="clientStatus" :is-test="isTest" />
            <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalClientNumber" />
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "./clients.api.js";

export default {
    data() {
        return {
            clientsList: [],
            totalClientNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            sortBy: "",
            sortByType: "",
            query: "",
            clientStatus: "active",
            isTest: "false",
        };
    },

    components: {
        SearchBar: () => import(/* webpackChunkName: "ClientsTopBar" */ "./components/SearchBar.vue"),
        List: () => import(/* webpackChunkName: "List" */ "./components/List.vue"),
        Pagination: () => import(/* webpackChunkName: "PaginationEl" */ "../../components/paginationEl.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
        document.title = "Klienter";
    },

    created() {
        this.updateUrlAndGetAll();
        // this.getClientsList();
    },

    methods: {
        searchInputChange() {
            this.$refs.paginationComponent.updateUrl(1);
            this.updateUrlAndGetAll();
        },

        syncDataWithQueryParameters() {
            this.$refs.paginationComponent.updateUrl(1);
            this.updateUrlAndGetAll();
        },

        updateUrlAndGetAll() {
            const { pageNumber, pageSize, search, clientStatus, sortBy, sortByType, isTest } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.sortBy = sortBy || "";
            this.sortByType = sortByType || "";
            this.clientStatus = clientStatus;
            this.isTest = isTest;
            this.getClientsList();
        },

        async getClientsList() {
            this.$waiting.start("loading");
            var response = await Api.getClientsList(this.pageNumber, this.pageSize, this.sortBy, this.sortByType, this.query, this.clientStatus, this.isTest);
            this.clientsList = response.data;
            this.totalClientNumber = parseInt(response.headers["x-pagination-totalcount"]);
            this.$waiting.end("loading");
        },
    },
};
</script>
