<template>
    <div>
        <div class="card-tail"><ClientsSearch @on-selected="clientSelected" /></div>
        <el-table class="p-4 rounded" :data="tableData" style="width: 100%" v-loading="$waiting.is('loading')" @current-change="routeToForm" :row-style="tableRowClassName">
            <el-table-column prop="clientNumber" width="110" label="Klientnummer" />
            <el-table-column prop="clientName" label="Klient" width="220" />
            <el-table-column prop="supplierName" label="Leverantör" width="220" />
            <el-table-column prop="invoiceNumber" label="Fakturanummer" />
            <el-table-column prop="amount" width="120" label="Belopp" />
            <el-table-column prop="invoiceDate" width="140" label="Fakturadatum" sortable />
            <el-table-column prop="registeredBy" width="140" label="Registrerad av" />
            <el-table-column prop="registeredDate" width="140" label="Registreringsdatum" />
            <el-table-column prop="approvedBy" width="120" label="Godkänd av" />
            <el-table-column prop="approvedDate" width="120" label="Godkänd datum" />
            <el-table-column
                prop="status"
                label="Status"
                :filters="[
                    { text: 'Utkast', value: 'Utkast' },
                    { text: 'InväntarAMLAttest', value: 'InväntarAMLAttest' },
                    { text: 'Attesteras', value: 'Attesteras' },
                    { text: 'VäntarBokförd', value: 'VäntarBokförd' },
                    { text: 'VäntarBetalning', value: 'VäntarBetalning' },
                    { text: 'Betald', value: 'Betald' },
                    { text: 'Nekad', value: 'Nekad' },
                    { text: 'Kompleteras', value: 'Kompleteras' },
                    { text: 'Kompleterad', value: 'Kompleterad' },
                    { text: 'Deleted', value: 'Deleted' },
                    { text: 'AdminNekad', value: 'AdminNekad' },
                    { text: 'KvittoSkapat', value: 'KvittoSkapat' },
                ]"
                :filter-method="filterByStatus"
            />
        </el-table>
    </div>
</template>
<script>
import Api from "../supplierInvoice.api";

export default {
    data() {
        return {
            tableData: [],
        };
    },

    watch: {
        isGlobalClientSelected(newValue) {
            if (!newValue) {
                this.getHighValueSupplierInvoices();
            }
        },
    },

    components: {
        ClientsSearch: () => import("@/components/clientsSearch.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Leverantörsfaktura");
        document.title = "Leverantörsfaktura";
    },

    async created() {
        this.getHighValueSupplierInvoices();
    },

    methods: {
        async getHighValueSupplierInvoices() {
            this.$waiting.start("loading");
            this.tableData = await Api.getHighValueSupplierInvoices(this.globalSelectedClientId);
            this.$waiting.end("loading");
        },

        filterByStatus(value, row) {
            return row.status === value;
        },

        async clientSelected(data) {
            if (data) {
                this.tableData = await Api.getHighValueSupplierInvoices(data.value);
            } else {
                this.tableData = await Api.getHighValueSupplierInvoices();
            }
        },

        routeToForm(row) {
            if (row.version != 0) {
                this.$router.push(`/supplierInvoice?docId=` + row.recordId);
            }
        },

        tableRowClassName({ row }) {
            if (row.version === 0) {
                return { background: "#F8F8F8" };
            }
            return { cursor: "pointer" };
        },
    },

    computed: {
        globalSelectedClientId() {
            return this.isGlobalClientSelected ? this.$store.state.topBar.selectedClient.value : "";
        },

        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
