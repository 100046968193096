var render, staticRenderFns
import script from "./Stack.vue?vue&type=script&lang=js&"
export * from "./Stack.vue?vue&type=script&lang=js&"
import style0 from "./Stack.vue?vue&type=style&index=0&id=7f98b922&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f98b922",
  null
  
)

export default component.exports