import Vue from "vue";

export default {
    sendLogin: async loginDto => {
        const response = await Vue.axios.post(`login/yubikey`, loginDto);
        return response.data;
    },

    getClients: async () => {
        try {
            const response = await Vue.axios.get(`booking/clients`);
            return response.data;
        } catch (error) {
            console.log("🚀 ~ file: login.api.js ~ line 19 ~ getClients: ~ error", error);
            return [];
        }
    },
    getAccountPlans: async () => {
        const response = await Vue.axios.get(`booking/accountPlans`);
        return response.data;
    },
    getFeatureFlags: async () => {
        const response = await Vue.axios.get(`login/featureFlags`);
        return response.data;
    },
};
