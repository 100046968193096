<template>
    <!-- <div class="control has-icons-left"> -->
    <div class="control">
        <div class="select" :class="{ 'is-loading': loading }" style="width: 100%">
            <select v-model="selectedLocal" :disabled="loading" @change.prevent="changed" style="width: 100%">
                <option v-if="hasRequestText" disabled value="">{{ requestText }}</option>
                <option :key="key" v-for="(option, key) in options" :value="option.value">
                    {{ option.name }}
                </option>
            </select>
            <!-- <div class="icon is-small is-left">
        <i class="fas fa-list" />
      </div> -->
        </div>
        <p class="help is-danger">{{ error }}</p>
    </div>
</template>

<script>
export default {
    props: ["options", "state", "requestText", "value", "error"],
    data: function() {
        return {
            selectedLocal: this.value,
        };
    },
    methods: {
        changed: function(e) {
            this.selectedLocal = e.target.value;
            this.$emit("on-change", e.target.value);
            this.$emit("input", e.target.value);
        },
    },
    watch: {
        value: {
            immediate: true,
            handler() {
                this.selectedLocal = this.value;
            },
        },
    },
    computed: {
        loading: function() {
            return this.state === "loading";
        },
        hasRequestText: function() {
            return this.requestText !== undefined && this.requestText !== "";
        },
    },
};
</script>
