import Vue from "vue";

export default {
    // getClients: async () => {
    //     try {
    //         const response = await Vue.axios.get(`booking/clients`);
    //         return response.data;
    //     } catch (error) {
    //         console.log("🚀 ~ file: lopande.api.js ~ line 9 ~ getClients: ~ error", error);
    //         return [];
    //     }
    // },

    getPeriod1Data: async clientUuid => {
        try {
            const { data } = await Vue.axios.get(`booking/periods/${clientUuid}`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ line 9 ~ getPeriod1Data: ~ error", error);
            return [];
        }
    },

    getPeriod2Data: async (clientUuid, startDate, endDate) => {
        try {
            const { data } = await Vue.axios.get(`booking/months/${clientUuid}`, {
                params: {
                    startDate,
                    endDate,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ line 18 ~ getPeriod2Data: ~ error", error);
            return [];
        }
    },

    getAccounts: async clientUuid => {
        try {
            const { data } = await Vue.axios.get(`booking/accounts/${clientUuid}`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ line 44 ~ getAccounts: ~ error", error);
            return [];
        }
    },

    createVoucher: async (clientId, payload) => {
        try {
            return await Vue.axios.post(`clients/${clientId}/bookingVouchers`, payload);
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ line 60 ~ createVoucher: ~ error", error);
            return error;
        }
    },

    createVoucherTransactionLink: async (clientId, payload) => {
        console.log("clientId", clientId);
        console.log("payload", payload);
        try {
            return await Vue.axios.post(`project/${clientId}/link`, payload);
        } catch (error) {
            return error;
        }
    },

    editVoucher: async ({ clientId, voucherId, date, text, transactions }) => {
        try {
            await Vue.axios.put(`clients/${clientId}/bookingVouchers/${voucherId}`, {
                date,
                text,
                transactions,
            });
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ line 60 ~ createVoucher: ~ error", error);
            return [];
        }
    },

    getSeries: async () => {
        try {
            const { data } = await Vue.axios.get(`booking/series`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ line 70 ~ getSeries: ~ error", error);
            return [];
        }
    },

    getVouchers: async ({ clientId, startDate, endDate, number, account, date, registerDate, text, amount, pageSize, pagenumber }) => {
        try {
            const response = await Vue.axios.get(`clients/${clientId}/bookingVouchers`, {
                // const response = await Vue.axios.get(`clients/b0f9071b-c386-4998-b450-761d46515016/bookingVouchers`, {
                params: {
                    startDate,
                    endDate,
                    number,
                    account,
                    date,
                    registerDate,
                    text,
                    amount,
                    pageSize,
                    pagenumber,
                },
            });
            return response;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ line 80 ~ getVouchers: ~ error", error);
            return [];
        }
    },

    getVoucherDetails: async ({ clientId, voucherId }) => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/bookingVouchers/${voucherId}`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ line 103 ~ getVoucherDetails: ~ error", error);
            return [];
        }
    },

    getHuvudbok: async ({ clientId, startDate, endDate }) => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/bookingLedgers`, {
                params: {
                    startDate,
                    endDate,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ getHuvudbok: ~ error", error);
            return [];
        }
    },

    getRakenskapsar: async ({ clientId }) => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/bookingFinancialYears`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ getRakenskapsar: ~ error", error);
            return [];
        }
    },

    deleteRakenskapsar: async (clientId, voucherId) => {
        try {
            await Vue.axios.delete(`clients/${clientId}/bookingFinancialYears/${voucherId}/vouchers/all`);
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ getRakenskapsar: ~ error", error);
            return false;
        }
    },

    copyRakenskapsar: async (clientId, voucherId) => {
        try {
            const response = await Vue.axios.post(`clients/${clientId}/bookingFinancialYears/${voucherId}/vouchers/copy`);
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getResultatrakning: async ({ clientId, startDate, endDate }) => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/booking-results`, {
                params: {
                    startDate,
                    endDate,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ getResultatrakning: ~ error", error);
            return [];
        }
    },

    getResultatbalances: async ({ clientId, startDate, endDate }) => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/booking-balances`, {
                params: {
                    startDate,
                    endDate,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ getResultatbalances: ~ error", error);
            return [];
        }
    },

    getAccountDetails: async ({ clientId, accountNumber, startDate, endDate }) => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/bookingLedgers/${accountNumber}`, {
                params: {
                    startDate,
                    endDate,
                },
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ getAccountDetails: ~ error", error);
            return [];
        }
    },

    uploadFile: async ({ clientId, voucherId, fileContent, fileType, fileName }) => {
        try {
            const data = await Vue.axios.post(`clients/${clientId}/bookingVouchers/${voucherId}/documents`, {
                fileContent,
                fileType,
                fileName,
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ uploadFile: ~ error", error);
            return false;
        }
    },

    deleteFile: async ({ clientId, voucherId, documentId }) => {
        try {
            await Vue.axios.delete(`clients/${clientId}/bookingVouchers/${voucherId}/documents/${documentId}`);
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ deleteFile: ~ error", error);
            return [];
        }
    },

    toggleCloseStatus: async ({ clientId, financialYearId, closed }) => {
        try {
            await Vue.axios.post(`clients/${clientId}/bookingFinancialYears/${financialYearId}/close-state`, { closed });
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ toggleCloseStatus: ~ error", error);
            return false;
        }
    },

    toggleOpeningBalanceStatus: async ({ clientId, financialYearId, openingBalanceStatus }) => {
        try {
            await Vue.axios.post(`clients/${clientId}/bookingFinancialYears/${financialYearId}/opening-balance`, { openingBalanceStatus });
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ toggleOpeningBalanceStatus: ~ error", error);
            return false;
        }
    },

    getBookingSystemType: async clientId => {
        try {
            const { data } = await Vue.axios.get(`clients/${clientId}/booking-system`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ getBookingSystemType: ~ error", error);
            throw error;
        }
    },

    getProjects: async clientId => {
        try {
            console.log("🚀 - file: lopande.api.js:266 - getProjects: - clientId", clientId);
            // const { data } = await Vue.axios.get(`project/${clientId}`);
            const data = [];
            return data;
        } catch (error) {
            throw error;
        }
    },

    calculatePeriodAmount: async ({ clientId, amount, costAccount, startDate, stopDate }) => {
        try {
            const { data } = await Vue.axios.post(`clients/${clientId}/accruals`, {
                costAccount,
                amount,
                startDate,
                stopDate,
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: lopande.api.js ~ calculatePeriodAmount: ~ error", error);
            throw error;
        }
    },
};
