import AccountPlans from "./AccountPlans.vue";

const routes = [
    {
        path: "/accountPlans",
        name: "accountPlans",
        component: AccountPlans,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
