import Vue from "vue";

export default {
    getAccountPlans: async () => {
        try {
            const { data } = await Vue.axios.get(`account-plans`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountPlans.api.js ~ getAccountPlans: ~ e", e);
            return [];
        }
    },

    getAccountsByPlan: async planId => {
        try {
            const { data } = await Vue.axios.get(`account-plans/${planId}/accounts`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountPlans.api.js ~ getAccountsByPlan: ~ e", e);
            return [];
        }
    },

    getAccountPlansTypes: async () => {
        try {
            const { data } = await Vue.axios.get(`account-plans/account-types`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountPlans.api.js ~ getAccountPlansTypes: ~ e", e);
            return [];
        }
    },

    getSubLedgerTypes: async () => {
        try {
            const { data } = await Vue.axios.get(`account-plans/subledger-types`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountPlans.api.js ~ getSubLedgersTypes: ~ e", e);
            return [];
        }
    },

    deleteItem: async ({ planId, itemId }) => {
        try {
            await Vue.axios.delete(`account-plans/${planId}/accounts/${itemId}`);
        } catch (e) {
            console.log("🚀 ~ file: accountPlans.api.js ~ deleteItem: ~ e", e);
            return false;
        }
    },

    getDetails: async ({ planId, itemId }) => {
        try {
            const { data } = await Vue.axios.get(`account-plans/${planId}/accounts/${itemId}`);
            return data;
        } catch (e) {
            console.log("🚀 ~ file: accountPlans.api.js ~ getDetails: ~ e", e);
            return false;
        }
    },

    updateItem: async ({ planId, itemId, formData }) => {
        try {
            await Vue.axios.put(`account-plans/${planId}/accounts/${itemId}`, {
                ...formData,
            });
        } catch (e) {
            console.log("🚀 ~ file: accountPlans.api.js ~ update: ~ e", e);
            throw e;
        }
    },

    create: async ({ planId, formData }) => {
        try {
            await Vue.axios.post(`account-plans/${planId}/accounts`, {
                ...formData,
            });
        } catch (e) {
            console.log("🚀 ~ file: accountPlans.api.js ~ create: ~ e", e);
            throw e;
        }
    },
};
