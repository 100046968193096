export default {
    created() {
        this.sizes = [
            { name: "gutter", value: 24 },
            { name: "xxsmall", value: 4 },
            { name: "xsmall", value: 8 },
            { name: "small", value: 12 },
            { name: "medium", value: 20 },
            { name: "large", value: 32 },
            { name: "xlarge", value: 48 },
            { name: "xxlarge", value: 96 },
        ];
    },
    methods: {
        getPixels(value) {
            if (isNaN(value)) {
                const result = this.sizes.find(item => item.name === value);
                return result == undefined ? 0 : result.value;
            } else {
                return Number(value);
            }
        },
        getValues(value) {
            let result = { mobile: 0, tablet: 0, laptop: 0, desktop: 0 };
            if (!Array.isArray(value)) {
                const pix = this.getPixels(value);
                result.mobile = pix;
                result.tablet = pix;
                result.laptop = pix;
                result.desktop = pix;
            } else {
                if (value.length === 1) {
                    const pix = this.getPixels(value[0]);
                    result.mobile = pix;
                    result.tablet = pix;
                    result.laptop = pix;
                    result.desktop = pix;
                } else if (value.length === 2) {
                    const pix0 = this.getPixels(value[0]);
                    const pix1 = this.getPixels(value[1]);
                    result.mobile = pix0;
                    result.tablet = pix0;
                    result.laptop = pix1;
                    result.desktop = pix1;
                } else if (value.length === 4) {
                    result.mobile = this.getPixels(value[0]);
                    result.tablet = this.getPixels(value[1]);
                    result.laptop = this.getPixels(value[2]);
                    result.desktop = this.getPixels(value[3]);
                }
            }
            return result;
        },
    },
};
