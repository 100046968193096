import Lopande from "./Lopande.vue";
const routes = [
    {
        path: "/lopande",
        name: "lopande",
        component: Lopande,
        props: route => ({ query: route.query }),
        meta: {
            forAuth: true,
            title: "Lopande",
        },
        children: [
            {
                path: "/lopande_add/:clientId?",
                name: "lopande_add",
                component: () => import(/* webpackChunkName: "LopandeNewVoucher" */ "./VouchersNew/VoucherNew"),
                meta: {
                    title: "+ Ver",
                },
            },
            {
                path: "/lopande_huvudbok/:clientId?",
                name: "lopande_huvudbok",
                props: route => ({ query: route.query }),
                component: () => import(/* webpackChunkName: "LopandeHuvudbok" */ "./Huvudbok/LopandeHuvudbok"),
                meta: {
                    title: "HB",
                },
            },
            {
                path: "/lopande_verifikationer/:clientId?",
                name: "lopande_verifikationer",
                props: route => ({ query: route.query }),
                component: () => import(/* webpackChunkName: "LopandeVerifikationer" */ "./Verifikationer/LopandeVerifikationer"),
                meta: {
                    title: "Ver",
                },
            },
            {
                path: "/lopande_resultatrakning/:clientId?",
                name: "lopande_resultatrakning",
                props: route => ({ query: route.query }),
                component: () => import(/* webpackChunkName: "LopandeResultatrakning" */ "./Resultatrakning/LopandeResultatrakning"),
                meta: {
                    title: "RR",
                },
            },
            {
                path: "/lopande_balansrakning/:clientId?",
                name: "lopande_balansrakning",
                component: () => import(/* webpackChunkName: "LopandeBalansrakning" */ "./Balansrakning/LopandeBalansrakning"),
                meta: {
                    title: "BR",
                },
            },
            {
                path: "/lopande_rakenskapsar/:clientId?",
                name: "lopande_rakenskapsar",
                component: () => import(/* webpackChunkName: "LopandeRakenskapsar" */ "./Rakenskapsar/LopandeRakenskapsar"),
                meta: {
                    title: "Rä",
                },
            },
            {
                path: "/lopande_export/:clientId?",
                name: "lopande_export",
                component: () => import(/* webpackChunkName: "LopandeExport" */ "./Export/LopandeExport"),
                meta: {
                    title: "Export",
                },
            },
            {
                path: "/lopande_import/:clientId?",
                name: "lopande_import",
                component: () => import(/* webpackChunkName: "LopandeImport" */ "./Import/LopandeImport"),
                meta: {
                    title: "Import",
                },
            },
            {
                path: "/lopande_accrual/:clientId?",
                name: "lopande_accrual",
                component: () => import(/* webpackChunkName: "LopandeAccrual" */ "./Accrual/LopandeAccrual"),
                meta: {
                    title: "Periodisering",
                },
            },
            {
                path: "/lopande_bilagor/:clientId?",
                name: "lopande_bilagor",
                component: () => import(/* webpackChunkName: "LopandeBilagor" */ "./Bilagor/LopandeBilagor"),
                meta: {
                    title: "Bilagor",
                },
            },
        ],
    },
];
export default routes;
