import Vue from "vue";

export default {
    getClientNameAndEmail: async clientId => {
        try {
            const response = await Vue.axios.get(`factorings/${clientId}/clientNameAndEmail`);
            return response.data;
        } catch (error) {
            console.error("API error:", error);
            return {};
        }
    },
    getClientsFactoringDecisionsList: async clientId => {
        try {
            const response = await Vue.axios.get(`factorings/${clientId}`);
            return response.data;
        } catch (e) {
            console.error("API error: getClientsFactoringDecisionsList", e);
            return {};
        }
    },
    getClientFactoringDecisionNotes: async clientId => {
        try {
            const response = await Vue.axios.get(`factorings/${clientId}/notes`);
            return response.data;
        } catch (e) {
            console.error("API error: getClientFactoringDecisionNotes", e);
            return [];
        }
    },
    getClientsFactoringList: async (pageNumber, pageSize, search, sortBy, sortByType, clientName, clientNr, status) => {
        try {
            const response = await Vue.axios.get(`factorings`, {
                params: {
                    pageNumber,
                    pageSize,
                    sortBy,
                    sortType: sortByType,
                    search,
                    clientName,
                    clientNr,
                    status,
                },
            });
            return response;
        } catch (e) {
            console.error("API error: getClientsFactoringList", e);
            return {};
        }
    },
    getCounts: async (search, currency, clientId, startDate, endDate) => {
        try {
            const { data } = await Vue.axios.get(`factorings/counts`, {
                params: {
                    search,
                    currency,
                    startDate,
                    endDate,
                    clientId,
                },
            });
            return data;
        } catch (error) {
            console.error("API error: getCounts", error);
            return [];
        }
    },
    getInvoiceCounts: async (search, currency, clientId, startDate, endDate) => {
        try {
            const { data } = await Vue.axios.get(`factorings/invoiceCounts`, {
                params: {
                    search,
                    currency,
                    startDate,
                    endDate,
                    clientId,
                },
            });
            return data;
        } catch (error) {
            console.error("API error: getInvoiceCounts", error);
            return [];
        }
    },

    getClientHeader: async clientId => {
        try {
            const { data } = await Vue.axios.get(`factorings/${clientId}/header`);
            return data;
        } catch (e) {
            console.error("API error: getClientHeader", e);
            return {};
        }
    },

    getFirstDraftOrUpdateInfo: async (clientId, factoringDecisionId) => {
        try {
            const { data } = await Vue.axios.get(`factorings/draft/${clientId}/${factoringDecisionId}`);
            return data;
        } catch (e) {
            console.error("API error: getFirstDraftOrUpdateInfo", e);
            return {};
        }
    },

    getCreditInfoForClient: async (clientId, factoringDecisionId) => {
        try {
            const { data } = await Vue.axios.get(`factorings/draft/${clientId}/${factoringDecisionId}/getCreditInfoClient`);
            return data;
        } catch (e) {
            console.error("API error: getCreditInfoForClient", e);
            return {};
        }
    },

    createNewDecision: async clientId => {
        try {
            const { data } = await Vue.axios.post(`factorings/draft/${clientId}/newDecision`);
            return data;
        } catch (e) {
            console.error("API error: createNewDecision", e);
            return {};
        }
    },

    updateSpecificInfo: async ({ clientId, factoringDecisionId, decisionType }) => {
        try {
            const { data } = await Vue.axios.put(`factorings/draft/${clientId}/${factoringDecisionId}/${decisionType}/updateSpecific`);
            return data;
        } catch (e) {
            console.error("API error: updateSpecificInfo", e);
            return {};
        }
    },

    saveComment: async ({ clientId, formData, factoringDecisionId, decisionData }) => {
        try {
            const { data } = await Vue.axios.post(`factorings/draft/${clientId}/comment`, {
                Comment: formData.comment,
                DecisionType: formData.decisionType,
                FactoringDecisionId: factoringDecisionId,
                DecisionData: decisionData,
            });
            return data;
        } catch (e) {
            console.error("API error: saveComment", e);
            throw e;
        }
    },

    getComments: async (clientId, factoringDecisionId, decisionType, comment) => {
        try {
            const { data } = await Vue.axios.get(`factorings/draft/${clientId}/getcomments`, {
                params: {
                    factoringDecisionId,
                    decisionType,
                    comment,
                },
            });
            return data;
        } catch (e) {
            throw e;
        }
    },

    signDecision: async ({ clientId, factoringDecisionId, nameAndEmail, signRequestData, yesOrNo }) => {
        try {
            const saveData = {
                FactoringDecisionId: factoringDecisionId,
                DecisionData: signRequestData,
                NameAndEmail: nameAndEmail,
                YesOrNo: yesOrNo,
            };

            var response = await Vue.axios.post(`factorings/draft/${factoringDecisionId}/${clientId}/sign`, saveData);
            return response;
        } catch (e) {
            console.error("API error: signDecision", e);
        }
    },

    deleteSignDecision: async ({ clientId, factoringDecisionId }) => {
        try {
            var response = await Vue.axios.delete(`factorings/draft/${factoringDecisionId}/${clientId}/deletesign`);
            return response;
        } catch (e) {
            console.error("API error: deleteSignDecision", e);
            return {};
        }
    },

    updateClientOverruled: async (clientId, factoringOverruled) => {
        try {
            await Vue.axios.put(`factorings/draft/${clientId}/overrule`, {
                FactoringEnabled: factoringOverruled,
            });
        } catch (e) {
            console.error("API error: updateClientOverruled", e);
            return {};
        }
    },

    getInvoicesForFactoringDecisionId: async (clientId, factoringDecisionId, invoiceId) => {
        try {
            const response = await Vue.axios.get(`factorings/${clientId}/${factoringDecisionId}/${invoiceId}/getInvoices`);
            return response.data;
        } catch (error) {
            console.error("API error: getInvoicesForFactoringDecisionId", error);
            return {};
        }
    },

    getAwaitingApprovalInvoicesForAllClients: async clientId => {
        try {
            const response = await Vue.axios.get(`factorings/${clientId}/getAwaitingApprovalInvoices`);
            return response.data;
        } catch (error) {
            console.error("API error: getAwaitingApprovalInvoicesForAllClients", error);
            return {};
        }
    },

    getOtherStatusInvoicesForAllClients: async (clientId, pageNumber, pageSize, search, sortBy, sortByType, clientName, clientNr, status) => {
        try {
            const response = await Vue.axios.get(`factorings/${clientId}/getOtherStatusInvoices`, {
                params: {
                    pageNumber,
                    pageSize,
                    sortBy,
                    sortType: sortByType,
                    search,
                    clientName,
                    clientNr,
                    status,
                },
            });
            return response;
        } catch (error) {
            console.error("API error: getOtherStatusInvoicesForAllClients", error);
            return {};
        }
    },

    getDraftInvoiceInfo: async (clientId, invoiceId) => {
        try {
            const { data } = await Vue.axios.get(`factorings/${clientId}/${invoiceId}/getDraftInvoiceInfo`);
            return data;
        } catch (e) {
            console.error("API error: getDraftInvoiceInfo", e);
            return {};
        }
    },

    getInvoiceInfo: async (clientId, invoiceId) => {
        try {
            const { data } = await Vue.axios.get(`factorings/${clientId}/${invoiceId}/getInvoiceInfo`);
            return data;
        } catch (e) {
            console.error("API error: getInvoiceInfo", e);
            return {};
        }
    },
    updateInputFields: async ({ clientId, draftInvoiceId, fields }) => {
        try {
            var response = await Vue.axios.put(`factorings/${clientId}/${draftInvoiceId}/updateCustomerFields`, fields);

            return response.data;
        } catch (error) {
            console.error("Error updating input fields:", error);
            return { success: false, error };
        }
    },

    saveCommentInvoicePage: async ({ clientId, formData, factoringInvoiceDecisionId, decisionData }) => {
        try {
            const response = await Vue.axios.post(`factorings/${clientId}/invoiceComment`, {
                Comment: formData.comment,
                DecisionType: formData.decisionType,
                FactoringInvoiceDecisionId: factoringInvoiceDecisionId,
                DecisionData: decisionData,
            });
            return response;
        } catch (e) {
            console.error("Error creating comment:", e);
            throw e;
        }
    },

    updateRetrievedCustomerInfo: async ({ draftInvoiceId, grossAmount, factoringInvoiceDecisionId }) => {
        try {
            const response = await Vue.axios.put(`factorings/${draftInvoiceId}/${grossAmount}/${factoringInvoiceDecisionId}/customerInformation`);
            return response;
        } catch (e) {
            console.error("Api error: updateRetrievedCustomerInfo", e);
            return {};
        }
    },

    saveAdditionalComment: async ({ clientId, factoringInvoiceDecisionId, additionalComment }) => {
        try {
            await Vue.axios.put(`factorings/${clientId}/${factoringInvoiceDecisionId}/${additionalComment}/additionalComment`);
        } catch (e) {
            console.error("Api error: saveAdditionalComment", e);
            throw e;
        }
    },

    saveCommission: async ({ clientId, draftInvoiceId, commissionPercentage, factoringInvoiceDecisionId }) => {
        try {
            await Vue.axios.put(`factorings/${clientId}/${draftInvoiceId}/${commissionPercentage}/${factoringInvoiceDecisionId}/commission`);
        } catch (e) {
            console.error("Api error: saveCommission", e);
            throw e;
        }
    },

    saveFactoringInvoiceDocumentBlobs: async ({ clientId, draftInvoiceId, filesData }) => {
        try {
            const formData = new FormData();
            filesData.forEach(file => {
                formData.append("files", file.raw); // Use 'files' as the key
            });

            const { response } = await Vue.axios.post(`factorings/${clientId}/${draftInvoiceId}/invoiceBlob`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            return response;
        } catch (e) {
            console.error("Api error: saveFactoringInvoiceDocumentBlobs", e);
            throw e;
        }
    },

    approveInvoice: async ({ clientId, factoringInvoiceDecisionId, draftInvoiceId, details }) => {
        const saveData = {
            FactoringDecisionId: factoringInvoiceDecisionId,
            DecisionData: details,
        };
        const response = await Vue.axios.put(`factorings/${clientId}/${factoringInvoiceDecisionId}/${draftInvoiceId}/approve`, saveData);
        return response.data;
    },

    denyInvoice: async ({ factoringInvoiceDecisionId, details }) => {
        const response = await Vue.axios.post(`factorings/${factoringInvoiceDecisionId}/deny`, details);
        return response.data;
    },

    getRedFlagCustomers: async () => {
        try {
            const response = await Vue.axios.get(`factorings/redFlagCustomers`);
            return response.data;
        } catch (e) {
            console.error("API error: getRedFlagCustomers", e);
            return [];
        }
    },

    saveCustomer: async formData => {
        try {
            const response = await Vue.axios.post(`factorings/addManualCustomer`, formData);
            return response.data;
        } catch (e) {
            console.error("API error: saveCustomer", e);
            return [];
        }
    },

    saveInvoiceDate: async ({ invoiceDate, draftInvoiceId }) => {
        const invoiceDateChanges = {
            InvoiceDate: invoiceDate,
            DraftInvoiceId: draftInvoiceId,
        };
        var response = await Vue.axios.put(`factorings/saveDates`, invoiceDateChanges);
        return response.data;
    },
};
