import Faq from "./Faq.vue";

const routes = [
    {
        path: "/faq",
        name: "faq",
        component: Faq,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
