import Vue from "vue";
export default {
    getKycByClientList: async () => {
        const response = await Vue.axios.get(`kycByClient/kycByClientList`);
        return response.data;
    },
    getListByClientId: async clientId => {
        const response = await Vue.axios.get(`kycByClient/list/${clientId}`);
        return response.data;
    },
    getkycByClientIndividualList: async clientId => {
        const response = await Vue.axios.get(`kycByClient/kycByClientIndividualList?clientId=${clientId}`);
        return response.data;
    },
    kycByClientDetails: async clientId => {
        const response = await Vue.axios.get(`kycByClient/kycByClientDetails?clientId=${clientId}`);
        return response.data;
    },
    clientDetails: async clientId => {
        const response = await Vue.axios.get(`kycByClient/clientDetails?clientId=${clientId}`);
        return response.data;
    },
    snicode: async clientId => {
        const response = await Vue.axios.get(`kycByClient/sniCode?clientId=${clientId}`);
        return response.data;
    },
    copyData: async clientId => {
        const response = await Vue.axios.get(`kycByClient/copyInformation?clientId=${clientId}`);
        return response.data;
    },
    getCountryList: async () => {
        try {
            const { data } = await Vue.axios.get(`clients/countryList`);
            return data;
        } catch (e) {
            return [];
        }
    },
    getKycStatusCount: async () => {
        try {
            const { data } = await Vue.axios.get(`kycByClient/statusCounts`);
            return data;
        } catch (e) {
            return [];
        }
    },
    getListByClientNew: async clientId => {
        try {
            const { data } = await Vue.axios.get(`kycByClient?clientId=${clientId}`);
            return data;
        } catch (e) {
            return [];
        }
    },
    getListByStatus: async status => {
        if (!status) {
            return [];
        }
        try {
            const { data } = await Vue.axios.get(`kycByClient?kycByClientStatus=${status}`);
            return data;
        } catch (e) {
            return [];
        }
    },
    getListByClientStatus: async (clientId, status) => {
        try {
            const { data } = await Vue.axios.get(`kycByClient?clientId=${clientId}&kycByClientStatus=${status}`);
            return data;
        } catch (e) {
            return [];
        }
    },
    sendKycQuestionnaire: async clientIds => {
        try {
            const response = await Vue.axios.post(`kycByClient/sendQuestionnaire`, clientIds);
            return response.data;
        } catch (e) {
            return [];
        }
    },
    getClientKycData: async clientId => {
        try {
            const response = await Vue.axios.get(`kycByClient/${clientId}/companyInfo`);
            return response.data;
        } catch (e) {
            return [];
        }
    },
    getClientKycOwners: async clientId => {
        try {
            const response = await Vue.axios.get(`kycByClient/${clientId}/owners`);
            return response.data;
        } catch (e) {
            return [];
        }
    },
    approveClientKyc: async clientId => {
        try {
            const response = await Vue.axios.put(`kycByClient/${clientId}/approve`);
            return response.data;
        } catch (e) {
            return [];
        }
    },
    updateKycStatus: async (clientId, status) => {
        try {
            const response = await Vue.axios.put(`kycByClient/${clientId}/kycStatus?kycStatus=${status}`);
            return response.data;
        } catch (e) {
            return [];
        }
    },
    changeClientKycRiskGrade: async (clientId, riskGradeManual, comment) => {
        try {
            const response = await Vue.axios.put(`kycByClient/${clientId}/riskGrade`, {
                riskGradeManual: riskGradeManual,
                comment: comment,
            });
            return response.data;
        } catch (e) {
            return [];
        }
    },
    saveOwnerInfo: async (clientId, tempData) => {
        var response = await Vue.axios.post(`kycByClient/${clientId}/owner`, tempData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response.data;
    },
};
