import Vue from "vue";
import VueRouter from "vue-router";
import ConsiliationRoutes from "../pages/conciliation/conciliation.routes.js";
import TaxAccountRoutes from "../pages/taxAccount/taxAccount.routes.js";
import ClosingBookRoutes from "../pages/closingBook/closingBook.routes.js";
import AnnualReportRoutes from "../pages/annualReport/annualReport.routes.js";
import LoginRoutes from "../pages/login/login.routes.js";
import SupplierInvoiceRoutes from "../pages/supplierInvoice/supplierInvoice.routes.js";
import Onboarding from "../pages/onboarding/onboarding.routes.js";
import Email from "../pages/epost/email.routes.js";
import KycByClient from "../pages/kycByClient/kycByClient.routes.js";
import CustomerAccountsLedger from "../pages/customerAccountsLedger/customerAccountsLedger.routes.js";
import SalaryVacation from "../pages/salaryVacation/salaryVacation.routes.js";
import VacationDebt from "../pages/vacationDebt/vacationDebt.routes.js";
import Invoices from "../pages/invoices/invoices.routes.js";
import Inpayments from "../pages/inpayments/inpayments.routes";
import BankFiles from "../pages/bank_files/bank_files.routes.js";
import Dashboard from "../pages/dashboard/dashboard.routes.js";
import Avstamning from "../pages/avstamning/avstamning.routes.js";
import AttAtgarda from "../pages/attAtgarda/att_atgarda.routes.js";
import Kvitton from "../pages/kvitton/kvitton.routes.js";
import Lopande from "../pages/lopande/lopande.routes.js";
import YearEndDisposition from "../pages/yearEndDisposition/yearEndDisposition.routes.js";
import K10Declaration from "../pages/k10_declaration/k10_declaration.routes.js";
import AccountPlans from "../pages/accountPlans/accountPlans.routes.js";
import ClientDetails from "../pages/clients/clients.routes.js";
import Loggar from "../pages/loggar/loggar.routes.js";
import Suppliers from "../pages/suppliers/suppliers.routes.js";
import AccountCategories from "../pages/accountCategories/accountCategories.routes.js";
import Documents from "../pages/documents/documents.routes.js";
import AccountFiles from "../pages/account_files/account_files.routes.js";
import Shopify from "../pages/shopify/shopify.routes.js";
import FactoringRoutes from "../pages/factoring/factoring.routes.js";
import ChatAgent from "../pages/chatAgent/chatAgent.routes.js";
import Faq from "../pages/Faq/faq.routes.js";

Vue.use(VueRouter);

var allRoutes = [];

// eslint-disable-next-line no-undef
allRoutes = [
    ...ConsiliationRoutes,
    ...SupplierInvoiceRoutes,
    ...TaxAccountRoutes,
    ...Onboarding,
    ...Email,
    ...KycByClient,
    ...ClosingBookRoutes,
    ...AnnualReportRoutes,
    ...CustomerAccountsLedger,
    ...SalaryVacation,
    ...VacationDebt,
    ...Invoices,
    ...Inpayments,
    ...BankFiles,
    ...Dashboard,
    ...Avstamning,
    ...AttAtgarda,
    ...Kvitton,
    ...Lopande,
    ...YearEndDisposition,
    ...K10Declaration,
    ...AccountPlans,
    ...ClientDetails,
    ...Loggar,
    ...Suppliers,
    ...AccountCategories,
    ...Documents,
    ...AccountFiles,
    ...Shopify,
    ...FactoringRoutes,
    ...ChatAgent,
    ...Faq,
];

var routes = [
    ...LoginRoutes,
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "AppLayout" */ "@/pages/AppLayout.vue"),
        meta: {
            forAuth: true,
        },
        children: allRoutes,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.forAuth)) {
        if (!Vue.auth.isAuthenticated()) {
            next({ path: "/login" });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
