import Inpayments from "./Inpayments.vue";

const routes = [
    {
        path: "/inpayments",
        name: "inpayments",
        component: Inpayments,
        meta: {
            title: "Inbetalningar",
            forAuth: true,
        },
    },
    {
        path: "/inpayments/:obTransactionId",
        name: "inpayment_details",
        component: () => import(/* webpackChunkName: "InpaymentDetails" */ "./InpaymentDetails.vue"),
        meta: {
            title: "Inbetalningar",
            forAuth: true,
        },
    },
];
export default routes;
