<template>
    <Stack :space="20">
        <Columns align-y="top">
            <Column>
                <Stack :space="30">
                    <Stack :space="10"><Heading level="2" color="comp"> Verksamhetsbeskrivning </Heading></Stack>
                    <Stack :space="10">
                        <Columns>
                            <Column>
                                <Stack :space="10">
                                    <TextBase size="medium" weight="bold">Uppskattad omsättning 12 mån.</TextBase>
                                    <TextBase size="small" weight="light">{{ getEnum.turnOverOptions[companyInfo.onboardingClients.expectedTurnover] }}</TextBase>
                                </Stack>
                            </Column>
                            <Column align="right">
                                <Stack :space="10">
                                    <TextBase size="medium" weight="bold">Svaren inhämtade:</TextBase><TextBase size="small" weight="light">{{ companyInfo.onboardingClients.createDate }}</TextBase>
                                </Stack>
                            </Column>
                        </Columns>
                    </Stack>
                    <Stack :space="10">
                        <TextBase size="medium" weight="bold">Verksamhetsbeskrivning</TextBase>
                        <TextBase size="small" weight="light">{{ companyInfo.onboardingClients.businessOverview }}</TextBase>
                    </Stack>
                    <Stack :space="10">
                        <TextBase size="medium" weight="bold">Var och vilka är företagskunder</TextBase>
                        <TextBase size="small" weight="light" :key="key + 'B'" v-for="(option, key) in customerTypes" :value="option.value" v-show="option.show">{{ option.name }}</TextBase>
                        <TextBase size="small" weight="light" v-bind:key="Id + option.Id + 'C'" v-for="(option, Id) in getCustomerCountries"> -{{ option.name }} </TextBase>
                    </Stack>
                    <Stack :space="10">
                        <TextBase size="medium" weight="bold">Hur säljs produkterna/tjänsterna</TextBase>
                        <TextBase size="small" weight="light" v-bind:key="Id" v-for="(option, Id) in sellingOptions" v-show="option.show">
                            {{ option.name }}
                        </TextBase>
                        <TextBase size="small" weight="light">{{ companyInfo.onboardingClients.sellingMethodDescription }}</TextBase>
                    </Stack>
                    <Stack :space="10">
                        <TextBase size="medium" weight="bold">Kontanthantering</TextBase>
                        <TextBase size="small" weight="light">{{ getEnum.yesNoOption[companyInfo.onboardingClients.cashHandling] }}</TextBase>
                    </Stack>
                    <Stack :space="10">
                        <TextBase size="medium" weight="bold">I vilka länder finns leverantörerna.</TextBase>
                        <TextBase size="small" weight="light">{{ getEnum.yesNoOption[companyInfo.onboardingClients.supplierInSweden] }}</TextBase>
                        <TextBase size="small" weight="light" v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in getsSupplierCountries"> -{{ option.name }} </TextBase>
                    </Stack>
                </Stack>
            </Column>
            <Column />
        </Columns>
        <Stack :space="10">
            <Columns :space="5" align-y="top">
                <Column :width="5"><TextBase size="small" weight="bolder">Sni</TextBase></Column>
                <Column :width="15"><TextBase size="small" weight="bolder">Beskrivning</TextBase></Column>
                <Column :width="10"><TextBase size="small" weight="bolder">Risk</TextBase></Column>
                <Column :width="10"><TextBase size="small" weight="bolder">Kontantintensiv</TextBase></Column>
            </Columns>
            <Control v-bind:key="Id + option.Id + 'D'" v-for="(option, Id) in sniCode">
                <Columns :space="5" align-y="top">
                    <Column :width="5">
                        <TextBase size="small" weight="light">{{ option.sniCode }}</TextBase>
                    </Column>
                    <Column :width="15">
                        <TextBase size="small" weight="light">{{ option.description }}</TextBase>
                    </Column>
                    <Column :width="10">
                        <TextBase size="small" weight="light">{{ getEnum.riskYesOrNo[option.risk] }}</TextBase>
                    </Column>
                    <Column :width="10">
                        <TextBase size="small" weight="light">{{ option.cashIntensive }}</TextBase>
                    </Column>
                </Columns>
            </Control>
        </Stack>
        <button class="button is-info is-small is-rounded" @click="goback()">
            Tillbaka
        </button>
    </Stack>
</template>
<script>
import moment from "moment";
import "moment/locale/se";
import Stack from "../../../components/layout/Stack.vue";
import Heading from "../../../components/content/Heading.vue";
import Columns from "../../../components/layout/Columns.vue";
import Column from "../../../components/layout/Column.vue";
import Control from "../../../components/layout/Control.js";
import TextBase from "../../../components/content/Text.vue";
import Enum from "../onboarding.enum.js";

import Api from "../onboarding.api.js";
export default {
    props: ["onboardClientId", "companyInfo"],
    components: {
        Stack,
        Heading,
        Columns,
        Column,
        TextBase,
        Control,
    },
    watch: {
        companyInfo: {
            immediate: true,
            async handler() {
                if (this.companyInfo.onboardClientAdmin != null) this.sniCode = await Api.getSniCodeData(this.companyInfo.onboardClientAdmin.sniCodeJson);

                this.companyInfo.onboardingClients.createDate = moment(String(this.companyInfo.onboardingClients.createDate)).format("YYYY-MM-DD");
            },
        },
    },
    data() {
        return {
            operationsData: {},
            sniCode: [],
            turnOverOptions: [
                { id: 1, value: "Under en halv miljon kronor" },
                { id: 2, value: "Mellan en halv miljon och en miljon kronor" },
                { id: 3, value: "Mellan en miljon och tre miljoner kronor" },
                { id: 4, value: "Över tre miljoner kronor" },
            ],
        };
    },
    methods: {
        goback() {
            this.$router.push(`/onboardingList/`);
        },
    },
    computed: {
        getsSupplierCountries() {
            let supplierArr = [];
            if (this.companyInfo != undefined && this.companyInfo.onboardingCountryDetails != undefined) {
                this.companyInfo.onboardingCountryDetails.forEach(country => {
                    if (country.onboardCountryType == 1) {
                        var obj = {};
                        obj["Id"] = country.countryId;
                        obj["name"] = country.country.nameSe;
                        supplierArr.push(obj);
                    }
                });
            }
            return supplierArr;
        },
        sellingOptions() {
            let sellingOptions = [
                { name: "Personlig kontakt", show: this.companyInfo.onboardingClients.sellingMethodShop },
                { name: "Hemsida", show: this.companyInfo.onboardingClients.sellingMethodInternet },
                { name: "Annat", show: this.companyInfo.onboardingClients.sellingMethodOther },
            ];
            return sellingOptions;
        },
        getEnum() {
            return Enum;
        },
        getCustomerCountries() {
            let customerArr = [];
            if (this.companyInfo.onboardingCountryDetails != undefined && this.companyInfo.onboardingCountryDetails != undefined) {
                this.companyInfo.onboardingCountryDetails.forEach(country => {
                    if (country.onboardCountryType == 2) {
                        var obj = {};
                        obj["Id"] = country.countryId;
                        obj["name"] = country.country.nameSe;
                        customerArr.push(obj);
                    }
                });
            }
            return customerArr;
        },
        customerTypes() {
            let a = [
                { name: "Privatpersoner i Sverige", show: this.companyInfo.onboardingClients.customerSwedenPrivatePerson },
                { name: "Privatpersoner inom EU/ESS", show: this.companyInfo.onboardingClients.customerPrivateWithinEu },
                { name: "Privatpersoner utanför EU/ESS", show: this.companyInfo.onboardingClients.customerPrivateOutsideEu },
                { name: "Företag i Sverige", show: this.companyInfo.onboardingClients.customerCompanyInSweden },
                { name: "Företag inom EU-ESS", show: this.companyInfo.onboardingClients.customerCompanyWithinEu },
                { name: "Företag utanför EU/ESS", show: this.companyInfo.onboardingClients.customerCompanyOutsideEu },
            ];
            return a;
        },
    },
};
</script>
