import OnboardingList from "./OnboardingList/onboardingList.vue";
import onboardingForm from "./OnboardingForm/onboardingForm.vue";
const routes = [
    {
        path: "/onboardingList",
        name: "onboardingList",
        component: OnboardingList,
        meta: {
            forAuth: true,
        },
    },
    {
        path: "/onboardingForm",
        name: "onboardingForm",
        component: onboardingForm,
        meta: {
            forAuth: true,
        },
    },
];
export default routes;
