<template>
    <div class="card-tail card-f-h">
        <Table :items-data="filteredData" />
    </div>
</template>
<script>
import Api from "./yearEndDisposition.api";

export default {
    components: {
        Table: () => import(/* webpackChunkName: "YearEndDispositionTable" */ "./components/YearEndDispositionTable"),
    },

    data() {
        return {
            itemsData: [],
            filteredData: [],
        };
    },

    watch: {
        isGlobalClientSelected: function() {
            this.getFilteredData();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Bokslutsdisposition");
        document.title = "Bokslutsdisposition";
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.itemsData = await Api.getAll();
            this.getFilteredData();
        },

        getFilteredData() {
            if (this.isGlobalClientSelected) {
                this.filteredData = this.itemsData.filter(item => item.clientId == this.$store.state.topBar.selectedClient.value);
            } else {
                this.filteredData = this.itemsData;
            }
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
