import Vue from "vue";
import qs from "qs";

export default {
    createCreditorInvoice: async (id, dto, booking) => {
        const response = await Vue.axios.post(`documents/${id}/creditorInvoice`, {
            clientId: dto.clientId,
            creditorId: dto.creditorId,
            invoiceDate: dto.invoiceDate,
            dueDate: dto.dueDate,
            ocrNumber: dto.ocrNumber,
            invoiceNumber: dto.invoiceNumber,
            currency: dto.currency,
            bankAccountNumber: dto.bankAccountNumber,
            bankGiro: dto.bankGiro,
            plusGiro: dto.plusGiro,
            clearing: dto.clearing,
            iban: dto.iban,
            bicSwift: dto.bicSwift,
            grossAmount: Number(dto.grossAmount),
            originalGrossAmount: Number(dto.originalGrossAmount),
            originalNetAmount: Number(dto.originalNetAmount),
            originalVatAmount: Number(dto.originalVatAmount),
            reminderFee: Number(dto.reminderFee),
            supplierVatNumber: dto.supplierVatNumber,
            supplierAddress: dto.supplierAddress,
            supplierCountry: dto.supplierCountry,
            reverseConstruction: dto.reverseConstruction,
            momsNumber: dto.momsNumber,
            vatRate: dto.vatRate,
            paymentType: dto.paymentType,
            carVat: dto.carVat,
            creditNote: true,
            comment: dto.comment,
            booking: booking != null ? booking : null,
        });
        return response;
    },
    sendForAttest: async (id, dto, booking) => {
        const response = await Vue.axios.post(`documents/${id}/attest`, {
            clientId: dto.clientId,
            creditorId: dto.creditorId,
            invoiceDate: dto.invoiceDate,
            dueDate: dto.dueDate,
            ocrNumber: dto.ocrNumber,
            invoiceNumber: dto.invoiceNumber,
            currency: dto.currency,
            bankAccountNumber: dto.bankAccountNumber,
            bankGiro: dto.bankGiro,
            plusGiro: dto.plusGiro,
            clearing: dto.clearing,
            iban: dto.iban,
            bicSwift: dto.bicSwift,
            grossAmount: Number(dto.grossAmount),
            originalGrossAmount: Number(dto.originalGrossAmount),
            originalNetAmount: Number(dto.originalNetAmount),
            originalVatAmount: Number(dto.originalVatAmount),
            reminderFee: Number(dto.reminderFee),
            supplierVatNumber: dto.supplierVatNumber,
            supplierAddress: dto.supplierAddress,
            supplierCountry: dto.supplierCountry,
            reverseConstruction: dto.reverseConstruction,
            momsNumber: dto.momsNumber,
            vatRate: dto.vatRate,
            paymentType: dto.paymentType,
            carVat: dto.carVat,
            creditNote: dto.creditNote,
            comment: dto.comment,
            booking: booking != null ? booking : null,
        });
        return response;
    },
    CreateReceipt: async (id, dto, booking) => {
        const response = await Vue.axios.post(`documents/${id}/receipt`, {
            clientId: dto.clientId,
            creditorId: dto.creditorId,
            invoiceDate: dto.invoiceDate,
            dueDate: dto.dueDate,
            ocrNumber: dto.ocrNumber,
            invoiceNumber: dto.invoiceNumber,
            currency: dto.currency,
            bankAccountNumber: dto.bankAccountNumber,
            bankGiro: dto.bankGiro,
            plusGiro: dto.plusGiro,
            clearing: dto.clearing,
            iban: dto.iban,
            bicSwift: dto.bicSwift,
            grossAmount: Number(dto.grossAmount),
            originalGrossAmount: Number(dto.originalGrossAmount),
            originalNetAmount: Number(dto.originalNetAmount),
            originalVatAmount: Number(dto.originalVatAmount),
            reminderFee: Number(dto.reminderFee),
            supplierVatNumber: dto.supplierVatNumber,
            supplierAddress: dto.supplierAddress,
            supplierCountry: dto.supplierCountry,
            reverseConstruction: dto.reverseConstruction,
            momsNumber: dto.momsNumber,
            vatRate: dto.vatRate,
            paymentType: dto.paymentType,
            carVat: dto.carVat,
            creditNote: dto.creditNote,
            comment: dto.comment,
            booking: booking != null ? booking : null,
        });
        return response;
    },
    postSupplierInvoiceFiles: async formData => {
        const response = await Vue.axios.post(`documents/upload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    },
    updateSupplierInvoiceFile: async (formData, id) => {
        const response = await Vue.axios.put(`documents/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    },
    getDocuments: async (pageNumber, pageSize, search, sortBy, sortByType, status, currency, dateFrom, dateTo, clientId) => {
        const response = await Vue.axios.get(`documents/filtered`, {
            params: { pageNumber, pageSize, search, sortBy, sortType: sortByType, status, currency, startDate: dateFrom, endDate: dateTo, clientId },
        });
        return response;
    },
    getDocumentById: async id => {
        const response = await Vue.axios.get(`documents/${id}`);
        return response.data;
    },
    deleteDocument: async id => {
        const response = await Vue.axios.delete(`documents/${id}`);
        return response.data;
    },
    checkForDuplicates: async (clientId, invoiceNumber, ocrNumber) => {
        const response = await Vue.axios.get(`documents/duplicates`, {
            params: {
                clientId,
                invoiceNumber,
                ocrNumber,
            },
        });
        return response.data;
    },
    getSuppliersList: async search => {
        const response = await Vue.axios.get(`supplierinvoices/suppliersList`, {
            params: {
                search,
            },
        });
        return response.data;
    },
    getSuppliers: async supplier => {
        var response = await Vue.axios.post(`adminsupplierinvoice/supplierList`, supplier);
        return response.data;
    },
    createNewSupplier: async supplier => {
        var response = await Vue.axios.post(`adminsupplierinvoice/newSupplier`, supplier);
        return response.data;
    },
    updateSupplier: async supplier => {
        const supplierId = supplier.value;
        var response = await Vue.axios.put(`adminsupplierinvoice/supplier/${supplierId}`, supplier);
        return response.data;
    },
    postSupplierAccount: async searchAccountObj => {
        var response = await Vue.axios.post(`adminsupplierinvoice/postSupplierAccount`, searchAccountObj);
        return response.data;
    },
    updateSupplierDefaultAccounts: async (supplierId, defaultAccounts) => {
        var response = await Vue.axios.put(`adminsupplierinvoice/defaultAccounts/${supplierId}`, defaultAccounts);
        return response.data;
    },
    getCurrenciesAsync: async () => {
        const response = await Vue.axios.get(`adminsupplierinvoice/getCurrencies`);
        return response.data;
    },
    GetConsumableCreditorInvoices: async (clientId, creditorId, amount) => {
        var response = await Vue.axios.get(`supplierinvoices/consumable?clientId=${clientId}&creditorId=${creditorId}&amount=${amount}`);
        return response.data;
    },
    ConsumeCreditedInvoice: async (creditorInvoiceId, creditedInvoiceId) => {
        var response = await Vue.axios.post(`supplierinvoices/consume`, { creditorInvoiceId, creditedInvoiceId });
        return response.data;
    },
    GetNextDocument: async (id, lockedIds) => {
        console.log("lid from api", lockedIds);
        var response = await Vue.axios.get(`documents/${id}/next`, {
            params: { lockedIds },
            paramsSerializer: params => {
                return qs.stringify(params);
            },
        });
        return response.data;
    },
    RegisterBookingTime: async (documentId, enterTime, handlingTimeType, handlingTimeActionType) => {
        var response = await Vue.axios.post(`documents/${documentId}/time`, { enterTime, handlingTimeType, handlingTimeActionType });
        return response.data;
    },
    SendForSupplement: async (documentId, clientId, uploadedDocumentType, comment) => {
        var response = await Vue.axios.post(`documents/${documentId}/supplement`, { clientId, uploadedDocumentType, comment });
        return response;
    },
    getEvents: async documentId => {
        var response = await Vue.axios.get(`documents/${documentId}/events`);
        return response.data;
    },
    GetOpenFinancialYears: async clientId => {
        var response = await Vue.axios.get(`clients/${clientId}/financialyears`);
        return response.data;
    },
};
