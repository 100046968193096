<template>
    <div>
        <div class="card-tail">
            <TopBar @change="searchInputChange" />
        </div>
        <div class="flex w-full">
            <div class="card-tail">
                <SuppliersTable :key="componentKey" :table-data="suppliersData" @sortTypeChanged="syncDataWithQueryParameters" @setHead="setHead" @multipleSelection="handleMultipleSelection" />
                <Pagination ref="paginationComponent" @change="updateUrlAndGetAll" :total-amount="totalSuppliersNumber" :page-number-prop="pageNumber" />
            </div>
            <div class="card-tail ml-3 w-full h-1/2" v-if="supplierId != 0">
                <HeadSupplierForm @completed="updateUrlAndGetAll" :supplier-id="supplierId" :multiple-selection="multipleSelection" />
            </div>
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "./suppliers.api.js";

export default {
    data() {
        return {
            multipleSelection: [],
            suppliersData: [],
            totalSuppliersNumber: 0,
            pageNumber: 1,
            pageSize: 15,
            query: "",
            query2: "",
            sortBy: "",
            sortByType: "",
            supplierId: 0,
            componentKey: 0,
        };
    },

    components: {
        TopBar: () => import(/* webpackChunkName: "TopBar" */ "./components/TopBar.vue"),
        SuppliersTable: () => import(/* webpackChunkName: "TopBar" */ "./components/SuppliersTable.vue"),
        Pagination: () => import(/* webpackChunkName: "InvoicesPagination" */ "./components/Pagination.vue"),
        HeadSupplierForm: () => import(/* webpackChunkName: "InvoicesPagination" */ "./components/HeadSupplierForm.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Leverantörer");
        document.title = "Leverantörer";
    },

    created() {
        this.updateUrlAndGetAll();
    },

    methods: {
        forceRerender() {
            this.supplierId = 0;
            this.componentKey += 1;
            this.multipleSelection = [];
        },

        setHead(value) {
            this.supplierId = value;
        },

        handleMultipleSelection(value) {
            this.multipleSelection = value;
        },

        syncDataWithQueryParameters() {
            this.pageNumber = 1;
            setTimeout(() => {
                this.$refs.paginationComponent.updateUrl(+this.pageNumber);
                this.updateUrlAndGetAll();
            }, 200);
        },

        searchInputChange() {
            this.$refs.paginationComponent.updateUrl(1);
            this.updateUrlAndGetAll();
        },

        updateUrlAndGetAll() {
            this.forceRerender();
            const { pageNumber, pageSize, search, search2, sortBy, sortByType } = queryString.parse(window.location.search, { sort: false });
            this.query = search;
            this.query2 = search2;
            this.pageNumber = pageNumber || this.pageNumber;
            this.pageSize = pageSize || this.pageSize;
            this.sortBy = sortBy || "";
            this.sortByType = sortByType || "";
            this.getAll();
        },

        async getAll() {
            this.$waiting.start("loading");
            const response = await Api.getAll(this.pageNumber, this.pageSize, this.query, this.query2, this.sortBy, this.sortByType);
            this.suppliersData = response.data;
            this.totalSuppliersNumber = parseInt(response.headers["x-pagination-totalcount"]);
            this.$waiting.end("loading");
        },
    },
};
</script>
