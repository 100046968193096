import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import supplierInvoiceStore from "../pages/supplierInvoice/supplierInvoice.store.js";
import loginStore from "./login.js";
import topBarStore from "../components/topbar/topBar.store.js";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    plugins: [createPersistedState()],
    state: {
        sidebarCollapsed: false,
        topbarTitle: "",
        confirmationVisible: false,
        confirmationAxiosBody: "",
        confirmationAxiosBodyText: "",
        clientList: [],
        accountPlans: [],
        featureFlags: [],
    },
    mutations: {
        toggleSidebar(state) {
            localStorage.setItem("sidebarCollapsed", !state.sidebarCollapsed);
            state.sidebarCollapsed = !state.sidebarCollapsed;
        },
        setTopbarTitle(state, payload) {
            state.topbarTitle = payload;
        },
        showConfirmationModal(state, payload) {
            state.confirmationVisible = true;
            state.confirmationAxiosBody = payload.axiosBody;
            state.confirmationAxiosBodyText = payload?.bodyText;
        },
        closeConfirmationModal(state) {
            state.confirmationVisible = false;
            state.confirmationAxiosBody = "";
        },
        setClientList(state, value) {
            state.clientList = value;
        },
        clearClientList(state) {
            state.clientList = [];
        },
        setAccountPlans(state, value) {
            state.accountPlans = value;
        },
        setFeatureFlags(state, value){
            state.featureFlags = value;
        },
    },
    actions: {},
    modules: {
        supplierInvoice: supplierInvoiceStore,
        login: loginStore,
        topBar: topBarStore,
    },
});
