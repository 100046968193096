import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookie from "vue-cookie";
import Auth from "./auth.js";
import VeeValidate from "vee-validate";
import Toasted from "vue-toasted";
import VueWaiting from "vue-waiting";
import "./dialog/dialog.js";
import "./components/drawer/drawer.js";
import "./plugins/elementui.js";
import "./plugins/axios.js";
import "./assets/tailwind.css";
// import "./assets/tailwind_prod.css";
import "./featureFlag.js";

require("default-passive-events");
require("../src/filters/SwedishNumberFormat.js");
require("./prototypes.js");
require("./assets/main.scss");

Vue.config.productionTip = false;
Vue.use(Auth);
Vue.use(VueCookie);
Vue.use(VueWaiting);
Vue.use(VeeValidate, {
    validity: true,
    fieldsBagName: "fieldVee",
});
Vue.use(Toasted);

new Vue({
    router,
    store,
    waiting: new VueWaiting(),
    render: h => h(App),
}).$mount("#app");
