<template>
    <div class="card-tail card-f-h">
        <div>
            <div class="tabs is-boxed" v-bind:class="{ disabledbutton: isLoading }" style="margin-top: 20px">
                <li v-bind:key="key" v-for="(tab, key) in tabs" :class="{ 'is-active': tab.isActive }">
                    <div style="display: flex; flex-direction: row">
                        <span>
                            <a @click="selectTab(tab)">
                                <TextBase size="medium" weight="bold" color="black">{{ tab.name }}</TextBase>
                                <span v-if="isLoading && tab.isActive">
                                    <button class="button is-loading is-white is-small" />
                                </span>
                            </a>
                        </span>
                    </div>
                </li>
            </div>
        </div>
        <div style="margin-top: 35px">
            <component v-bind:is="currentTabComponent" :onboard-client-id="informationProp" :company-info="companyInfo" :banks="banks" />
        </div>
    </div>
</template>
<script>
import Api from "../onboarding.api";
import companyInformation from "./companyInformation.vue";
import operations from "./operations.vue";
import ownerInformation from "./ownerInformation.vue";
import TextBase from "../../../components/content/Text.vue";
export default {
    data: function() {
        return {
            companyInfo: {},
            isLoading: false,
            informationProp: undefined,
            banks: [],
            clientDetails: undefined,
            tabs: [
                { name: "Företagsinfo", isActive: true, value: "CompanyInformation" },
                { name: "Verksamhet", isActive: false, value: "Operation" },
                { name: "Verklighuvudman", isActive: false, value: "OwnerInformation" },
                // { name: "Noteringar", isActive: false, value: "Notes" }
            ],
            selectedTab: {},
        };
    },
    components: {
        companyInformation,
        ownerInformation,
        operations,
        TextBase,
    },
    async created() {
        var onbaordClientId = this.$route.query.onboardingId;
        this.companyInfo = await Api.getCompanyInfo(onbaordClientId);
        const isStockCompany = this.companyInfo.onboardingClients.companyType == 5 ? true : false;
        this.banks = await Api.getBanks(isStockCompany);
        document.title = "Onboarding - " + this.companyInfo.onboardingClients.companyName;
        this.informationProp = onbaordClientId;
    },
    methods: {
        toggleNewVersion() {
            this.newVersion = !this.newVersion;
        },
        checkloading(value) {
            if (value != undefined) {
                this.isLoading = false;
            }
        },
        selectTab(selectedTab) {
            if (selectedTab != undefined) {
                this.tabs.forEach(tab => {
                    tab.isActive = tab.value == selectedTab.value;
                });
                this.selectedTab = selectedTab;
            }
        },
    },
    computed: {
        currentTabComponent() {
            switch (this.selectedTab.value) {
                case "OwnerInformation":
                    return "ownerInformation";
                case "CompanyInformation":
                    return "companyInformation";
                case "Operation":
                    return "operations";
                // case "Notes":
                //   return "notes";
                default:
                    return "companyInformation";
            }
        },
    },
};
</script>
<style scoped>
.tabs {
    justify-content: left;
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}
.page-header {
    font-size: 35px;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    margin-top: 20px;
}
</style>
