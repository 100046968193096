import Suppliers from "./Suppliers.vue";
const routes = [
    {
        path: "/suppliers",
        name: "suppliers",
        component: Suppliers,
        meta: {
            title: "Leverantörer",
            forAuth: true,
        },
    },
];

export default routes;
