import Login from "./login";

const routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            forAuth: false,
        },
    },
];
export default routes;
